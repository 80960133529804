import { IValidation } from "../../../Utils/validatePassword";
import * as React from "react";
import styles from "./PasswordValidation.module.scss";
import cn from "classnames";

interface IProps {
	className?: string;
	validation: IValidation;
}

const Item = ({ label, valid }: {
	label: string;
	valid: boolean;
}) => (
		<dl>
			<dt>{label}</dt>
			<dd>
				<i className={cn("fa fa-lg", {
					"fa-check text-success": valid,
					"fa-times text-danger": !valid
				})} />
			</dd>
		</dl>
	);

const PasswordValidation = ({ className, validation }: IProps) => (
	<div className={className}>
		<div>Your password must meet the following criteria: </div>
		<div className={styles.displayRow}>
			<Item
				label="Is password 10 characters long?"
				valid={validation.length}
			/>
			<Item
				label="Does password contain special characters?"
				valid={validation.special}
			/>
			<Item
				label="Does password contain numbers?"
				valid={validation.number}
			/>
			<Item
				label="Does password contain uppercase characters?"
				valid={validation.upper}
			/>
			<Item
				label="Does password contain lowercase characters?"
				valid={validation.lower}
			/>
			<Item
				label="Does password match confirm password?"
				valid={validation.confirm}
			/>
		</div>
	</div>
);

export default PasswordValidation;