import * as Dto from "../Models/dto";
import getAccountStatuses from "./getAccountStatuses";
import getWorkflowStepsById from "./getWorkflowStepsById";
import { createSelector } from "reselect";

export default createSelector(
	getWorkflowStepsById,
	getAccountStatuses,
	(workflowStepsById, accountStatuses) => {
		const map: { [workflowStepId: number]: { [acctStatusId: number]: Dto.IAccountStatus; }; } = {};

		for (const workflowStepId of Object.keys(workflowStepsById).map(Number)) {
			map[workflowStepId] = {};
		}

		for (const accountStatus of accountStatuses) {
			const { accountWorkflowStepId } = accountStatus;
			const group = map[accountWorkflowStepId] || (map[accountWorkflowStepId] = {});
			group[accountStatus.acctStatusId] = accountStatus;
		}

		return map;
	}
);