enum AccountStatuses {
	OpenActiveAccount = 10,
	DodLocated = 20,
	DodFoundTooOld = 21,
	EstateLocated = 30,
	EstateMatchVerified = 31,
	EstateMatchRejected = 32,
	EstateIncomplete = 33,
	EstateExpired = 34,
	EstateFound = 35,
	EstateClosed = 36,
	EstateFoundApproved = 37, // Aprove
	LineOfBusinessApproved = 371,
	LowBalanceHold = 38,
	EstateFoundInvalidLicensure = 39,
	ClaimGenerated = 40,
	ClaimFiled = 41,
	ClaimVoided = 42,
	ClaimMailed = 43,
	ClaimValidatedByClient = 44,
	ValidationFailed = 45,
	ClaimSigned = 46,
	ClaimNotarized = 47,
	ClaimMailingSigned = 48,
	ClaimMailingNotarized = 49,
	ClientRecalled = 50, // Recall
	LowBalance = 51,
	Bankrupt = 52,
	StatueOfLimitation = 53,
	ProcessingTimeExpired = 54,
	AccountSettled = 55,
	EffortsExhausted = 56,
	ClaimStricken = 57,
	PlacedForCollections = 58,
	MinorAccount = 59,
	ProbatePredictorKeep = 70,
	ProbatePredictorReject = 75,
	ClaimDisputed = 80,
	ClaimMailedForced = 81,
	StatementsRequired = 85,
	StatementsRequiredAwaitingFeedback = 86,
	ClaimReturnedAwaitingDocs = 90,
	ClaimReturnedProcessing = 91,
	WithdrawRequested = 100,
	ClaimAmended = 92,
	AccountPurged = 999,
	NewRecord = 1001,
	NewRecallRecord = 1002,
	NewReconciliationRecord = 1004,
	UpdateAccount = 1010,
	UpdateAccountNeedsReview = 1015,
	UpdateAccountNeedsReviewRecallReopened = 1016,
	UpdateAccountProcessed = 1019,
	NewAccount = 1020,
	NewAccountNeedsReview = 1025,
	NewAccountProcessed = 1029, // Place
	RecallAccount = 1050,
	RecallAccountNeedsReview = 1054,
	NotRecallable = 1055,
	NotRecallableAccountNotFound = 1056,
	RecallAccountProcessed = 1059,
	DeletedRecord = 1090 // Delete
}

export default AccountStatuses;