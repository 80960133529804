import * as React from "react";
import cn from "classnames";
import IToast from "../IToast";
import styles from "./Toast.module.scss";

interface IProps {
	hiding: boolean;
	toast: IToast;
	onDismiss(id: string): void;
	onHidden(id: string): void;
}

interface IState {
	initial: boolean;
}

export default class Toast extends React.Component<IProps, IState> {
	private _timeoutHandle: number | undefined;
	private _initialHandle: number | undefined;

	constructor(props: IProps, context: any) {
		super(props, context);

		if (__DEV__ && props.hiding) {
			console.error(`[${__filename}] props.${nameof(props.hiding)} must be initially false`);
		}

		this.state = { initial: true };

		this._handleDismiss = this._handleDismiss.bind(this);
		this._handleMouseOver = this._handleMouseOver.bind(this);
		this._handleMouseOut = this._handleMouseOut.bind(this);
	}

	componentDidMount() {
		this._startTimeout();
		this._initialHandle = setTimeout(() => this.setState({ initial: false }));
	}

	componentDidUpdate(prevProps: IProps) {
		if (prevProps.hiding !== this.props.hiding) {
			if (this.props.hiding) {
				setTimeout(() => this.props.onHidden(this.props.toast.id), 750);
			} else if (__DEV__) {
				console.error(`[${__filename}] props.${nameof(this.props.hiding)} can only change from false to true`);
			}
		}
	}

	componentWillUnmount() {
		clearTimeout(this._timeoutHandle);
		clearTimeout(this._initialHandle);
	}

	render() {
		const { props, state } = this;

		return (
			<div
				className={cn(
					`toast toast-${props.toast.type}`,
					state.initial ? styles.initial
						: props.hiding ? styles.flipOutX
							: styles.flipInX
				)}
				onClick={this._handleDismiss}
				onMouseEnter={this._handleMouseOver}
				onMouseLeave={this._handleMouseOut}
			>
				<div className="toast-message">
					{props.toast.message}
				</div>
			</div>
		);
	}

	private _startTimeout() {
		const { timeout } = this.props.toast;
		if (timeout != null && !this.props.hiding) {
			this._timeoutHandle = setTimeout(this._handleDismiss, timeout) as any;
		}
	}

	private _handleDismiss() {
		clearTimeout(this._timeoutHandle);
		if (!this.props.hiding) {
			this.props.onDismiss(this.props.toast.id);
		}
	}

	private _handleMouseOver() {
		clearTimeout(this._timeoutHandle);
	}

	private _handleMouseOut() {
		if (!this.props.hiding) {
			this._startTimeout();
		}
	}
}
