import isAuthenticated from "./selectors/isAuthenticated";
import isCurrentTabLoaded from "./selectors/isCurrentTabLoaded";
import BusyOverlayLegacyConnector from "./Components/Common/BusyOverlay/LegacyConnector/LegacyConnector";
import Loader from "./Components/Common/Loader/Loader";
import connect from "./Utils/connect";
import isEmpty from "./Utils/isEmpty";
import { getUserAuthenticated, lookupToken } from "./ActionCreators/Api/loginActionCreators";
import React from "react";
import Dashboard from "./Components/Dashboard/Dashboard";
import ChangePassword from "./Components/Password/ChangePassword";
import SetPassword from "./Components/Password/SetPassword";
import SetSecurityQuestion from "./Components/SecurityQuestion/SetSecurityQuestion";
import GetSecurityAnswer from "./Components/SecurityQuestion/GetSecurityAnswer";
import LockView from "./Components/LockView/LockView";
import SsoLockView from "./Components/LockView/SsoLockView";
import Login from "./Components/Login/Login";
import BusyOverlay from "./Components/Common/BusyOverlay/BusyOverlay";
import InvalidResetTokenModal from "./Components/Auth/InvalidResetTokenModal/InvalidResetTokenModal";
import PasswordResetSuccessModal from "./Components/Auth/PasswordResetSuccessModal/PasswordResetSuccessModal";
import styles from "./App.module.scss";
import ToastEmitter from "./Components/Common/ToastEmitter/ToastEmitter";
import NotificationBar from "./Components/NotificationBar/NotificationBar";
import UpdateModal from "./Components/Common/UpdateModal";
import Registration from "./Components/Registration/Registration";
import * as storage from "./Utils/storage";
import { appName } from "./Utils/branding";
import { getPreStartupData } from "./ActionCreators/Api/startupActionCreators";
import { OK } from "./Models/apiConstants";
import handleGenericError from "./Utils/handleGenericError";
import { postAuth } from "./ActionCreators/LoginActionCreators";
import isLoadingCurrentUser from "./selectors/isLoadingCurrentUser";

enum AppState {
	Initialize,
	CreatePassword,
	ValidateSecurityAnswer,
	Locked,
	SsoLocked,
	SsoLoggedOut,
	ChangePassword,
	CreateSecurityQuestion,
	Login,
	Dashboard
}

interface IOwnProps {
	register: any;
	location: {
		query: {
			t?: string | undefined,
			login?: string;
			""?: string | string[];
		} | undefined;
	};
}

interface IConnectedProps {
	appState: AppState;
	loggingOut: boolean;
	showInvalidResetTokenModal: boolean;
	showPasswordResetSuccessModal: boolean;
	onInitialize(resetToken: string | null): void;
	onInvalidResetTokenModalHide(): void;
	onPasswordResetSuccessModalHide(): void;
}

interface IProps extends IOwnProps, IConnectedProps { }

class App extends React.PureComponent<IProps> {
	render(): React.ReactElement<{}> {
		const { props } = this;

		let content;
		let locked = false;

		if (props.register.isRegister) {
			content = <Registration registrationId={props.register.registrationId} />;
		} else {
			switch (props.appState) {
				case AppState.Initialize:
					content = <div key="progress" className="progress"><Loader /></div>;
					break;
				case AppState.CreatePassword:
					content = <SetPassword />;
					break;
				case AppState.ValidateSecurityAnswer:
					content = <GetSecurityAnswer />;
					break;
				case AppState.Locked:
					content = <div key="lockedApplication"><LockView /></div>;
					locked = true;
					break;
				case AppState.SsoLocked:
					content = (
						<SsoLockView>
							You have been logged out of your session due to inactivity.
						</SsoLockView>
					);
					break;
				case AppState.SsoLoggedOut:
					content = (
						<SsoLockView>
							You are currently logged out of {appName}.
						</SsoLockView>
					);
					break;
				case AppState.ChangePassword:
					content = <ChangePassword />;
					break;
				case AppState.CreateSecurityQuestion:
					content = <SetSecurityQuestion />;
					break;
				case AppState.Login:
					content = <Login />;
					break;
				case AppState.Dashboard:
					content = (
						<div
							key="dashboard"
							className={styles.dashboard}
						>
							<NotificationBar />
							{/* <div className={styles.headerContainer}>
								<Header />
							</div> */}
							<Dashboard />
							{/* <Footer /> */}
						</div>
					);
					break;
				default:
					if (__DEV__) {
						console.error(`$[${__filename}] Invalid ${nameof(props.appState)}: ${JSON.stringify(this.props.appState)}`);
					}
					break;
			}
		}

		return (
			<BusyOverlay.Provider locked={locked}>
				<div>
					{content}
					<InvalidResetTokenModal
						show={props.showInvalidResetTokenModal}
						onHide={props.onInvalidResetTokenModalHide}
					/>
					<PasswordResetSuccessModal
						show={props.showPasswordResetSuccessModal}
						onHide={props.onPasswordResetSuccessModalHide}
					/>
					<UpdateModal />
					<BusyOverlay message={props.loggingOut ? "Logging out..." : null} />
					<BusyOverlayLegacyConnector locked={locked} />
					<ToastEmitter />
				</div>
			</BusyOverlay.Provider>
		);
	}

	componentDidMount(): void {
		const { location: { query }, onInitialize } = this.props;
		const resetToken = (query && query.t) || null;
		onInitialize(resetToken);
	}
}

const checkIfUserIsAuthenticated = async () => {
	let response;
	try {
		response = await getUserAuthenticated();
	} catch (_) { }

	if (response?.status === OK) {
		await postAuth();
	}
}

const onInitialize = async (resetToken: string | null) => {
	try {
		if (!isEmpty(resetToken)) {
			await lookupToken(resetToken!);
		} else {
			await Promise.all([
				checkIfUserIsAuthenticated(),
				getPreStartupData()
			]);
		}
	} catch (error) {
		handleGenericError(error);
	}
};

const redirectToAppWithoutResetToken = () => {
	window.location.href = window.location.href.split("?")[0];
};

export default connect<IConnectedProps, IOwnProps>(
	App,
	(state, ownProps) => {
		const { auth } = state;

		const authenticated = isAuthenticated(state);
		const tabLoaded = isCurrentTabLoaded(state);
		const isSso = auth.loginUser?.isSingleSignOn ?? false;
		const loadingCurrentUser = isLoadingCurrentUser(state);
		const isPasswordReset = !isEmpty(auth.loginUser.resetToken);

		const ready = auth.ready && (isPasswordReset || auth.authenticated || auth.preStartupData != null);

		let appState: AppState;
		if (!ready || (auth.alreadyLoggedIn && (!tabLoaded || loadingCurrentUser))) {
			appState = AppState.Initialize;
		} else if (isPasswordReset) {
			appState = auth.securityAnswerValidated
				? AppState.CreatePassword
				: AppState.ValidateSecurityAnswer;
		} else if (auth.passwordChangeReason != null) {
			appState = AppState.ChangePassword;
		} else if (auth.locked && !isSso) {
			appState = AppState.Locked;
		} else if (auth.locked && isSso) {
			appState = AppState.SsoLocked;
		} else if (authenticated && auth.needsSecurityQuestion) {
			appState = AppState.CreateSecurityQuestion;
		} else if (authenticated && tabLoaded && !loadingCurrentUser) {
			appState = AppState.Dashboard;
		} else if (process.env.REACT_APP_APPLICATION === "access" && auth.preStartupData?.singleSignOn) {
			const adminLogin = (ownProps.location.query != null && "login" in ownProps.location.query)
				|| storage.get<boolean>("PF_Admin_Login_580fe3bc");
			appState = adminLogin ? AppState.Login : AppState.SsoLoggedOut;
		} else {
			appState = AppState.Login;
		}

		return {
			appState,
			loggingOut: auth.loggingOut && !auth.locked,
			showInvalidResetTokenModal: auth.resetTokenInvalid,
			showPasswordResetSuccessModal: auth.resetTokenSuccess,
			onInitialize,
			onInvalidResetTokenModalHide: redirectToAppWithoutResetToken,
			onPasswordResetSuccessModalHide: redirectToAppWithoutResetToken
		};
	}
);