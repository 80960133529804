/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IBaseResponse,
	ILoginUser,
	IResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IChangePasswordActionParams {
	user: ILoginUser;
	captchaResponse: string;
	captchaChallenge: string;
}

export class ChangePasswordAction extends ApiActionBase<IChangePasswordActionParams, IResponse<ILoginUser>> {
	constructor(details: IDetails<IChangePasswordActionParams, IResponse<ILoginUser>>) {
		super("Api/LoginActions/ChangePasswordAction", details);
	}
}

export interface IClientLogoutActionParams {
	/* no parameters */
}

export class ClientLogoutAction extends ApiActionBase<IClientLogoutActionParams, IResponse<boolean>> {
	constructor(details: IDetails<IClientLogoutActionParams, IResponse<boolean>>) {
		super("Api/LoginActions/ClientLogoutAction", details);
	}
}

export interface ICreateResetTokenActionParams {
	userName: string;
	captchaResponse: string;
	captchaChallenge: string;
}

export class CreateResetTokenAction extends ApiActionBase<ICreateResetTokenActionParams, IBaseResponse> {
	constructor(details: IDetails<ICreateResetTokenActionParams, IBaseResponse>) {
		super("Api/LoginActions/CreateResetTokenAction", details);
	}
}

export interface IDoLoginActionParams {
	user: ILoginUser;
	captchaResponse: string;
	captchaChallenge: string;
}

export class DoLoginAction extends ApiActionBase<IDoLoginActionParams, IResponse<ILoginUser>> {
	constructor(details: IDetails<IDoLoginActionParams, IResponse<ILoginUser>>) {
		super("Api/LoginActions/DoLoginAction", details);
	}
}

export interface IGetSecurityQuestionActionParams {
	user: ILoginUser;
	captchaResponse: string;
	captchaChallenge: string;
}

export class GetSecurityQuestionAction extends ApiActionBase<IGetSecurityQuestionActionParams, IResponse<ILoginUser>> {
	constructor(details: IDetails<IGetSecurityQuestionActionParams, IResponse<ILoginUser>>) {
		super("Api/LoginActions/GetSecurityQuestionAction", details);
	}
}

export interface IGetUserAuthenticatedActionParams {
	/* no parameters */
}

export class GetUserAuthenticatedAction extends ApiActionBase<IGetUserAuthenticatedActionParams, IResponse<ILoginUser>> {
	constructor(details: IDetails<IGetUserAuthenticatedActionParams, IResponse<ILoginUser>>) {
		super("Api/LoginActions/GetUserAuthenticatedAction", details);
	}
}

export interface IKeepAliveActionParams {
	/* no parameters */
}

export class KeepAliveAction extends ApiActionBase<IKeepAliveActionParams, IBaseResponse> {
	constructor(details: IDetails<IKeepAliveActionParams, IBaseResponse>) {
		super("Api/LoginActions/KeepAliveAction", details);
	}
}

export interface ILookupTokenActionParams {
	token: string;
}

export class LookupTokenAction extends ApiActionBase<ILookupTokenActionParams, IResponse<ILoginUser>> {
	constructor(details: IDetails<ILookupTokenActionParams, IResponse<ILoginUser>>) {
		super("Api/LoginActions/LookupTokenAction", details);
	}
}

export interface ISaveSecurityQuestionAnswerActionParams {
	user: ILoginUser;
	captchaResponse: string;
	captchaChallenge: string;
}

export class SaveSecurityQuestionAnswerAction extends ApiActionBase<ISaveSecurityQuestionAnswerActionParams, IResponse<ILoginUser>> {
	constructor(details: IDetails<ISaveSecurityQuestionAnswerActionParams, IResponse<ILoginUser>>) {
		super("Api/LoginActions/SaveSecurityQuestionAnswerAction", details);
	}
}

export interface IValidateSecurityAnswerActionParams {
	user: ILoginUser;
	captchaResponse: string;
	captchaChallenge: string;
}

export class ValidateSecurityAnswerAction extends ApiActionBase<IValidateSecurityAnswerActionParams, IResponse<ILoginUser>> {
	constructor(details: IDetails<IValidateSecurityAnswerActionParams, IResponse<ILoginUser>>) {
		super("Api/LoginActions/ValidateSecurityAnswerAction", details);
	}
}

/* tslint:enable:max-line-length */