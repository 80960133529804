import React, { ReactElement } from "react";
import DraggableModal from "../Common/DraggableModal/DraggableModal";
import { Modal } from "react-bootstrap";
import connect from "../../Utils/connect";
import { appName } from "../../Utils/branding";

interface IProps {
	show: boolean;
}

class UpdateModal extends React.Component<IProps> {
	render(): ReactElement<{}> {
		return (
			<DraggableModal
				bsSize="large"
				show={this.props.show}
				onHide={() => null}
			>
				<Modal.Header closeButton>
					<Modal.Title>Refresh Required</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						A new version of {appName} has been released.
						Please refresh your browser.
					</p>
				</Modal.Body>
			</DraggableModal>
		);
	}
}

export default connect<IProps, {}>(UpdateModal, state => ({ show: state.auth.needsUpdate }));