import formatZip from "../../Utils/formatZip";
import FormattingInput, { IProps as _IProps } from "./FormattingInput";

export { zip as validZip } from "../../Utils/validate";
export type IProps = _IProps;

export default class ZipCodeInput extends FormattingInput {
	static defaultProps = {
		maxLength: 9,
		placeholder: "#####-####"
	};

	protected allowKey(key: string) {
		return /\d/.test(key);
	}

	protected formatValue(rawValue: string) {
		return formatZip(rawValue);
	}
}