import { passwordChangeSuccessMessage } from "../../../ActionCreators/LoginActionCreators";
import pure from "../../../Utils/pure";
import * as React from "react";
import AuthModal from "../AuthModal/AuthModal";

interface IProps {
	show: boolean;
	onHide(): void;
}

const PasswordResetSuccessModal = (props: IProps) => (
	<AuthModal {...props}>
		{passwordChangeSuccessMessage}
	</AuthModal>
);

export default pure(PasswordResetSuccessModal);