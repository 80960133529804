import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import {
	GetClientProfilePricingHistoriesAction,
	SaveClientProfileAction
} from "../../Actions/Api/clientActions";
import getLoadState from "../../Utils/getLoadState";

export interface IState {
	history: Dto.IClientProfilePricingHistory[];
	loading: AsyncState | null;
}

const initialState: IState = {
	history: [],
	loading: null
};

export default function pricingHistoryReducer(
	previousState = initialState,
	action: IAction
): IState {
	if (action instanceof GetClientProfilePricingHistoriesAction) {
		let loading = getLoadState(action);
		let { history } = previousState;

		if (loading === AsyncState.Resolved) {
			history = action.response!.clientProfilePricingHistories;
		}

		return Object.assign({}, previousState, {
			loading,
			history
		});
	}

	if (action instanceof SaveClientProfileAction && getLoadState(action) === AsyncState.Resolved) {
		// saving the client profile potentially updates the pricing so invalidate
		return initialState;
	}

	return previousState;
}