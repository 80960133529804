import * as React from "react";
import YesNoQuestion from "../../../../YesNoQuestion/YesNoQuestion";
import pure from "../../../../../../../Utils/pure";
import * as Help from "../Help";
import * as Dto from "../../../../../../../Models/dto";
import { appName } from "../../../../../../../Utils/branding";
import ClientProfileProducts from "../../../../../../../Models/ClientProfileProducts";
import HelpContent from "../../../../../../Common/HelpContent/HelpContent";

export interface IValidation {
	valid: boolean;
	agencyFlag: boolean;
	medicalFlag: boolean;
	hospitalFlag: boolean | null;
	disclosureFlag: boolean | null;
}

export interface IMedicalFlags {
	agencyFlag: boolean | null;
	medicalFlag: boolean | null;
	hospitalFlag: boolean | null;
	initialDisclosureFlag: boolean | null;
	showInitialDisclosureFlag: boolean;
	clientProfileProductId: number;
}

interface IProps {
	validation: IValidation;
	flags: IMedicalFlags;
	onChange(flags: IMedicalFlags): void;
}

export function getMedicalFlags(clientProfile: Dto.IClientProfile): IMedicalFlags {
	return {
		agencyFlag: clientProfile.agencyFlag,
		medicalFlag: clientProfile.medicalFlag,
		hospitalFlag: clientProfile.hospitalFlag,
		initialDisclosureFlag: clientProfile.initialDisclosureFlag,
		showInitialDisclosureFlag: clientProfile.showInitialDisclosureFlag,
		clientProfileProductId: clientProfile.clientProfileProductId
	};
}

export function validate(flags: IMedicalFlags): IValidation {
	const agencyFlag = flags.agencyFlag != null;
	const medicalFlag = flags.medicalFlag != null;
	const hospitalFlag = showHospitalFlagQuestion(flags) ? flags.hospitalFlag != null : null;
	const disclosureFlag = showDisclosureFlagQuestion(flags) ? flags.initialDisclosureFlag != null : null;

	return {
		valid: agencyFlag && medicalFlag && (hospitalFlag === null || hospitalFlag) && (disclosureFlag == null || disclosureFlag),
		agencyFlag,
		medicalFlag,
		hospitalFlag,
		disclosureFlag
	};
}

function showHospitalFlagQuestion({ agencyFlag, medicalFlag }: { agencyFlag: boolean | null, medicalFlag: boolean | null }) {
	return !agencyFlag && medicalFlag;
}

function showDisclosureFlagQuestion(flags: IMedicalFlags) {
	return flags.clientProfileProductId !== ClientProfileProducts.EstateMatchDataOnlyDodFinder
		&& flags.agencyFlag === true
		&& flags.showInitialDisclosureFlag;
}

function handleAgencyFlagChange(agencyFlag: boolean, props: IProps) {
	props.onChange({
		...props.flags,
		agencyFlag: agencyFlag,
		medicalFlag: null
	});
}

function handleMedicalFlagChange(medicalFlag: boolean, props: IProps) {
	// force new selection if question is visible, set to false if question is hidden
	const { agencyFlag } = props.flags;
	const hospitalFlag = showHospitalFlagQuestion({ agencyFlag, medicalFlag }) ? null : false;
	props.onChange({ ...props.flags, medicalFlag, hospitalFlag });
}

const Label = (props: {
	text: string;
	help: React.ReactNode;
}) => (
		<HelpContent help={props.help}>
			{props.text}
		</HelpContent>
	);

export const AgencyMedicalQuestions = (props: IProps) => {
	const { flags, validation, onChange } = props;

	const medicalLabel = flags.agencyFlag
		? `Will you use ${appName} to collect or service any medical debt?`
		: "Are you a healthcare provider?";

	return (
		<div>
			<YesNoQuestion
				key="agencyFlag"
				label={(
					<Label
						text="Are you a 3rd party debt collector, debt buyer, or collections law firm?"
						help={<Help.AgencyFlag />}
					/>
				)}
				value={flags.agencyFlag}
				hasError={!validation.agencyFlag}
				onChange={it => handleAgencyFlagChange(it, props)}
				id="agencyFlagQuestion"
			/>

			<YesNoQuestion
				key="medicalFlag"
				label={(
					<Label
						text={medicalLabel}
						help={<Help.MedicalFlag agencyFlag={!!flags.agencyFlag} />}
					/>
				)}
				value={flags.medicalFlag}
				hasError={!validation.medicalFlag}
				onChange={it => handleMedicalFlagChange(it, props)}
				id="medicalFlagQuestion"
			/>

			{showHospitalFlagQuestion(flags) && (
				<YesNoQuestion
					key="hospitalFlag"
					label={(
						<Label
							text="Are you, or do you collect on behalf of, a hospital or health system? "
							help={<Help.HospitalFlag />}
						/>
					)}
					value={flags.hospitalFlag}
					hasError={!validation.hospitalFlag}
					onChange={it => onChange({ ...flags, hospitalFlag: it })}
					id="hospitalFlagQuestion"
				/>
			)}

			{showDisclosureFlagQuestion(flags) && (
				<YesNoQuestion
					label={`Does ${appName} need to include, in your Claim Packages, initial disclosures pursuant to the Fair Debt Collection Practices Act or other Consumer Protection laws?`}
					value={flags.initialDisclosureFlag}
					hasError={!validation.disclosureFlag}
					onChange={disclosureFlag => onChange({ ...flags, initialDisclosureFlag: disclosureFlag })}
				/>
			)}
		</div>
	);
};

export default pure(AgencyMedicalQuestions);