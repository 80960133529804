/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	SaveFeedbackAction,
	ISaveFeedbackActionParams
} from "../../Actions/Api/feedbackActions";
import {
	IBaseResponse,
	IFeedback
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const saveFeedbackEndpoint = "Api/Feedback/SaveFeedback";

export function saveFeedback(
	feedback: IFeedback, 
	__options__?: IOptions<IBaseResponse>
) {
	return apiExecute<ISaveFeedbackActionParams, SaveFeedbackAction, IBaseResponse>(
		saveFeedbackEndpoint, 
		true, 
		__options__, 
		SaveFeedbackAction,
		{ feedback }
	);
}

/* tslint:enable:max-line-length */