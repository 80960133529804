import * as React from "react";
import uniqueRandom from "./uniqueRandom";
import renderNonVisibleComponent from "./renderNonVisibleComponent";
import getDisplayName from "./getDisplayName";
import withoutProperties from "./withoutProperties";
import propTypes from "prop-types";

interface IContextStore<TState> {
	getState(): TState;
	subscribe(callback: Callback): Callback;
	update(props: TState): void;
}

type Callback = () => void;

function createStore<TState>(state: TState): IContextStore<TState> {
	let storeState = state;
	let subscriptions: Callback[] = [];

	return {
		getState: () => storeState,
		subscribe: (callback: Callback) => {
			subscriptions = [...subscriptions, callback];
			return () => {
				subscriptions = subscriptions.filter(it => it !== callback);
			};
		},
		update: (state) => {
			storeState = state;
			for (const subscription of subscriptions) {
				subscription();
			}
		}
	};
}

function withoutChildren<TProps>(props: TProps & { children?: React.ReactNode }): TProps {
	return withoutProperties(props, nameof(props.children));
}

export default function createContextComponent<TProps>(name: string) {
	const key = `${name}_${uniqueRandom()}`;
	const types = {
		[key]: propTypes.any
	};

	class Context extends React.PureComponent<TProps> {
		static childContextTypes = types;
		static displayName = name;

		private _store = createStore(withoutChildren(this.props));

		getChildContext() {
			return {
				[key]: this._store
			};
		}

		componentDidUpdate() {
			this._store.update(withoutChildren(this.props));
		}

		render() {
			return renderNonVisibleComponent(this.props.children);
		}
	}

	function connectToContext<TOtherProps>(Component: React.StatelessComponent<TProps & TOtherProps>): React.ComponentClass<TOtherProps>;
	function connectToContext<TOtherProps>(Component: React.ComponentClass<TProps & TOtherProps>): React.ComponentClass<TOtherProps>;
	function connectToContext<TOtherProps>(Component: any): React.ComponentClass<TOtherProps> {
		class Container extends React.Component<TOtherProps, TProps> {
			static contextTypes = types;
			static displayName = `${name}Connector(${getDisplayName(Component)})}`;

			private _unsubscribe: Callback | undefined;

			UNSAFE_componentWillMount() {
				const store = this.context[key] as IContextStore<TProps>;
				if (store != null) {
					const callback = () => this.setState(store.getState());
					this._unsubscribe = store.subscribe(callback);
					callback();
				}
			}

			componentWillUnmount() {
				if (this._unsubscribe != null) {
					this._unsubscribe();
				}
			}

			render() {
				// prevent keys with undefined values in props from overwriting defined values in state.  
				// e.g., state: { foo: "from-context" }, props: { foo: undefined }
				const props: TProps = Object.assign({}, this.state);
				for (const key of Object.keys(this.props)) {
					const value = (this.props as any)[key];
					if (value !== undefined) {
						(props as any)[key] = value;
					}
				}

				return <Component {...props} />;
			}
		}

		return Container;
	}

	return {
		Component: Context,
		connectToContext
	};
}