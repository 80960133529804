import * as Dto from "../Models/dto";
import getAccountImportStatuses from "./getAccountImportStatuses";
import getWorkflowStepsById from "./getWorkflowStepsById";
import { createSelector } from "reselect";

export default createSelector(
	getWorkflowStepsById,
	getAccountImportStatuses,
	(workflowStepsById, accountImportStatuses) => {
		const map: { [workflowStepId: number]: { [acctStatusId: number]: Dto.IAccountImportStatus; }; } = {};
		
		for (const workflowStepId of Object.keys(workflowStepsById).map(Number)) {
			map[workflowStepId] = {};
		}
		
		for (const accountStatus of accountImportStatuses) {
			const { accountWorkflowStepId } = accountStatus;
			const group = map[accountWorkflowStepId] || (map[accountWorkflowStepId] = {});
			group[accountStatus.acctImportStatusId] = accountStatus;
		}

		return map;
	}
);