/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IAccountImport,
	IAccountImportFile,
	IAccountsResponse,
	IBaseResponse,
	ICollectionResponse,
	IResponse,
	IUploadSample
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IAddAccountImportsActionParams {
	accountImports: IAccountImport[];
}

export class AddAccountImportsAction extends ApiActionBase<IAddAccountImportsActionParams, ICollectionResponse<IAccountImport>> {
	constructor(details: IDetails<IAddAccountImportsActionParams, ICollectionResponse<IAccountImport>>) {
		super("Api/AccountImportActions/AddAccountImportsAction", details);
	}
}

export interface IDeleteAccountImportActionParams {
	accountImport: IAccountImport;
}

export class DeleteAccountImportAction extends ApiActionBase<IDeleteAccountImportActionParams, IResponse<IAccountImport>> {
	constructor(details: IDetails<IDeleteAccountImportActionParams, IResponse<IAccountImport>>) {
		super("Api/AccountImportActions/DeleteAccountImportAction", details);
	}
}

export interface IDeleteAllAccountImportsActionParams {
	accountWorkflowStatusID: number;
	expectedCount: number;
	clientIDsForDelete: number[];
	statusIds: number[];
}

export class DeleteAllAccountImportsAction extends ApiActionBase<IDeleteAllAccountImportsActionParams, IBaseResponse> {
	constructor(details: IDetails<IDeleteAllAccountImportsActionParams, IBaseResponse>) {
		super("Api/AccountImportActions/DeleteAllAccountImportsAction", details);
	}
}

export interface IGetAccountImportsByAccountStatusAndWorkflowStatusIDActionParams {
	accountWorkflowStatusID: number;
	searchClientIDs: number[];
	startIndex: number;
	endIndex: number;
	sortField: string;
	sortDirection: string;
	accountImportStatusIds: number[] | null;
}

export class GetAccountImportsByAccountStatusAndWorkflowStatusIDAction extends ApiActionBase<IGetAccountImportsByAccountStatusAndWorkflowStatusIDActionParams, IAccountsResponse> {
	constructor(details: IDetails<IGetAccountImportsByAccountStatusAndWorkflowStatusIDActionParams, IAccountsResponse>) {
		super("Api/AccountImportActions/GetAccountImportsByAccountStatusAndWorkflowStatusIDAction", details);
	}
}

export interface IGetAccountImportsByAccountWorkflowStatusIDActionParams {
	accountWorkflowStatusID: number;
	searchClientIDs: number[];
	startIndex: number;
	endIndex: number;
	sortField: string;
	sortDirection: string;
}

export class GetAccountImportsByAccountWorkflowStatusIDAction extends ApiActionBase<IGetAccountImportsByAccountWorkflowStatusIDActionParams, IAccountsResponse> {
	constructor(details: IDetails<IGetAccountImportsByAccountWorkflowStatusIDActionParams, IAccountsResponse>) {
		super("Api/AccountImportActions/GetAccountImportsByAccountWorkflowStatusIDAction", details);
	}
}

export interface IGetSampleFromFileActionParams {
	data: string | Blob;
	fileName: string;
	statusID: number;
	clientID: number;
}

export class GetSampleFromFileAction extends ApiActionBase<IGetSampleFromFileActionParams, IResponse<IUploadSample>> {
	constructor(details: IDetails<IGetSampleFromFileActionParams, IResponse<IUploadSample>>) {
		super("Api/AccountImportActions/GetSampleFromFileAction", details);
	}
}

export interface IGetUploadHistoryActionParams {
	/* no parameters */
}

export class GetUploadHistoryAction extends ApiActionBase<IGetUploadHistoryActionParams, ICollectionResponse<IAccountImportFile>> {
	constructor(details: IDetails<IGetUploadHistoryActionParams, ICollectionResponse<IAccountImportFile>>) {
		super("Api/AccountImportActions/GetUploadHistoryAction", details);
	}
}

export interface IImportFromFileActionParams {
	refGuid: string;
}

export class ImportFromFileAction extends ApiActionBase<IImportFromFileActionParams, IResponse<boolean>> {
	constructor(details: IDetails<IImportFromFileActionParams, IResponse<boolean>>) {
		super("Api/AccountImportActions/ImportFromFileAction", details);
	}
}

export interface IPlaceAllAccountImportsActionParams {
	expectedCount: number;
	clientIDsForPlacement: number[];
}

export class PlaceAllAccountImportsAction extends ApiActionBase<IPlaceAllAccountImportsActionParams, IBaseResponse> {
	constructor(details: IDetails<IPlaceAllAccountImportsActionParams, IBaseResponse>) {
		super("Api/AccountImportActions/PlaceAllAccountImportsAction", details);
	}
}

export interface ISaveAccountImportActionParams {
	accountImport: IAccountImport;
}

export class SaveAccountImportAction extends ApiActionBase<ISaveAccountImportActionParams, IResponse<IAccountImport>> {
	constructor(details: IDetails<ISaveAccountImportActionParams, IResponse<IAccountImport>>) {
		super("Api/AccountImportActions/SaveAccountImportAction", details);
	}
}

export interface ISaveAccountImportsActionParams {
	accountImports: IAccountImport[];
}

export class SaveAccountImportsAction extends ApiActionBase<ISaveAccountImportsActionParams, IBaseResponse> {
	constructor(details: IDetails<ISaveAccountImportsActionParams, IBaseResponse>) {
		super("Api/AccountImportActions/SaveAccountImportsAction", details);
	}
}

/* tslint:enable:max-line-length */