import { MODIFIED_BY_ANOTHER_USER, OK } from "../../Models/apiConstants";
import withoutProperties from "../../Utils/withoutProperties";
import getLoadState from "../../Utils/getLoadState";
import assign from "../../Utils/assign";
import AsyncState from "../../Models/AsyncState";
import {
	DeleteUserSearchAction,
	GetClientProfileUserSearchesAction,
	SaveUserSearchAction
} from "../../Actions/Api/accountActions";
import IAction from "../../Actions/IAction";
import { IClientProfileUserSearch } from "../../Models/dto";
import keyBy from "lodash/keyBy";

export interface IState {
	loadState: AsyncState | null;
	byId: { [id: number]: IClientProfileUserSearch };
}

export default function userSearchReducer(
	previousState: IState = {
		loadState: null,
		byId: {}
	},
	action: IAction
): IState {
	if (action instanceof GetClientProfileUserSearchesAction) {
		const loadState = getLoadState(action);

		let { byId } = previousState;
		if (loadState === AsyncState.Resolved) {
			byId = assign(
				previousState.byId,
				keyBy(action.response!.items, it => it.clientProfileUserSearchId)
			);
		}

		return {
			loadState,
			byId
		};
	}

	if (
		action instanceof SaveUserSearchAction
		&& action.state === AsyncState.Resolved
		&& (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)
	) {
		const search = action.response!.item;
		return assign(previousState, {
			byId: assign(previousState.byId, {
				[search.clientProfileUserSearchId]: search
			})
		});
	}

	if (action instanceof DeleteUserSearchAction && getLoadState(action) === AsyncState.Resolved) {
		return assign(previousState, {
			byId: withoutProperties(previousState.byId, action.params.userSearch.clientProfileUserSearchId)
		});
	}

	return previousState;
}