import * as React from "react";
import Loader from "../../Common/Loader/Loader";
import { AsyncState } from "../../../Models/IAsync";
import * as toast from "../../../Utils/toast";
import cn from "classnames";
import captchaNoise from "./captcha-noise.png";
import styles from "../Captcha.module.scss";
import { ReactComponent as RefreshIcon } from "./refresh-security.svg";
import Button from "../../Common/Button/Button";
import VisuallyHidden from "../../Common/VisuallyHidden/VisuallyHidden";

export interface IOwnProps {
	name?: string;
}

export interface ILoaderProps {
	loading: AsyncState;
}

export interface IConnectedProps {
	value: string;
	imageBytes: string | null;
	failing: boolean;
	challenge?: string | null;
	onRefresh(): void;
	onChange(value: string): any;
	onInvalidate(): void;
}

export interface IProps extends IOwnProps, ILoaderProps, IConnectedProps { }

export const reloadDelays: { [state: number]: number | undefined } = {
	[AsyncState.Rejected]: 10 * 1000,
	[AsyncState.Resolved]: (5 * 60 * 1000) - (15 * 1000) // 15 seconds before 5 minutes
};

const Captcha = (props: IProps) => {
	const showLoading = props.loading !== AsyncState.Resolved;

	const content = showLoading
		? <Loader key="loader" className={styles.loader} size={8} margin={1} />
		: <img key="img" src={"data:image/png;base64," + props.imageBytes} alt="captcha" />;

	return (
		<div>
			<div
				className={cn("form-control", styles.image, "captcha-container")}
				style={{
					backgroundImage: showLoading ? undefined : `url(${captchaNoise})`
				}}
			>
				{content}
			</div>
			<p className={styles.instructions}>Please enter the above verification text in the field below.</p>
			<div className={styles.input}>
				<input
					className="form-control"
					disabled={showLoading}
					value={props.value}
					placeholder={__DEV__ && props.challenge === "__NOT_REQUIRED__" ? "Not Required" : undefined}
					maxLength={4}
					name={props.name}
					onChange={event => {
						const { value } = event.currentTarget as HTMLInputElement;
						props.onChange(value);
					}}
				/>
				<Button
					onClick={props.onRefresh}
					disabled={showLoading}
				>
					<VisuallyHidden>Refresh Captcha</VisuallyHidden>
					<RefreshIcon aria-hidden />
				</Button>
			</div>
		</div>
	);
};

export default class CaptchaContainer extends React.PureComponent<IProps> {
	private _errorToast: toast.IToastDetail | null = null;

	componentDidMount() {
		this._updateError();
	}

	componentDidUpdate() {
		this._updateError();
	}

	componentWillUnmount() {
		this.props.onInvalidate();
	}

	render() {
		return <Captcha {...this.props} />;
	}

	private _updateError() {
		if (this.props.failing) {
			if (this._errorToast == null) {
				this._errorToast = toast.error("Failed to get captcha! Please contact administrator", {
					timeout: null
				});
			}
		} else if (this._errorToast != null) {
			this._errorToast.clear();
			this._errorToast = null;
		}
	}
}