import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import { GetStartupDataAction } from "../../Actions/Api/startupActions";
import { SaveClientProfileFTPAction } from "../../Actions/Api/ftpActions";
import getLoadState from "../../Utils/getLoadState";
import keyBy from "lodash/keyBy";
import { ApproveAction } from "../../Actions/Api/claimPackageReviewActions";

export interface IState {
	accountImportStatuses: {
		byId: { [acctImportStatusId: number]: Dto.IAccountImportStatus };
		ids: number[];
	};
	accountStatuses: {
		byId: { [acctStatusId: number]: Dto.IAccountStatus };
		ids: number[];
	};
	businessTypes: Dto.IClientProfileBusinessType[];
	clientCapabilities: { [clientId: number]: Dto.IClientCapability | undefined };
	clientUserId1Label: string | null;
	clientUserId2Label: string | null;
	documentTypes: Dto.IDocumentType[];
	industryTypes: Dto.IClientProfileIndustryType[];
	integrationPartners: { [integrationPartnerId: number]: Dto.IClientProfileIntegrationPartner };
	useAgreement: Dto.ILegalDocument | null;
	pricing: Dto.IClientProfilePricing | null;
	products: { [productId: number]: Dto.IClientProfileProduct | undefined };
	profileStatuses: { [statusId: number]: Dto.IClientProfileStatus };
	redactSsn: boolean;
	solTypes: Dto.ISOLType[];
	states: Dto.IState[];
	workflowSteps: Dto.IAccountWorkflowStep[];
	releaseNotesGuid: string;
	loading: AsyncState | null;
	version: string;
	ftpLocked: boolean;
	claimPackageReviewStatus: string;
	hasCustomClaimPackageMessage: boolean;
	claimPackageReviewGracePeriodExpiration: string | null;
	feedbackUrl: string | null;
	showContacts: boolean;
	statusesMapToSingleWorkflow: boolean;
}

function normalizeCollection<T>(items: T[], getId: (item: T) => number) {
	return {
		byId: keyBy(items, getId),
		ids: items.map(getId)
	};
}

/** A reducer for all the static startup data that never changes */
export default function startupReducer(
	previousState: IState = {
		accountImportStatuses: {
			byId: {},
			ids: []
		},
		accountStatuses: {
			byId: {},
			ids: []
		},
		businessTypes: [],
		clientCapabilities: {},
		clientUserId1Label: null,
		clientUserId2Label: null,
		documentTypes: [],
		industryTypes: [],
		integrationPartners: {},
		useAgreement: null,
		pricing: null,
		products: {},
		profileStatuses: {},
		redactSsn: process.env.REACT_APP_APPLICATION === "access",
		solTypes: [],
		states: [],
		workflowSteps: [],
		loading: null,
		version: "",
		releaseNotesGuid: "",
		ftpLocked: false,
		claimPackageReviewStatus: "NA",
		hasCustomClaimPackageMessage: false,
		claimPackageReviewGracePeriodExpiration: null,
		feedbackUrl: null,
		showContacts: false,
		statusesMapToSingleWorkflow: true
	},
	action: IAction
) {
	if (action instanceof GetStartupDataAction) {
		let loading = getLoadState(action);
		let nextState = Object.assign({}, previousState, { loading });

		if (loading === AsyncState.Resolved) {
			const { item } = action.response!;
			nextState.accountImportStatuses = normalizeCollection(item.accountImportStatusList, it => it.acctImportStatusId);
			nextState.accountStatuses = normalizeCollection(item.accountStatusList, it => it.acctStatusId);
			nextState.businessTypes = item.businessTypes;
			nextState.clientCapabilities = keyBy(item.clientCapabilities || [], it => it.clientId);
			nextState.clientUserId1Label = item.clientUserId1Label;
			nextState.clientUserId2Label = item.clientUserId2Label;
			nextState.documentTypes = item.documentTypes;
			nextState.industryTypes = item.industryTypes;
			nextState.integrationPartners = keyBy(item.clientProfileIntegrationPartners, it => it.integrationPartnerId);
			nextState.useAgreement = item.userAgreement;
			nextState.pricing = item.cpPricing;
			nextState.products = keyBy(item.clientProfileProducts, it => it.clientProfileProductId);
			nextState.profileStatuses = keyBy(item.clientProfileStatusList, it => it.clientProfileStatusId);
			nextState.redactSsn = item.redactSsn;
			nextState.solTypes = item.solTypes;
			nextState.states = item.stateList;
			nextState.workflowSteps = item.workflowSteps;
			nextState.version = item.version;
			nextState.releaseNotesGuid = item.releaseNotesGuid;
			nextState.ftpLocked = item.ftpLocked;
			nextState.claimPackageReviewStatus = item.claimPackageReviewStatus;
			nextState.hasCustomClaimPackageMessage = item.hasCustomClaimPackageMessage;
			nextState.claimPackageReviewGracePeriodExpiration = item.claimPackageReviewGracePeriodExpiration;
			nextState.feedbackUrl = item.feedbackUrl;
			nextState.showContacts = item.showContacts;
			nextState.statusesMapToSingleWorkflow = item.statusesMapToSingleWorkflow;
		}

		return nextState;
	}

	if (action instanceof SaveClientProfileFTPAction) {
		if (getLoadState(action) === AsyncState.Resolved) {
			return {
				...previousState,
				ftpLocked: false
			};
		}
	}

	if (action instanceof ApproveAction) {
		return {
			...previousState,
			hasCustomClaimPackageMessage: false,
			claimPackageReviewStatus: "NOT_MISSING"
		};
	}

	return previousState;
}