import * as React from "react";
import cn from "classnames";
import withoutProperties from "../../../Utils/withoutProperties";
import { AsyncState } from "../../../Models/IAsync";
import Select, { IOption } from "../Select/Select";

interface IProps<TItem, TId> extends React.SelectHTMLAttributes<HTMLSelectElement> {
	loading: AsyncState | null;
	getId: (item: TItem) => TId;
	stringifyId?: (id: TId) => string;
	parseId: (id: string) => TId | null;
	getLabel: (item: TItem) => string;
	selectedId: TId | null;
	items: TItem[];
	optional?: boolean;
	onSelectionChange(id: TId | null, item: TItem | null): unknown;
}

const EMPTY_ID = `__AsyncSelector_Empty_ee76e8a5__`;

class AsyncSelector<TItem, TId> extends React.Component<IProps<TItem, TId>> {
	static defaultProps = {
		stringifyId: (it: any) => it.toString()
	};

	render() {
		const { props } = this;
		const otherProps = withoutProperties(props, [
			nameof(props.loading),
			nameof(props.getId),
			nameof(props.stringifyId),
			nameof(props.parseId),
			nameof(props.getLabel),
			nameof(props.selectedId),
			nameof(props.items),
			nameof(props.optional),
			nameof(props.onSelectionChange)
		]);

		const selectedId = props.selectedId == null ? EMPTY_ID : props.stringifyId!(props.selectedId);
		let disabled = props.disabled;
		let className = props.className;

		let options: IOption[];
		switch (props.loading) {
			case null:
			case AsyncState.Pending:
				disabled = true;
				options = [
					{
						value: selectedId,
						label: props.disabled ? "" : "Loading..."
					}
				];
				break;
			case AsyncState.Rejected:
				disabled = true;
				className = cn(className, "has-error");
				options = [
					{
						value: selectedId,
						label: props.disabled ? "" : "Error!"
					}
				];
				break;
			case AsyncState.Resolved:
				options = props.items.map(item => {
					const id = props.stringifyId!(props.getId(item));
					return {
						value: id,
						label: props.getLabel(item)
					};
				});

				if (props.optional || selectedId === EMPTY_ID) {
					options.unshift({
						value: EMPTY_ID,
						label: ""
					})
				}
				break;
			default:
				throw new Error("Unexpected state");
		}

		return (
			<Select
				{...otherProps}
				className={className}
				disabled={disabled}
				value={selectedId}
				options={options}
				onChange={(value, event) => {

					const id = value === EMPTY_ID ? null : props.parseId(value);
					const item = (id != null && props.items.find(it => props.getId(it) === id)) || null;
					props.onSelectionChange(id, item);

					if (props.onChange != null) {
						props.onChange(event);
					}
				}}
			/>
		);
	}
}

export default AsyncSelector;