import { GetResourcesAction, GetLatestArticlesAction, GetDynamicKpisAction, GetStaticKpisAction, IGetDynamicKpisActionParams, GetReviewItemsAction, DismissReviewItemAction, GetHomeReportsAction, IGetHomeReportDataActionParams, GetHomeReportDataAction } from "../../../Actions/Api/homeActions";
import ResourceType from "../../../Models/ResourceType";
import getLoadState from "../../../Utils/getLoadState";
import { AsyncState } from "../../../Models/IAsync";
import IAction from "../../../Actions/IAction";
import { IResource, IBaseResponse, ICollectionResponse, IResponse, IKpi, IReviewItem, IHomeReport, IReportData } from "../../../Models/dto";
import ApiActionBase from "../../../Actions/ApiActionBase";
import { DynamicKpiDateChangedAction, DynamicKpiAffiliateChangedAction, ReportAffiliateChangedAction } from "../../../Actions/Home/HomeActions";
import isEqual from "lodash/isEqual";
import AccountStatusUpdatedAction from "../../../Actions/Accounts/AccountStatusUpdatedAction";
import { ChangeAccountClaimStatusAction } from "../../../Actions/Api/claimActions";
import { RollbackAccountApprovalAction } from "../../../Actions/Api/accountActions";
import { ImportFromFileAction, AddAccountImportsAction } from "../../../Actions/Api/accountImportActions";
import moment from "moment";
import WorkFlowStatusDataLoadedAction from "../../../Actions/Accounts/WorkFlowStatusDataLoadedAction";
import { OK } from "../../../Models/apiConstants";
import keyBy from "lodash/keyBy";

interface IDataCollection<T> {

	loadState: AsyncState | null;
	timestamp: string | null;
	data: T;
}

type IResourceCollection = IDataCollection<IResource[]>;

export interface IState {
	bannerAds: IResourceCollection;
	latest: IResourceCollection;
	articles: IResourceCollection;
	training: IResourceCollection;
	dynamicKpis: IDataCollection<IKpi[]> & {
		startDate: string | null | undefined;
		endDate: string | null | undefined;
		clientIds: number[] | null;
		requestId: string | null;
		params: IGetDynamicKpisActionParams | null;
	};
	staticKpis: IDataCollection<IKpi[]> & {
		requestId: string | null;
	};
	reviewItems: IDataCollection<IReviewItem[]>;
	availableReports: IDataCollection<IHomeReport[]>;
	reportClientIds: number[] | null;
	reportData: IDataCollection<IReportData | null> & {
		requestId: string | null;
		params: IGetHomeReportDataActionParams | null;
	}
}

const EMPTY_RESOURCE_COLLECTION: IResourceCollection = {
	loadState: null,
	timestamp: null,
	data: []
}

function updateDataCollection<T, TResponse extends IBaseResponse, TAction extends ApiActionBase<any, TResponse>>(
	previousState: IDataCollection<T>,
	action: TAction,
	getData: (response: TResponse) => T
): IDataCollection<T> {
	const loadState = getLoadState(action);

	let { data } = previousState;
	if (loadState === AsyncState.Resolved) {
		if (action.response == null) {
			throw new Error("Unexpected state");
		}

		data = getData(action.response);
	}

	return {
		loadState,
		timestamp: action.timestamp,
		data
	};
}

function updateResourceCollection(
	previousState: IState,
	key: "bannerAds" | "latest" | "articles" | "training",
	action: GetResourcesAction | GetLatestArticlesAction
): IState {
	return {
		...previousState,
		[key]: updateDataCollection<IResource[], ICollectionResponse<IResource>, GetResourcesAction | GetLatestArticlesAction>(
			previousState[key],
			action,
			it => it.items
		)
	};
}



const INITIAL_STATE = {
	bannerAds: EMPTY_RESOURCE_COLLECTION,
	latest: EMPTY_RESOURCE_COLLECTION,
	articles: EMPTY_RESOURCE_COLLECTION,
	training: EMPTY_RESOURCE_COLLECTION,
	dynamicKpis: {
		startDate: undefined,
		endDate: undefined,
		clientIds: null,
		loadState: null,
		timestamp: null,
		data: [],
		requestId: null,
		params: null
	},
	staticKpis: {
		loadState: null,
		timestamp: null,
		data: [],
		requestId: null
	},
	reviewItems: {
		loadState: null,
		timestamp: null,
		data: []
	},
	reportClientIds: null,
	availableReports: {
		loadState: null,
		timestamp: null,
		data: []
	},
	reportData: {
		loadState: null,
		timestamp: null,
		data: null,
		params: null,
		requestId: null
	}
};
export default function homeReducer(
	previousState: IState = INITIAL_STATE,
	action: IAction
): IState {
	if (process.env.REACT_APP_HOME_TAB !== "home") {
		return previousState;
	}

	if (action instanceof GetResourcesAction) {
		switch (action.params.resourceTypeId) {
			case ResourceType.HomeBanner:
				return updateResourceCollection(previousState, "bannerAds", action);
			case ResourceType.Article:
				return updateResourceCollection(previousState, "articles", action);
			case ResourceType.Training:
				return updateResourceCollection(previousState, "training", action);
		}

		return previousState;
	}

	if (action instanceof GetLatestArticlesAction) {
		return updateResourceCollection(previousState, "latest", action);
	}

	if (action instanceof GetDynamicKpisAction) {
		const { loadState, timestamp, data } = updateDataCollection<IKpi[], ICollectionResponse<IKpi>, GetDynamicKpisAction>(
			previousState.dynamicKpis,
			action,
			it => it.items
		);

		let { requestId, params } = previousState.dynamicKpis;
		if (loadState === AsyncState.Pending) {
			({ requestId, params } = action);
		} else if (action.requestId !== requestId) {
			return previousState;
		}

		return {
			...previousState,
			dynamicKpis: {
				...previousState.dynamicKpis,
				loadState,
				timestamp,
				data,
				requestId,
				params,
			}
		};
	}

	if (action instanceof DynamicKpiDateChangedAction) {
		return {
			...previousState,
			dynamicKpis: {
				...previousState.dynamicKpis,
				startDate: action.startDate,
				endDate: action.endDate
			}
		};
	}

	if (action instanceof DynamicKpiAffiliateChangedAction) {
		return {
			...previousState,
			dynamicKpis: {
				...previousState.dynamicKpis,
				clientIds: action.clientIds
			}
		};
	}

	if (action instanceof GetStaticKpisAction) {
		const { loadState, timestamp, data } = updateDataCollection<IKpi[], ICollectionResponse<IKpi>, GetStaticKpisAction>(
			previousState.staticKpis,
			action,
			it => it.items
		);

		let { requestId } = previousState.staticKpis;
		if (loadState === AsyncState.Pending) {
			({ requestId } = action);
		} else if (action.requestId !== requestId) {
			return previousState;
		}

		return {
			...previousState,
			staticKpis: {
				loadState,
				timestamp,
				data,
				requestId
			}
		};
	}

	if (
		process.env.REACT_APP_APPLICATION !== "access"
		&& (action instanceof ImportFromFileAction || action instanceof AddAccountImportsAction)
		&& action.state === AsyncState.Resolved
	) {
		return {
			...previousState,
			dynamicKpis: INITIAL_STATE.dynamicKpis,
			staticKpis: INITIAL_STATE.staticKpis
		};
	}

	if (action instanceof WorkFlowStatusDataLoadedAction && action.data.status === OK) {
		const counts = keyBy(action.data.items, it => it.statusId);
		return {
			...previousState,
			staticKpis: {
				...previousState.staticKpis,
				data: previousState.staticKpis.data.map(kpi => {
					if (kpi.workflowCountId != null && kpi.workflowCountId in counts) {
						return {
							...kpi,
							count: counts[kpi.workflowCountId].count
						};
					}

					return kpi;
				})
			}
		};
	}

	if (action instanceof GetReviewItemsAction) {
		return {
			...previousState,
			reviewItems: updateDataCollection<IReviewItem[], ICollectionResponse<IReviewItem>, GetReviewItemsAction>(
				previousState.reviewItems,
				action,
				it => it.items
			)
		};
	}

	if (action instanceof DismissReviewItemAction && action.state === AsyncState.Pending) {
		const { reviewItems } = previousState;
		return {
			...previousState,
			reviewItems: {
				...reviewItems,
				data: reviewItems.data.filter(it => !isEqual(it, action.params.reviewItem))
			}
		};
	}

	if (
		action instanceof AccountStatusUpdatedAction
		|| (
			(action instanceof ChangeAccountClaimStatusAction || action instanceof RollbackAccountApprovalAction)
			&& getLoadState(action) === AsyncState.Resolved
		)
	) {
		let { dynamicKpis } = previousState;
		if (dynamicKpis.endDate != null) {
			const today = moment(action.timestamp).startOf("day");
			if (moment(dynamicKpis.endDate).isSameOrAfter(today)) {
				dynamicKpis = INITIAL_STATE.dynamicKpis;
			}
		}

		return {
			...previousState,
			reviewItems: INITIAL_STATE.reviewItems,
			staticKpis: INITIAL_STATE.staticKpis,
			dynamicKpis
		};
	}

	if (action instanceof GetHomeReportsAction) {
		return {
			...previousState,
			availableReports: updateDataCollection<IHomeReport[], ICollectionResponse<IHomeReport>, GetHomeReportsAction>(
				previousState.availableReports,
				action,
				it => it.items
			)
		};
	}

	if (action instanceof ReportAffiliateChangedAction) {
		return {
			...previousState,
			reportClientIds: action.clientIds
		};
	}

	if (action instanceof GetHomeReportDataAction) {
		let { loadState, timestamp, data } = updateDataCollection<IReportData | null, IResponse<IReportData>, GetHomeReportDataAction>(
			previousState.reportData,
			action,
			it => it.item
		);

		let { requestId, params } = previousState.reportData;
		if (loadState === AsyncState.Pending) {
			({ requestId, params } = action);
			data = null;
		} else if (action.requestId !== requestId) {
			return previousState;
		}

		return {
			...previousState,
			reportData: {
				...previousState.reportData,
				loadState,
				timestamp,
				data,
				requestId,
				params,
			}
		};
	}


	return previousState;
}