import { OK } from "./apiConstants";
import { IFakeJQueryXhr } from "../Utils/apiExecute";

interface IApiRequest<TParameters, TResponse> {
	params: TParameters;
	xhr: IFakeJQueryXhr<TResponse>;
	response: TResponse | null;
	error: {
		textStatus: string;
		errorThrown: any;
	} | null;
}

export function isPending(request: IApiRequest<any, any> | null) {
	return request == null || request.xhr.state() === "pending";
}

export function isError(request: IApiRequest<any, any> | null) {
	return !isPending(request) && (request!.response == null || request!.response.status !== OK);
}

export default IApiRequest;