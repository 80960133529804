import React from "react";
import cn from "classnames";
import withoutProperties from "../../../Utils/withoutProperties";
import styles from "./IconAlert.module.scss";

interface IProps extends React.HTMLAttributes<HTMLElement> {
	icon: string;
	alertType: string;
}

export default class IconAlert extends React.Component<IProps, {}> {
	render() {
		const { props } = this;
		const otherProps = withoutProperties(props, [
			nameof(props.icon),
			nameof(props.alertType)
		]);

		return (
			<div {...otherProps} className={cn(props.className, "alert", styles.alert, props.alertType)}>
				<i className={cn("fa", props.icon)} />
				<div>
					{props.children}
				</div>
			</div>
		);
	}
}