import { getAllDocumentsByAcctID } from "../ActionCreators/Api/documentActionCreators";
import { makeFakeJqueryXhr } from "../Utils/apiExecute";
import { IGetAllDocumentsByAcctIDActionParams } from "../Actions/Api/documentActions";

const DocumentApi = {

	getAllDocumentsByAcctID(params: IGetAllDocumentsByAcctIDActionParams) {
		return makeFakeJqueryXhr(getAllDocumentsByAcctID(params.acctID));
	}
};

export default DocumentApi;
