/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IDismissNotificationActionParams {
	notificationId: number;
}

export class DismissNotificationAction extends ApiActionBase<IDismissNotificationActionParams, IResponse<boolean>> {
	constructor(details: IDetails<IDismissNotificationActionParams, IResponse<boolean>>) {
		super("Api/UserNotificationActions/DismissNotificationAction", details);
	}
}

/* tslint:enable:max-line-length */