import React from "react";
import cn from "classnames";
import styles from "./Forms.module.scss";
import icon from "../../../../Images/registration/admin-profile-icon.jpg";
import Form from "../../Common/Form/Form";
import FormGroup from "../../Common/FormGroup/FormGroup";
import PhoneInput from "../../Common/PhoneInput";
import { phone as validPhone } from "../../../Utils/validate";
import isEmpty from "../../../Utils/isEmpty";
import { IProps } from "./Forms";
import { appName } from "../../../Utils/branding";

interface IValid {
	phoneValid: boolean;
	titleValid: boolean;
	middleNameValid: boolean;
	allValid: boolean;
}

function validate(props: IProps): IValid {
	const phoneValid = !isEmpty(props.model.adminPhone) && validPhone(props.model.adminPhone);
	const titleValid = isEmpty(props.model.adminTitle) || props.model.adminTitle.length <= 50;
	const middleNameValid = isEmpty(props.model.adminMiddleName) || props.model.adminMiddleName.length <= 50;
	const allValid = phoneValid && titleValid && middleNameValid;

	return {
		phoneValid,
		titleValid,
		middleNameValid,
		allValid
	};
}

const AdminProfile = (props: IProps) => {
	const valid = validate(props);

	return (
		<div className={styles.page}>
			<div className={styles.header}>
				<img src={icon} alt="" height={50} width="auto" />
				<div>
					<div className={styles.title}>
						Administrator profile
					</div>
					<div className={styles.subtitle}>
						The administrator role receives full access to your organization’s
						{appName} profile.
					</div>
				</div>
			</div>

			<div className={styles.fields}>
				<Form
					onSubmit={e => e.preventDefault()}
					horizontal
				>
					<FormGroup label="First Name">
						{id => (
							<span id={id} className={styles.readonly}>
								{props.data.adminFirstName}
							</span>
						)}
					</FormGroup>

					<FormGroup
						label="Middle Name"
						hasError={!valid.middleNameValid}
					>
						{id => (
							<input
								type="text"
								className={cn("form-control")}
								id={id}
								maxLength={50}
								value={props.model.adminMiddleName}
								onChange={e => props.setValue(e.target.value, (v, m) => m.adminMiddleName = v)}
							/>
						)}
					</FormGroup>

					<FormGroup label="Last Name">
						{id => (
							<span id={id} className={styles.readonly}>
								{props.data.adminLastName}
							</span>
						)}
					</FormGroup>

					<FormGroup
						label="Title"
						hasError={!valid.titleValid}
					>
						{id => (
							<input
								type="text"
								className={cn("form-control")}
								id={id}
								maxLength={50}
								value={props.model.adminTitle}
								onChange={e => props.setValue(e.target.value, (v, m) => m.adminTitle = v)}
							/>
						)}
					</FormGroup>

					<FormGroup label="Email">
						{id => (
							<span id={id} className={styles.readonly}>
								{props.data.adminEmail}
							</span>
						)}
					</FormGroup>

					<FormGroup
						required
						label="Phone"
						hasError={!valid.phoneValid}
					>
						{id => (
							<PhoneInput
								type="text"
								className={cn("form-control")}
								id={id}
								maxLength={50}
								value={props.model.adminPhone}
								onChange={e => props.setValue(e.target.value, (v, m) => m.adminPhone = v)}
							/>
						)}
					</FormGroup>

					<div className={styles.actions}>
						<button
							type="button"
							className="btn btn-light"
							onClick={() => props.onBack()}
						>
							Back
						</button>
						<button
							type="button"
							className="btn btn-warning"
							disabled={!valid.allValid}
							onClick={() => props.onConfirm()}
						>
							Continue
						</button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default AdminProfile;