import isIE from "../../Utils/isIE";
import React from "react";
import { findDOMNode } from "react-dom";
import cn from "classnames";

interface ISelection {
	start: number;
	end: number;
}

export function getSelection(input: HTMLInputElement) {
	return {
		start: input.selectionStart!,
		end: input.selectionEnd!
	};
}

export function setSelection(input: HTMLInputElement, offsets: ISelection) {
	var start = offsets.start;
	var end = offsets.end;
	if (end === undefined) {
		end = start;
	}

	input.selectionStart = start;
	input.selectionEnd = Math.min(end, input.value.length);
}

export interface IInputProps extends Omit<React.HTMLProps<HTMLInputElement>, "value"> {
	value: string;
}

export interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "value"> {
	value?: string | null;
	disableFormatting?: boolean;
	renderInput?(props: IInputProps): JSX.Element;
}

interface IState {
	focused: boolean;
}

// TODO refactor this away from an abstract class to a standalone component
// that instead takes the abstract methods as props
abstract class FormattingInput extends React.Component<IProps, IState> {
	private _input: HTMLInputElement | null | undefined;

	private _oldValue: string | null | undefined;
	private _oldSelection: ISelection | null | undefined;
	private _handleFocusOnNextSelect = false;

	constructor(props: IProps, context: any) {
		super(props, context);

		this.state = {
			focused: false
		};
	}

	componentDidMount() {
		const focused = document.activeElement === this._input;
		if (focused !== this.state.focused) {
			this.setState({ focused });
		}
	}

	render() {
		const rawValue = this.props.value || "";
		const value = (this.state.focused || this.props.disableFormatting) ? rawValue : (this.formatValue(rawValue) || "");

		let { maxLength } = this.props;
		if (maxLength != null && value != null && !this.state.focused) {
			// increase the max length when not focused to allow for formatting
			// ie11 shows the field as invalid and prevents a form submit otherwise
			maxLength = Math.max(maxLength, value.length);
		}

		const { renderInput, value: _0, disableFormatting: _1, ...inputProps } = this.props;
		Object.assign(inputProps, {
			ref: it => this._input = it,
			className: cn("form-control", this.props.className),
			value,
			maxLength,
			onKeyPress: e => this._onKeyPress(e),
			onBlur: e => this._onBlur(e),
			onFocus: e => this._onFocus(e),
			onSelect: e => this._onSelect(e),
			onPaste: e => this._onPaste(e)
		} as React.HTMLProps<HTMLInputElement>);

		return (renderInput || this._renderInput)({
			...inputProps,
			ref: it => this._input = it,
			className: cn("form-control", this.props.className),
			value,
			maxLength,
			onKeyPress: e => this._onKeyPress(e),
			onBlur: e => this._onBlur(e),
			onFocus: e => this._onFocus(e),
			onSelect: e => this._onSelect(e),
			onPaste: e => this._onPaste(e)
		});
	}

	private _renderInput(inputProps: IInputProps) {
		return <input {...inputProps} />;
	}

	focus() {
		this._getInput()!.focus();
	}

	protected abstract allowKey(key: string): boolean;
	protected abstract formatValue(rawValue: string): string | null;

	private _onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key !== "Enter" && !this.allowKey(e.key)) {
			e.preventDefault();
		}

		if (this.props.onKeyPress) {
			this.props.onKeyPress(e);
		}
	}

	private _onBlur(e: React.FocusEvent<HTMLInputElement>) {
		this._oldValue = null;
		this._oldSelection = null;

		this.setState({ focused: false });

		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	}

	private _onFocus(event: React.FocusEvent<HTMLInputElement>) {
		if (isIE) {
			// in ie we need to capture the current selection now before it changes...
			this._handleFocus();
		} else {
			// ...for other browsers capture it on the select event that happens immediately after focus
			this._handleFocusOnNextSelect = true;
		}

		if (this.props.onFocus) {
			this.props.onFocus(event);
		}
	}

	private _onPaste(event: React.ClipboardEvent<HTMLInputElement>) {
		const value = event.clipboardData.getData("text");
		if (value) {
			// filter the pasted value in case they pasted it with formatting included
			const filteredValue = [...value].filter(it => this.allowKey(it)).join("");
			if (value !== filteredValue) {
				event.preventDefault();
				event.currentTarget.value = filteredValue;

				if (this.props.onChange != null) {
					// would be nicer if we could trigger a native change event that react
					// would pick up on, but I'm unable to get it to work in IE
					this.props.onChange(event as any);
				}
			}
		}

		if (this.props.onPaste != null) {
			this.props.onPaste(event);
		}
	}

	private _getInput() {
		if (this.props.renderInput == null || this._input != null) {
			return this._input;
		}

		// when using `renderInput`, we might not get the input ref set correctly,
		// rely on findDOMNode instead
		const element = findDOMNode(this);
		return element == null ? null
			: element.nodeName === "input" ? element as HTMLInputElement
				: (element as Element).querySelector("input");
	}

	private _handleFocus() {
		const input = this._getInput()!;

		this._oldValue = input.value;
		this._oldSelection = getSelection(input);

		this.setState({ focused: true }, () => {
			this._restoreSelection();
		});
	}

	private _onSelect(_event: React.SyntheticEvent<HTMLInputElement>) {
		if (this._handleFocusOnNextSelect) {
			this._handleFocusOnNextSelect = false;
			this._handleFocus();
		}
	}

	private _restoreSelection() {
		if (this._oldValue == null || this._oldSelection == null) {
			return;
		}

		const input = this._getInput()!;

		const oldValue = this._oldValue;
		const newValue = input.value;
		const { start, end } = this._oldSelection;

		this._oldValue = null;
		this._oldSelection = null;

		const oldSlices = [
			oldValue.substring(0, start), // left of selection
			oldValue.substring(start, end), // selected
			oldValue.substring(end) // right of selection
		];
		const newSlices = ["", "", ""];

		let newChars = newValue;

		for (let i = 0; i < 3; i++) {
			const oldSlice = oldSlices[i];
			for (let j = 0, l = oldSlice.length; j < l; j++) {
				const oldChar = oldSlice[j];
				if (newChars[0] === oldChar) {
					newChars = newChars.substr(1);
					newSlices[i] += oldChar;
				}
			}
		}

		if (__DEV__ && newChars.length > 0) {
			console.error(`[${__filename}] Unable to translate selection from ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
		}

		const newStart = newSlices[0].length;
		const newEnd = newStart + newSlices[1].length;

		setSelection(input, {
			start: newStart,
			end: newEnd
		});
	}
}

export default FormattingInput;