import React, { Fragment } from "react";
import Overlay from "../Overlay/Overlay";
import propTypes from "prop-types";

interface IProps {
	locked?: boolean;
	children?: React.ReactChild;
}

interface IMessage {
	id: string;
	message: string | null;
	showWhenLocked: boolean;
}

export interface IStore {
	getMessage(locked: boolean): string | null;
	update(id: string, message: string | null, showWhenLocked?: boolean): void;
	subscribe(callback: () => void): () => void;
}

export const STORE_CONTEXT_KEY = "__Provider_store__";

function createStore(): IStore {
	let messages: IMessage[] = [];
	let callbacks: (() => void)[] = [];

	return {
		getMessage(locked) {
			for (const message of messages) {
				if (!locked || message.showWhenLocked) {
					return message.message;
				}
			}

			return null;
		},
		update(id, message, showWhenLocked = false) {
			messages = [...messages.filter(it => it.id !== id)];
			if (message != null) {
				messages.push({ id, message, showWhenLocked });
			}

			for (const callback of callbacks) {
				callback();
			}
		},
		subscribe(callback: () => void) {
			callbacks = [...callbacks, callback];
			return () => {
				callbacks = callbacks.filter(it => it !== callback);
			};
		}
	};
}

export default class Provider extends React.Component<IProps> {
	static childContextTypes = {
		[STORE_CONTEXT_KEY]: propTypes.shape({
			[nameof<IStore>(it => it.getMessage)]: propTypes.func.isRequired,
			[nameof<IStore>(it => it.update)]: propTypes.func.isRequired,
			[nameof<IStore>(it => it.subscribe)]: propTypes.func.isRequired
		})
	};

	private _store: IStore;

	constructor(props: IProps, context: any) {
		super(props, context);
		this._store = createStore();
	}

	render() {
		return (
			<Fragment>
				{this.props.children}
				<Overlay
					store={this._store}
					locked={this.props.locked}
				/>
			</Fragment>
		);
	}

	getChildContext() {
		return {
			[STORE_CONTEXT_KEY]: this._store
		};
	}
}