import { tabLoaded } from "../../../ActionCreators/AppActionCreators";
import ActiveTabEnum from "../../../Models/ActiveTabEnum";
import createModuleLoader from "../../../Utils/createModuleLoader";

const { loader, load } = createModuleLoader<typeof import("./Home").default>();

if (process.env.REACT_APP_HOME_TAB === "home") {
	(async () => {
		const homeModule = await import("./Home" /* webpackChunkName: "home" */);
		load(homeModule.default);
		tabLoaded(ActiveTabEnum.Home);
	})();
}

export default loader;
