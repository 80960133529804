/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	ICollectionResponse,
	ILegalDocumentType
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IGetLegalDocumentTypesForClientProfileActionParams {
	/* no parameters */
}

export class GetLegalDocumentTypesForClientProfileAction extends ApiActionBase<IGetLegalDocumentTypesForClientProfileActionParams, ICollectionResponse<ILegalDocumentType>> {
	constructor(details: IDetails<IGetLegalDocumentTypesForClientProfileActionParams, ICollectionResponse<ILegalDocumentType>>) {
		super("Api/LegalDocumentActions/GetLegalDocumentTypesForClientProfileAction", details);
	}
}

/* tslint:enable:max-line-length */