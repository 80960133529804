import * as React from "react";
import Provider, { IStore, STORE_CONTEXT_KEY } from "./Provider/Provider";
import renderNonVisibleComponent from "../../../Utils/renderNonVisibleComponent";
import uid from "../../../Utils/uid";

interface IProps {
	message: string | null;
	showWhenLocked?: boolean;
	children?: React.ReactChild;
}

export default class BusyOverlay extends React.PureComponent<IProps> {
	static Provider = Provider;
	static contextTypes = Provider.childContextTypes;

	private _uid!: string;
	private _store: IStore | null | undefined;

	componentDidMount() {
		this._uid = uid();
		this._store = this.context[STORE_CONTEXT_KEY];

		this._updateStore();
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.message !== prevProps.message) {
			this._updateStore();
		}
	}

	componentWillUnmount() {
		this._updateStore(null);
		this._store = null;
	}

	render() {
		return renderNonVisibleComponent(this.props.children);
	}

	private _updateStore(message = this.props.message) {
		this._store!.update(this._uid, message, this.props.showWhenLocked);
	}
}
