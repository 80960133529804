import { startBusy } from "../ActionCreators/AppActionCreators";
import noop from "lodash/noop";

export default function <T>() {
	const callbacks: (() => void)[] = [];

	let resolve: (module: T) => void;
	const promise = new Promise<T>(r => resolve = r);

	const loader = {
		module: null as T | null,
		loaded: false,
		onLoad: (callback: () => void) => {
			if (loader.loaded) {
				callback();
				return noop;
			}

			callbacks.push(callback);
			return () => {
				const index = callbacks.indexOf(callback);
				if (index !== -1) {
					callbacks.splice(index, 1);
				}
			};
		},
		waitFor: async () => {
			const stopBusy = startBusy("Loading...");
			await promise;
			stopBusy();
		}
	};

	const load = (module: T) => {
		loader.module = module;
		loader.loaded = true;

		while (callbacks.length) {
			callbacks.shift()!();
		}

		resolve(module);
	};

	return {
		loader,
		load
	};
}
