import IAction from "../../../../Actions/IAction";
import { GetLockedAccountsAction, GetAccountValidationAction } from "../../../../Actions/Api/accountValidationActions";
import { AsyncState } from "../../../../Models/IAsync";
import { OK, ERROR } from "../../../../Models/apiConstants";

export interface IState {
	timestamp: string | null;
	loading: boolean;
	ids: {
		[acctId: number]: true | undefined;
	};
}

const empty: IState["ids"] = {};

export default function lockedAccountsReducer(
	previousState: IState = {
		timestamp: null,
		loading: false,
		ids: empty
	},
	action: IAction
): IState {
	if (process.env.REACT_APP_APPLICATION !== "access") {
		return previousState;
	}

	if (action instanceof GetLockedAccountsAction) {
		let { timestamp, ids } = previousState;

		const loading = action.state === AsyncState.Pending;
		if (!loading) {
			timestamp = action.timestamp;
		}

		if (
			action.state === AsyncState.Resolved
			&& action.response != null
			&& action.response.status === OK
		) {
			ids = action.response.items.reduce(
				(acc, acctId) => ({
					...acc,
					[acctId]: true as const
				}),
				empty
			);
		}

		return {
			timestamp,
			loading,
			ids
		};
	}

	if (
		action instanceof GetAccountValidationAction
		&& action.state === AsyncState.Resolved
		&& action.response != null
		&& (
			action.response.status === OK
			|| (action.response.status === ERROR && action.response.statusMessage === "ACCOUNT LOCKED")
		)
		&& !previousState.ids[action.params.acctId]
	) {
		return {
			...previousState,
			ids: {
				...previousState.ids,
				[action.params.acctId]: true
			}
		};
	}

	return previousState;
}