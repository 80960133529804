import AsyncContainer from "../../Common/AsyncContainer/AsyncContainer";
import AsyncState from "../../../Models/AsyncState";
import * as React from "react";
import styles from "./LicenseContainer.module.scss";
import cn from "classnames";

interface IProps {
	read: boolean;
	license: string | null;
	loadState: AsyncState | null;
	warning: string;
	className?: string;
	onRead(): void;
}

export default class LicenseContainer extends React.PureComponent<IProps> {
	private _element: HTMLDivElement | null | undefined;

	constructor(props: IProps, context: any) {
		super(props, context);
		this._check = this._check.bind(this);
	}

	componentDidMount() {
		this._check();
	}

	componentDidUpdate() {
		this._check();
	}

	render() {
		const { props } = this;

		return (
			<AsyncContainer
				loadState={props.loadState}
				className={cn(props.className, styles.container)}
			>
				<div
					ref={it => this._element = it}
					id="acceptLicenseScrollContainer"
					className={styles.license}
					dangerouslySetInnerHTML={props.license == null ? undefined : { __html: props.license }}
					onScroll={props.read ? undefined : this._check}
				/>
				{!props.read && (
					<div className={styles.warning}>
						<li className="fa fa-exclamation-triangle" />{" "}
						{props.warning}
					</div>
				)}
			</AsyncContainer>
		);
	}

	private _check() {
		if (
			!this.props.read
			&& this.props.loadState === AsyncState.Resolved
			&& this._element!.scrollHeight - this._element!.scrollTop - this._element!.parentElement!.clientHeight <= 3
		) {
			this.props.onRead();
		}
	}
}