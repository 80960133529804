import getCurrentPermissions from "./getCurrentPermissions";
import ActiveTabEnum from "../Models/ActiveTabEnum";
import { createSelector } from "reselect";

export default createSelector(
	getCurrentPermissions,
	permissions => {
		const tabs = [];

		if (
			process.env.REACT_APP_HOME_TAB === "home"
			&& (process.env.REACT_APP_APPLICATION !== "access" || permissions.dashboardAccess)
		) {
			tabs.push(ActiveTabEnum.Home);
		}

		tabs.push(ActiveTabEnum.Accounts);

		if (permissions.reporting) {
			if (process.env.REACT_APP_APPLICATION === "pfod") {
				tabs.push(ActiveTabEnum.Reporting);
			}

			if (process.env.REACT_APP_APPLICATION === "access") {
				tabs.push(ActiveTabEnum.AccessReports);
			}
		}

		tabs.push(ActiveTabEnum.Settings);

		return tabs;
	}
);