/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IClientProfileParty,
	IPreStartupData,
	IResponse,
	IStartupData
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IGetLoginUserActionParams {
	/* no parameters */
}

export class GetLoginUserAction extends ApiActionBase<IGetLoginUserActionParams, IResponse<IClientProfileParty>> {
	constructor(details: IDetails<IGetLoginUserActionParams, IResponse<IClientProfileParty>>) {
		super("Api/StartupActions/GetLoginUserAction", details);
	}
}

export interface IGetPreStartupDataActionParams {
	/* no parameters */
}

export class GetPreStartupDataAction extends ApiActionBase<IGetPreStartupDataActionParams, IResponse<IPreStartupData>> {
	constructor(details: IDetails<IGetPreStartupDataActionParams, IResponse<IPreStartupData>>) {
		super("Api/StartupActions/GetPreStartupDataAction", details);
	}
}

export interface IGetStartupDataActionParams {
	/* no parameters */
}

export class GetStartupDataAction extends ApiActionBase<IGetStartupDataActionParams, IResponse<IStartupData>> {
	constructor(details: IDetails<IGetStartupDataActionParams, IResponse<IStartupData>>) {
		super("Api/StartupActions/GetStartupDataAction", details);
	}
}

/* tslint:enable:max-line-length */