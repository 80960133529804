import { GetReportV4Action } from "../../../Actions/Api/pfodReportingActions";
import AsyncState from "../../../Models/AsyncState";
import getLoadState from "../../../Utils/getLoadState";
import { DeleteReportAction, /*GetReportAction,*/ SaveReportAction } from "../../../Actions/Api/pfodReportingActions";
import ReportCriteriaUpdatedAction from "../../../Actions/Reports/ReportCriteriaUpdatedAction";
import assign from "../../../Utils/assign";
import { isSameReport } from "../../../Utils/reportUtils";
import ReportSelectedAction from "../../../Actions/Reports/ReportSelectedAction";
import IAction from "../../../Actions/IAction";
import * as Dto from "../../../Models/dto";

interface IDataRequest {
	id: string | null;
	loadState: AsyncState | null;
	report: Dto.IReport | null;
	response: Dto.IReportData | null;
}

export interface IState {
	report: Dto.IReport | null;
	useDefaults: boolean;
	data: IDataRequest;
	preferredOutputFormat: string | null;
}

const EMPTY_REQUEST: IDataRequest = {
	id: null,
	loadState: null,
	report: null,
	response: null
};

const INITIAL_STATE: IState = {
	report: null,
	useDefaults: false,
	data: EMPTY_REQUEST,
	preferredOutputFormat: "IMAGE"
};

export default function reportsReducer(
	previousState = INITIAL_STATE,
	action: IAction
): IState {
	if (action instanceof ReportSelectedAction && !isSameReport(previousState.report, action.data)) {
		let report = action.data;

		const useDefaults = report.userReportId <= 0;
		return assign<IState, Pick<IState, "report" | "data" | "useDefaults">>(previousState, {
			report,
			data: EMPTY_REQUEST,
			useDefaults
		});
	}

	if (action instanceof ReportCriteriaUpdatedAction) {
		let { preferredOutputFormat } = previousState;
		const { requestedOutputFormat } = action.data;
		if (
			requestedOutputFormat != null
			&& previousState.report != null
			&& requestedOutputFormat !== previousState.report.requestedOutputFormat
		) {
			preferredOutputFormat = requestedOutputFormat;
		}

		return assign<IState, Pick<IState, "report" | "useDefaults" | "preferredOutputFormat">>(previousState, {
			report: action.data,
			useDefaults: false,
			preferredOutputFormat
		});
	}

	if (action instanceof GetReportV4Action) {
		let { data } = previousState;

		const loadState = getLoadState(action);
		if (loadState === AsyncState.Pending) {
			data = {
				id: action.requestId,
				loadState,
				report: action.params.report,
				response: null
			};
		} else if (data.id !== action.requestId) {
			// this response is for a previous getReport request, ignore it
			return previousState;
		} else if (loadState === AsyncState.Resolved) {
			data = assign<IDataRequest, Pick<IDataRequest, "loadState" | "response">>(data, {
				loadState,
				response: action.response!.item
			});
		}

		return assign(previousState, {
			data
		});
	}

	if (
		action instanceof SaveReportAction
		&& getLoadState(action) === AsyncState.Resolved
		&& action.params.report.userReportId <= 0
	) {
		// saved a new report, select it
		return assign<IState, Pick<IState, "report" | "useDefaults">>(previousState, {
			report: action.response!.item,
			useDefaults: false
		});
	}

	if (
		action instanceof DeleteReportAction
		&& getLoadState(action) === AsyncState.Resolved
		&& previousState.report != null
		&& action.params.reportID === previousState.report.userReportId
	) {
		// current report was deleted, clear everything
		const { preferredOutputFormat } = previousState;
		return assign(INITIAL_STATE, { preferredOutputFormat });
	}

	return previousState;
}