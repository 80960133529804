import { MODIFIED_BY_ANOTHER_USER, OK } from "../../Models/apiConstants";
import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import { GetStartupDataAction } from "../../Actions/Api/startupActions";
import {
	GetClientAction,
	SaveAffiliateAction,
	SaveNewAffiliateAction,
	DeleteAffiliateAction,
	SaveClientProfileAction,
	UseParentAffiliateModelAction
} from "../../Actions/Api/clientActions";
import getLoadState from "../../Utils/getLoadState";
import withoutProperties from "../../Utils/withoutProperties";

type ById = { [clientId: number]: IClient };

export interface IClient {
	clientId: number;
	min: Dto.IClientMin;
	loading: AsyncState;
	full: Dto.IClient;
}

export interface IState {
	byId: ById;
	loading: AsyncState | null;
	saving: { [clientId: number]: boolean };
	savingNew: boolean;
	deleting: { [clientId: number]: boolean };
}

function createMin(full: Dto.IClient): Dto.IClientMin {
	return {
		clientId: full.clientId,
		onDemandId: full.onDemandId,
		activeFlag: full.activeFlag,
		lowBalanceAmount: full.lowBalanceAmount,
		isDataComplete: full.isDataComplete,
		name: full.name,
		dirName: full.dirName,
		creationTimestamp: full.creationTimestamp,
		defaultSecureFlag: full.defaultSecureFlag,
		lockSecureFlag: full.lockSecureFlag,
		interestFlag: full.interestFlag,
		writeTimestamp: full.writeTimestamp,
		claimBasis: full.claimBasis,
		userCanChangeClaimBasis: full.userCanChangeClaimBasis
	};
}

function updateById(previousById: ById, full: Dto.IClient) {
	const { clientId } = full;
	return Object.assign({}, previousById, {
		[clientId]: Object.assign({}, previousById[clientId], {
			clientId,
			min: createMin(full),
			loading: AsyncState.Resolved,
			full
		})
	});
}

export default function affiliatesReducer(
	previousState: IState = {
		byId: {},
		loading: null,
		saving: {},
		savingNew: false,
		deleting: {}
	},
	action: IAction
) {
	if (action instanceof GetStartupDataAction) {
		const loading = getLoadState(action);

		let { byId } = previousState;
		if (loading === AsyncState.Resolved) {
			byId = Object.assign({}, byId);
			for (const min of action.response!.item.clientsForUser) {
				const { clientId } = min;
				byId[clientId] = Object.assign({
					clientId,
					loading: null,
					full: null
				}, byId[clientId], { min });
			}
		}

		return Object.assign({}, previousState, {
			loading,
			byId
		});
	}

	if (action instanceof GetClientAction) {
		const { clientID } = action.params;
		const client = previousState.byId[clientID];

		const loading = getLoadState(action);

		let min: Dto.IClientMin | null = null;
		let full: Dto.IClient | null = null;
		if (loading === AsyncState.Resolved) {
			full = action.response!.item;
			min = createMin(full);
		} else if (client != null) {
			min = client.min;
			full = client.full;
		}

		return Object.assign({}, previousState, {
			byId: Object.assign({}, previousState.byId, {
				[clientID]: {
					clientId: clientID,
					loading,
					min,
					full
				}
			})
		});
	}

	if (action instanceof SaveAffiliateAction) {
		const { clientId } = action.params.affiliate;

		let { byId, saving } = previousState;
		if (action.state === AsyncState.Pending) {
			saving = Object.assign({}, saving, {
				[clientId]: true
			});
		} else {
			saving = withoutProperties(saving, clientId);
			if (action.state === AsyncState.Resolved) {
				switch (action.response!.status) {
					case OK:
					case MODIFIED_BY_ANOTHER_USER:
						byId = updateById(byId, action.response!.item);
						break;
					default:
						break;
				}
			}
		}

		return Object.assign({}, previousState, {
			byId,
			saving
		});
	}

	if (action instanceof SaveNewAffiliateAction) {
		let { byId } = previousState;
		let savingNew: boolean;

		if (action.state === AsyncState.Pending) {
			savingNew = true;
		} else {
			savingNew = false;
			if (action.state === AsyncState.Resolved) {
				switch (action.response!.status) {
					case OK:
						byId = updateById(byId, action.response!.affiliate);
						break;
					default:
						break;
				}
			}
		}

		return Object.assign({}, previousState, {
			byId,
			savingNew
		});
	}

	if (action instanceof DeleteAffiliateAction) {
		const { clientID } = action.params;

		let { byId, deleting } = previousState;
		if (action.state === AsyncState.Pending) {
			deleting = Object.assign({}, deleting, {
				[clientID]: true
			});
		} else {
			deleting = withoutProperties(deleting, clientID);
			if (action.state === AsyncState.Resolved && action.response!.status === OK) {
				byId = withoutProperties(byId, clientID);
			}
		}

		return Object.assign({}, previousState, {
			byId,
			deleting
		});
	}

	if (action instanceof SaveClientProfileAction && getLoadState(action) === AsyncState.Resolved) {
		// saving the client profile potentially updates clients also so force reloads as needed

		const byId: ById = {};
		for (const clientId of Object.keys(previousState.byId).map(it => +it)) {
			byId[clientId] = Object.assign({}, previousState.byId[clientId], {
				loading: null,
				full: null
			});
		}

		return Object.assign({}, previousState, { byId });
	}

	if (action instanceof UseParentAffiliateModelAction) {
		let { byId, saving } = previousState;
		const clientId = Object.keys(byId)[0];

		if (action.state === AsyncState.Pending) {
			saving = Object.assign({}, saving, {
				[clientId]: true
			});
		} else {
			saving = withoutProperties(saving, clientId);
			if (action.state === AsyncState.Resolved) {
				switch (action.response!.status) {
					case OK:
						byId = updateById(byId, action.response!.client);
						break;
					case MODIFIED_BY_ANOTHER_USER:
						if (action.response!.client != null) {
							byId = updateById(byId, action.response!.client);
						}
						break;
					default:
						break;
				}
			}
		}

		return Object.assign({}, previousState, {
			byId,
			saving
		});
	}

	return previousState;
}