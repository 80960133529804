export interface IPermissions {
	applicationAdministrator: boolean;
	clientAdministrator: boolean;
	flaggedView: boolean;
	flaggedModify: boolean;
	awaitingPlacementView: boolean;
	awaitingPlacementModify: boolean;
	nonMatchedView: boolean;
	nonMatchedModify: boolean;
	dodmatchedView: boolean;
	dodmatchedModify: boolean;
	estateMatchedView: boolean;
	estateMatchedModify: boolean;
	approvedView: boolean;
	approvedModify: boolean;
	processedClaimsView: boolean;
	processedClaimsModify: boolean;
	filedClaimsView: boolean;
	filedClaimsModify: boolean;
	disputedClaimsView: boolean;
	disputedClaimsModify: boolean;
	returnedClaimsView: boolean;
	returnedClaimsModify: boolean;
	closedView: boolean;
	closedModify: boolean;
	viewSsn: boolean;
	reporting: boolean;
	editAccountData: boolean;
	exportAccounts: boolean;
	addAccounts: boolean;
	addDocuments: boolean;
	affiliateManager: boolean;
	attachStatements: boolean;
	lineOfBusinessApproval: boolean;
	requestSatisfactionReleaseDocuments: boolean;
	requestWithdrawalDocuments: boolean;
	requestAgreeToSatisfactionReleaseDocuments: boolean;
	requestWithdrawalDupsDocuments: boolean;
	updateAccountData: boolean;
	performWorkflowActions: boolean;
	validate: boolean;
	dashboardAccess: boolean;
	manageAPIKeys: boolean;
}

export type Permission = "Application_Administrator"
	| "Client_Administrator"
	| "Flagged_View"
	| "Flagged_Modify"
	| "AwaitingPlacement_View"
	| "AwaitingPlacement_Modify"
	| "NonMatched_View"
	| "NonMatched_Modify"
	| "DODMatched_View"
	| "DODMatched_Modify"
	| "EstateMatched_View"
	| "EstateMatched_Modify"
	| "Approved_View"
	| "Approved_Modify"
	| "ProcessedClaims_View"
	| "ProcessedClaims_Modify"
	| "FiledClaims_View"
	| "FiledClaims_Modify"
	| "DisputedClaims_View"
	| "DisputedClaims_Modify"
	| "ReturnedClaims_View"
	| "ReturnedClaims_Modify"
	| "Closed_View"
	| "Closed_Modify"
	| "View_SSN"
	| "Reporting"
	| "Edit_Account_Data"
	| "Perform_Workflow_Actions"
	| "Export_Accounts"
	| "Add_Accounts"
	| "Add_Documents"
	| "Affiliate_Manager"
	| "Attach Statements"
	| "Line of Business Approval"
	| "Request Satisfaction Release Documents"
	| "Request Withdrawal Documents"
	| "Request Agree to Satisfaction Release Documents"
	| "Request Withdrawal Dups Documents"
	| "Update Account Data"
	| "Validate"
	| "Dashboard Access"
	| "Manage API Keys";

export const Permissions = {
	ApplicationAdministrator: "Application_Administrator" as "Application_Administrator",
	ClientAdministrator: "Client_Administrator" as "Client_Administrator",
	FlaggedView: "Flagged_View" as "Flagged_View",
	FlaggedModify: "Flagged_Modify" as "Flagged_Modify",
	AwaitingPlacementView: "AwaitingPlacement_View" as "AwaitingPlacement_View",
	AwaitingPlacementModify: "AwaitingPlacement_Modify" as "AwaitingPlacement_Modify",
	NonMatchedView: "NonMatched_View" as "NonMatched_View",
	NonMatchedModify: "NonMatched_Modify" as "NonMatched_Modify",
	DODMatchedView: "DODMatched_View" as "DODMatched_View",
	DODMatchedModify: "DODMatched_Modify" as "DODMatched_Modify",
	EstateMatchedView: "EstateMatched_View" as "EstateMatched_View",
	EstateMatchedModify: "EstateMatched_Modify" as "EstateMatched_Modify",
	ApprovedView: "Approved_View" as "Approved_View",
	ApprovedModify: "Approved_Modify" as "Approved_Modify",
	ProcessedClaimsView: "ProcessedClaims_View" as "ProcessedClaims_View",
	ProcessedClaimsModify: "ProcessedClaims_Modify" as "ProcessedClaims_Modify",
	FiledClaimsView: "FiledClaims_View" as "FiledClaims_View",
	FiledClaimsModify: "FiledClaims_Modify" as "FiledClaims_Modify",
	DisputedClaimsView: "DisputedClaims_View" as "DisputedClaims_View",
	DisputedClaimsModify: "DisputedClaims_Modify" as "DisputedClaims_Modify",
	ReturnedClaimsView: "ReturnedClaims_View" as "ReturnedClaims_View",
	ReturnedClaimsModify: "ReturnedClaims_Modify" as "ReturnedClaims_Modify",
	ClosedView: "Closed_View" as "Closed_View",
	ClosedModify: "Closed_Modify" as "Closed_Modify",
	ViewSSN: "View_SSN" as "View_SSN",
	Reporting: "Reporting" as "Reporting",
	EditAccountData: "Edit_Account_Data" as "Edit_Account_Data",
	PerformWorkflowActions: "Perform_Workflow_Actions" as "Perform_Workflow_Actions",
	ExportAccounts: "Export_Accounts" as "Export_Accounts",
	AddAccounts: "Add_Accounts" as "Add_Accounts",
	AddDocuments: "Add_Documents" as "Add_Documents",
	AffiliateManager: "Affiliate_Manager" as "Affiliate_Manager",
	AttachStatements: "Attach Statements" as "Attach Statements",
	LineOfBusinessApproval: "Line of Business Approval" as "Line of Business Approval",
	RequestSatisfactionReleaseDocuments: "Request Satisfaction Release Documents" as "Request Satisfaction Release Documents",
	RequestWithdrawalDocuments: "Request Withdrawal Documents" as "Request Withdrawal Documents",
	RequestAgreeToSatisfactionReleaseDocuments: "Request Agree to Satisfaction Release Documents" as "Request Agree to Satisfaction Release Documents",
	RequestWithdrawalDupsDocuments: "Request Withdrawal Dups Documents" as "Request Withdrawal Dups Documents",
	UpdateAccountData: "Update Account Data" as "Update Account Data",
	Validate: "Validate" as "Validate",
	DashboardAccess: "Dashboard Access" as "Dashboard Access",
	ManageAPIKeys: "Manage API Keys" as "Manage API Keys"
};

export const map = {
	[Permissions.ApplicationAdministrator]: nameof<IPermissions>(it => it.applicationAdministrator) as "applicationAdministrator",
	[Permissions.ClientAdministrator]: nameof<IPermissions>(it => it.clientAdministrator) as "clientAdministrator",
	[Permissions.FlaggedView]: nameof<IPermissions>(it => it.flaggedView) as "flaggedView",
	[Permissions.FlaggedModify]: nameof<IPermissions>(it => it.flaggedModify) as "flaggedModify",
	[Permissions.AwaitingPlacementView]: nameof<IPermissions>(it => it.awaitingPlacementView) as "awaitingPlacementView",
	[Permissions.AwaitingPlacementModify]: nameof<IPermissions>(it => it.awaitingPlacementModify) as "awaitingPlacementModify",
	[Permissions.NonMatchedView]: nameof<IPermissions>(it => it.nonMatchedView) as "nonMatchedView",
	[Permissions.NonMatchedModify]: nameof<IPermissions>(it => it.nonMatchedModify) as "nonMatchedModify",
	[Permissions.DODMatchedView]: nameof<IPermissions>(it => it.dodmatchedView) as "dodmatchedView",
	[Permissions.DODMatchedModify]: nameof<IPermissions>(it => it.dodmatchedModify) as "dodmatchedModify",
	[Permissions.EstateMatchedView]: nameof<IPermissions>(it => it.estateMatchedView) as "estateMatchedView",
	[Permissions.EstateMatchedModify]: nameof<IPermissions>(it => it.estateMatchedModify) as "estateMatchedModify",
	[Permissions.ApprovedView]: nameof<IPermissions>(it => it.approvedView) as "approvedView",
	[Permissions.ApprovedModify]: nameof<IPermissions>(it => it.approvedModify) as "approvedModify",
	[Permissions.ProcessedClaimsView]: nameof<IPermissions>(it => it.processedClaimsView) as "processedClaimsView",
	[Permissions.ProcessedClaimsModify]: nameof<IPermissions>(it => it.processedClaimsModify) as "processedClaimsModify",
	[Permissions.FiledClaimsView]: nameof<IPermissions>(it => it.filedClaimsView) as "filedClaimsView",
	[Permissions.FiledClaimsModify]: nameof<IPermissions>(it => it.filedClaimsModify) as "filedClaimsModify",
	[Permissions.DisputedClaimsView]: nameof<IPermissions>(it => it.disputedClaimsView) as "disputedClaimsView",
	[Permissions.DisputedClaimsModify]: nameof<IPermissions>(it => it.disputedClaimsModify) as "disputedClaimsModify",
	[Permissions.ReturnedClaimsView]: nameof<IPermissions>(it => it.returnedClaimsView) as "returnedClaimsView",
	[Permissions.ReturnedClaimsModify]: nameof<IPermissions>(it => it.returnedClaimsModify) as "returnedClaimsModify",
	[Permissions.ClosedView]: nameof<IPermissions>(it => it.closedView) as "closedView",
	[Permissions.ClosedModify]: nameof<IPermissions>(it => it.closedModify) as "closedModify",
	[Permissions.ViewSSN]: nameof<IPermissions>(it => it.viewSsn) as "viewSsn",
	[Permissions.Reporting]: nameof<IPermissions>(it => it.reporting) as "reporting",
	[Permissions.EditAccountData]: nameof<IPermissions>(it => it.editAccountData) as "editAccountData",
	[Permissions.PerformWorkflowActions]: nameof<IPermissions>(it => it.performWorkflowActions) as "performWorkflowActions",
	[Permissions.ExportAccounts]: nameof<IPermissions>(it => it.exportAccounts) as "exportAccounts",
	[Permissions.AddAccounts]: nameof<IPermissions>(it => it.addAccounts) as "addAccounts",
	[Permissions.AddDocuments]: nameof<IPermissions>(it => it.addDocuments) as "addDocuments",
	[Permissions.AffiliateManager]: nameof<IPermissions>(it => it.affiliateManager) as "affiliateManager",
	[Permissions.AttachStatements]: nameof<IPermissions>(it => it.attachStatements) as "attachStatements",
	[Permissions.LineOfBusinessApproval]: nameof<IPermissions>(it => it.lineOfBusinessApproval) as "lineOfBusinessApproval",
	[Permissions.RequestSatisfactionReleaseDocuments]: nameof<IPermissions>(it => it.requestSatisfactionReleaseDocuments) as "requestSatisfactionReleaseDocuments",
	[Permissions.RequestWithdrawalDocuments]: nameof<IPermissions>(it => it.requestWithdrawalDocuments) as "requestWithdrawalDocuments",
	[Permissions.RequestAgreeToSatisfactionReleaseDocuments]: nameof<IPermissions>(it => it.requestAgreeToSatisfactionReleaseDocuments) as "requestAgreeToSatisfactionReleaseDocuments",
	[Permissions.RequestWithdrawalDupsDocuments]: nameof<IPermissions>(it => it.requestWithdrawalDupsDocuments) as "requestWithdrawalDupsDocuments",
	[Permissions.UpdateAccountData]: nameof<IPermissions>(it => it.updateAccountData) as "updateAccountData",
	[Permissions.Validate]: nameof<IPermissions>(it => it.validate) as "validate",
	[Permissions.DashboardAccess]: nameof<IPermissions>(it => it.dashboardAccess) as "dashboardAccess",
	[Permissions.ManageAPIKeys]: nameof<IPermissions>(it => it.manageAPIKeys) as "manageAPIKeys"
};