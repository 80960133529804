import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";

export default class RememberMeAction implements IAction {
	rememberMe: boolean;

	constructor(rememberMe: boolean) {
		this.rememberMe = rememberMe;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"RememberMeAction", { "rememberMe": this.rememberMe.toString() });
	}
}