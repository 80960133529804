import getCurrentUserDenormalized from "../../../../selectors/getCurrentUserDenormalized";
import { startBusy } from "../../../../ActionCreators/AppActionCreators";
import ReduxStore from "../../../../Stores/ReduxStore";
import IconAlert from "../../../Common/IconAlert/IconAlert";
import assign from "../../../../Utils/assign";
import handleGenericError from "../../../../Utils/handleGenericError";
import handleApiResponse from "../../../../Utils/handleApiResponse";
import { saveMyClientProfileParty } from "../../../../ActionCreators/Api/clientActionCreators";
import React from "react";
import * as Dto from "../../../../Models/dto";
import connect from "../../../../Utils/connect";
import styles from "./UserAgreement.module.scss";
import { Modal } from "react-bootstrap";
import DraggableModal from "../../../Common/DraggableModal/DraggableModal";
import * as selectors from "./selectors";
import noop from "lodash/noop";
import { appName } from "../../../../Utils/branding";
import CheckboxInput2 from "../../../Common/CheckboxInput2/CheckboxInput2";
import Button from "../../../Common/Button/Button";

interface IOwnProps { }

interface IConnectedProps {
	userAgreement: Dto.ILegalDocument | null;
	show: boolean;
	onAccept(): void;
}

interface IProps extends IOwnProps, IConnectedProps { }

interface IState {
	readAgreement: boolean;
	acceptedAgreement: boolean;
}

class UserAgreement extends React.PureComponent<IProps, IState> {
	private _agreementElement: HTMLDivElement | null | undefined;

	constructor(props: IProps, context: any) {
		super(props, context);

		this.state = {
			readAgreement: false,
			acceptedAgreement: false
		};

		this._handleAgreementScroll = this._handleAgreementScroll.bind(this);
		this._handleAgreementToggle = this._handleAgreementToggle.bind(this);
	}

	componentDidMount() {
		this._handleAgreementScroll();
	}

	componentDidUpdate() {
		this._handleAgreementScroll();
	}

	render() {
		const { props } = this;
		const { userAgreement } = props;

		return (
			<DraggableModal
				show={props.show}
				onHide={noop} // not getting away that easy
				bsSize="lg"
			>
				<Modal.Header>
					<Modal.Title>System User Terms and Conditions</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<IconAlert
						alertType="alert-warning"
						icon="fa-exclamation-triangle"
					>
						The {appName} System User Terms and Conditions has been updated.
						You must review and approve the new System User Terms and Conditions or you will no
						longer be able to access {appName}.
					</IconAlert>
					<div className={styles.userAgreementContent}>
						<div
							ref={it => this._agreementElement = it}
							dangerouslySetInnerHTML={{ __html: userAgreement == null ? "" : userAgreement.text }}
							onScroll={this.state.readAgreement ? undefined : this._handleAgreementScroll}
						/>
					</div>
					<div className={styles.agreeRow}>
						<CheckboxInput2
							value={this.state.acceptedAgreement}
							onChange={this._handleAgreementToggle}
						>
							I understand the System User Terms and Conditions and agree to the above.
						</CheckboxInput2>
						{!this.state.readAgreement && (
							<div className="text-right text-danger">You must read the entire System User Terms and Conditions.</div>
						)}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick={props.onAccept}
						disabled={!this.state.readAgreement || !this.state.acceptedAgreement}
						primary
					>
						Continue
					</Button>
				</Modal.Footer>
			</DraggableModal>
		);
	}

	private _handleAgreementScroll() {
		if (this.state.readAgreement || this.props.userAgreement == null || this._agreementElement == null) {
			return;
		}

		if (this._agreementElement.scrollHeight - this._agreementElement.scrollTop - this._agreementElement.parentElement!.clientHeight <= 3) {
			this.setState({ readAgreement: true });
		}
	}

	private _handleAgreementToggle() {
		this.setState(oldState => assign(oldState, {
			acceptedAgreement: !oldState.acceptedAgreement
		}));
	}
}

async function onAccept() {
	const state = ReduxStore.getState();

	const userAgreement = selectors.getUserAgreement(state);
	const user = getCurrentUserDenormalized(state)!;

	const updatedUser = assign(user, {
		userAgreementAccepted: true,
		userAgreementAcceptedLegalDocId: userAgreement!.legalDocId
	});

	const stopBusy = startBusy("Saving...");

	try {
		const response = await saveMyClientProfileParty(updatedUser);
		handleApiResponse(response);
	} catch (error) {
		handleGenericError(error);
	} finally {
		stopBusy();
	}
}

export default connect<IConnectedProps, IOwnProps>(
	UserAgreement,
	state => ({
		userAgreement: selectors.getUserAgreement(state),
		show: selectors.getShowUserAgreement(state),
		onAccept
	})
);