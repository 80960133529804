import * as React from "react";
import ReduxStore from "../Stores/ReduxStore";
import { IState } from "../Reducers/rootReducer";
import getDisplayName from "./getDisplayName";
import { AsyncState, combineStates } from "../Models/IAsync";
import Dispatcher from "../Dispatcher/Dispatcher";

interface ILoader<TOwnProps> {
	blocking: boolean;
	condition(state: IState, ownProps: TOwnProps): AsyncState | null;
	load(state: IState, ownProps: TOwnProps): void;
}

export function loader<TOwnProps>(
	condition: (state: IState, ownProps: TOwnProps) => AsyncState | null,
	load: (state: IState, ownProps: TOwnProps) => void,
	blocking = true
): ILoader<TOwnProps> {
	return {
		condition,
		load,
		blocking
	};
}

function connect<TConnectedProps, TOwnProps>(
	WrappedComponent: React.ComponentClass<TConnectedProps & TOwnProps>,
	stateToProps: (state: IState, ownProps: TOwnProps, loadState: AsyncState) => TConnectedProps,
	...loaders: ILoader<TOwnProps>[]
): React.ComponentClass<TOwnProps>;
function connect<TConnectedProps, TOwnProps>(
	WrappedComponent: React.StatelessComponent<TConnectedProps & TOwnProps>,
	stateToProps: (state: IState, ownProps: TOwnProps, loadState: AsyncState) => TConnectedProps,
	...loaders: ILoader<TOwnProps>[]
): React.ComponentClass<TOwnProps>;
function connect<TConnectedProps, TOwnProps>(
	WrappedComponent: any,
	stateToProps: (state: IState, ownProps: TOwnProps, loadState: AsyncState) => TConnectedProps,
	...loaders: ILoader<TOwnProps>[]
): React.ComponentClass<TOwnProps> {
	class ConnectedComponent extends React.Component<TOwnProps, TConnectedProps> {
		static displayName = `${nameof(ConnectedComponent)}(${getDisplayName(WrappedComponent)})`;

		private _loadHandle: number | undefined;

		private _update = () => {
			this.setState(this._getConnectedProps());
			this._tryLoad();
		}

		constructor(props: TOwnProps, context: any) {
			super(props, context);
			this.state = this._getConnectedProps();
		}

		componentDidMount() {
			ReduxStore.addListener(this._update);
			this._tryLoad();
		}

		componentWillUnmount() {
			ReduxStore.removeListener(this._update);
		}

		componentDidUpdate() {
			// ownProps changed, do the load check
			this._tryLoad();
		}

		render() {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
				/>
			);
		}

		private _getConnectedProps() {
			const state = ReduxStore.getState();
			const loadState = combineStates(
				...loaders.filter(it => it.blocking).map(it => it.condition(state, this.props))
			);
			return stateToProps(state, this.props, loadState);
		}

		private _tryLoad() {
			if (loaders.length === 0) {
				return;
			}

			// this timeout stuff sucks but is necessary until we go full redux
			clearTimeout(this._loadHandle);
			if (Dispatcher.isDispatching()) {
				this._loadHandle = setTimeout(() => this._load());
			} else {
				this._load();
			}
		}

		private _load() {
			for (const loader of loaders) {
				const state = ReduxStore.getState();
				const loadState = loader.condition(state, this.props);
				if (loadState == null) {
					loader.load(state, this.props);
				}
			}
		}
	}

	return ConnectedComponent;
}

export type IAppState = IState;
export default connect;