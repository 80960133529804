/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IAffiliateReconResult,
	IApplicationRole,
	IBedFundPolicy,
	IClaimBasisDesc,
	IClient,
	IClientProfile,
	IClientProfileAndClientResponse,
	IClientProfileIPWhiteList,
	IClientProfileParty,
	IClientProfilePricingHistoriesResponse,
	IClientProfileRole,
	IClientProfileStatementEmail,
	IClientRegistrationDataResponse,
	ICollectionResponse,
	INewAffiliateResponse,
	IResponse,
	ISaveClientProfileResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IAffiliateReconActionParams {
	data: string | Blob;
	fileName: string;
}

export class AffiliateReconAction extends ApiActionBase<IAffiliateReconActionParams, IResponse<IAffiliateReconResult>> {
	constructor(details: IDetails<IAffiliateReconActionParams, IResponse<IAffiliateReconResult>>) {
		super("Api/ClientActions/AffiliateReconAction", details);
	}
}

export interface IDeleteAffiliateActionParams {
	clientID: number;
}

export class DeleteAffiliateAction extends ApiActionBase<IDeleteAffiliateActionParams, IResponse<boolean>> {
	constructor(details: IDetails<IDeleteAffiliateActionParams, IResponse<boolean>>) {
		super("Api/ClientActions/DeleteAffiliateAction", details);
	}
}

export interface IDeleteClientProfilePartyActionParams {
	clientProfileParty: IClientProfileParty;
}

export class DeleteClientProfilePartyAction extends ApiActionBase<IDeleteClientProfilePartyActionParams, IResponse<IClientProfileParty>> {
	constructor(details: IDetails<IDeleteClientProfilePartyActionParams, IResponse<IClientProfileParty>>) {
		super("Api/ClientActions/DeleteClientProfilePartyAction", details);
	}
}

export interface IDeleteClientProfileRoleActionParams {
	clientProfileRole: IClientProfileRole;
}

export class DeleteClientProfileRoleAction extends ApiActionBase<IDeleteClientProfileRoleActionParams, IResponse<IClientProfileRole>> {
	constructor(details: IDetails<IDeleteClientProfileRoleActionParams, IResponse<IClientProfileRole>>) {
		super("Api/ClientActions/DeleteClientProfileRoleAction", details);
	}
}

export interface IGetAllUsersActionParams {
	/* no parameters */
}

export class GetAllUsersAction extends ApiActionBase<IGetAllUsersActionParams, ICollectionResponse<IClientProfileParty>> {
	constructor(details: IDetails<IGetAllUsersActionParams, ICollectionResponse<IClientProfileParty>>) {
		super("Api/ClientActions/GetAllUsersAction", details);
	}
}

export interface IGetAllUsersForAffiliateManagerActionParams {
	/* no parameters */
}

export class GetAllUsersForAffiliateManagerAction extends ApiActionBase<IGetAllUsersForAffiliateManagerActionParams, ICollectionResponse<IClientProfileParty>> {
	constructor(details: IDetails<IGetAllUsersForAffiliateManagerActionParams, ICollectionResponse<IClientProfileParty>>) {
		super("Api/ClientActions/GetAllUsersForAffiliateManagerAction", details);
	}
}

export interface IGetApplicationRolesActionParams {
	/* no parameters */
}

export class GetApplicationRolesAction extends ApiActionBase<IGetApplicationRolesActionParams, ICollectionResponse<IApplicationRole>> {
	constructor(details: IDetails<IGetApplicationRolesActionParams, ICollectionResponse<IApplicationRole>>) {
		super("Api/ClientActions/GetApplicationRolesAction", details);
	}
}

export interface IGetBedFundPolicyForClientIDActionParams {
	clientID: number;
}

export class GetBedFundPolicyForClientIDAction extends ApiActionBase<IGetBedFundPolicyForClientIDActionParams, IResponse<IBedFundPolicy>> {
	constructor(details: IDetails<IGetBedFundPolicyForClientIDActionParams, IResponse<IBedFundPolicy>>) {
		super("Api/ClientActions/GetBedFundPolicyForClientIDAction", details);
	}
}

export interface IGetClaimBasisOptionsActionParams {
	/* no parameters */
}

export class GetClaimBasisOptionsAction extends ApiActionBase<IGetClaimBasisOptionsActionParams, ICollectionResponse<IClaimBasisDesc>> {
	constructor(details: IDetails<IGetClaimBasisOptionsActionParams, ICollectionResponse<IClaimBasisDesc>>) {
		super("Api/ClientActions/GetClaimBasisOptionsAction", details);
	}
}

export interface IGetClientActionParams {
	clientID: number;
}

export class GetClientAction extends ApiActionBase<IGetClientActionParams, IResponse<IClient>> {
	constructor(details: IDetails<IGetClientActionParams, IResponse<IClient>>) {
		super("Api/ClientActions/GetClientAction", details);
	}
}

export interface IGetClientProfileActionParams {
	/* no parameters */
}

export class GetClientProfileAction extends ApiActionBase<IGetClientProfileActionParams, IResponse<IClientProfile>> {
	constructor(details: IDetails<IGetClientProfileActionParams, IResponse<IClientProfile>>) {
		super("Api/ClientActions/GetClientProfileAction", details);
	}
}

export interface IGetClientProfileIPWhiteListActionParams {
	/* no parameters */
}

export class GetClientProfileIPWhiteListAction extends ApiActionBase<IGetClientProfileIPWhiteListActionParams, ICollectionResponse<IClientProfileIPWhiteList>> {
	constructor(details: IDetails<IGetClientProfileIPWhiteListActionParams, ICollectionResponse<IClientProfileIPWhiteList>>) {
		super("Api/ClientActions/GetClientProfileIPWhiteListAction", details);
	}
}

export interface IGetClientProfilePricingHistoriesActionParams {
	/* no parameters */
}

export class GetClientProfilePricingHistoriesAction extends ApiActionBase<IGetClientProfilePricingHistoriesActionParams, IClientProfilePricingHistoriesResponse> {
	constructor(details: IDetails<IGetClientProfilePricingHistoriesActionParams, IClientProfilePricingHistoriesResponse>) {
		super("Api/ClientActions/GetClientProfilePricingHistoriesAction", details);
	}
}

export interface IGetClientProfileRolesActionParams {
	/* no parameters */
}

export class GetClientProfileRolesAction extends ApiActionBase<IGetClientProfileRolesActionParams, ICollectionResponse<IClientProfileRole>> {
	constructor(details: IDetails<IGetClientProfileRolesActionParams, ICollectionResponse<IClientProfileRole>>) {
		super("Api/ClientActions/GetClientProfileRolesAction", details);
	}
}

export interface IGetClientProfileStatementEmailAddressesActionParams {
	/* no parameters */
}

export class GetClientProfileStatementEmailAddressesAction extends ApiActionBase<IGetClientProfileStatementEmailAddressesActionParams, ICollectionResponse<IClientProfileStatementEmail>> {
	constructor(details: IDetails<IGetClientProfileStatementEmailAddressesActionParams, ICollectionResponse<IClientProfileStatementEmail>>) {
		super("Api/ClientActions/GetClientProfileStatementEmailAddressesAction", details);
	}
}

export interface IGetClientRegistrationDataActionParams {
	/* no parameters */
}

export class GetClientRegistrationDataAction extends ApiActionBase<IGetClientRegistrationDataActionParams, IClientRegistrationDataResponse> {
	constructor(details: IDetails<IGetClientRegistrationDataActionParams, IClientRegistrationDataResponse>) {
		super("Api/ClientActions/GetClientRegistrationDataAction", details);
	}
}

export interface IResetPasswordActionParams {
	clientProfileParty: IClientProfileParty;
}

export class ResetPasswordAction extends ApiActionBase<IResetPasswordActionParams, IResponse<IClientProfileParty>> {
	constructor(details: IDetails<IResetPasswordActionParams, IResponse<IClientProfileParty>>) {
		super("Api/ClientActions/ResetPasswordAction", details);
	}
}

export interface ISaveAffiliateActionParams {
	affiliate: IClient;
}

export class SaveAffiliateAction extends ApiActionBase<ISaveAffiliateActionParams, IResponse<IClient>> {
	constructor(details: IDetails<ISaveAffiliateActionParams, IResponse<IClient>>) {
		super("Api/ClientActions/SaveAffiliateAction", details);
	}
}

export interface ISaveClientProfileActionParams {
	clientProfile: IClientProfile;
}

export class SaveClientProfileAction extends ApiActionBase<ISaveClientProfileActionParams, ISaveClientProfileResponse> {
	constructor(details: IDetails<ISaveClientProfileActionParams, ISaveClientProfileResponse>) {
		super("Api/ClientActions/SaveClientProfileAction", details);
	}
}

export interface ISaveClientProfileIPWhiteListActionParams {
	whitelist: IClientProfileIPWhiteList[];
}

export class SaveClientProfileIPWhiteListAction extends ApiActionBase<ISaveClientProfileIPWhiteListActionParams, ICollectionResponse<IClientProfileIPWhiteList>> {
	constructor(details: IDetails<ISaveClientProfileIPWhiteListActionParams, ICollectionResponse<IClientProfileIPWhiteList>>) {
		super("Api/ClientActions/SaveClientProfileIPWhiteListAction", details);
	}
}

export interface ISaveClientProfilePartyActionParams {
	clientProfileParty: IClientProfileParty;
}

export class SaveClientProfilePartyAction extends ApiActionBase<ISaveClientProfilePartyActionParams, IResponse<IClientProfileParty>> {
	constructor(details: IDetails<ISaveClientProfilePartyActionParams, IResponse<IClientProfileParty>>) {
		super("Api/ClientActions/SaveClientProfilePartyAction", details);
	}
}

export interface ISaveClientProfileRoleActionParams {
	clientProfileRole: IClientProfileRole;
}

export class SaveClientProfileRoleAction extends ApiActionBase<ISaveClientProfileRoleActionParams, IResponse<IClientProfileRole>> {
	constructor(details: IDetails<ISaveClientProfileRoleActionParams, IResponse<IClientProfileRole>>) {
		super("Api/ClientActions/SaveClientProfileRoleAction", details);
	}
}

export interface ISaveClientProfileStatementEmailAddressesActionParams {
	emailAddresses: IClientProfileStatementEmail[];
}

export class SaveClientProfileStatementEmailAddressesAction extends ApiActionBase<ISaveClientProfileStatementEmailAddressesActionParams, ICollectionResponse<IClientProfileStatementEmail>> {
	constructor(details: IDetails<ISaveClientProfileStatementEmailAddressesActionParams, ICollectionResponse<IClientProfileStatementEmail>>) {
		super("Api/ClientActions/SaveClientProfileStatementEmailAddressesAction", details);
	}
}

export interface ISaveMyClientProfilePartyActionParams {
	clientProfileParty: IClientProfileParty;
}

export class SaveMyClientProfilePartyAction extends ApiActionBase<ISaveMyClientProfilePartyActionParams, IResponse<IClientProfileParty>> {
	constructor(details: IDetails<ISaveMyClientProfilePartyActionParams, IResponse<IClientProfileParty>>) {
		super("Api/ClientActions/SaveMyClientProfilePartyAction", details);
	}
}

export interface ISaveNewAffiliateActionParams {
	affiliate: IClient;
	affiliateManager: IClientProfileParty;
	renamingDefault: boolean;
}

export class SaveNewAffiliateAction extends ApiActionBase<ISaveNewAffiliateActionParams, INewAffiliateResponse> {
	constructor(details: IDetails<ISaveNewAffiliateActionParams, INewAffiliateResponse>) {
		super("Api/ClientActions/SaveNewAffiliateAction", details);
	}
}

export interface IUseParentAffiliateModelActionParams {
	writeTimestamp: string;
	clientWriteTimestamp: string;
}

export class UseParentAffiliateModelAction extends ApiActionBase<IUseParentAffiliateModelActionParams, IClientProfileAndClientResponse> {
	constructor(details: IDetails<IUseParentAffiliateModelActionParams, IClientProfileAndClientResponse>) {
		super("Api/ClientActions/UseParentAffiliateModelAction", details);
	}
}

/* tslint:enable:max-line-length */