import { ActionBase } from "../IAction";
import { IClientProfileUserSearch } from "../../Models/dto";

export default class ReviewItemSearchAction extends ActionBase {
	constructor(
		public workflowId: number,
		public search: Partial<IClientProfileUserSearch>
	) {
		super(nameof(ReviewItemSearchAction));
	}
}