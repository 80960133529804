/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	AdminSwitchClientProfileAction,
	IAdminSwitchClientProfileActionParams,
	GetAllClientProfilesAction,
	IGetAllClientProfilesActionParams
} from "../../Actions/Api/administrationActions";
import {
	IBaseResponse,
	IClientProfileMin,
	ICollectionResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const adminSwitchClientProfileEndpoint = "Api/Administration/AdminSwitchClientProfile";

export function adminSwitchClientProfile(
	targetClientProfileID: number, 
	__options__?: IOptions<IBaseResponse>
) {
	return apiExecute<IAdminSwitchClientProfileActionParams, AdminSwitchClientProfileAction, IBaseResponse>(
		adminSwitchClientProfileEndpoint, 
		true, 
		__options__, 
		AdminSwitchClientProfileAction,
		{ targetClientProfileID }
	);
}

export const getAllClientProfilesEndpoint = "Api/Administration/GetAllClientProfiles";

export function getAllClientProfiles(
	__options__?: IOptions<ICollectionResponse<IClientProfileMin>>
) {
	return apiExecute<IGetAllClientProfilesActionParams, GetAllClientProfilesAction, ICollectionResponse<IClientProfileMin>>(
		getAllClientProfilesEndpoint, 
		true, 
		__options__, 
		GetAllClientProfilesAction,
		{ /* no parameters */ }
	);
}

/* tslint:enable:max-line-length */