import noop from "lodash/noop";

export default function catchAsyncErrors<T>(promise: Promise<T>, handler: (error: any) => void = noop) {
	if (__DEV__) {
		return promise.catch(error => {
			console.warn(error);
			handler(error);
		});
	} else {
		return promise.catch(handler);
	}
}