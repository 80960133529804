/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	ICollectionResponse,
	IReport,
	IReportData,
	IResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IDeleteReportActionParams {
	reportID: number;
}

export class DeleteReportAction extends ApiActionBase<IDeleteReportActionParams, IResponse<number>> {
	constructor(details: IDetails<IDeleteReportActionParams, IResponse<number>>) {
		super("Api/PfodReportingActions/DeleteReportAction", details);
	}
}

export interface IGetAvailableReportsV4ActionParams {
	/* no parameters */
}

export class GetAvailableReportsV4Action extends ApiActionBase<IGetAvailableReportsV4ActionParams, ICollectionResponse<IReport>> {
	constructor(details: IDetails<IGetAvailableReportsV4ActionParams, ICollectionResponse<IReport>>) {
		super("Api/PfodReportingActions/GetAvailableReportsV4Action", details);
	}
}

export interface IGetReportV4ActionParams {
	report: IReport;
}

export class GetReportV4Action extends ApiActionBase<IGetReportV4ActionParams, IResponse<IReportData>> {
	constructor(details: IDetails<IGetReportV4ActionParams, IResponse<IReportData>>) {
		super("Api/PfodReportingActions/GetReportV4Action", details);
	}
}

export interface ISaveReportActionParams {
	report: IReport;
}

export class SaveReportAction extends ApiActionBase<ISaveReportActionParams, IResponse<IReport>> {
	constructor(details: IDetails<ISaveReportActionParams, IResponse<IReport>>) {
		super("Api/PfodReportingActions/SaveReportAction", details);
	}
}

/* tslint:enable:max-line-length */