import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";
import * as AccountsData from "../../Models/AccountsData";

export default class SortChangedAction implements IAction {
	sortInfo: Array<AccountsData.ISortInfo>;

	constructor(sortInfo: Array<AccountsData.ISortInfo>) {
		this.sortInfo = sortInfo;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"SortChangedAction", { "sortInfo": JSON.stringify(this.sortInfo) });
	}
}