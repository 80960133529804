import isEmpty from "./isEmpty";

const digitRegexes: { [length: number]: RegExp } = {};

function checkDigits(value: string | null, ...allowedLengths: number[]) {
	if (!isEmpty(value)) {
		const validLength = allowedLengths.some(length => {
			const regex = digitRegexes[length] || (digitRegexes[length] = new RegExp(`^\\d{${length}}$`));
			return regex.test(value!);
		});

		if (!validLength) {
			return false;
		}
	}

	return true;
}

export function zip(value: string) {
	return checkDigits(value, 5, 9);
}

export function ssn(value: string) {
	return checkDigits(value, 9);
}

const R_REDACTED_SSN = /^XXXXX\d{4}/;
export function redactedSsn(value: string | null) {
	return isEmpty(value) || R_REDACTED_SSN.test(value!);
}

export function phone(value: string | null) {
	return checkDigits(value, 10);
}

export const TOLL_FREE_AREA_CODES = [800, 888, 877, 866, 855, 844, 833].map(it => it.toString());
export function tollFreePhone(value: string) {
	return isEmpty(value) || (phone(value) && TOLL_FREE_AREA_CODES.indexOf(value.substr(0, 3)) !== -1);
}

/* tslint:disable:max-line-length */
// eslint-disable-next-line no-control-regex, no-useless-escape
const R_EMAIL = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i;
/* tslint:enable:max-line-length */

export function email(value: string) {
	return isEmpty(value) || R_EMAIL.test(value);
}