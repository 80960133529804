/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	ICollectionResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IGetInvoicesActionParams {
	/* no parameters */
}

export class GetInvoicesAction extends ApiActionBase<IGetInvoicesActionParams, ICollectionResponse<string>> {
	constructor(details: IDetails<IGetInvoicesActionParams, ICollectionResponse<string>>) {
		super("Api/InvoiceActions/GetInvoicesAction", details);
	}
}

/* tslint:enable:max-line-length */