import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./HelpPopup.module.scss";
import { ReactComponent as TooltipIcon } from "../../../../Images/tooltip.svg";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";

export interface IProps {
	className?: ClassValue;
	title?: React.ReactNode;
	children?: React.ReactNode;
	showOnFocus?: boolean;
	placement?: "top" | "right" | "bottom" | "left";
	onButtonClick?: () => void;
}

export const CONTAINER_CLASS = "HelpPopup__container_56ebe775";

function findContainer(node: HTMLElement, checkForExplictContainer = true): HTMLElement {
	if (checkForExplictContainer) {
		const container = node.closest(`.${CONTAINER_CLASS}`);
		if (container != null && container instanceof HTMLElement) {
			return container;
		}
	}

	if (
		node === document.body
		|| node.classList.contains("tab-content")
		|| node.classList.contains("flex-content")
		|| node.parentElement == null
	) {
		return node;
	}

	return findContainer(node.parentElement, false);
}

export default function HelpPopup(props: IProps) {
	const [show, setShow] = useState(false);
	const [focused, setFocused] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [forceShow, setForceShow] = useState(false);

	useEffect(() => {
		if (show) {
			const handleResize = () => {
				setShow(false);
				setHovered(false);
			};

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}
	}, [show]);

	useEffect(() => {
		if (props.showOnFocus) {
			setShow(false);
		}
	}, [props.showOnFocus]);


	const overlay = (
		<div className={styles.tooltipContent}>
			{props.title && (
				<div className={styles.title}>
					{props.title}
				</div>
			)}
			{props.children}
		</div>
	);

	return (
		<Tooltip
			placement={props.placement ?? "right"}
			trigger={[]}
			overlay={overlay}
			visible={
				(props.showOnFocus ? (focused || hovered) : show)
				|| (process.env.NODE_ENV === "development" && forceShow)
			}
			overlayClassName={styles.tooltipOverlay}
			getTooltipContainer={findContainer}
			destroyTooltipOnHide
		>
			<button
				type="button"
				className={cn("HelpPopup", styles.link, props.className)}
				onClick={() => {
					props.onButtonClick?.();
					if (!props.showOnFocus) {
						setShow(!show);
					}
				}}
				onFocus={() => setFocused(true)}
				onBlur={() => {
					setShow(false);
					setFocused(false);
				}}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				onKeyDown={props.showOnFocus ? undefined : event => {
					if (event.key === "Escape") {
						setShow(false);
					}
				}}
				ref={(
					process.env.NODE_ENV === "development"
						? node => {
							if (node != null) {
								(node as any).__force__ = (value = !forceShow) => setForceShow(value);
							}
						}
						: undefined
				)}
			>
				<TooltipIcon aria-hidden />
				<VisuallyHidden>
					Help:
					{overlay}
				</VisuallyHidden>
			</button>
		</Tooltip>
	);
}
