const R_SPECIAL = /(?=.*[@#~`*$%{}()!|/+-,.<>?])/;
const R_UPPER = /(?=.*[A-Z])/;
const R_LOWER = /(?=.*[a-z])/;
const R_NUMBER = /(?=.*[0-9])/;

export interface IValidation {
	valid: boolean;
	length: boolean;
	special: boolean;
	upper: boolean;
	lower: boolean;
	number: boolean;
	confirm: boolean;
}

export default function validatePassword(password: string, confirmPassword: string, minLength = 10): IValidation {
	password = password || "";
	confirmPassword = confirmPassword || "";

	const length = password.length >= minLength;
	const special = R_SPECIAL.test(password);
	const upper = R_UPPER.test(password);
	const lower = R_LOWER.test(password);
	const number = R_NUMBER.test(password);
	const confirm = password.length > 0 && password === confirmPassword;

	return {
		valid: length && special && upper && lower && number && confirm,
		length,
		special,
		upper,
		lower,
		number,
		confirm
	};
}