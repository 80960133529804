import React from "react";
import AuthHeader from "../Auth/AuthHeader/AuthHeader";
//import styles from "./LockView.module.less";
import AuthPage from "../Auth/AuthPage/AuthPage";

interface IProps {
	children: React.ReactNode;
}

const SsoLockView = ({ children }: IProps) => {
	return (
		<AuthPage onSubmit={e => e.preventDefault()}>
			<AuthHeader>Logged Out</AuthHeader>
			<p>
				{children}
			</p>
		</AuthPage>
	);
};

export default SsoLockView;