import React from "react";
import styles from "./Forms.module.scss";
import icon from "../../../../Images/registration/confirm-icon.jpg";
import { IProps } from "./Forms";
import Form from "../../Common/Form/Form";
import FormGroup from "../../Common/FormGroup/FormGroup";
import DatePicker from "../../Common/DatePicker/DatePicker";
import TimeInput from "../..//Dashboard/Settings/CompanyProfile/CompanyDetails/Questions/HoursOfOperation/Day/TimeInput/TimeInput";
import { ITime } from "../../Dashboard/Settings/CompanyProfile/CompanyDetails/Questions/HoursOfOperation/Day/models";
import moment from "moment";
import momenttz from "moment-timezone";
import { appName } from "../../../Utils/branding";

interface IValid {
	option1Valid: boolean;
	option2Valid: boolean;
	option3Valid: boolean;
	tzValid: boolean;
	allValid: boolean;
}

interface IState {
	date1: moment.Moment | null;
	time1: ITime | null;
	date2: moment.Moment | null;
	time2: ITime | null;
	date3: moment.Moment | null;
	time3: ITime | null;
	tzOptions: string[];
	tz: string;
}
export default class NextSteps extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = this.propsToState();
	}

	propsToState(): IState {
		const d1 = this.getDateFields(0);
		const d2 = this.getDateFields(1);
		const d3 = this.getDateFields(2);

		return {
			date1: d1.date,
			time1: d1.time,
			date2: d2.date,
			time2: d2.time,
			date3: d3.date,
			time3: d3.time,
			tzOptions: this.getTzOptions(),
			tz: this.props.model.timezone || this.getInitialTimeZone()
		};
	}

	dateToStr(date: moment.Moment, time: ITime): string {
		return moment(date)
			.hour(time.hour)
			.minute(time.minute)
			.format("YYYY-MM-DDTHH:mm:ss");
	}

	onDone() {
		const arr: string[] = [];
		if (this.state.date1 && this.state.time1) {
			arr.push(this.dateToStr(this.state.date1, this.state.time1));
		}

		if (this.state.date2 && this.state.time2) {
			arr.push(this.dateToStr(this.state.date2, this.state.time2));
		}

		if (this.state.date3 && this.state.time3) {
			arr.push(this.dateToStr(this.state.date3, this.state.time3));
		}

		this.props.setValue(arr, (v, m) => m.implementationOptions = v);
		this.props.setValue(this.state.tz, (v, m) => m.timezone = v);
		this.props.onConfirm();
	}

	getDateFields(n: number): { date: moment.Moment | null, time: ITime | null } {
		if (!this.props.model.implementationOptions.length) {
			return { date: null, time: null };
		}

		const str = this.props.model.implementationOptions[n];
		if (!str) {
			return { date: null, time: null };
		}

		const m = moment(str);
		return {
			date: moment(m).startOf("day"),
			time: {
				hour: m.hour(),
				minute: m.minute()
			}
		};
	}

	validate(): IValid {
		const minDate = moment().startOf("day").add(2, "day");
		let option1Valid = (!!this.state.date1) === (!!this.state.time1);
		let option2Valid = (!!this.state.date2) === (!!this.state.time2);
		let option3Valid = (!!this.state.date3) === (!!this.state.time3);

		option1Valid = option1Valid && (!this.state.date1 || !this.state.date1.isBefore(minDate));
		option2Valid = option2Valid && (!this.state.date2 || !this.state.date2.isBefore(minDate));
		option3Valid = option3Valid && (!this.state.date3 || !this.state.date3.isBefore(minDate));

		let tzValid = !!this.state.tz;

		return {
			option1Valid,
			option2Valid,
			option3Valid,
			tzValid: tzValid,
			allValid: option1Valid
				&& option2Valid
				&& option3Valid
				&& tzValid
		};
	}

	getWeekdays(): moment.Moment[] {
		const result: moment.Moment[] = [];
		let curDate = moment().startOf("day").add(1, "day");

		for (let i = 0; i < 30; i++) {
			curDate = curDate.clone().add(1, "day");
			const day = curDate.format("dddd");
			if (day === "Saturday" || day === "Sunday") {
				continue;
			}

			result.push(curDate);
		}

		return result;
	}

	render() {
		const { props } = this;
		const valid = this.validate();
		const weekdays = this.getWeekdays();

		return (
			<div className={styles.page}>
				<div className={styles.header}>
					<img src={icon} alt="" height={50} width="auto" />
					<div>
						<div className={styles.title}>
							Implementation kick-off meeting
						</div>
						<div className={styles.subtitle}>
							<p>
								Let us know if you have some dates in mind to meet with our team and get started.
							</p>

							<p>
								This meeting is reserved for setting up your {appName} company profile,
								discussing account placement strategy and placement file requirements.
								Key individuals to have in this meeting would be representation from the
								Compliance, Operations and IT Departments.
							</p>
						</div>
					</div>
				</div>

				<div className={styles.fields}>
					<Form
						horizontal
						onSubmit={e => e.preventDefault()}
					>
						<FormGroup
							label="Time Zone"
							hasError={!valid.tzValid}
						>
							{id => (
								<select
									id={id}
									value={this.state.tz}
									className="form-control"
									onChange={e => this.setState({ tz: e.target.value })}
								>
									{this.state.tzOptions.map((it, idx) => (
										<option
											key={idx}
											value={it}
										>
											{it}
										</option>
									))}
								</select>
							)}
						</FormGroup>

						<FormGroup
							label="Option 1"
							hasError={!valid.option1Valid}
						>
							{id => (
								<div id={id} className={styles.dateTimeInput}>
									<DatePicker
										selected={this.state.date1}
										onChange={d => this.setState({ date1: d })}
										placeholderText="mm/dd/yyyy"
										includeDates={weekdays}
									/>
									<TimeInput
										value={this.state.time1}
										onChange={(v, _r) => this.setState({ time1: v })}
										placeholder="12:00 AM"
									/>
								</div>
							)}
						</FormGroup>

						<FormGroup
							label="Option 2"
							hasError={!valid.option2Valid}
						>
							{id => (
								<div id={id} className={styles.dateTimeInput}>
									<DatePicker
										selected={this.state.date2}
										onChange={d => this.setState({ date2: d })}
										placeholderText="mm/dd/yyyy"
										includeDates={weekdays}
									/>
									<TimeInput
										value={this.state.time2}
										onChange={(v, _r) => this.setState({ time2: v })}
										placeholder="12:00 AM"
									/>
								</div>
							)}
						</FormGroup>

						<FormGroup
							label="Option 3"
							hasError={!valid.option3Valid}
						>
							{id => (
								<div id={id} className={styles.dateTimeInput}>
									<DatePicker
										selected={this.state.date3}
										onChange={d => this.setState({ date3: d })}
										placeholderText="mm/dd/yyyy"
										includeDates={weekdays}
									/>
									<TimeInput
										value={this.state.time3}
										onChange={(v, _r) => this.setState({ time3: v })}
										placeholder="12:00 AM"
									/>
								</div>
							)}
						</FormGroup>

						<div className={styles.actions}>
							<button
								type="button"
								className="btn btn-light"
								onClick={() => props.onBack()}
							>
								Back
							</button>
							<button
								type="button"
								className="btn btn-warning"
								disabled={!valid.allValid}
								onClick={() => this.onDone()}
							>
								Continue
							</button>
						</div>
					</Form>
				</div>
			</div>
		);
	}

	getTzOptions(): string[] {
		return [
			...momenttz.tz.names().filter((it: string) => it.includes("US/")).sort((a: string, b: string) => a > b ? 1 : -1),
			...momenttz.tz.names().filter((it: string) => !it.includes("US/")).sort((a: string, b: string) => a > b ? 1 : -1)
		];
	}

	getInitialTimeZone() {
		const timezone = momenttz.tz.guess();

		switch (timezone) {
			case "America/Anchorage":
				return "US/Alaska";
			case "America/Adak":
				return "US/Aleutian";
			case "America/Phoenix":
				return "US/Arizona";
			case "America/Chicago":
				return "US/Central";
			case "America/New_York":
				return "US/Eastern";
			case "America/Indiana/Indianapolis":
				return "US/East-Indiana";
			case "Pacific/Honolulu":
				return "US/Hawaii";
			case "America/Indiana/Knox":
				return "US/Indiana-Starke";
			case "America/Detroit":
				return "US/Michigan";
			case "America/Denver":
				return "US/Mountain";
			case "America/Los_Angeles":
				return "US/Pacific";
			case "Pacific/Pago_Pago":
				return "US/Samoa";
			default:
				return timezone;
		}
	}
}