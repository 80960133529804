import { ERROR, OK } from "../../Models/apiConstants";
import ApiActionBase from "../../Actions/ApiActionBase";
import isEmpty from "../../Utils/isEmpty";
import UserLockedOutAction from "../../Actions/Login/UserLockedOutAction";
import RememberMeAction from "../../Actions/Login/RememberMeAction";
import NeedsUpdateAction from "../../Actions/Login/NeedsUpdateAction";
import getLoadState from "../../Utils/getLoadState";
import {
	ChangePasswordAction,
	ClientLogoutAction,
	CreateResetTokenAction,
	DoLoginAction,
	GetUserAuthenticatedAction,
	LookupTokenAction,
	SaveSecurityQuestionAnswerAction,
	ValidateSecurityAnswerAction
} from "../../Actions/Api/loginActions";
import LoginUserUpdatedAction from "../../Actions/Login/LoginUserUpdatedAction";
import { NULL_DATE_STRING } from "../../Utils/dateUtils";
import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import * as storage from "../../Utils/storage";
import { GetPreStartupDataAction, GetLoginUserAction } from "../../Actions/Api/startupActions";

export interface IState {
	loginUser: Dto.ILoginUser;
	ready: boolean;
	alreadyLoggedIn: boolean;
	authenticating: boolean;
	authenticated: boolean;
	locked: boolean;
	needsUpdate: boolean;
	needsSecurityQuestion: boolean;
	savingSecurityQuestion: boolean;
	validatingSecurityAnswer: boolean;
	securityAnswerValidated: boolean;
	passwordChangeReason: string | null;
	changingPassword: boolean;
	loggingOut: boolean;
	creatingResetToken: boolean;
	resetTokenInvalid: boolean;
	resetTokenSuccess: boolean;
	rememberMe: boolean;
	preStartupData: Dto.IPreStartupData | null;
	_devPassword?: string;
}

const REMEMBER_ME_KEY = "rememberMe";
const USERNAME_KEY = "userNameV2";
const PASSWORD_KEY = "passwordV2";

const INITIAL_STATE: IState = {
	loginUser: {
		confirmPassword: "",
		forceChangePassword: false,
		forceChangePasswordReason: null,
		lastLoginDate: NULL_DATE_STRING,
		oldPassword: "",
		password: "",
		resetToken: null,
		securityAnswer: "",
		securityQuestion: "",
		isSingleSignOn: false,
		userName: ""
	},
	rememberMe: storage.get(REMEMBER_ME_KEY, true)!,
	ready: false,
	alreadyLoggedIn: false,
	authenticating: false,
	authenticated: false,
	locked: false,
	needsUpdate: false,
	needsSecurityQuestion: false,
	savingSecurityQuestion: false,
	validatingSecurityAnswer: false,
	securityAnswerValidated: false,
	passwordChangeReason: null,
	changingPassword: false,
	loggingOut: false,
	creatingResetToken: false,
	resetTokenInvalid: false,
	resetTokenSuccess: false,
	preStartupData: null
};

if (INITIAL_STATE.rememberMe) {
	INITIAL_STATE.loginUser.userName = storage.get(USERNAME_KEY, "")!;

	if (__DEV__) {
		INITIAL_STATE._devPassword = storage.get(PASSWORD_KEY, "");
	}
}

function restoreDevPassword(state: IState) {
	if (!__DEV__ || !state.rememberMe) {
		return state;
	}

	const loginUser = Object.assign({}, state.loginUser, {
		password: state.loginUser.forceChangePassword ? "" : state._devPassword,
		oldPassword: state._devPassword
	});

	return Object.assign({}, state, { loginUser });
}

function tryUpdateLoginUser<TParams extends { [name: string]: any }, TAction extends ApiActionBase<TParams, TResponse>, TResponse extends Dto.IResponse<Dto.ILoginUser>>(
	previousState: IState,
	action: TAction
) {
	const loadState = getLoadState(action);

	let nextState = previousState;
	if (loadState === AsyncState.Resolved && action.response!.item != null) {
		const loginUser = action.response!.item;
		const { securityQuestion, isSingleSignOn } = loginUser;
		nextState = restoreDevPassword(Object.assign({}, previousState, {
			loginUser,
			needsSecurityQuestion: !isSingleSignOn && (securityQuestion == null || securityQuestion.length === 0)
		}));
	}

	return {
		loadState,
		nextState
	};
}

export default function authReducer(
	previousState: IState = restoreDevPassword(INITIAL_STATE),
	action: IAction
): IState {
	if (action instanceof LoginUserUpdatedAction) {
		const nextState = Object.assign({}, previousState, {
			loginUser: action.loginUser
		});

		if (__DEV__) {
			nextState._devPassword = action.loginUser.password || nextState._devPassword;
		}

		return nextState;
	}

	if (action instanceof RememberMeAction) {
		return Object.assign({}, previousState, {
			rememberMe: action.rememberMe
		});
	}

	if (action instanceof NeedsUpdateAction) {
		return Object.assign({}, previousState, {
			needsUpdate: true
		});
	}

	if (action instanceof GetUserAuthenticatedAction) {
		const { loadState, nextState } = tryUpdateLoginUser(previousState, action);
		const authenticated = loadState === AsyncState.Resolved;

		return Object.assign({}, nextState, {
			ready: loadState !== AsyncState.Pending,
			authenticated,
			alreadyLoggedIn: authenticated
		});
	}

	if (action instanceof GetPreStartupDataAction
		&& action.state === AsyncState.Resolved
		&& action.response != null
		&& action.response.status === OK
	) {
		return {
			...previousState,
			preStartupData: action.response.item
		};
	}

	if (action instanceof DoLoginAction) {
		const { loadState, nextState } = tryUpdateLoginUser(previousState, action);

		const authenticating = loadState === AsyncState.Pending;
		const authenticated = loadState === AsyncState.Resolved && !action.response!.item.forceChangePassword;
		const locked = authenticated ? false : previousState.locked;

		let { passwordChangeReason } = previousState;
		if (loadState === AsyncState.Resolved) {
			passwordChangeReason = action.response!.item.forceChangePassword
				? (action.response!.item.forceChangePasswordReason || "UNKNOWN")
				: null;
		}

		return Object.assign({}, nextState, {
			authenticating,
			authenticated,
			locked,
			passwordChangeReason
		});
	}

	if (action instanceof UserLockedOutAction) {
		return Object.assign({}, previousState, {
			locked: true,
			authenticated: false // optimistically assume unauthenticated
		});
	}

	if (action instanceof SaveSecurityQuestionAnswerAction) {
		const { loadState, nextState } = tryUpdateLoginUser(previousState, action);
		return Object.assign({}, nextState, {
			savingSecurityQuestion: loadState === AsyncState.Pending,
			needsSecurityQuestion: loadState === AsyncState.Resolved ? false : previousState.needsSecurityQuestion
		});
	}

	if (action instanceof ValidateSecurityAnswerAction) {
		const { loadState, nextState } = tryUpdateLoginUser(previousState, action);
		return Object.assign({}, nextState, {
			validatingSecurityAnswer: loadState === AsyncState.Pending,
			securityAnswerValidated: loadState === AsyncState.Resolved
		});
	}

	if (action instanceof ChangePasswordAction) {
		const { loadState, nextState } = tryUpdateLoginUser(previousState, action);
		const usingResetToken = loadState !== AsyncState.Pending && !isEmpty(action.params.user.resetToken);
		const authenticated = loadState === AsyncState.Resolved && !usingResetToken;

		// if we aren't using a reset token, this api call also authenticates the user, and in that case
		// we want to delay clearing these props until after the getLoginUser api call finishes
		let { passwordChangeReason, changingPassword } = nextState;

		if (usingResetToken) {
			passwordChangeReason = loadState === AsyncState.Resolved ? null : previousState.passwordChangeReason;
			changingPassword = loadState === AsyncState.Pending;
		} else if (loadState === AsyncState.Pending) {
			changingPassword = true;
		}

		return Object.assign({}, nextState, {
			passwordChangeReason,
			changingPassword,
			authenticated,
			locked: authenticated ? false : previousState.authenticated,
			resetTokenInvalid: action.state === AsyncState.Resolved
				&& action.response!.status === ERROR
				&& action.response!.statusMessage === "INVALID_RESET_TOKEN",
			resetTokenSuccess: loadState === AsyncState.Resolved && usingResetToken
		});
	}

	if (action instanceof GetLoginUserAction && action.state !== AsyncState.Pending) {
		return {
			...previousState,
			passwordChangeReason: null,
			changingPassword: false
		}
	}

	if (action instanceof LookupTokenAction) {
		const { loadState, nextState } = tryUpdateLoginUser(previousState, action);
		return Object.assign({}, nextState, {
			ready: loadState === AsyncState.Resolved,
			resetTokenInvalid: action.state === AsyncState.Resolved && loadState !== AsyncState.Resolved
		});
	}

	if (action instanceof CreateResetTokenAction) {
		return Object.assign({}, previousState, {
			creatingResetToken: action.state === AsyncState.Pending
		});
	}

	if (action instanceof ClientLogoutAction) {
		return Object.assign({}, previousState, {
			loggingOut: action.state === AsyncState.Pending
		});
	}

	return previousState;
}

export function onChange(state: IState) {
	const { loginUser: { userName }, rememberMe } = state;

	storage.set(REMEMBER_ME_KEY, rememberMe);

	if (rememberMe) {
		if (userName != null && userName.length > 0) {
			storage.set(USERNAME_KEY, userName);
		}

		if (__DEV__) {
			const { _devPassword } = state;
			if (_devPassword != null && _devPassword.length > 0) {
				storage.set(PASSWORD_KEY, _devPassword);
			}
		}
	} else {
		storage.remove(USERNAME_KEY);
		if (__DEV__) {
			storage.remove(PASSWORD_KEY);
		}
	}
}