import AffiliateSelectedAction from "../../../../Actions/UploadAccount/AffiliateSelectedAction";
import AccountsUploadCompletedAction from "../../../../Actions/UploadAccount/AccountsUploadCompletedAction";
import assign from "../../../../Utils/assign";
import ImportStatusChangedAction from "../../../../Actions/UploadAccount/ImportStatusChangedAction";
import IAction from "../../../../Actions/IAction";
export interface IState {
	statusId: number | null;
	clientId: number;
}

const INITIAL_STATE: IState = {
	statusId: null,
	clientId: -1
};

export default function uploadFileReducer(
	previousState = INITIAL_STATE,
	action: IAction
): IState {
	if (action instanceof ImportStatusChangedAction) {
		return assign<IState, Pick<IState, "statusId">>(previousState, {
			statusId: action.statusId
		});
	}

	if (action instanceof AffiliateSelectedAction) {
		return assign(previousState, {
			clientId: action.selectedAffiliate
		});
	}

	if (action instanceof AccountsUploadCompletedAction) {
		return INITIAL_STATE;
	}

	return previousState;
}