import Form from "../../Common/Form/Form";
import * as React from "react";
import styles from "./AuthForm.module.scss";
import cn from "classnames";
import { appName, logoLogin } from "../../../Utils/branding";
import moment from "moment";

export interface IProps {
	className?: string;
	onSubmit?: React.FormEventHandler<HTMLFormElement>;
	children?: React.ReactNode;
}

const AuthForm = (props: IProps) => (
	<div className={cn(styles.container, props.className)}>
		<div className={styles.logo}>
			{logoLogin}
		</div>
		<Form onSubmit={props.onSubmit}>
			{props.children}
		</Form>

		<div className={styles.terms}>
			© {moment().format("YYYY")} Forte, LLC. All rights reserved. {appName} is a trademark of
			Forte, LLC. This is a private information site for Forte's authorized users.
			Unauthorized use of this system is prohibited and subject to criminal and civil
			penalties. System use may be monitored, recorded and subject to audit. Your use
			indicates your consent to monitoring and recording in addition to the {appName} License
			terms and conditions and Forte's privacy statement.
		</div>
	</div>
);

export default AuthForm;
export { default as FormGroup } from "../../Common/FormGroup/FormGroup";