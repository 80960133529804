import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";
import * as Dto from "../../Models/dto";

export default class ReportCriteriaUpdatedAction implements IAction {
	data: Dto.IReport;

	constructor(data: Dto.IReport) {
		this.data = data;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"ReportCriteriaUpdatedAction", {
				"data": JSON.stringify(this.data)
			});
	}
}