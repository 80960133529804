import * as React from "react";
import ReactDOM from "react-dom";
import { Modal, Sizes } from "react-bootstrap";
import DraggableModal from "../DraggableModal/DraggableModal";
import Button from "../Button/Button";
import withoutProperties from "../../../Utils/withoutProperties";
import styles from "./Confirm.module.scss";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";

type ReactNodeFactory = (onConfirm: () => void, onCancel: () => void) => React.ReactNode;

interface IOptions {
	message: React.ReactNode | ReactNodeFactory;
	header?: React.ReactNode;
	buttons?: React.ReactNode | ReactNodeFactory;
}

interface IProps extends IOptions, React.HTMLAttributes<HTMLElement> {
	onConfirm: (result: boolean) => void;

	// copied from ReactBootstrap.ModalProps, except onHide is now optional
	onHide?: () => void;
	animation?: boolean;
	backdrop?: boolean | string;
	bsSize?: Sizes;
	container?: any;
	dialogClassName?: string;
	dialogComponent?: any;
	enforceFocus?: boolean;
	keyboard?: boolean;
	onEnter?: (node: HTMLElement) => any;
	onEntered?: (node: HTMLElement) => any;
	onEntering?: (node: HTMLElement) => any;
	onExit?: (node: HTMLElement) => any;
	onExited?: (node: HTMLElement) => any;
	onExiting?: (node: HTMLElement) => any;
	show?: boolean;
}

function getReactNode(target: React.ReactNode | ReactNodeFactory, onConfirm: () => void, onCancel: () => void) {
	return typeof target === "function" ? (target as ReactNodeFactory)(onConfirm, onCancel) : target;
}

function getDefaultButtons(onConfirm: () => void, onCancel: () => void) {
	return (
		<>
			<Button
				label="Ok"
				primary
				onClick={onConfirm}
			/>
			<Button
				label="Cancel"
				onClick={onCancel}
			/>
		</>
	);
}

export function addClass(node: HTMLElement) {
	(node.parentElement as HTMLElement).classList.add(styles.confirm);
}

const Confirm = (props: IProps) => {
	const modalProps = withoutProperties(props, [
		nameof(props.message),
		nameof(props.header),
		nameof(props.onConfirm)
	]);

	const handleConfirm = (result: boolean) => {
		props.onConfirm(result);
		if (props.onHide != null) {
			props.onHide();
		}
	};

	const onConfirm = () => handleConfirm(true);
	const onCancel = () => handleConfirm(false);

	const message = getReactNode(props.message, onConfirm, onCancel);
	const buttons = getReactNode(props.buttons || getDefaultButtons, onConfirm, onCancel);

	return (
		<DraggableModal
			{...(modalProps as any)}
			onHide={onCancel}
			onEnter={node => {
				addClass(node);

				if (modalProps.onEnter != null) {
					modalProps.onEnter(node);
				}
			}}
		>
			{props.header == null ? null : (
				<Modal.Header closeButton>
					<Modal.Title>{props.header}</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body>
				{message}
			</Modal.Body>
			<Modal.Footer>
				{buttons}
			</Modal.Footer>
		</DraggableModal>
	);
};

export function confirm(message: React.ReactNode | ReactNodeFactory, onConfirm?: () => void, onCancel?: () => void): Promise<boolean>;
export function confirm(options: IOptions, onConfirm?: () => void, onCancel?: () => void): Promise<boolean>;
export function confirm(messageOrOptions: React.ReactNode | ReactNodeFactory | IOptions, onConfirm?: () => void, onCancel?: () => void): Promise<boolean> {
	let options: IOptions;
	if (
		typeof messageOrOptions !== "object"
		|| React.isValidElement<any>(messageOrOptions)
	) {
		options = {
			message: messageOrOptions as React.ReactNode
		};
	} else {
		options = messageOrOptions as IOptions;
	}

	const container = document.body.appendChild(document.createElement("div")) as HTMLDivElement;

	return new Promise<boolean>(resolve => {
		const render = (show: boolean, confirmed?: boolean) => {
			ReactDOM.render((
				<Confirm
					{...options}
					show={show}
					onConfirm={result => {
						confirmed = result;

						if (result) {
							if (onConfirm != null) {
								onConfirm();
							}
						} else if (onCancel != null) {
							onCancel();
						}

						render(false, result);
					}}
					onExited={() => {
						resolve(confirmed);
						ReactDOM.unmountComponentAtNode(container);
						process.nextTick(() => container.parentNode!.removeChild(container));
					}}
				/>
			), container);
		};

		render(true);
	});
}

type MessageFactory = () => React.ReactChild | null | undefined;
type Message = React.ReactChild | MessageFactory;

async function confirmAllPromise(messages: Message[]): Promise<boolean> {
	const remainingMessages = [...messages];
	while (remainingMessages.length > 0) {
		let message: Message | null | undefined = remainingMessages.shift();
		if (typeof message === "function") {
			message = message();
			if (!message) {
				continue;
			}
		}

		const confirmed = await confirm(message);
		if (!confirmed) {
			return false;
		}
	}

	return true;
}

export function confirmAll(messages: Message[], onConfirm?: () => void, onCancel?: () => void) {
	return confirmAllPromise(messages).then(confirmed => {
		if (confirmed) {
			if (onConfirm != null) {
				onConfirm();
			}
		} else if (onCancel != null) {
			onCancel();
		}
	});
}

export async function prompt(title: string, initialValue = "") {
	let value = "";
	const message: ReactNodeFactory = (onConfirm) => (
		<form onSubmit={e => {
			e.preventDefault();
			onConfirm();
		}}>
			<input
				type="text"
				className="form-control"
				defaultValue={initialValue}
				onChange={e => value = e.currentTarget.value}
				autoFocus
			/>
			{/* visually hidden sumbit button to allow enter to trigger a submit */}
			<VisuallyHidden>
				<input
					type="submit"
					aria-hidden
				/>
			</VisuallyHidden>
		</form>
	);

	const confirmed = await confirm({
		header: title,
		message
	});

	return confirmed ? value : null;
}

export default Object.assign(Confirm, { confirm });