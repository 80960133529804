import handleGenericError from "./handleGenericError";
import handleApiResponse from "./handleApiResponse";
import {
	advancedSearchAccounts,
	getClientProfileUserSearches,
	saveAccounts
} from "../ActionCreators/Api/accountActionCreators";
import {
	saveAccountImports
} from "../ActionCreators/Api/accountImportActionCreators";
import { getAccountWorkflowStatusCounts as getAccountWorkflowStatusCountsPfod } from "../ActionCreators/Api/accountWorkflowActionCreators";
import * as toast from "../Utils/toast";
import AccountActionCreators from "../ActionCreators/AccountActionCreators";
import SearchActionCreators from "../ActionCreators/SearchActionCreators";
import { default as AppActionCreators, startBusy } from "../ActionCreators/AppActionCreators";
import * as Dto from "../Models/dto";
import * as AccountsData from "../Models/AccountsData";
import Column from "../Models/Column";
import { getAccountWorkflowStatusCounts as getAccountWorkflowStatusCountsAccess } from "../ActionCreators/Api/accountActionCreators";

interface ISelector<TSource, TValue> {
	(source: TSource): TValue;
}

function select<TSource, TValue>(source: TSource, selector?: ISelector<TSource, TValue> | TValue) {
	if (typeof selector === "function") {
		return (selector as ISelector<TSource, TValue>)(source);
	}
	return selector as TValue | undefined;
}

type IAccount = Dto.IAccount;
type IAccountImport = Dto.IAccountImport;

export const STRENGTH_METER = "strengthMeter";
export const LOCKED_INDICATOR = "__locked__";

class AccountsContext {
	defaultSortInfo: AccountsData.ISortInfo = { name: "acctId", dir: 1 };

	getDefaultSearchDisplayColumns(): Array<number> {
		return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 13, 22, 16];
	}

	GetAccountColumns(): Array<AccountsData.IAccountColumn> {
		var columns: Array<AccountsData.IAccountColumn> = [];
		columns.push({ id: Column.PrimaryAccountNumber, name: nameof<Dto.IAccount | Dto.IAccountImport>(it => it.primaryAccountNumber), displayText: "Primary Account Number" });
		columns.push({ id: Column.SecondaryAccountNumber, name: nameof<Dto.IAccount | Dto.IAccountImport>(it => it.secondaryAccountNumber), displayText: "Secondary Account Number" });
		columns.push({ id: Column.FirstName, name: nameof<Dto.IAccount | Dto.IAccountImport>(it => it.firstName), displayText: "First Name" });
		columns.push({ id: Column.LastName, name: nameof<Dto.IAccount | Dto.IAccountImport>(it => it.lastName), displayText: "Last Name" });
		columns.push({ id: Column.SocialSecurityNumber, name: nameof<Dto.IAccount | Dto.IAccountImport>(it => it.ssn), displayText: "Social Security Number" });
		columns.push({ id: Column.Balance, name: nameof<Dto.IAccount | Dto.IAccountImport>(it => it.balance), displayText: "Balance" });
		columns.push({ id: Column.DateofDeath, name: nameof<Dto.IAccount | Dto.IAccountImport>(it => it.dod), displayText: "Date of Death", isDate: true });
		columns.push({ id: Column.DateofBirth, name: nameof<Dto.IAccount | Dto.IAccountImport>(it => it.dob), displayText: "Date of Birth", isDate: true });
		columns.push({ id: Column.AccountStatus, name: nameof<Dto.IAccount>(it => it.acctStatusId), displayText: "Status" });
		columns.push({ id: Column.AccountImportStatus, name: nameof<Dto.IAccountImport>(it => it.acctImportStatusId), displayText: "Status" });
		columns.push({ id: Column.StrengthMeter, name: STRENGTH_METER, displayText: "Strength Meter" }); // UI 
		columns.push({ id: Column.PlacementDate, name: nameof<Dto.IAccount>(it => it.placedTimestamp), displayText: "Placement Date", isDate: true });
		columns.push({ id: Column.ExpirationDate, name: nameof<Dto.IAccount>(it => it.estateExpireDate), displayText: "Expiration Date", isDate: true });
		columns.push({ id: Column.AccountID, name: nameof<Dto.IAccount | Dto.IAccountImport>(it => it.acctId), displayText: "Account ID" });
		columns.push({ id: Column.ClaimID, name: nameof<Dto.IAccount>(it => it.printedClaimId), displayText: "Claim ID" });
		columns.push({ id: Column.MailedDate, name: nameof<Dto.IAccount>(it => it.mailedDate), displayText: "Mailed Date", isDate: true });
		columns.push({ id: Column.Workflow, name: nameof<Dto.IAccount | Dto.IAccountImport>(it => it.workflowName), displayText: "Workflow" });
		columns.push({ id: Column.AffiliateID, name: nameof<Dto.IAccount | Dto.IAccountImport>(it => it.clientId), displayText: "Affiliate ID" });
		columns.push({ id: Column.EstateDOD, name: nameof<Dto.IAccount>(it => it.estateDod), displayText: "Estate DOD", isDate: true });
		columns.push({ id: Column.VerifiedDOD, name: nameof<Dto.IAccount>(it => it.dodFound), displayText: "Verified DOD", isDate: true });
		columns.push({ id: Column.DODFoundTimestamp, name: nameof<Dto.IAccount>(it => it.dodFoundTimestamp), displayText: "DOD Found Timestamp", isDate: true });
		columns.push({ id: Column.WorkflowDate, name: nameof<Dto.IAccount>(it => it.workflowDate), displayText: "Workflow Date", isDate: true });
		columns.push({ id: Column.PetitionDate, name: nameof<Dto.IAccount>(it => it.petitionDate), displayText: "Petition Date", isDate: true });
		columns.push({ id: Column.StatementsRequired, name: nameof<Dto.IAccount>(it => it.statementsRequired), displayText: "Statements Required" });
		columns.push({ id: Column.ClosedDate, name: nameof<Dto.IAccount>(it => it.closedDate), displayText: "Closed Date", isDate: true });
		columns.push({ id: Column.SettledDate, name: nameof<Dto.IAccount>(it => it.settledDate), displayText: "Settled Date", isDate: true });
		columns.push({ id: Column.SettledAmount, name: nameof<Dto.IAccount>(it => it.settledAmount), displayText: "Settled Amount" });
		columns.push({ id: Column.CaseNumber, name: nameof<Dto.IAccount>(it => it.caseNumber), displayText: "Case Number", isDate: true });
		columns.push({ id: Column.EstateMatchDate, name: nameof<Dto.IAccount>(it => it.estateMatchDate), displayText: "Estate Match Date", isDate: true });
		columns.push({ id: Column.PendingApproval, name: nameof<Dto.IAccount>(it => it.pendingApproval), displayText: "Pending Approval" });
		columns.push({ id: Column.SettlementRequested, name: nameof<Dto.IAccount>(it => it.settlementRequested), displayText: "Settlement Requested" });
		columns.push({ id: Column.WithdrawalRequested, name: nameof<Dto.IAccount>(it => it.withdrawalRequested), displayText: "Withdrawal Requested" });
		columns.push({ id: Column.StatementIncluded, name: nameof<Dto.IAccount>(it => it.statementIncluded), displayText: "Statement Included" });
		columns.push({ id: Column.Amend, name: nameof<Dto.IAccount>(it => it.claimAmendmentRequested), displayText: "Amend" });

		return columns;
	}

	GetExportFields(): AccountsData.IExportField[] {
		return [
			{ fieldName: "Account ID", internalName: "AcctID" },
			{ fieldName: "Affiliate ID", internalName: "OnDemandID" },
			{ fieldName: "Primary Account Number", internalName: "PrimaryAccountNumber" },
			{ fieldName: "Secondary Account Number", internalName: "SecondaryAccountNumber" },
			{ fieldName: "Balance", internalName: "Balance" },
			{ fieldName: "Prefix Name", internalName: "PrefixName" },
			{ fieldName: "First Name", internalName: "FirstName" },
			{ fieldName: "Middle Name", internalName: "MiddleName" },
			{ fieldName: "Last Name", internalName: "LastName" },
			{ fieldName: "Suffix Name", internalName: "SuffixName" },
			{ fieldName: "SSN", internalName: "SSN" },
			{ fieldName: "DOD", internalName: "DOD" },
			{ fieldName: "DOB", internalName: "DOB" },
			{ fieldName: "Verified DOD", internalName: "DODFound" },
			{ fieldName: "DOD Found Timestamp", internalName: "DODFoundTimestamp" },
			{ fieldName: "Address 1", internalName: "Address1" },
			{ fieldName: "Address 2", internalName: "Address2" },
			{ fieldName: "City", internalName: "City" },
			{ fieldName: "State", internalName: "State" },
			{ fieldName: "Zip", internalName: "Zip" },
			{ fieldName: "Last Activity Date", internalName: "LastActivity" },
			{ fieldName: "Secure Description", internalName: "SecureDescription" },
			{ fieldName: "Notes", internalName: "Notes" },
			{ fieldName: "Placement Date", internalName: "PlacedTimestamp" },
			{ fieldName: "Workflow Step", internalName: "WorkflowName" },
			{ fieldName: "Workflow Date", internalName: "WorkflowDate" },
			{ fieldName: "Account Status", internalName: "StatusName" },
			{ fieldName: "Closed Date", internalName: "ClosedDate" },
			{ fieldName: "Settled Date", internalName: "SettledDate" },
			{ fieldName: "Settled Amount", internalName: "SettledAmount" },
			{ fieldName: "Claim ID", internalName: "ClaimID" },
			{ fieldName: "Claim Balance", internalName: "ClaimBalance" },
			{ fieldName: "Claim Print Date", internalName: "FirstPrintTimestamp" },
			{ fieldName: "Claim Mail Date", internalName: "MailDate" },
			{ fieldName: "Estate Case Number", internalName: "CaseNumber" },
			{ fieldName: "Estate DOD", internalName: "EstateDOD" },
			{ fieldName: "Estate Petition Date", internalName: "PetitionDate" },
			{ fieldName: "Estate Expiration Date", internalName: "EstateExpireDate" },
			{ fieldName: "Court Name", internalName: "CourtName" },
			{ fieldName: "Court Branch", internalName: "CourtBranch" },
			{ fieldName: "Court County", internalName: "CourtCounty" },
			{ fieldName: "Court State", internalName: "CourtState" },
			{ fieldName: "Attorney Name", internalName: "AttyName" },
			{ fieldName: "Attorney Address 1", internalName: "AttyAddress1" },
			{ fieldName: "Attorney Address 2", internalName: "AttyAddress2" },
			{ fieldName: "Attorney City", internalName: "AttyCity" },
			{ fieldName: "Attorney State", internalName: "AttyState" },
			{ fieldName: "Attorney Zip", internalName: "AttyZip" },
			{ fieldName: "Attorney Phone", internalName: "AttyPhone" },
			{ fieldName: "Attorney Firm", internalName: "AttyFirm" },
			{ fieldName: "Representative Name", internalName: "RepName" },
			{ fieldName: "Representative Address 1", internalName: "RepAddress1" },
			{ fieldName: "Representative Address 2", internalName: "RepAddress2" },
			{ fieldName: "Representative City", internalName: "RepCity" },
			{ fieldName: "Representative State", internalName: "RepState" },
			{ fieldName: "Representative Zip", internalName: "RepZip" },
			{ fieldName: "Representative Phone", internalName: "RepPhone" },
			{ fieldName: "Representative Firm", internalName: "RepFirm" },
			{ fieldName: "Estate Match Date", internalName: "EstateMatchDate" },
			{ fieldName: "Flagged Details", internalName: "FlaggedDetails" },
			{ fieldName: "Account Fee", internalName: "AccountFee" }
		];
	}

	GetAccountImportStatus(): Array<Dto.IAccountImportStatus> {
		var importStatuses: Array<Dto.IAccountImportStatus> = [];
		importStatuses.push({ acctImportStatusId: 1001, accountWorkflowStepId: 1, displayName: "Add/Update Accounts" });
		importStatuses.push({ acctImportStatusId: 1002, accountWorkflowStepId: 1, displayName: "Recall Accounts" });
		importStatuses.push({ acctImportStatusId: 1004, accountWorkflowStepId: 1, displayName: "Full Reconciliation" });

		return importStatuses;
	}

	async GetAccountWorkflowStatusCounts(clientIds: number[]) {
		try {
			let data: Dto.ICollectionResponse<Dto.IAccountWorkflowStatusCount>;
			if (process.env.REACT_APP_APPLICATION === "access") {
				data = await getAccountWorkflowStatusCountsAccess(clientIds);
			} else if (process.env.REACT_APP_APPLICATION === "pfod") {
				data = await getAccountWorkflowStatusCountsPfod(clientIds);
			} else {
				throw new Error("Unexpected state");
			}

			handleApiResponse(data, () => AccountActionCreators.workFlowStatusDataLoaded(data));
		} catch (error) {
			handleGenericError(error);
		}
	}

	async SearchAccounts(searchCriteria: AccountsData.IAdvancedSearchAccounts, isSimpleSearch?: boolean) {
		const hideBusy = AppActionCreators.startBusy("Retrieving...");

		try {
			const data = await advancedSearchAccounts(
				searchCriteria.userSearch,
				searchCriteria.startIndex,
				searchCriteria.endIndex,
				searchCriteria.sortField,
				searchCriteria.sortDirection
			);

			if (data.status === "Exception") {
				toast.error("An error occured while executing your search.");
			} else {
				if (isSimpleSearch) {
					AccountActionCreators.accountsFiltered(data);
				} else {
					SearchActionCreators.searchResultsFetched(data);
				}

				if ((data.accounts?.length ?? 0) + (data.accountImports?.length ?? 0) === 0) {
					toast.info("No accounts were identified using the search parameters entered.");
				}
			}
		} catch (error) {
			handleGenericError(error);
		} finally {
			hideBusy();
		}
	}

	async SaveAccountImports(accountImports: Array<Dto.IAccountImport>) {
		const stopBusy = startBusy("Saving...");
		try {
			const data = await saveAccountImports(accountImports);
			handleApiResponse(data, () => AccountActionCreators.accountStatusUpdated());
		} catch (error) {
			handleGenericError(error);
		} finally {
			stopBusy();
		}
	}

	async SaveAccounts(accounts: Array<Dto.IAccount>) {
		const stopBusy = startBusy("Saving...");
		try {
			const data = await saveAccounts(accounts);
			handleApiResponse(data, () => AccountActionCreators.accountStatusUpdated());
		} catch (error) {
			handleGenericError(error);
		} finally {
			stopBusy();
		}
	}

	async GetClientProfileUserSearches() {
		try {
			const response = await getClientProfileUserSearches();
			handleApiResponse(response);
		} catch (error) {
			handleGenericError(error);
		}
	}

	isAccountImport(account: IAccount | IAccountImport | null | undefined): account is IAccountImport {
		return account != null && "acctImportId" in account;
	}

	isAccount(account: IAccount | IAccountImport | null | undefined): account is IAccount {
		return account != null && !this.isAccountImport(account);
	}

	/** 
	 * @deprecated Use AccountsContext.isAccountImport 
	 */
	ifAccountImport<T>(account: IAccount | IAccountImport, then?: ISelector<IAccountImport, T> | T, _else?: ISelector<IAccount, T> | T) {
		if (this.isAccountImport(account)) {
			return select(account as IAccountImport, then);
		}

		return select(account as IAccount, _else);
	}

	/** 
	 * @deprecated Use AccountsContext.isAccount 
	 */
	ifAccount<T>(account: IAccount | IAccountImport, then: ISelector<IAccount, T> | T, _else?: ISelector<IAccountImport, T> | T) {
		return this.ifAccountImport<T>(account, _else, then);
	}

	getStatusId(account: Dto.IAccount | Dto.IAccountImport) {
		if (this.isAccount(account)) {
			return account.acctStatusId;
		}

		return account.acctImportStatusId;
	}
}

export default new AccountsContext();
