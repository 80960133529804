import * as Flux from "flux";
import IAction from "../Actions/IAction";

const Dispatcher = new Flux.Dispatcher<IAction>();

const originalDispatch = Dispatcher.dispatch;
const dispatchQueue: IAction[] = [];

Dispatcher.dispatch = action => {
	if (Dispatcher.isDispatching()) {
		dispatchQueue.push(action);
	} else {
		originalDispatch.call(Dispatcher, action);
		while (dispatchQueue.length > 0) {
			Dispatcher.dispatch(dispatchQueue.shift()!);
		}
	}
};

export default Dispatcher;