import { MODIFIED_BY_ANOTHER_USER, OK } from "../../../../Models/apiConstants";
import IAction from "../../../../Actions/IAction";
import * as Dto from "../../../../Models/dto";
import { EnableAction, EditAction, DiscardChangesAction } from "../../../../Actions/Settings/FtpActions";
import { GetClientProfileFTPAction, SaveClientProfileFTPAction, DeleteClientProfileFTPAction } from "../../../../Actions/Api/ftpActions";
import getLoadState from "../../../../Utils/getLoadState";
import { AsyncState } from "../../../../Models/IAsync";
import { NULL_DATE_STRING } from "../../../../Utils/dateUtils";

export interface IEdit extends Omit<Dto.IClientProfileFTP, "publicKeyFileBytes"> {
	publicKeyFileBytes: string | Blob | null;
	passwordConfirm: string;
}

export interface IState {
	enabled: boolean;
	edit: IEdit | null;
}

const newEdit: IEdit = {
	clientProfileFtpId: -1,
	clientProfileId: -1, // will be assigned on backend
	confirmationEmailFlag: true,
	password: "",
	passwordConfirm: "",
	publicKeyFileBytes: null,
	publicKeyFileChanged: false,
	publicKeyFileName: "",
	usePgp: false,
	username: "",
	ftpReconFile: "",
	ftpReconIncludeFlagged: false,
	writeTimestamp: NULL_DATE_STRING,
	exportFlag: false,
	exportDelimiter: null as any
};

function updateEdit(previousState: IState, ftp: Dto.IClientProfileFTP | null): IState {
	const enabled = ftp != null;
	const edit = enabled ? Object.assign({}, ftp, {
		passwordConfirm: ""
	}) : newEdit;

	return Object.assign({}, previousState, {
		enabled,
		edit
	});
}

function ftpReducer(
	previousState: IState = {
		enabled: false,
		edit: null
	},
	action: IAction
): IState {
	if (action instanceof GetClientProfileFTPAction && getLoadState(action) === AsyncState.Resolved) {
		return updateEdit(previousState, action.response!.item);
	}

	if (action instanceof SaveClientProfileFTPAction && action.state === AsyncState.Resolved) {
		switch (action.response!.status) {
			case OK:
			case MODIFIED_BY_ANOTHER_USER:
				return updateEdit(previousState, action.response!.item);
			default:
				break;
		}
	}

	if (action instanceof DeleteClientProfileFTPAction && getLoadState(action) === AsyncState.Resolved) {
		return updateEdit(previousState, null);
	}

	if (action instanceof EnableAction) {
		return Object.assign({}, previousState, {
			enabled: true
		});
	}

	if (action instanceof EditAction) {
		return Object.assign({}, previousState, {
			edit: action.edit
		});
	}

	if (action instanceof DiscardChangesAction) {
		return updateEdit(previousState, action.ftp);
	}

	return previousState;
}

export default ftpReducer;