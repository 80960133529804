import connect from "../../Utils/connect";
import moment from "moment";
import { editCaptcha, refreshCaptcha, invalidateCaptcha } from "../../ActionCreators/LoginActionCreators";
import enhanceWithLoaders, { createLoader } from "../../Utils/enhanceWithLoaders";
import Control, { IOwnProps, ILoaderProps, IConnectedProps, reloadDelays } from "./Control/Control";

const WithLoaders = enhanceWithLoaders<ILoaderProps, IOwnProps & IConnectedProps>(
	Control,
	loading => ({ loading }),
	createLoader<IOwnProps & IConnectedProps>(
		state => {
			const { loading, timestamp } = state.ui.captcha;
			const delay = reloadDelays[loading!];

			return {
				loadState: loading,
				invalidAt: delay == null ? null : moment(timestamp).add(delay)
			};
		},
		(_state, ownProps) => ownProps.onRefresh()
	)
);

export default connect<IConnectedProps, IOwnProps>(
	WithLoaders,
	state => {
		const { failing, response: value, imageBytes } = state.ui.captcha;

		const props: IConnectedProps = {
			failing,
			imageBytes,
			value,
			onChange: editCaptcha,
			onRefresh: refreshCaptcha,
			onInvalidate: invalidateCaptcha
		};

		if (__DEV__) {
			props.challenge = state.ui.captcha.challenge;
		}

		return props;
	}
);