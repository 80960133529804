import { ApiError } from "./apiExecute";
import { genericError } from "./toast";

export default function handleGenericError(error: any) {
	if (__DEV__) {
		console.warn(error);
	}

	if (error instanceof ApiError && error.type === "abort") {
		return;
	}

	genericError();
}