import combineReducers from "../../../../Utils/combineReducers";
import { SectionChangedAction } from "../../../../Actions/Settings/SettingsActions";
import SettingsSection from "../../../../Models/SettingsSection";
import IAction from "../../../../Actions/IAction";
import mySettingsReducer, { IState as IMySettingsState } from "./mySettingsReducer";
import companyProfileReducer, { IState as ICompanyProfileState } from "./companyProfileReducer";
import affiliatesReducer, { IState as IAffiliatesState } from "./affiliatesReducer";
import billingReducer, { IState as IBillingState } from "./billingReducer";
import rolesReducer, { IState as IRolesState } from "./rolesReducer";
import usersReducer, { IState as IUsersState } from "./usersReducer";
import termsReducer, { IState as ITermsState } from "./termsReducer";
import claimBasisOptionsReducer, { IState as IClaimBasisOptionsState } from "./claimBasisOptionsReducer";
import ftpReducer, { IState as IFtpState } from "./ftpReducer";
import ipWhitelistReducer, { IState as IWhitelistState } from "./ipWhitelistReducer";
import * as storage from "../../../../Utils/storage";
import { ClaimPackageReviewAction } from '../../../../Actions/Settings/CompanyProfileActions';

export interface IState {
	activeSection: SettingsSection;
	mySettings: IMySettingsState;
	companyProfile: ICompanyProfileState;
	affiliates: IAffiliatesState;
	roles: IRolesState;
	users: IUsersState;
	billing: IBillingState;
	terms: ITermsState;
	ftp: IFtpState;
	whitelist: IWhitelistState;
	claimBasisOptions: IClaimBasisOptionsState;
}

const localStorageKey = `__${nameof(activeSectionReducer)}_initialSection__`;
const initialSection = __DEV__ ? storage.get<SettingsSection>(localStorageKey, SettingsSection.MySettings)! : SettingsSection.MySettings;

function activeSectionReducer(previousState: SettingsSection = initialSection, action: IAction) {
	if (action instanceof SectionChangedAction) {
		if (__DEV__) {
			storage.set(localStorageKey, action.section);
		}

		return action.section;
	}

	if (action instanceof ClaimPackageReviewAction && action.show && previousState !== SettingsSection.CompanyProfile) {
		return SettingsSection.CompanyProfile;
	}

	return previousState;
}

export default combineReducers<IState>({
	[nameof<IState>(it => it.activeSection)]: activeSectionReducer,
	[nameof<IState>(it => it.mySettings)]: mySettingsReducer,
	[nameof<IState>(it => it.companyProfile)]: companyProfileReducer,
	[nameof<IState>(it => it.affiliates)]: affiliatesReducer,
	[nameof<IState>(it => it.roles)]: rolesReducer,
	[nameof<IState>(it => it.users)]: usersReducer,
	[nameof<IState>(it => it.billing)]: billingReducer,
	[nameof<IState>(it => it.terms)]: termsReducer,
	[nameof<IState>(it => it.ftp)]: ftpReducer,
	[nameof<IState>(it => it.whitelist)]: ipWhitelistReducer,
	[nameof<IState>(it => it.claimBasisOptions)]: claimBasisOptionsReducer
});
