import TabLoadedAction from "../Actions/TabLoadedAction";
import ActiveTabEnum from "../Models/ActiveTabEnum";
import handleGenericError from "../Utils/handleGenericError";
import handleApiResponse from "../Utils/handleApiResponse";
import Dispatcher from "../Dispatcher/Dispatcher";
import ActiveTabChangedAction from "../Actions/ActiveTabChangedAction";
import { StartBusyAction, StopBusyAction } from "../Actions/BusyAction";
import ContactSupportAction from "../Actions/ContactSupportAction";
import { adminSwitchClientProfile } from "./Api/administrationActionCreators";
import { confirm } from "../Components/Common/Confirm/Confirm";

class AppActionCreators {
	startBusy = startBusy;
	stopBusy = stopBusy;

	activeTabChanged(activeTabIndex: number): void {
		Dispatcher.dispatch(new ActiveTabChangedAction(activeTabIndex));
	}

	contactSupport(show: boolean) {
		Dispatcher.dispatch(new ContactSupportAction(show));
	}
}

export function startBusy(message: string = "Loading...", showWhenLocked = false) {
	const action = new StartBusyAction(message, showWhenLocked);
	Dispatcher.dispatch(action);

	return () => stopBusy(action.id);
}

if (__DEV__) {
	const callbacks: (() => void)[] = [];
	(window as any)["startBusy"] = (message: string = "Testing...") => {
		const cb = startBusy(message);
		callbacks.push(cb);
	};

	(window as any)["stopBusy"] = () => {
		if (callbacks.length > 0) {
			callbacks.shift()!();
		}
	};
}

export function stopBusy(id: number) {
	Dispatcher.dispatch(new StopBusyAction(id));
}

export async function switchClientProfile(id: number, autoConfirm: boolean) {
	if (!autoConfirm && !await confirm("Switching client profiles will reload the window. Do you wish to proceed?")) {
		return;
	}

	const stopBusy = startBusy("Initializing...");

	try {
		const response = await adminSwitchClientProfile(id);
		handleApiResponse(response, () => window.location.reload());
	} catch (error) {
		handleGenericError(error);
		stopBusy();
	}
}

export function tabLoaded(tab: ActiveTabEnum) {
	Dispatcher.dispatch(new TabLoadedAction(tab));
}

export default new AppActionCreators();
