import * as Dto from "../Models/dto";
import keyBy from "lodash/keyBy";
import filter from "lodash/filter";
import uniq from "lodash/uniq";

type WorkflowStepMap = { [workflowStepId: number]: Dto.IAccountWorkflowStep; };
type AccountStatusMap = { [acctStatusId: number]: Dto.IAccountStatus };

export function isSameReport(a: Dto.IReport | null, b: Dto.IReport | null) {
	if (a === b) {
		return true;
	}

	if (a == null || b == null) {
		return false;
	}

	return a.userReportId === b.userReportId && (a.userReportId > 0 || a.name === b.name);
}

export interface ISpecialParams {
	workflowParam: Dto.IReportParam | undefined;
	acctStatusParam: Dto.IReportParam | undefined;
	allowedOutputParam: Dto.IReportParam;
}

export function getSpecialParams(params: Dto.IReportParam[]): ISpecialParams {
	let workflowParam;
	let acctStatusParam;
	let allowedOutputParam: Dto.IReportParam | undefined;

	for (const param of params) {
		switch (param.name) {
			case "Workflow":
				workflowParam = param;
				break;
			case "AccountStatus":
				acctStatusParam = param;
				break;
			case "AllowedOutput":
				allowedOutputParam = param;
				break;
			default:
				continue;
		}

		if (workflowParam && acctStatusParam && allowedOutputParam) {
			break;
		}
	}

	return {
		workflowParam,
		acctStatusParam,
		allowedOutputParam: allowedOutputParam!
	};
}

export function splitValues(value: string) {
	return value ? value.split(",") : [];
}

export function getLabel(values: string[], max: number) {
	const { length } = (values || []);
	switch (length) {
		case 0:
			return "None selected";
		case 1:
			return values[0];
		case max:
			return "All selected";
		default:
			return `${length} selected`;
	}
}

export function valueToWorkflowSteps(value: string, workflowSteps: WorkflowStepMap) {
	const values = splitValues(value);
	const valueMap = keyBy(values);

	return {
		getLabel: (max: number) => getLabel(values, max),
		value: keyBy(
			filter(workflowSteps, it => it.description in valueMap),
			it => it.accountWorkflowStepId
		) as WorkflowStepMap
	};
}

export function workflowStepsToValue(workflowSteps: Dto.IAccountWorkflowStep[]) {
	return workflowSteps.map(it => it.description).join(",");
}

export function valueToAccountStatuses(value: string, workflowSteps: WorkflowStepMap, accountStatuses: AccountStatusMap) {
	const valueMap = keyBy(splitValues(value));

	return keyBy(
		filter(accountStatuses, it => it.accountWorkflowStepId in workflowSteps && it.displayName in valueMap),
		it => it.acctStatusId
	) as AccountStatusMap;
}

export function accountStatusesToValue(accountStatuses: Dto.IAccountStatus[]) {
	return uniq(accountStatuses.map(it => it.displayName)).join(",");
}