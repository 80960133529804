import {
	ChangePasswordAction,
	CreateResetTokenAction,
	DoLoginAction,
	SaveSecurityQuestionAnswerAction,
	ValidateSecurityAnswerAction
} from "../../Actions/Api/loginActions";
import IAction from "../../Actions/IAction";
import { AsyncState } from "../../Models/IAsync";
import { GetCaptchaChallengeAndImageAction } from "../../Actions/Api/captchaActions";
import EditCaptchaAction from "../../Actions/Login/EditCaptchaAction";
import InvalidateCaptchaAction from "../../Actions/Login/InvalidateCaptchaAction";
import getLoadState from "../../Utils/getLoadState";

export interface IState {
	loading: AsyncState | null;
	failing: boolean;
	timestamp: string;
	challenge: string | null;
	imageBytes: string | null;
	response: string;
}

const initialState: IState = {
	loading: null,
	failing: false,
	timestamp: "",
	challenge: null,
	imageBytes: null,
	response: ""
};

export default function captchaReducer(
	previousState: IState = initialState,
	action: IAction
): IState {
	if (action instanceof GetCaptchaChallengeAndImageAction) {
		const loading = getLoadState(action);
		const { timestamp } = action;

		let { failing, challenge, imageBytes } = previousState;
		switch (loading) {
			case AsyncState.Rejected:
				failing = true;
				break;
			case AsyncState.Resolved:
				failing = false;
				challenge = action.response!.challenge;
				imageBytes = action.response!.imageBytes as string;
				break;
			case null:
			case AsyncState.Pending:
				break;
			default:
				if (__DEV__) {
					console.error(`[${__filename}] Unexpected state: ${JSON.stringify(loading)}`);
				}
				break;
		}

		return {
			loading,
			failing,
			timestamp,
			challenge,
			imageBytes,
			response: ""
		};
	}

	if (action instanceof EditCaptchaAction) {
		if (previousState.loading === AsyncState.Resolved) {
			return Object.assign({}, previousState, {
				response: action.value
			});
		}
	}

	if (action instanceof InvalidateCaptchaAction && action.challenge === previousState.challenge) {
		return initialState;
	}

	if (
		action instanceof DoLoginAction
		|| action instanceof CreateResetTokenAction
		|| action instanceof SaveSecurityQuestionAnswerAction
		|| action instanceof ValidateSecurityAnswerAction
		|| action instanceof ChangePasswordAction
	) {
		if (action.state !== AsyncState.Pending && action.params.captchaChallenge === previousState.challenge) {
			return initialState;
		}
	}

	return previousState;
}