import React from "react";
import * as Dto from "../../../../Models/dto";
import withoutProperties from "../../../../Utils/withoutProperties";
import { AsyncState } from "../../../../Models/IAsync";
import _AsyncSelector from "../../AsyncSelector/AsyncSelector";
import isEmpty from "../../../../Utils/isEmpty";

export interface IOwnProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
	abbreviation: string | null;
	onSelectionChange: (abbreviation: string | null, state: Dto.IState | null) => void;
	optional?: boolean;
}

export interface IConnectedProps {
	loading: AsyncState | null;
	states: Dto.IState[];
}

interface IProps extends IOwnProps, IConnectedProps { }

class AsyncSelector extends _AsyncSelector<Dto.IState, string> { }

const StateInput: React.StatelessComponent<IProps> = (props: IProps) => {
	const otherProps = withoutProperties(props, [
		nameof(props.states),
		nameof(props.abbreviation)
	]);

	const abbreviation = isEmpty(props.abbreviation) ? null : props.abbreviation;
	const optional = props.optional == null ? true : props.optional;

	return (
		<AsyncSelector
			{...otherProps}
			getId={it => it.abbreviation}
			parseId={it => it}
			getLabel={it => it.abbreviation}
			selectedId={abbreviation}
			items={props.states}
			optional={optional}
		/>
	);
};

export default StateInput;