import assign from "../../../../Utils/assign";
import * as actions from "../../../../Actions/Settings/TermsActions";
import IAction from "../../../../Actions/IAction";
import * as Dto from "../../../../Models/dto";

export interface IState {
	legalDocument: Dto.ILegalDocument | null;
}

export default function legalDocumentsReducer(
	previousState: IState = {
		legalDocument: null
	},
	action: IAction
) {
	if (action instanceof actions.ViewTermsAction) {
		const { legalDocument } = action;
		return assign(previousState, { legalDocument });
	}

	return previousState;
}