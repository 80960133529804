import React from "react";
import cn from "classnames";
import styles from "./ProductConfirm.module.scss";
import { appName } from "../../../Utils/branding";

interface IProps {
	product: number;
	onConfirm: () => void;
}

const ProductConfirm = ({ onConfirm, product }: IProps) => (
	<div>
		<div className={styles.header}>
			Let's get started
		</div>
		<div className={styles.subtitle}>
			Welcome to {appName}<br />
			Please confirm that the product below is the solution your organization is registering for.
		</div>
		<div
			className={cn(
				product === 1 && styles.claimFilingLogo,
				product === 2 && styles.dataOnlyLogo
			)}
		>
		</div>
		<div className={styles.productTitle}>
			{product === 1 ? "Estate Match Claim Filing" : "Estate Match Data Only"}
		</div>
		<div className={styles.productSubtitle}>
			{
				product === 1
					? "Probated estate location, and claim presentation"
					: "Probated estate location"
			}
		</div>
		<button
			className={cn("btn", "btn-warning", styles.confirm)}
			onClick={onConfirm}
		>
			Confirm
		</button>
	</div>
);

export default ProductConfirm;