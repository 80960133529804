import { makeAsync } from "../Models/IAsync";
import { IState } from "../Reducers/rootReducer";
import { createSelector } from "reselect";

export const getRolesByIdAsync = createSelector(
	(state: IState) => state.entities.roles.byId,
	(state: IState) => state.entities.roles.loading,
	(rolesById, loadState) => makeAsync(loadState, rolesById)
);

export default createSelector(
	getRolesByIdAsync,
	({ payload }) => payload!
);