/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	GetCaptchaChallengeAndImageAction,
	IGetCaptchaChallengeAndImageActionParams
} from "../../Actions/Api/captchaActions";
import {
	ICaptchaResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const getCaptchaChallengeAndImageEndpoint = "Api/Captcha/GetCaptchaChallengeAndImage";

export function getCaptchaChallengeAndImage(
	__options__?: IOptions<ICaptchaResponse>
) {
	return apiExecute<IGetCaptchaChallengeAndImageActionParams, GetCaptchaChallengeAndImageAction, ICaptchaResponse>(
		getCaptchaChallengeAndImageEndpoint, 
		false, 
		__options__, 
		GetCaptchaChallengeAndImageAction,
		{ /* no parameters */ }
	);
}

/* tslint:enable:max-line-length */