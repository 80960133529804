/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	ChangePasswordAction,
	IChangePasswordActionParams,
	ClientLogoutAction,
	IClientLogoutActionParams,
	CreateResetTokenAction,
	ICreateResetTokenActionParams,
	DoLoginAction,
	IDoLoginActionParams,
	GetSecurityQuestionAction,
	IGetSecurityQuestionActionParams,
	GetUserAuthenticatedAction,
	IGetUserAuthenticatedActionParams,
	KeepAliveAction,
	IKeepAliveActionParams,
	LookupTokenAction,
	ILookupTokenActionParams,
	SaveSecurityQuestionAnswerAction,
	ISaveSecurityQuestionAnswerActionParams,
	ValidateSecurityAnswerAction,
	IValidateSecurityAnswerActionParams
} from "../../Actions/Api/loginActions";
import {
	IBaseResponse,
	ILoginUser,
	IResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const changePasswordEndpoint = "Api/Login/ChangePassword";

export function changePassword(
	user: ILoginUser, 
	captchaResponse: string, 
	captchaChallenge: string, 
	__options__?: IOptions<IResponse<ILoginUser>>
) {
	return apiExecute<IChangePasswordActionParams, ChangePasswordAction, IResponse<ILoginUser>>(
		changePasswordEndpoint, 
		false, 
		__options__, 
		ChangePasswordAction,
		{
			user,
			captchaResponse,
			captchaChallenge
		}
	);
}

export const clientLogoutEndpoint = "Api/Login/ClientLogout";

export function clientLogout(
	__options__?: IOptions<IResponse<boolean>>
) {
	return apiExecute<IClientLogoutActionParams, ClientLogoutAction, IResponse<boolean>>(
		clientLogoutEndpoint, 
		true, 
		__options__, 
		ClientLogoutAction,
		{ /* no parameters */ }
	);
}

export const createResetTokenEndpoint = "Api/Login/CreateResetToken";

export function createResetToken(
	userName: string, 
	captchaResponse: string, 
	captchaChallenge: string, 
	__options__?: IOptions<IBaseResponse>
) {
	return apiExecute<ICreateResetTokenActionParams, CreateResetTokenAction, IBaseResponse>(
		createResetTokenEndpoint, 
		false, 
		__options__, 
		CreateResetTokenAction,
		{
			userName,
			captchaResponse,
			captchaChallenge
		}
	);
}

export const doLoginEndpoint = "Api/Login/DoLogin";

export function doLogin(
	user: ILoginUser, 
	captchaResponse: string, 
	captchaChallenge: string, 
	__options__?: IOptions<IResponse<ILoginUser>>
) {
	return apiExecute<IDoLoginActionParams, DoLoginAction, IResponse<ILoginUser>>(
		doLoginEndpoint, 
		false, 
		__options__, 
		DoLoginAction,
		{
			user,
			captchaResponse,
			captchaChallenge
		}
	);
}

export const getSecurityQuestionEndpoint = "Api/Login/GetSecurityQuestion";

export function getSecurityQuestion(
	user: ILoginUser, 
	captchaResponse: string, 
	captchaChallenge: string, 
	__options__?: IOptions<IResponse<ILoginUser>>
) {
	return apiExecute<IGetSecurityQuestionActionParams, GetSecurityQuestionAction, IResponse<ILoginUser>>(
		getSecurityQuestionEndpoint, 
		false, 
		__options__, 
		GetSecurityQuestionAction,
		{
			user,
			captchaResponse,
			captchaChallenge
		}
	);
}

export const getUserAuthenticatedEndpoint = "Api/Login/GetUserAuthenticated";

export function getUserAuthenticated(
	__options__?: IOptions<IResponse<ILoginUser>>
) {
	return apiExecute<IGetUserAuthenticatedActionParams, GetUserAuthenticatedAction, IResponse<ILoginUser>>(
		getUserAuthenticatedEndpoint, 
		true, 
		__options__, 
		GetUserAuthenticatedAction,
		{ /* no parameters */ }
	);
}

export const keepAliveEndpoint = "Api/Login/KeepAlive";

export function keepAlive(
	__options__?: IOptions<IBaseResponse>
) {
	return apiExecute<IKeepAliveActionParams, KeepAliveAction, IBaseResponse>(
		keepAliveEndpoint, 
		true, 
		__options__, 
		KeepAliveAction,
		{ /* no parameters */ }
	);
}

export const lookupTokenEndpoint = "Api/Login/LookupToken";

export function lookupToken(
	token: string, 
	__options__?: IOptions<IResponse<ILoginUser>>
) {
	return apiExecute<ILookupTokenActionParams, LookupTokenAction, IResponse<ILoginUser>>(
		lookupTokenEndpoint, 
		false, 
		__options__, 
		LookupTokenAction,
		{ token }
	);
}

export const saveSecurityQuestionAnswerEndpoint = "Api/Login/SaveSecurityQuestionAnswer";

export function saveSecurityQuestionAnswer(
	user: ILoginUser, 
	captchaResponse: string, 
	captchaChallenge: string, 
	__options__?: IOptions<IResponse<ILoginUser>>
) {
	return apiExecute<ISaveSecurityQuestionAnswerActionParams, SaveSecurityQuestionAnswerAction, IResponse<ILoginUser>>(
		saveSecurityQuestionAnswerEndpoint, 
		true, 
		__options__, 
		SaveSecurityQuestionAnswerAction,
		{
			user,
			captchaResponse,
			captchaChallenge
		}
	);
}

export const validateSecurityAnswerEndpoint = "Api/Login/ValidateSecurityAnswer";

export function validateSecurityAnswer(
	user: ILoginUser, 
	captchaResponse: string, 
	captchaChallenge: string, 
	__options__?: IOptions<IResponse<ILoginUser>>
) {
	return apiExecute<IValidateSecurityAnswerActionParams, ValidateSecurityAnswerAction, IResponse<ILoginUser>>(
		validateSecurityAnswerEndpoint, 
		false, 
		__options__, 
		ValidateSecurityAnswerAction,
		{
			user,
			captchaResponse,
			captchaChallenge
		}
	);
}

/* tslint:enable:max-line-length */