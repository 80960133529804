import {
	CompleteRegistrationAction,
	GetPendingRegistrationAction
} from "../../Actions/Api/clientRegistrationActions";
import {
	IPendingRegistrationResponse,
	ICompleteRegistrationModel
} from "../../Models/dto";
import IAction from "../../Actions/IAction";
import getLoadState from "../../Utils/getLoadState";
import { AsyncState } from "../../Models/IAsync";
import NextStepAction from "../../Actions/Registration/NextStepAction";
import PreviousStepAction from "../../Actions/Registration/PreviousStepAction";

export enum RegistrationStep {
	ProductSelection,
	Terms,
	Nda,
	Baa,
	AdminProfile,
	Security,
	Profile,
	NextSteps,
	Confirm,
	Complete
}

export interface IState {
	loading: AsyncState;
	currentStep: RegistrationStep | null;
	requiredSteps: RegistrationStep[] | null;
	data: IPendingRegistrationResponse | null;
}

const initialState: IState = {
	loading: AsyncState.Pending,
	currentStep: null,
	requiredSteps: null,
	data: null
};

function getRequiredSteps(data: IPendingRegistrationResponse, model: ICompleteRegistrationModel | null) {
	const result: RegistrationStep[] = [RegistrationStep.ProductSelection];

	if (!data.termsSigned) {
		result.push(RegistrationStep.Terms);
	}

	if (!data.ndaSigned) {
		result.push(RegistrationStep.Nda);
	}

	result.push(RegistrationStep.AdminProfile);
	result.push(RegistrationStep.Security);
	result.push(RegistrationStep.Profile);

	if (!data.baaSigned && model && (model.medicalFlag || model.hospitalFlag)) {
		result.push(RegistrationStep.Baa);
	}

	result.push(RegistrationStep.NextSteps);
	result.push(RegistrationStep.Confirm);
	return result;
}

export default function registrationReducer(
	previousState: IState = initialState,
	action: IAction
): IState {
	if (action instanceof GetPendingRegistrationAction) {
		switch (getLoadState(action)) {
			case AsyncState.Rejected:
				return { ...previousState, loading: AsyncState.Rejected };
			case AsyncState.Pending:
				return { ...previousState, loading: AsyncState.Pending };
			default:
				return {
					...previousState,
					data: action.response,
					loading: AsyncState.Resolved,
					currentStep: RegistrationStep.ProductSelection,
					requiredSteps: getRequiredSteps(action.response!, null)
				};
		}
	}

	if (action instanceof NextStepAction) {
		const steps = getRequiredSteps(previousState.data!, action.model);
		const currentStep = steps[steps.indexOf(previousState.currentStep!) + 1];
		return {
			...previousState,
			requiredSteps: steps,
			currentStep: currentStep
		};
	}

	if (action instanceof PreviousStepAction) {
		const steps = getRequiredSteps(previousState.data!, action.model);
		const currentStep = steps[steps.indexOf(previousState.currentStep!) - 1];
		return {
			...previousState,
			requiredSteps: steps,
			currentStep: currentStep
		};
	}

	if (action instanceof CompleteRegistrationAction) {
		switch (getLoadState(action)) {
			case AsyncState.Rejected:
				return { ...previousState, loading: AsyncState.Rejected };
			case AsyncState.Pending:
				return { ...previousState, loading: AsyncState.Pending };
			default:
				return {
					...previousState,
					loading: AsyncState.Resolved,
					currentStep: RegistrationStep.Complete
				};
		}
	}

	return previousState;
}