import { IState } from "../../../../Reducers/rootReducer";
import getCurrentUser from "../../../../selectors/getCurrentUser";
import { createSelector } from "reselect";

export const getUserAgreement = (state: IState) => state.entities.startup.useAgreement;
export const getShowUserAgreement = createSelector(
	getCurrentUser,
	getUserAgreement,
	(currentUser, userAgreement) => currentUser != null
		&& userAgreement != null
		&& !currentUser.userAgreementAccepted
);