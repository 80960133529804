import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";
import * as Dto from "../../Models/dto";

export default class WorkFlowStatusDataLoadedAction implements IAction {
	data: Dto.ICollectionResponse<Dto.IAccountWorkflowStatusCount>;

	constructor(data: Dto.ICollectionResponse<Dto.IAccountWorkflowStatusCount>) {
		this.data = data;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"WorkFlowStatusDataLoadedAction", { "data": JSON.stringify(this.data) });
	}
}