import * as React from "react";

export default function renderNonVisibleComponent(children: React.ReactNode) {
	const count = React.Children.count(children);
	if (count === 1) {
		return React.Children.only(children) as JSX.Element;
	} else if (__DEV__ && count !== 0) {
		console.error(`[${__filename}] Multiple children not supported`);
	}

	return null;
}
