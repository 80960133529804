import React from "react";
import SupportForm from "./SupportForm";
import connect from "../../Utils/connect";
import AppActionCreators from "../../ActionCreators/AppActionCreators";
import styles from "./Footer.module.scss";
import MainContent from "../Common/MainContent/MainContent";
import Button from "../Common/Button/Button";
import { ReactComponent as PaperPlaneIcon } from "./paper-plane.svg";
import { ReactComponent as PoweredByForteLogo } from "./powered-by-forte.svg";
import VisuallyHidden from "../Common/VisuallyHidden/VisuallyHidden";
import HelpPopup from "../Common/HelpPopup/HelpPopup";
import HelpContent from "../Common/HelpContent/HelpContent";
import getClientProfile from "../../selectors/getClientProfile";

interface IOwnProps { }

interface IConnectedProps {
	version: string;
	guid: string;
	feedbackUrl: string | null;
	contactSupport: boolean;
	contactSupportShowResetPasswordOption: boolean;
	onContactSupport(show: boolean): void;
	clientID: number;
	clientName: string;
}

interface IProps extends IOwnProps, IConnectedProps { }

function Footer(props: IProps) {
	const { version, guid } = props;

	return (
		<MainContent className={styles.container}>
			<div className={styles.sectionLeft}>
				{guid &&
					<span>
						<HelpContent
							title="Release Notes"
							help="View a summary of the latest application updates here."
						>
							<a
								className={styles.link}
								href={`/Download/ReleaseNotes/${guid}`}
							>
								Release Notes {version && `(${version})`}
							</a>
						</HelpContent>
					</span>
				}
				{props.feedbackUrl != null && (
					<span>
						<a
							className={styles.link}
							href={props.feedbackUrl}
							target="_blank"
							rel="noopener noreferrer"
						>
							Leave feedback
						</a>
					</span>
				)}
			</div>
			<div className={styles.sectionCenter}>
				<PoweredByForteLogo aria-hidden />
				<VisuallyHidden>
					Powered by Forte
				</VisuallyHidden>
			</div>
			<div className={styles.sectionRight}>
				<HelpPopup title="Contact Support">
					Questions? To connect with our support team, reach out through the Contact support link, or by phone.
				</HelpPopup>

				<a
					href="tel:+18773265686"
					itemProp="telephone"
					className={styles.phoneLink}
				>
					1.877.326.5686
				</a>
				<Button
					className={styles.contactSupportButton}
					onClick={() => props.onContactSupport(true)}
					primary
				>
					<PaperPlaneIcon aria-hidden />
					<span>
						{" "}Contact Support
						</span>
				</Button>
			</div>
			<SupportForm
				onHide={() => props.onContactSupport(false)}
				show={props.contactSupport}
				showResetPasswordOption={props.contactSupportShowResetPasswordOption}
				clientID={props.clientID}
				clientName={props.clientName}
			/>
		</MainContent>
	);
}

const onContactSupport = AppActionCreators.contactSupport.bind(AppActionCreators);

export default connect<IConnectedProps, IOwnProps>(
	Footer,
	state => {
		return {
			version: state.entities.startup.version,
			guid: state.entities.startup.releaseNotesGuid,
			feedbackUrl: state.entities.startup.feedbackUrl,
			contactSupport: state.ui.contactSupport,
			contactSupportShowResetPasswordOption: !(getClientProfile(state)?.singleSignOn ?? true),
			onContactSupport,
			clientID: getClientProfile(state)?.clientProfileId ?? 0,
			clientName: getClientProfile(state)?.name ?? ""
		};
	}
);