import * as React from "react";
import pure from "../../../Utils/pure";
import Button from "../Button/Button";
import classNames from "classnames";

interface IProps {
	value: boolean | null | undefined;
	yesLabel?: string;
	noLabel?: string;
	disabled?: boolean | null;
	onChange(value: boolean): void;
	className?: ClassValue;
}

function handleChange(value: boolean, props: IProps) {
	if (value !== props.value) {
		props.onChange(value);
	}
}

const YesNoSelector: React.StatelessComponent<IProps> = (props: IProps) => {
	const yesSelected = props.value;
	const noSelected = props.value != null && !props.value; // treat null/undefined as no selection

	return (
		<div className={classNames("btn-group", props.className)}>
			<Button
				onClick={() => handleChange(true, props)}
				disabled={props.disabled ?? undefined}
				active={yesSelected ?? false}
				success={yesSelected ?? false}
			>
				{props.yesLabel ?? "Yes"}
			</Button>
			<Button
				onClick={() => handleChange(false, props)}
				disabled={props.disabled ?? undefined}
				active={noSelected ?? false}
				danger={noSelected ?? false}
			>
				{props.noLabel ?? "No"}
			</Button>
		</div>
	);
};

export default pure(YesNoSelector);