import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";

export default class ExportFieldSelectedAction implements IAction {
	data: Array<string>;

	constructor(data: Array<string>) {
		this.data = data;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"ExportFieldSelectedAction", { "data": JSON.stringify(this.data) });
	}
}