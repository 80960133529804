import React from "react";
import { appName } from "../../../Utils/branding";

const Complete = () => {
	return (
		<div
			style={{
				margin: "auto",
				maxWidth: "700px",
				paddingTop: "100px",
				fontSize: "16px",
				color: "#58595B"
			}}
		>
			<p style={{ fontWeight: "bold" }}>
				Congratulations on completing your registration for {appName}.
			</p>
			<p>
				A member from our {appName} team will be reaching out to confirm
				and schedule your implementation kick-off meeting. If you would like to set up your
				company profile prior to your kick-off meeting, select the application link below.
				If not, our {appName} team will walk you through the set-up process
				during the kick-off meeting.
			</p>
			<p>
				{appName}: <a href="https://probatefinderondemand.com/Application/">https://probatefinderondemand.com/Application/</a>
			</p>
			<p>
				If you have any questions, please contact us at&nbsp;
				<a href="mailto:contactsupport@probatefinderondemand.com">contactsupport@probatefinderondemand.com</a>
			</p>
		</div>
	);
};

export default Complete;