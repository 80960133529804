import { MODIFIED_BY_ANOTHER_USER, OK } from "../Models/apiConstants";
import getLoadState from "../Utils/getLoadState";
import { ApiError, IFakeJQueryXhr } from "../Utils/apiExecute";
import { IGetProbateCaseActionParams } from "../Actions/Api/probateCaseActions";
import {
	IGetClaimDisputeFromClaimIDActionParams,
	IGetClaimIDLinkedAccountsActionParams
} from "../Actions/Api/claimActions";
import AccountApi from "../Models/AccountApi";
import {
	IGetAccountHistoriesByAccountIDActionParams,
	IGetBankoInfoActionParams,
	IGetClaimsByAccountIDActionParams,
	IGetClientProfileBillingsActionParams
} from "../Actions/Api/accountActions";
import NavigateToAccountAction from "../Actions/Accounts/NavigateToAccountAction";
import BaseStore from "./BaseStore";
import IAction from "./../Actions/IAction";
import Dispatcher from "../Dispatcher/Dispatcher";
import AccountsStore from "./AccountsStore";
import SearchAccountsStore from "./SearchAccountsStore";
import * as actions from "../Actions/AccountDetailsActions";
import ProbateCaseApi from "../Models/ProbateCaseApi";
import DocumentApi from "../Models/DocumentApi";
import IApiRequest, { isPending, isError } from "../Models/IApiRequest";
import * as toast from "../Utils/toast";
import isEmptyDate from "../Utils/isEmptyDate";
import ClaimApi from "../Models/ClaimApi";
import IMailingParty from "../Models/IMailingParty";
import AccountsActionEnum from "../Models/AccountsActionEnum";
import cloneDeep from "lodash/cloneDeep";
import * as Dto from "../Models/dto";
import moment from "moment";
import {
	IGetAllDocumentsByAcctIDActionParams,
	UploadAccountClaimDocumentAction
} from "../Actions/Api/documentActions";
import { AsyncState } from "../Models/IAsync";
import isSameAccount from "../Utils/isSameAccount";

type IAccount = Dto.IAccount;
type IAccountImport = Dto.IAccountImport;

export interface IProbateCaseRequest extends IApiRequest<IGetProbateCaseActionParams, Dto.IResponse<Dto.IProbateCase>> { }
export interface IBankoRequest extends IApiRequest<IGetBankoInfoActionParams, Dto.IResponse<Dto.IBankruptcyCase>> { }
export interface IClaimsRequest extends IApiRequest<IGetClaimsByAccountIDActionParams, Dto.IAccountClaimsResponse> { }
export interface IAccountClaimsRequest extends IApiRequest<IGetClaimIDLinkedAccountsActionParams, Dto.ICollectionResponse<Dto.IAccountClaims>> { }
export interface IDocumentsRequest extends IApiRequest<IGetAllDocumentsByAcctIDActionParams, Dto.ILinkedDocumentResponse> { }
export interface IDisupteRequest extends IApiRequest<IGetClaimDisputeFromClaimIDActionParams, Dto.IResponse<Dto.IClaimDispute>> { }
export interface IFeesRequest extends IApiRequest<IGetClientProfileBillingsActionParams, Dto.IClientProfileBillingResponse> { }
export interface IHistoryRequest extends IApiRequest<IGetAccountHistoriesByAccountIDActionParams, Dto.ICollectionResponse<Dto.IAccountHistory>> { }

export interface IClaimEditReceipt {
	confirmed: boolean;
	date: moment.Moment | null;
}

export interface IClaimEdit {
	claimId: number;
	notes: string;
	courtReceipt: IClaimEditReceipt;
	attyReceipt: IClaimEditReceipt;
	repReceipt: IClaimEditReceipt;
	agreeToRelease: IAgreeToReleaseData;
}

export interface IAgreeToReleaseData {
	amount: number | null;
	dueDate: string | null;
	attorney: IMailingParty;
	rep: IMailingParty;
}

export interface IAccountDetailsState {
	activeTab: number;

	account: IAccount | IAccountImport | null;

	estate: {
		probateCase: IProbateCaseRequest | null;
		banko: IBankoRequest | null;
	};

	details: {
		edit: IAccount | IAccountImport | null;
	};

	claims: {
		request: IClaimsRequest | null;
		edits: { [claimId: number]: IClaimEdit };
		accountClaims: IAccountClaimsRequest | null;
	};

	documents: {
		documents: IDocumentsRequest | null;
		showAddDocumentModal: boolean;
	};

	disputes: {
		request: IDisupteRequest | null;
		edit: Dto.IClaimDispute | null;
	};

	fees: {
		request: IFeesRequest | null;
	};

	history: {
		showing: boolean;
		request: IHistoryRequest | null;
	};
}

enum AccountSource {
	Workflows,
	Search,
	None
}

export const TAG = "AccountDetailsStore"; // nameof(AccountDetailsStore);

class AccountDetailsStore extends BaseStore {
	private _state: IAccountDetailsState = {
		activeTab: 0,

		account: null,

		details: {
			edit: null
		},

		estate: {
			probateCase: null,
			banko: null
		},

		claims: {
			request: null,
			edits: [],
			accountClaims: null
		},

		documents: {
			documents: null,
			showAddDocumentModal: false
		},

		disputes: {
			request: null,
			edit: null
		},

		fees: {
			request: null
		},

		history: {
			showing: false,
			request: null
		}
	};

	constructor() {
		super();

		Dispatcher.register(it => this._processActions(it));

		this._selectionChangeCheck();
	}

	getState() {
		return this._state;
	}

	isClaimEditDirty(edit: IClaimEdit, claim: Dto.IClaim) {
		const original = this._getClaimEdit(claim);

		return original.notes !== edit.notes
			|| !this._areClaimEditReceiptsEqual(original.courtReceipt, edit.courtReceipt)
			|| !this._areClaimEditReceiptsEqual(original.attyReceipt, edit.attyReceipt)
			|| !this._areClaimEditReceiptsEqual(original.repReceipt, edit.repReceipt);
	}

	private _processActions(action: IAction) {
		if (action instanceof NavigateToAccountAction) {
			this._state = Object.assign({}, this._state, {
				activeTab: 0
			});
			this.emitChange();
		}

		if (action instanceof actions.TabChangeAction) {
			this._state = Object.assign({}, this._state, {
				activeTab: action.index
			});
			this.emitChange();
		}

		if (action instanceof actions.UpdateAccountEditAction) {
			this._state = Object.assign({}, this._state, {
				details: Object.assign({}, this._state.details, {
					edit: Object.assign({}, this._state.details.edit, action.account)
				})
			});

			this.emitChange();
		}

		if (action instanceof actions.SaveAccountSuccessAction) {
			if (__DEV__ && action.response.item != null && !isSameAccount(action.response.item, this._state.account)) {
				console.error(`[${__filename}] Unexpected account saved`);
				return;
			}

			this._state = Object.assign({}, this._state, {
				account: Object.assign({}, this._state.account, action.response.item)
			});

			this._updateAccountEdit();

			this.emitChange();

			switch (action.response.status) {
				case OK:
					toast.success("Account saved successfully.");
					break;
				case MODIFIED_BY_ANOTHER_USER:
					toast.warning("This account was modified by another user. The account was refreshed but the changes were lost. Please re-enter your changes.");
					break;
				case "DuplicateAccountNumberError":
					toast.error("Account number already exists.");
					break;
				default:
					toast.genericError();
			}
		}

		if (action instanceof actions.SaveAccountErrorAction) {
			toast.genericError();
		}

		if (action instanceof actions.RequestProbateCaseAction) {
			this._handleApiRequest<IGetProbateCaseActionParams, Dto.IResponse<Dto.IProbateCase>>(
				() => this._getProbateCaseParams(this._state.account!),
				params => ProbateCaseApi.getProbateCase(params),
				state => state.estate.probateCase!,
				(state, request) => {
					const estate = Object.assign({}, state.estate, {
						probateCase: request
					});

					return Object.assign({}, state, { estate });
				}
			);
		}

		if (action instanceof actions.RequestBankoAction) {
			this._handleApiRequest<IGetBankoInfoActionParams, Dto.IResponse<Dto.IBankruptcyCase>>(
				() => ({ acctID: this._state.account!.acctId }),
				params => AccountApi.getBankoInfo(params),
				state => state.estate.banko!,
				(state, request) => {
					const estate = Object.assign({}, state.estate, {
						banko: request
					});

					return Object.assign({}, state, { estate });
				}
			);
		}

		if (action instanceof actions.RequestClaimsAction) {
			const { acctId } = this._state.account!;
			this._handleApiRequest<IGetClaimsByAccountIDActionParams, Dto.IAccountClaimsResponse>(
				() => ({ acctId }),
				params => AccountApi.getClaimsByAccountID(params),
				state => state.claims.request!,
				(state, request) => {
					const claims = Object.assign({}, state.claims, {
						request,
						edits: []
					});

					return Object.assign({}, state, { claims });
				},
				() => this._updateClaimEdits()
			);
		}

		if (action instanceof actions.ClaimEditChangeAction) {
			const { claimId } = action.edit;
			const edit = Object.assign({}, this._state.claims.edits[claimId], action.edit);

			this._state = Object.assign({}, this._state, {
				claims: Object.assign({}, this._state.claims, {
					edits: Object.assign({}, this._state.claims.edits, {
						[claimId]: edit
					})
				})
			});

			this.emitChange();
		}

		if (action instanceof actions.ClaimDiscardChangesAction) {
			this._updateClaimEdits();
			this.emitChange();
		}

		if (action instanceof actions.ClaimSaveSuccessAction) {
			const { claimId } = action.response.item;
			const { claims } = this._state;

			this._state = Object.assign({}, this._state, {
				claims: Object.assign({}, claims, {
					request: Object.assign({}, claims.request, {
						response: Object.assign({}, claims.request!.response, {
							items: claims.request!.response!.claims.map(claim => {
								if (claim.claimId === claimId) {
									return Object.assign({}, claim, action.response.item);
								}

								return claim;
							})
						})
					}),
					edits: Object.assign({}, claims.edits, {
						[claimId]: Object.assign({}, claims.edits[claimId], this._getClaimEdit(action.response.item))
					})
				})
			});

			this.emitChange();

			if (action.response.status !== OK) {
				toast.genericError();
			}
		}

		if (action instanceof actions.ClaimSaveErrorAction) {
			toast.genericError();
		}

		if (action instanceof actions.RequestAccountClaimsAction) {
			this._handleApiRequest<IGetClaimIDLinkedAccountsActionParams, Dto.ICollectionResponse<Dto.IAccountClaims>>(
				() => ({ acctIDs: [this._state.account!.acctId] }),
				params => ClaimApi.getClaimIdLinkedAccounts(params),
				state => state.claims.accountClaims!,
				(state, request) => {
					const claims = Object.assign({}, state.claims, { accountClaims: request });
					return Object.assign({}, state, { claims });
				},
				() => this._updateAgreeToReleaseParties()
			);
		}

		if (action instanceof actions.RequestDocumentsAction) {
			this._handleApiRequest<IGetAllDocumentsByAcctIDActionParams, Dto.ILinkedDocumentResponse>(
				() => ({ acctID: this._state.account!.acctId }),
				params => DocumentApi.getAllDocumentsByAcctID(params),
				state => state.documents.documents!,
				(state, request) => {
					const documents = Object.assign({}, state.documents, { documents: request });
					return Object.assign({}, state, { documents });
				}
			);
		}

		if (action instanceof actions.RequestDisputeAction) {
			this._handleApiRequest<IGetClaimDisputeFromClaimIDActionParams, Dto.IResponse<Dto.IClaimDispute>>(
				() => this._getClaimDisputeParams(this._state.account as Dto.IAccount),
				params => ClaimApi.getClaimDisputeFromClaimID(params),
				state => state.disputes.request!,
				(state, request) => {
					const disputes = Object.assign({}, state.disputes, { request });
					return Object.assign({}, state, { disputes });
				},
				() => this._updateDisputeEdit()
			);
		}

		if (action instanceof actions.UpdateDisputeEditAction) {
			this._state = Object.assign({}, this._state, {
				disputes: Object.assign({}, this._state.disputes, {
					edit: Object.assign({}, this._state.disputes.edit, action.dispute)
				})
			});

			this.emitChange();
		}

		if (action instanceof actions.SaveDisputeSuccessAction) {
			this._state = Object.assign({}, this._state, {
				disputes: Object.assign({}, this._state.disputes, {
					request: Object.assign({}, this._state.disputes.request, {
						response: Object.assign({}, this._state.disputes.request!.response, {
							item: action.response.item
						})
					})
				})
			});

			this._updateDisputeEdit();

			this.emitChange();

			if (action.response.status !== OK) {
				toast.genericError();
			}
		}

		if (action instanceof actions.SaveDisputeErrorAction) {
			toast.genericError();
		}

		if (action instanceof actions.RequestFeesAction) {
			this._handleApiRequest<IGetClientProfileBillingsActionParams, Dto.IClientProfileBillingResponse>(
				() => ({ acctID: this._state.account!.acctId }),
				params => AccountApi.getClientProfileBillings(params),
				state => state.fees.request!,
				(state, request) => {
					const fees = Object.assign({}, state.fees, { request });
					return Object.assign({}, state, { fees });
				}
			);
		}

		if (action instanceof actions.RequestHistoryAction) {
			this._handleApiRequest<IGetAccountHistoriesByAccountIDActionParams, Dto.ICollectionResponse<Dto.IAccountHistory>>(
				() => ({ acctId: this._state.account!.acctId }),
				params => AccountApi.getAccountHistoriesByAccountID(params),
				state => state.history.request!,
				(state, request) => {
					const history = Object.assign({}, state.history, { request });
					return Object.assign({}, state, { history });
				}
			);
		}

		if (action instanceof actions.HistoryModalAction) {
			if (action.show !== this._state.history.showing) {
				this._state = Object.assign({}, this._state, {
					history: Object.assign({}, this._state.history, {
						showing: action.show
					})
				});
				this.emitChange();
			}
		}

		if (action instanceof actions.DeleteDocumentStartAction) {
			// do nothing
		}

		if (action instanceof actions.DeleteDocumentSuccessAction) {
			if (action.response.status === OK) {
				const { accountDocId, claimDocId } = action.document;
				const items = this._state.documents.documents!.response!.items.filter(it => {
					if (accountDocId > 0 && it.accountDocId === accountDocId) {
						return false;
					}

					if (claimDocId > 0 && it.claimDocId === claimDocId) {
						return false;
					}

					return true;
				});

				this._state = Object.assign({}, this._state, {
					documents: Object.assign({}, this._state.documents, {
						documents: Object.assign({}, this._state.documents.documents, {
							response: Object.assign({}, this._state.documents.documents!.response, {
								items
							})
						})
					})
				});

				this.emitChange();
			} else {
				toast.genericError();
			}
		}

		if (action instanceof actions.DeleteDocumentErrorAction) {
			toast.genericError();
		}

		if (action instanceof actions.AddDocumentModalAction) {
			this._updateAddDocumentModal(action.show);
			this.emitChange();
		}

		if (action instanceof UploadAccountClaimDocumentAction) {
			if (action.tags[TAG] && action.state !== AsyncState.Pending) {
				let showMessage: () => void;
				this._updateAddDocumentModal(false);

				if (getLoadState(action) === AsyncState.Resolved) {
					this._state = Object.assign({}, this._state, {
						documents: Object.assign({}, this._state.documents, {
							documents: Object.assign({}, this._state.documents.documents, {
								response: Object.assign({}, this._state.documents.documents!.response, {
									items: [...this._state.documents.documents!.response!.items, ...action.response!.items]
								})
							})
						})
					});

					showMessage = () => toast.success("Congratulations! The document has been successfully added to the application.");
				} else {
					showMessage = () => toast.genericError();
				}

				this.emitChange();
				showMessage();
			}
		}

		Dispatcher.waitFor([AccountsStore.token]);
		if (AccountsStore.wasChangeEmitted()) {
			this._selectionChangeCheck();
		} else {
			const accountSource = this._getAccountSource();
			if (accountSource === AccountSource.Search) {
				Dispatcher.waitFor([SearchAccountsStore.token]);
				if (SearchAccountsStore.wasChangeEmitted()) {
					this._selectionChangeCheck(accountSource);
				}
			}
		}
	}

	private _getAccountSource() {
		if (Dispatcher.isDispatching()) {
			Dispatcher.waitFor([AccountsStore.token]);
		}

		const displayState = AccountsStore.getCurrentDisplayState();
		switch (displayState) {
			case AccountsActionEnum.Normal:
			case AccountsActionEnum.Affiliates:
				return AccountSource.Workflows;
			case AccountsActionEnum.AdvancedSearch:
			case AccountsActionEnum.SimpleSearch:
				return AccountSource.Search;
			case AccountsActionEnum.AddAccount:
			case AccountsActionEnum.UploadFile:
				return AccountSource.None;
			default:
				if (__DEV__) {
					console.error(`[${__filename}] Unsupported display state (${displayState})`);
				}
				return AccountSource.None;
		}
	}

	private _selectionChangeCheck(accountSource: AccountSource = this._getAccountSource()) {
		let selectedAccounts: (IAccount | IAccountImport)[] = [];
		switch (accountSource) {
			case AccountSource.Workflows:
				if (Dispatcher.isDispatching()) {
					Dispatcher.waitFor([AccountsStore.token]);
				}

				selectedAccounts = AccountsStore.getSelectedAccounts();
				break;
			case AccountSource.Search:
				if (Dispatcher.isDispatching()) {
					Dispatcher.waitFor([SearchAccountsStore.token]);
				}

				selectedAccounts = SearchAccountsStore.getSelectedAccounts();
				break;
			case AccountSource.None:
				break;
			default:
				if (__DEV__) {
					console.error(`[${__filename}] Unsupported AccountSource (${accountSource})`);
				}
				break;
		}

		const account: IAccount | IAccountImport | null = selectedAccounts.length === 1 ? Object.assign({}, selectedAccounts[0]) : null;
		const activeTab = account == null ? 0 : this._state.activeTab;
		let { details, estate, claims, documents, disputes, fees, history } = this._state;

		if (!isSameAccount(account, this._state.account)) {
			estate = this._tryAbortXhr(estate, "probateCase");
			estate = this._tryAbortXhr(estate, "banko");

			claims = this._tryAbortXhr(claims, "request");
			claims = this._tryAbortXhr(claims, "accountClaims");
			claims = Object.assign({}, claims, {
				edits: {},
				agreeToRelease: {
					amount: null,
					dueDate: null,
					parties: {}
				}
			});

			documents = this._tryAbortXhr(documents, "documents");

			disputes = this._tryAbortXhr(disputes, "request");

			fees = this._tryAbortXhr(fees, "request");

			history = this._tryAbortXhr(history, "request");
		}

		this._state = Object.assign({}, this._state, {
			activeTab,
			account,
			details,
			estate,
			claims,
			documents,
			disputes,
			fees,
			history
		});

		this._updateAccountEdit();

		if (activeTab !== 3) {
			this._updateAddDocumentModal(false);
		}

		this.emitChange();
	}

	private _handleApiRequest<TParams, TResponse>(
		getParams: () => TParams,
		getXhr: (params: TParams) => IFakeJQueryXhr<TResponse>,
		getRequestFromState: (state: IAccountDetailsState) => IApiRequest<TParams, TResponse>,
		getUpdatedState: (oldState: IAccountDetailsState, newRequest: IApiRequest<TParams, TResponse>) => IAccountDetailsState,
		callback?: (request: IApiRequest<TParams, TResponse>) => void
	) {
		const oldRequest = getRequestFromState(this._state);
		if (oldRequest != null) {
			oldRequest.xhr.abort();
		}

		const params = getParams();
		const xhr = getXhr(params);

		let request: IApiRequest<TParams, TResponse> = {
			params,
			xhr,
			response: null,
			error: null
		};

		const updateAndEmitIfApplicable = (newRequest: IApiRequest<TParams, TResponse>) => {
			if (getRequestFromState(this._state) === request) {
				this._state = getUpdatedState(this._state, newRequest);

				if (callback) {
					callback(newRequest);
				}

				this.emitChange();
			}
		};

		xhr.then(response => {
			updateAndEmitIfApplicable(Object.assign({}, request, { response }));
		}, (error) => {
			if (error instanceof ApiError && error.type === "abort") {
				return;
			}

			updateAndEmitIfApplicable(Object.assign({}, request, {
				error
			}));
		});

		this._state = getUpdatedState(this._state, request);
		this.emitChange();
	}

	private _tryAbortXhr(section: any, requestKey: string = "request") {
		const request: IApiRequest<any, any> | null = section[requestKey] as any;
		if (request != null) {
			request.xhr.abort();
			return Object.assign({}, section, { [requestKey]: null });
		}

		return section;
	}

	private _updateAccountEdit() {
		this._state = Object.assign({}, this._state, {
			details: Object.assign({}, this._state.details, {
				edit: cloneDeep(this._state.account)
			})
		});
	}

	private _updateAddDocumentModal(show: boolean) {
		this._state = Object.assign({}, this._state, {
			documents: Object.assign({}, this._state.documents, {
				showAddDocumentModal: show
			})
		});
	}

	private _updateClaimEdits() {
		const claims = this._state.claims.request && this._state.claims.request.response && this._state.claims.request.response.claims;
		this._state = Object.assign({}, this._state, {
			claims: Object.assign({}, this._state.claims, {
				edits: this._getClaimEdits(claims || [])
			})
		});

		this._updateAgreeToReleaseParties();
	}

	private _updateDisputeEdit() {
		const dispute = this._state.disputes.request && this._state.disputes.request.response && this._state.disputes.request.response.item;
		this._state = Object.assign({}, this._state, {
			disputes: Object.assign({}, this._state.disputes, {
				edit: cloneDeep(dispute)
			})
		});
	}

	private _getProbateCaseParams(account: Dto.IAccount | Dto.IAccountImport): IGetProbateCaseActionParams {
		return {
			caseID: account.estateCaseId,
			acctID: account.acctId,
			claimID: -1
		};
	}

	private _getClaimDisputeParams(account: Dto.IAccount): IGetClaimDisputeFromClaimIDActionParams {
		return {
			accountID: account.acctId,
			claimID: account.printedClaimId
		};
	}

	private _getClaimEdits(claims: Dto.IClaim[]) {
		const edits: { [claimId: number]: IClaimEdit } = {};
		claims.forEach(it => edits[it.claimId] = this._getClaimEdit(it));

		return edits;
	}

	private _getClaimEdit(claim: Dto.IClaim): IClaimEdit {
		return {
			claimId: claim.claimId,
			notes: claim.notes,
			courtReceipt: this._getClaimEditReceipt(claim.courtCoverLetterReceiptConfirmedDate),
			attyReceipt: this._getClaimEditReceipt(claim.attyCoverLetterReceiptConfirmedDate),
			repReceipt: this._getClaimEditReceipt(claim.repCoverLetterReceiptConfirmedDate),
			agreeToRelease: {
				amount: null,
				dueDate: null,
				attorney: {
					selected: false,
					person: null
				},
				rep: {
					selected: false,
					person: null
				}
			}
		};
	}

	private _updateAgreeToReleaseParties() {
		const request = this._state.claims.accountClaims;
		if (!isPending(request!) && !isError(request!)) {
			const accountClaims = request!.response!.items[0];
			const { claimId } = accountClaims;
			const edit = this._state.claims.edits[claimId];

			this._state = Object.assign({}, this._state, {
				claims: Object.assign({}, this._state.claims, {
					edits: Object.assign({}, this._state.claims.edits, {
						[claimId]: Object.assign({}, edit, {
							agreeToRelease: Object.assign({}, edit.agreeToRelease, {
								attorney: Object.assign({}, edit.agreeToRelease.attorney, {
									person: accountClaims.atty
								}),
								rep: Object.assign({}, edit.agreeToRelease.rep, {
									person: accountClaims.personalRep
								})
							})
						})
					})
				})
			});
		}
	}

	private _getClaimEditReceipt(confirmedDate: string): IClaimEditReceipt {
		const confirmed = !isEmptyDate(confirmedDate);
		return {
			confirmed,
			date: confirmed ? moment(confirmedDate) : null
		};
	}

	private _areClaimEditReceiptsEqual(a: IClaimEditReceipt, b: IClaimEditReceipt) {
		if (a === b) {
			return true;
		}

		if (a.confirmed !== b.confirmed) {
			return false;
		}

		return !a.confirmed // not confirmed - we don't care about the dates
			|| (a.date || null) === (b.date || null) // both null (or undefined)
			|| (a.date != null && a.date.isSame(b.date as any)); // same date
	}
}

export default new AccountDetailsStore();
