type Key = string | number;

export default function withoutProperties<T>(source: T, propNames: Key[]): T;
export default function withoutProperties<T>(source: T, ...propNames: Key[]): T;
export default function withoutProperties<T>(source: T, propNameOrNames: Key | Key[], ...otherPropNames: Key[]): T {
	const propNames = Array.isArray(propNameOrNames)
		? propNameOrNames
		: [propNameOrNames, ...otherPropNames];

	const value: T = Object.assign({}, source);
	for (const propName of propNames) {
		delete value[propName as keyof T];
	}

	return value;
}