import { MODIFIED_BY_ANOTHER_USER, OK } from "../../../../Models/apiConstants";
//import * as Dto from "../../Models/dto";
import IAction from "../../../../Actions/IAction";
import {
	SelectAffiliateAction,
	SelectTabAction,
	EditAffiliateAction,
	AddAffiliateAction,
	SelectAffiliateManagerAction,
	EditAffiliateManagerAction
} from "../../../../Actions/Settings/AffiliatesActions";
import {
	GetClientAction,
	SaveAffiliateAction,
	DeleteAffiliateAction,
	SaveNewAffiliateAction,
	UseParentAffiliateModelAction
} from "../../../../Actions/Api/clientActions";
import * as Dto from "../../../../Models/dto";
import { AsyncState } from "../../../../Models/IAsync";
import { NULL_DATE_STRING } from "../../../../Utils/dateUtils";
import { createNewUser, makeNewUserReducer } from "./newUserUtils";
import reduceReducers from "reduce-reducers";

export enum Tab {
	AffiliateProfile,
	ClaimAddress,
	LowBalanceRules,
	SolRules,
	NoticeRules,
	BankruptcyRules,
	Users,
	AffiliateManager,
	ClaimPackageReview
}

export interface IState {
	selectedClientId: number | null;
	currentTab: Tab;
	edit: Dto.IClient | null;
	newClient: Dto.IClient | null;
	newClientAffiliateManagerId: number | null;
	newClientAffiliateManger: Dto.IClientProfilePartyNormalized | null;
}

const nullNewClient = {
	newClient: null as Dto.IClient | null,
	newClientAffiliateManagerId: null as number | null,
	newClientAffiliateManger: null as Dto.IClientProfilePartyNormalized | null
};

function createNewClient(clientProfile: Dto.IClientProfile, name = "", onDemandId = ""): Dto.IClient {
	return {
		activeFlag: true,
		address1: "",
		address2: "",
		agencyFlag: clientProfile.agencyFlag,
		city: "",
		clientId: -1,
		clientProfileBusinessTypeId: clientProfile.clientProfileBusinessTypeId,
		clientProfileBusinessTypeName: clientProfile.clientProfileBusinessTypeName,
		clientProfileBusinessTypeOther: clientProfile.clientProfileBusinessTypeOther,
		clientProfileIndustryTypeId: clientProfile.clientProfileIndustryTypeId,
		clientProfileIndustryTypeName: clientProfile.clientProfileIndustryTypeName,
		clientProfileIndustryTypeOther: clientProfile.clientProfileIndustryTypeOther,
		coLicenseContact: clientProfile.coLicenseContact,
		coLicenseExempt: clientProfile.coLicenseExempt,
		coLicenseFlag: clientProfile.coLicenseFlag,
		creationTimestamp: NULL_DATE_STRING,
		ctHospitalBedFundFlag: clientProfile.ctHospitalBedFundFlag ? null as any : false,
		customBacker: null,
		customBackerHeader: null,
		customBackerState: null,
		dirName: "",
		defaultSecureFlag: null,
		enableBanko: clientProfile.enableBanko,
		hospitalFlag: clientProfile.hospitalFlag,
		interestFlag: null,
		isDataComplete: false,
		lowBalanceAmount: clientProfile.lowBalanceAmount,
		medicalFlag: clientProfile.medicalFlag,
		mnHospitalFlag: clientProfile.mnHospitalFlag,
		mnHospitalPrimaryContact: clientProfile.mnHospitalPrimaryContact,
		mnLicense: clientProfile.mnLicense,
		mnLicenseExempt: clientProfile.mnLicenseExempt,
		mnLicenseFlag: clientProfile.mnLicenseFlag,
		name,
		ncLicense: clientProfile.ncLicense,
		ncLicenseExempt: clientProfile.ncLicenseExempt,
		ncLicenseFlag: clientProfile.ncLicenseFlag,
		numberOfAccounts: 0,
		nycLicense: clientProfile.nycLicense,
		nycLicenseContact: clientProfile.nycLicenseContact,
		nycLicenseExempt: clientProfile.nycLicenseExempt,
		nycLicenseFlag: clientProfile.nycLicenseFlag,
		onDemandId,
		phone: "",
		phoneExt: "",
		solTypeId: clientProfile.solTypeId,
		solTypeName: clientProfile.solTypeName,
		state: "",
		tnLicenseExempt: clientProfile.tnLicenseExempt,
		tnLicenseFlag: clientProfile.tnLicenseFlag,
		wiLicenseExempt: clientProfile.wiLicenseExempt,
		wiLicenseFlag: clientProfile.wiLicenseFlag,
		writeTimestamp: NULL_DATE_STRING,
		zip: "",
		lockSecureFlag: null,
		coNoticeOverride: clientProfile.coNoticeOverride,
		maNoticeOverride: clientProfile.maNoticeOverride,
		mnHospitalNoticeOverride: clientProfile.mnHospitalNoticeOverride,
		mnNoticeOverride: clientProfile.mnNoticeOverride,
		ncNoticeOverride: clientProfile.ncNoticeOverride,
		nyNoticeOverride: clientProfile.nyNoticeOverride,
		nycNoticeOverride: clientProfile.nycNoticeOverride,
		tnNoticeOverride: clientProfile.tnNoticeOverride,
		wiNoticeOverride: clientProfile.wiNoticeOverride,
		caNoticeOverride: clientProfile.caNoticeOverride,
		nvNoticeOverride: clientProfile.nvNoticeOverride,
		caLicenseExempt: clientProfile.caLicenseExempt,
		caLicenseFlag: clientProfile.caLicenseFlag,
		caLicense: clientProfile.caLicense,
		maLicenseExempt: clientProfile.maLicenseExempt,
		maLicenseFlag: clientProfile.maLicenseFlag,
		nvLicenseExempt: clientProfile.nvLicenseExempt,
		nvLicenseFlag: clientProfile.nvLicenseFlag,
		nyLicenseExempt: clientProfile.nyLicenseExempt,
		nyLicenseFlag: clientProfile.nyLicenseFlag,
		waLicense: clientProfile.waLicense,
		waLicenseExempt: clientProfile.waLicenseExempt,
		waLicenseFlag: clientProfile.waLicenseFlag,
		waNoticeOverride: clientProfile.waNoticeOverride,
		waMedicalFlag: clientProfile.waMedicalFlag ? null : false,
		waMedicalNotice: null as any,
		claimBasis: clientProfile.claimBasis,
		userCanChangeClaimBasis: clientProfile.userCanChangeClaimBasis
	};
}

function affiliatesReducer(
	previousState: IState = Object.assign({
		selectedClientId: null,
		currentTab: Tab.AffiliateProfile,
		edit: null
	}, nullNewClient),
	action: IAction
): IState {
	if (action instanceof SelectAffiliateAction) {
		const selectedClientId = action.client == null ? null : action.client.min.clientId;
		if (selectedClientId !== previousState.selectedClientId) {
			const currentTab = selectedClientId == null ? Tab.AffiliateProfile : previousState.currentTab;

			const edit = action.client != null && action.client.loading === AsyncState.Resolved
				? action.client.full // client already loaded 
				: null;

			return Object.assign({}, previousState, {
				selectedClientId,
				currentTab,
				edit
			});
		}
	}

	if (action instanceof SelectTabAction) {
		if (previousState.selectedClientId != null) {
			return Object.assign({}, previousState, {
				currentTab: action.tab
			});
		}
	}

	if (action instanceof GetClientAction) {
		if (
			action.state === AsyncState.Resolved
			&& action.response!.status === OK
			&& action.params.clientID === previousState.selectedClientId
		) {
			// selected client finished loading, assign to edit
			return Object.assign({}, previousState, {
				edit: action.response!.item
			});
		}
	}

	if (action instanceof EditAffiliateAction) {
		let { edit } = action;
		if (edit.clientId === -1) {
			if (__DEV__ && previousState.newClient == null) {
				console.error(`[${__filename}] Unexpected new affiliate edit`);
			}

			return Object.assign({}, previousState, {
				newClient: edit
			});
		} else {
			if (__DEV__ && (previousState.edit == null || previousState.edit.clientId !== edit.clientId)) {
				console.error(`[${__filename}] Unexpected edit`);
			}

			const { client } = action;
			if (
				edit.clientProfileIndustryTypeId === client!.clientProfileIndustryTypeId
				&& edit.clientProfileIndustryTypeId !== previousState.edit!.clientProfileIndustryTypeId
				&& edit.clientProfileBusinessTypeId == null
			) {
				// changing back to the original industry type, so reselect the original business type also
				edit = Object.assign({}, edit, {
					clientProfileBusinessTypeId: client!.clientProfileBusinessTypeId,
					clientProfileBusinessTypeName: client!.clientProfileBusinessTypeName
				});
			}

			return Object.assign({}, previousState, { edit });
		}
	}

	if (action instanceof AddAffiliateAction) {
		if (action.adding === (previousState.newClient == null)) {
			let { newClient, newClientAffiliateManagerId, newClientAffiliateManger } = nullNewClient;
			if (action.adding) {
				newClient = createNewClient(action.clientProfile, action.name, action.onDemandId);
				newClientAffiliateManagerId = action.currentUserId;
				newClientAffiliateManger = createNewUser(action.clientProfile);
			}

			return Object.assign({}, previousState, {
				newClient,
				newClientAffiliateManagerId,
				newClientAffiliateManger
			});
		}
	}

	if (action instanceof SaveAffiliateAction) {
		if (
			action.state === AsyncState.Resolved
			&& (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)
			&& action.params.affiliate.clientId === previousState.selectedClientId
		) {
			return Object.assign({}, previousState, {
				edit: action.response!.item
			});
		}
	}

	if (action instanceof SaveNewAffiliateAction) {
		if (action.state === AsyncState.Resolved && action.response!.status === OK) {
			const { affiliate } = action.response!;
			return Object.assign({
				selectedClientId: affiliate.clientId,
				currentTab: Tab.AffiliateProfile,
				edit: affiliate
			}, nullNewClient);
		}
	}

	if (
		action instanceof UseParentAffiliateModelAction
		&& action.state === AsyncState.Resolved
		&& (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)
		&& action.response!.client.clientId === previousState.selectedClientId
	) {
		return Object.assign({}, previousState, {
			edit: action.response!.client
		});
	}

	if (action instanceof DeleteAffiliateAction) {
		if (action.state === AsyncState.Resolved && action.response!.status === OK && action.params.clientID === previousState.selectedClientId) {
			return Object.assign({}, previousState, {
				selectedClientId: null,
				edit: null
			});
		}
	}

	if (action instanceof SelectAffiliateManagerAction) {
		return Object.assign({}, previousState, {
			newClientAffiliateManagerId: action.affiliateManagerId
		});
	}

	if (action instanceof EditAffiliateManagerAction) {
		if (__DEV__ && (previousState.newClient == null || previousState.newClientAffiliateManagerId !== -1)) {
			console.error(`[${__filename}] Unexpected affiliate manager update`);
		}

		return Object.assign({}, previousState, {
			newClientAffiliateManger: action.affiliateManager
		});
	}

	return previousState;
}

const newUserReducer = makeNewUserReducer<IState>(
	state => state.newClientAffiliateManger,
	(state, newClientAffiliateManger) => Object.assign({}, state, { newClientAffiliateManger })
);

export default reduceReducers(
	affiliatesReducer,
	newUserReducer
);