import { IRecentAccount, IAccount, IAddRecentAccount } from "../Models/dto";
import createGlobalReducer from "./createGlobalReducer";
import { getRecentAccounts, trackRecentAccount as trackRecentAccountApi } from "../ActionCreators/Api/accountActionCreators";
import { useEffect } from "react";
import handleApiResponse from "./handleApiResponse";

interface IState {
	accounts: IRecentAccount[] | null;
	pending: IAddRecentAccount[];
	loading: boolean;
}

interface ILoadStartAction {
	type: typeof LOAD_START;
}

interface ILoadSuccessAction {
	type: typeof LOAD_SUCCESS;
	payload: {
		accounts: IRecentAccount[];
	};
}

interface ILoadErrorAction {
	type: typeof LOAD_ERROR;
}

interface ITrackAction {
	type: typeof TRACK;
	payload: {
		account: IAccount,
		timestamp: Date;
	};
}

type RecentAccountAction = ILoadStartAction | ILoadSuccessAction | ILoadErrorAction | ITrackAction;

const LOAD_START = 0 as const;
const LOAD_SUCCESS = 1 as const;
const LOAD_ERROR = 2 as const;
const TRACK = 3 as const;

const INITIAL_STATE: IState = {
	accounts: null,
	pending: [],
	loading: false
};

function recentAccountsReducer(previousState: IState, action: RecentAccountAction): IState {
	switch (action.type) {
		case LOAD_START:
			return {
				...previousState,
				pending: INITIAL_STATE.pending,
				loading: true
			};
		case LOAD_SUCCESS:
			return {
				...previousState,
				accounts: action.payload.accounts,
				loading: false
			};
		case LOAD_ERROR:
			return {
				...previousState,
				accounts: previousState.accounts || [],
				loading: false
			};
		case TRACK:
			const { account, timestamp } = action.payload;

			let { accounts } = previousState;
			if (accounts == null || !accounts.some(it => it.acctId === account.acctId)) {
				// optimistally update the recent account list
				accounts = [
					{
						acctId: account.acctId,
						accountNumber: account.primaryAccountNumber,
						firstName: account.firstName,
						lastName: account.lastName
					},
					...(accounts || [])
				].slice(0, 10);
			}

			return {
				...previousState,
				accounts,
				pending: [
					...previousState.pending,
					{
						acctId: account.acctId,
						timestampUtc: timestamp.toISOString()
					}
				]
			};
		default:
			return previousState;
	}
}

const useRecentAccountReducer = createGlobalReducer(recentAccountsReducer, INITIAL_STATE);

export function trackRecentAccount(account: IAccount) {
	useRecentAccountReducer.dispatch({
		type: TRACK,
		payload: {
			account,
			timestamp: new Date()
		}
	});
}

export default function useRecentAccounts() {
	const [state, dispatch] = useRecentAccountReducer();

	const update = async () => {
		if (state.loading) {
			return;
		}

		const { pending } = state;
		if (state.accounts != null && pending.length === 0) {
			return;
		}

		dispatch({ type: LOAD_START });

		try {
			const response = await (pending.length > 0 ? trackRecentAccountApi(pending) : getRecentAccounts());
			handleApiResponse(response, () => dispatch({
				type: LOAD_SUCCESS,
				payload: {
					accounts: response.items
				}
			}));
		} catch (error) {
			console.error(error);
			dispatch({ type: LOAD_ERROR });
		}
	};

	useEffect(() => {
		update();
	});

	return {
		accounts: state.accounts || [],
		track: trackRecentAccount
	};
}