import { ERROR, OK } from "../../Models/apiConstants";
import handleApiResponse from "../../Utils/handleApiResponse";
import connect from "../../Utils/connect";
import BusyOverlay from "../Common/BusyOverlay/BusyOverlay";
import { validateSecurityAnswer } from "../../ActionCreators/Api/loginActionCreators";
import connectToCaptcha, { ICaptchaProps } from "../../Utils/connectToCaptcha";
import React from "react";
import ReduxStore from "../../Stores/ReduxStore";
import LoginActionCreators, {
	showInvalidCaptchaMessage
} from "../../ActionCreators/LoginActionCreators";
import * as toast from "../../Utils/toast";
import Captcha from "../Captcha/Captcha";
import cn from "classnames";
import noop from "lodash/noop";
import AuthPage, { FormGroup } from "../Auth/AuthPage/AuthPage";
import AuthButton from "../Auth/AuthButton/AuthButton";
import AuthHeader from "../Auth/AuthHeader/AuthHeader";

interface IOwnProps { }

interface IConnectedProps {
	question: string;
	answer: string;
	validating: boolean;
	onChange(answer: string): void;
	onValidate(): void;
}

interface IProps extends IOwnProps, ICaptchaProps, IConnectedProps { }

const CONTINUE = "Continue";

class GetSecurityAnswer extends React.PureComponent<IProps> {
	constructor(props: IProps, context: any) {
		super(props, context);

		this._handleChange = this._handleChange.bind(this);
		this._handleSubmit = this._handleSubmit.bind(this);
	}

	render(): React.ReactElement<{}> {
		const { props } = this;

		const answerValid = props.answer != null && props.answer.length > 0;

		return (
			<AuthPage onSubmit={this._handleSubmit}>
				<BusyOverlay message={props.validating ? "Validating..." : null} />
				<AuthHeader>Security Question</AuthHeader>
				<FormGroup>
					Answer the security question, verify the image text, and click {CONTINUE} below.
				</FormGroup>
				<FormGroup label="Question">
					{props.question}
				</FormGroup>
				<FormGroup label="Answer">
					{id => (
						<input
							id={id}
							type="password"
							className={cn("form-control", { errorInput: !answerValid })}
							maxLength={50}
							value={props.answer || ""}
							onChange={this._handleChange}
							autoComplete="current-security-answer"
						/>
					)}
				</FormGroup>
				<FormGroup>
					<Captcha />
				</FormGroup>
				<FormGroup>
					<AuthButton disabled={!answerValid || !props.captchaValid || props.validating}>
						{CONTINUE}
					</AuthButton>
				</FormGroup>
			</AuthPage>
		);
	}

	private _handleChange(event: React.FormEvent<HTMLElement>) {
		const { value } = event.currentTarget as HTMLInputElement;
		this.props.onChange(value);
	}

	private _handleSubmit(event: React.FormEvent<HTMLElement>) {
		event.preventDefault();
		this.props.onValidate();
	}
}

const ConnectedToCaptcha = connectToCaptcha<IOwnProps & IConnectedProps>(GetSecurityAnswer);

const onChange = (answer: string) => {
	const { auth: { loginUser } } = ReduxStore.getState();
	LoginActionCreators.loginUserUpdated(Object.assign({}, loginUser, {
		securityAnswer: answer
	}));
};

const onValidate = async () => {
	const { auth: { loginUser }, ui: { captcha: { challenge, response } } } = ReduxStore.getState();
	try {
		const data = await validateSecurityAnswer(loginUser, response, challenge!);
		handleApiResponse(data, {
			[OK]: noop,
			[ERROR]: {
				"LOGIN_INCORRECT_CAPTCHA": showInvalidCaptchaMessage,
				"*": () => toast.error("The answer provided does not match the answer on file.")
			}
		});
	} catch (error) {
		if (__DEV__) {
			console.error(error);
		}
		toast.genericError();
	}
};

export default connect<IConnectedProps, IOwnProps>(
	ConnectedToCaptcha,
	state => ({
		question: state.auth.loginUser.securityQuestion,
		answer: state.auth.loginUser.securityAnswer,
		validating: state.auth.validatingSecurityAnswer,
		onChange,
		onValidate
	})
);