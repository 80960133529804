import AsyncState from "../../Models/AsyncState";
import { isCurrentUserAppAdminAsync } from "../../selectors/isCurrentUserAppAdmin";
import ReduxStore from "../../Stores/ReduxStore";
import createModuleLoader from "../../Utils/createModuleLoader";
import _ProfileSwitcher from "./ProfileSwitcher/ProfileSwitcher";

export interface IAdmin {
	ProfileSwitcher: typeof _ProfileSwitcher | null;
}

const { loader, load } = createModuleLoader<IAdmin>();

async function check() {
	const { state, payload: appAdmin } = isCurrentUserAppAdminAsync(ReduxStore.getState());
	if (state != null && state !== AsyncState.Pending) {
		let ProfileSwitcher: typeof _ProfileSwitcher | null = null;
		if (appAdmin) {
			const module = await import("./ProfileSwitcher/ProfileSwitcher" /* webpackChunkName: "admin" */);
			ProfileSwitcher = module.default;
		}

		load({ ProfileSwitcher });

		ReduxStore.removeListener(check);
	}
}

ReduxStore.addListener(check);
check();

export default loader;
