import { useEffect, useReducer, useCallback, useState } from "react";

type Callback = () => void;

export default function createGlobalReducer<TState, TAction>(reducer: React.Reducer<TState, TAction>, initialState: TState) {
	let globalState = initialState;

	let subscriptions: Callback[] = [];
	const subscribe = (callback: Callback) => {
		subscriptions.push(callback);
		return () => {
			subscriptions = subscriptions.filter(it => it !== callback);
		};
	};

	const globalDispatch = (action: TAction) => {
		globalState = reducer(globalState, action);
		for (const subscription of subscriptions) {
			subscription();
		}
	};

	function useGlobalReducer(): [TState, React.Dispatch<TAction>] {
		const [localState, localDispatch] = useReducer(
			(_prevState: TState, newState: TState) => newState,
			globalState
		);

		useEffect(() => {
			return subscribe(() => localDispatch(globalState));
		}, []);

		return [
			localState,
			globalDispatch
		];
	}

	useGlobalReducer.getState = () => globalState;
	useGlobalReducer.dispatch = globalDispatch;

	return useGlobalReducer;
}

export function createGlobalState<T>(initialValue: T) {
	let globalValue = initialValue;

	let subscriptions: Callback[] = [];
	const subscribe = (callback: Callback) => {
		subscriptions.push(callback);
		return () => {
			subscriptions = subscriptions.filter(it => it !== callback);
		};
	};

	return function useGlobalState(): [T, React.Dispatch<React.SetStateAction<T>>] {
		const [localValue, setLocalValue] = useState(globalValue);

		useEffect(() => {
			return subscribe(() => setLocalValue(globalValue));
		}, []);

		return [
			localValue,
			useCallback((newValue: React.SetStateAction<T>) => {
				globalValue = typeof newValue === "function"
					? (newValue as (prevState: T) => T)(globalValue)
					: newValue;

				for (const subscription of subscriptions) {
					subscription();
				}
			}, [])
		];
	};
}