import { ITime } from "../models";

const R_TIME = /^\s*([01]?\d|2[0-3]):?(([0-5]\d))?\s*(am?|pm?)?\s*$/i;

export default function tryParseTime(value: string, preferPm = false): ITime | null {
	if (value == null) {
		return null;
	}

	const match = value.match(R_TIME);
	if (match == null) {
		return null;
	}

	let hour = parseInt(match[1], 10);
	const minute = match[3] == null ? 0 : parseInt(match[3], 10);
	let amPm = match[4];

	if (amPm == null && preferPm) {
		amPm = "p";
	}

	if (amPm != null) {
		switch (amPm[0].toLowerCase()) {
			case "a":
				if (hour === 12) {
					hour = 0;
				} else if (hour > 12) {
					return null;
				}
				break;
			case "p":
				if (hour < 12) {
					hour += 12;
				}
				break;
			default:
				if (__DEV__) {
					console.error(`[${__filename}] Unexpected value: ${JSON.stringify(amPm)}`);
				}
				break;
		}
	}

	return {
		hour,
		minute
	};
}