import * as React from "react";
import { appName } from "../../../../../../Utils/branding";

export const AgencyFlag = () => (
	<div>
		<p>
			This question applies to the specific collection notice requirements which individual states place upon 3rd-party debt
			collectors and debt buyers.
		</p>
		<p>
			This information is not intended as legal advice and does not establish an attorney-client relationship. If you have any
			questions, you are encouraged to seek the advice of a trusted legal professional.
		</p>
	</div>
);

export const MedicalFlag = ({ agencyFlag }: { agencyFlag: boolean; }) => (
	<div>
		<p>
			This question applies to the specific collection notice requirements which individual states place upon healthcare
			providers{agencyFlag ? " and the entities which collect debt on their behalf" : null}.
		</p>
		<p>
			This information is not intended as legal advice and does not establish an attorney-client relationship. If you have
			any questions, you are encouraged to seek the advice of a trusted legal professional.
		</p>
	</div>
);

export const HospitalFlag = () => (
	<div>
		<p>
			This question applies to specific collection notice requirements which individual states place upon hospitals,
			hospital systems and the entities which collect debt on their behalf.
		</p>

		<p>
			States placing specific collection notice requirements upon hospitals, hospital systems and the entities which
			collect debt upon their behalf have been defined. A hospital or hospital system is as follows:
		</p>

		<p>
			An establishment of the lodging, diagnosis, care and treatment of persons suffering from illness or other abnormal
			physical or mental conditions and includes inpatient psychiatric services of general hospitals. This includes care
			available 24 hours a day from persons licensed to practice professional nursing who are under the direction of a
			physician, services of a medical laboratory and medical, radiological dietary and pharmaceutical services.
		</p>

		<p>
			This information is not intended as legal advice and does not establish an attorney-client relationship. If you have
			any questions, you are encouraged to seek the advice of a trusted legal professional.
		</p>
	</div>
);

export const ClientFlag = () => (
	<div>
		<p>
			This is a determination that your company would make. {appName} cannot provide legal advice. If you have
			any questions, you are encouraged to seek the advice of a trusted legal professional.
		</p>
	</div>
);

export const UserCanChangeClaimBasis = () => (
	<div>
		<p>
			Yes: Permits users to change claim basis on individual accounts through the Claim Approval validation process.
		</p>
		<p>
			No: Locks in the selected default for all claims filed and does not allow users to change basis on an account level.
		</p>
	</div>
);

export const ClaimBasis = () => (
	<div>
		<p>
			The basis of claim statement selected will be set as your default statement that populates on the claim detail page of claims filed.
			The claim detail page is the last page of your claim package.
			If the basis of your inventory will vary, selecting “yes” to the following question will permit individual claim basis selection.
			Account numbers in the claim basis will be redacted.
		</p>
	</div>
);