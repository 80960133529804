import { IState } from "../Reducers/rootReducer";
import getCurrentTab from "./getCurrentTab";
import { createSelector } from "reselect";

export default createSelector(
	getCurrentTab,
	(state: IState) => state.ui.dashboard.tabs.active,
	(state: IState) => state.ui.dashboard.tabs.loaded,
	(currentTab, requestedTab, loaded) => {
		return (loaded[currentTab] && (requestedTab == null || loaded[requestedTab])) || false;
	}
);