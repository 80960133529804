import classNames from "classnames";
import React, { useMemo } from "react";
import styles from "./Select.module.scss";

export interface IOption {
	value: string;
	label?: string;
	disabled?: boolean;
}

interface IProps extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "value" | "onChange" | "className"> {
	value: string;
	options: (IOption | string)[];
	onChange(value: string, event: React.ChangeEvent<HTMLSelectElement>): void;
	className?: ClassValue;
}

export default function Select(props: IProps) {
	const {
		value,
		options,
		onChange,
		className,
		...otherProps
	} = props;

	const normalizedOptions = useMemo(() => options.map(option => {
		if (typeof option === "string") {
			return {
				value: option,
				label: option
			};
		}

		return {
			label: option.value,
			...option
		};
	}), [options]);

	return (
		<div className={classNames(styles.container, { [styles.disabled]: props.disabled }, className)}>
			<select
				{...otherProps}
				className={styles.select}
				value={value}
				onChange={e => onChange(e.currentTarget.value, e)}
			>
				{normalizedOptions.map(({ value, label, disabled }, index) => (
					<option
						key={index}
						value={value}
						disabled={disabled}
						className={classNames({ [styles.disabledOption]: disabled })}
					>
						{label}
					</option>
				))}
			</select>
			<div className={styles.decoration} />
		</div>
	);
}