/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	AddAccountImportsAction,
	IAddAccountImportsActionParams,
	DeleteAccountImportAction,
	IDeleteAccountImportActionParams,
	DeleteAllAccountImportsAction,
	IDeleteAllAccountImportsActionParams,
	GetAccountImportsByAccountStatusAndWorkflowStatusIDAction,
	IGetAccountImportsByAccountStatusAndWorkflowStatusIDActionParams,
	GetAccountImportsByAccountWorkflowStatusIDAction,
	IGetAccountImportsByAccountWorkflowStatusIDActionParams,
	GetSampleFromFileAction,
	IGetSampleFromFileActionParams,
	GetUploadHistoryAction,
	IGetUploadHistoryActionParams,
	ImportFromFileAction,
	IImportFromFileActionParams,
	PlaceAllAccountImportsAction,
	IPlaceAllAccountImportsActionParams,
	SaveAccountImportAction,
	ISaveAccountImportActionParams,
	SaveAccountImportsAction,
	ISaveAccountImportsActionParams
} from "../../Actions/Api/accountImportActions";
import {
	IAccountImport,
	IAccountImportFile,
	IAccountsResponse,
	IBaseResponse,
	ICollectionResponse,
	IResponse,
	IUploadSample
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const addAccountImportsEndpoint = "Api/AccountImport/AddAccountImports";

export function addAccountImports(
	accountImports: IAccountImport[], 
	__options__?: IOptions<ICollectionResponse<IAccountImport>>
) {
	return apiExecute<IAddAccountImportsActionParams, AddAccountImportsAction, ICollectionResponse<IAccountImport>>(
		addAccountImportsEndpoint, 
		true, 
		__options__, 
		AddAccountImportsAction,
		{ accountImports }
	);
}

export const deleteAccountImportEndpoint = "Api/AccountImport/DeleteAccountImport";

export function deleteAccountImport(
	accountImport: IAccountImport, 
	__options__?: IOptions<IResponse<IAccountImport>>
) {
	return apiExecute<IDeleteAccountImportActionParams, DeleteAccountImportAction, IResponse<IAccountImport>>(
		deleteAccountImportEndpoint, 
		true, 
		__options__, 
		DeleteAccountImportAction,
		{ accountImport }
	);
}

export const deleteAllAccountImportsEndpoint = "Api/AccountImport/DeleteAllAccountImports";

export function deleteAllAccountImports(
	accountWorkflowStatusID: number, 
	expectedCount: number, 
	clientIDsForDelete: number[], 
	statusIds: number[], 
	__options__?: IOptions<IBaseResponse>
) {
	return apiExecute<IDeleteAllAccountImportsActionParams, DeleteAllAccountImportsAction, IBaseResponse>(
		deleteAllAccountImportsEndpoint, 
		true, 
		__options__, 
		DeleteAllAccountImportsAction,
		{
			accountWorkflowStatusID,
			expectedCount,
			clientIDsForDelete,
			statusIds
		}
	);
}

export const getAccountImportsByAccountStatusAndWorkflowStatusIDEndpoint = "Api/AccountImport/GetAccountImportsByAccountStatusAndWorkflowStatusID";

export function getAccountImportsByAccountStatusAndWorkflowStatusID(
	accountWorkflowStatusID: number, 
	searchClientIDs: number[], 
	startIndex: number, 
	endIndex: number, 
	sortField: string, 
	sortDirection: string, 
	accountImportStatusIds: number[] | null, 
	__options__?: IOptions<IAccountsResponse>
) {
	return apiExecute<IGetAccountImportsByAccountStatusAndWorkflowStatusIDActionParams, GetAccountImportsByAccountStatusAndWorkflowStatusIDAction, IAccountsResponse>(
		getAccountImportsByAccountStatusAndWorkflowStatusIDEndpoint, 
		true, 
		__options__, 
		GetAccountImportsByAccountStatusAndWorkflowStatusIDAction,
		{
			accountWorkflowStatusID,
			searchClientIDs,
			startIndex,
			endIndex,
			sortField,
			sortDirection,
			accountImportStatusIds
		}
	);
}

export const getAccountImportsByAccountWorkflowStatusIDEndpoint = "Api/AccountImport/GetAccountImportsByAccountWorkflowStatusID";

export function getAccountImportsByAccountWorkflowStatusID(
	accountWorkflowStatusID: number, 
	searchClientIDs: number[], 
	startIndex: number, 
	endIndex: number, 
	sortField: string, 
	sortDirection: string, 
	__options__?: IOptions<IAccountsResponse>
) {
	return apiExecute<IGetAccountImportsByAccountWorkflowStatusIDActionParams, GetAccountImportsByAccountWorkflowStatusIDAction, IAccountsResponse>(
		getAccountImportsByAccountWorkflowStatusIDEndpoint, 
		true, 
		__options__, 
		GetAccountImportsByAccountWorkflowStatusIDAction,
		{
			accountWorkflowStatusID,
			searchClientIDs,
			startIndex,
			endIndex,
			sortField,
			sortDirection
		}
	);
}

export const getSampleFromFileEndpoint = "Api/AccountImport/GetSampleFromFile";

export function getSampleFromFile(
	data: string | Blob, 
	fileName: string, 
	statusID: number, 
	clientID: number, 
	__options__?: IOptions<IResponse<IUploadSample>>
) {
	return apiExecute<IGetSampleFromFileActionParams, GetSampleFromFileAction, IResponse<IUploadSample>>(
		getSampleFromFileEndpoint, 
		true, 
		__options__, 
		GetSampleFromFileAction,
		{
			data,
			fileName,
			statusID,
			clientID
		}
	);
}

export const getUploadHistoryEndpoint = "Api/AccountImport/GetUploadHistory";

export function getUploadHistory(
	__options__?: IOptions<ICollectionResponse<IAccountImportFile>>
) {
	return apiExecute<IGetUploadHistoryActionParams, GetUploadHistoryAction, ICollectionResponse<IAccountImportFile>>(
		getUploadHistoryEndpoint, 
		true, 
		__options__, 
		GetUploadHistoryAction,
		{ /* no parameters */ }
	);
}

export const importFromFileEndpoint = "Api/AccountImport/ImportFromFile";

export function importFromFile(
	refGuid: string, 
	__options__?: IOptions<IResponse<boolean>>
) {
	return apiExecute<IImportFromFileActionParams, ImportFromFileAction, IResponse<boolean>>(
		importFromFileEndpoint, 
		true, 
		__options__, 
		ImportFromFileAction,
		{ refGuid }
	);
}

export const placeAllAccountImportsEndpoint = "Api/AccountImport/PlaceAllAccountImports";

export function placeAllAccountImports(
	expectedCount: number, 
	clientIDsForPlacement: number[], 
	__options__?: IOptions<IBaseResponse>
) {
	return apiExecute<IPlaceAllAccountImportsActionParams, PlaceAllAccountImportsAction, IBaseResponse>(
		placeAllAccountImportsEndpoint, 
		true, 
		__options__, 
		PlaceAllAccountImportsAction,
		{
			expectedCount,
			clientIDsForPlacement
		}
	);
}

export const saveAccountImportEndpoint = "Api/AccountImport/SaveAccountImport";

export function saveAccountImport(
	accountImport: IAccountImport, 
	__options__?: IOptions<IResponse<IAccountImport>>
) {
	return apiExecute<ISaveAccountImportActionParams, SaveAccountImportAction, IResponse<IAccountImport>>(
		saveAccountImportEndpoint, 
		true, 
		__options__, 
		SaveAccountImportAction,
		{ accountImport }
	);
}

export const saveAccountImportsEndpoint = "Api/AccountImport/SaveAccountImports";

export function saveAccountImports(
	accountImports: IAccountImport[], 
	__options__?: IOptions<IBaseResponse>
) {
	return apiExecute<ISaveAccountImportsActionParams, SaveAccountImportsAction, IBaseResponse>(
		saveAccountImportsEndpoint, 
		true, 
		__options__, 
		SaveAccountImportsAction,
		{ accountImports }
	);
}

/* tslint:enable:max-line-length */