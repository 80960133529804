import * as React from "react";
import { Alert, AlertProps } from "react-bootstrap";
import { ReactComponent as Icon } from "../../../../../Images/tooltip.svg"
import classNames from "classnames";
import styles from "./InfoAlert.module.scss";

interface IProps extends Omit<AlertProps, "className" | "bsStyle"> {
	className?: ClassValue;
}

const InfoAlert = ({ children, className, ...props }: IProps) => (
	<Alert
		bsStyle="info"
		className={classNames(className, styles.container)}
		{...props}
	>
		<Icon aria-hidden />
		<div>
			{children}
		</div>
	</Alert>
);

export default InfoAlert;