import IAction from "../../Actions/IAction";
import { GetBedFundPolicyForClientIDAction } from "../../Actions/Api/clientActions";
import { AsyncState } from "../../Models/IAsync";
import * as Dto from "../../Models/dto";
import getLoadState from "../../Utils/getLoadState";

export interface IState {
	loading: { [clientId: number]: AsyncState };
	byId: { [clientId: number]: Dto.IBedFundPolicy };
}

export default function bedFundPolicyReducer(
	previousState: IState = {
		loading: {},
		byId: {}
	},
	action: IAction
): IState {
	if (action instanceof GetBedFundPolicyForClientIDAction) {
		const { clientID } = action.params;

		const loadState = getLoadState(action);
		const loading = Object.assign({}, previousState.loading, {
			[clientID]: loadState
		});

		let { byId } = previousState;
		if (loadState === AsyncState.Resolved) {
			const { item } = action.response!;
			if (item.policyId !== -1) {
				byId = Object.assign({}, byId, {
					[clientID]: item
				});
			}
		}

		return Object.assign({}, previousState, {
			loading,
			byId
		});
	}

	return previousState;
}