import Dispatcher from "../Dispatcher/Dispatcher";
import { ICompleteRegistrationModel } from "../Models/dto";
import NextStepAction from "../Actions/Registration/NextStepAction";
import PreviousStepAction from "../Actions/Registration/PreviousStepAction";

class RegistrationActionCreators {
	nextStep(model: ICompleteRegistrationModel): void {
		Dispatcher.dispatch(new NextStepAction(model));
	}

	prevStep(model: ICompleteRegistrationModel): void {
		Dispatcher.dispatch(new PreviousStepAction(model));
	}
}

export default new RegistrationActionCreators();