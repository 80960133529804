import * as Dto from "../Models/dto";
import { IState } from "../Reducers/rootReducer";
import { denormalize } from "../Utils/clientProfilePartyUtils";
import getRolesById from "./getRolesById";
import { createSelector } from "reselect";

export default function makeGetUserDenormalizedSelector(
	getUser: (state: IState) => Dto.IClientProfilePartyNormalized | null
) {
	return createSelector(
		getUser,
		getRolesById,
		denormalize
	);
}
