import { onChange } from "../Reducers/auth/authReducer";
import rootReducer, { IState } from "../Reducers/rootReducer";
import { createPersistedFluxStore, IPersistDetail } from "../Utils/persist";

const ReduxStore = createPersistedFluxStore(rootReducer, [
	{
		key: "ui.dashboard.pageSize",
		getValue: (it: IState) => it.ui.dashboard.pageSize,
		hydrate: (state: IState, pageSize: number) => Object.assign({}, state, {
			ui: Object.assign({}, state.ui, {
				dashboard: Object.assign({}, state.ui.dashboard, { pageSize })
			})
		})
	} as IPersistDetail<IState, number>
]);

let { auth } = ReduxStore.getState();
onChange(auth);
ReduxStore.addListener(() => {
	const newAuth = ReduxStore.getState().auth;
	if (auth !== newAuth) {
		onChange(auth = newAuth);
	}
});

export default ReduxStore;