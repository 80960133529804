import React, { CSSProperties, PureComponent } from "react";
import styles from "./Loader.module.scss";
import range from "lodash/range";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";
export interface IProps {
	loading?: boolean;
	color?: string;
	id?: string;
	className?: string;
	verticalAlign?: "baseline" | "length" | "sub" | "super" | "top" | "text-top" | "middle" | "bottom" | "text-bottom" | "initial" | "inherit";
	margin?: number;
	size?: number;
}

function random(top: number) {
	return Math.random() * top;
}

class Loader extends PureComponent<IProps> {
	static defaultProps: Partial<IProps> = {
		loading: true,
		size: 15,
		margin: 2
	};

	private _animationStyles: { [index: number]: CSSProperties } = {};

	getAnimationStyle(index: number) {
		if (this._animationStyles[index] == null) {
			this._animationStyles[index] = {
				animationDuration: random(100) / 100 + 0.6 + "s",
				animationDelay: random(100) / 100 - 0.2 + "s"
			};
		}

		return this._animationStyles[index];
	}

	getStyle(index: number) {
		return {
			backgroundColor: this.props.color,
			width: this.props.size,
			height: this.props.size,
			margin: this.props.margin,
			verticalAlign: this.props.verticalAlign,
			...this.getAnimationStyle(index)
		};
	}

	renderLoader(loading: boolean) {
		const { props } = this;

		if (loading) {
			const width = this.props.size! * 3 + this.props.margin! * 6;

			return (
				<div
					id={props.id}
					className={props.className}
				>
					<div
						className={styles.container}
						style={{ width }}
					>
						{range(9).map(index => (
							<div
								key={index}
								className={styles.ball}
								style={this.getStyle(index)}
							/>
						))}
					</div>
					<VisuallyHidden>
						Loading...
					</VisuallyHidden>
				</div>
			);
		}

		return null;
	}

	render() {
		return this.renderLoader(!!this.props.loading);
	}
}

export default Loader;