import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";
import AccountsActionEnum from "../../Models/AccountsActionEnum";

export default class AccountsDisplayChangedAction implements IAction {
	currentDisplayAction: AccountsActionEnum;
	tabId: number;

	constructor(data: AccountsActionEnum, tabId: number) {
		this.currentDisplayAction = data;
		this.tabId = tabId;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"AccountsDisplayChangedAction", { "currentDisplayAction": JSON.stringify(this.currentDisplayAction) });
	}
}