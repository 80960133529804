import { makeAsync } from "../Models/IAsync";
import { IState } from "../Reducers/rootReducer";
import { createSelector } from "reselect";

export const getClientProfileAsync = createSelector(
	(state: IState) => state.entities.clientProfile.loading,
	(state: IState) => state.entities.clientProfile.clientProfile,
	makeAsync
);

export default createSelector(
	getClientProfileAsync,
	({ payload }) => payload
);