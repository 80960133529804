import { ActionBase } from "../../../../../Actions/IAction";
import * as Dto from "../../../../../Models/dto";

export class UpdateAction extends ActionBase {
	constructor(public search: Dto.IClientProfileUserSearch, public selectedWorkflowIds?: number[]) {
		super(nameof(UpdateAction));
	}
}

export class SelectSearchAction extends ActionBase {
	constructor(public search: Dto.IClientProfileUserSearch) {
		super(nameof(SelectSearchAction));
	}
}

export class ClearAction extends ActionBase {
	constructor() {
		super(nameof(ClearAction));
	}
}
