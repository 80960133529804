import { ERROR, OK } from "../../Models/apiConstants";
import isAuthenticated from "../../selectors/isAuthenticated";
import isCurrentTabLoaded from "../../selectors/isCurrentTabLoaded";
import BusyOverlay from "../Common/BusyOverlay/BusyOverlay";
import handleGenericError from "../../Utils/handleGenericError";
import handleApiResponse from "../../Utils/handleApiResponse";
import * as toast from "../../Utils/toast";
import connect from "../../Utils/connect";
import { createResetToken } from "../../ActionCreators/Api/loginActionCreators";
import React from "react";
import ReduxStore from "../../Stores/ReduxStore";
import LoginActionCreators, { login, showInvalidCaptchaMessage } from "../../ActionCreators/LoginActionCreators";
import Captcha from "../Captcha/Captcha";
import connectToCaptcha, { ICaptchaProps } from "../../Utils/connectToCaptcha";
import styles from "./Login.module.scss";
import AuthPage, { FormGroup } from "../Auth/AuthPage/AuthPage";
import { appName } from "../../Utils/branding";
import { ReactComponent as ViewPasswordIcon } from "./view-password.svg";
import AuthButton from "../Auth/AuthButton/AuthButton";
import CheckboxInput2 from "../Common/CheckboxInput2/CheckboxInput2";
import Button from "../Common/Button/Button";
import AuthHeader from "../Auth/AuthHeader/AuthHeader";
import VisuallyHidden from "../Common/VisuallyHidden/VisuallyHidden";
import isLoadingCurrentUser from "../../selectors/isLoadingCurrentUser";

interface IOwnProps { }

interface IConnectedProps {
	userName: string;
	password: string;
	rememberMe: boolean;
	busyMessage: string | null;
	onChange(userName: string, password: string): void;
	onRememberMeChange(rememberMe: boolean): void;
	onForgotPassword(): void;
	onLogin(): void;
}

interface IState {
	pwdInputType: string;
}

interface IProps extends IOwnProps, ICaptchaProps, IConnectedProps { }

function isValid(value: string) {
	return value != null && value.length > 0;
}

class Login extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = { pwdInputType: "password" };
		this._handleUserNameChange = this._handleUserNameChange.bind(this);
		this._handlePasswordChange = this._handlePasswordChange.bind(this);
		this._handleRememberMeChange = this._handleRememberMeChange.bind(this);
		this._handleSubmit = this._handleSubmit.bind(this);
	}

	componentDidMount() {
		window.addEventListener("mouseup", this._hidePassword);
	}

	componentWillUnmount() {
		window.removeEventListener("mouseup", this._hidePassword);
	}

	private _showPassword = () => this.setState({ pwdInputType: "text" });

	private _hidePassword = () => this.setState({ pwdInputType: "password" });

	render(): React.ReactElement<{}> {
		const { props } = this;

		const userNameValid = isValid(props.userName);
		const passwordValid = isValid(props.password);

		return (
			<AuthPage onSubmit={this._handleSubmit}>
				<BusyOverlay message={props.busyMessage} />

				<AuthHeader>
					Welcome to {appName}
				</AuthHeader>

				<FormGroup label="Username (email address)">
					{id => (
						<input
							id={id}
							className="form-control"
							onChange={this._handleUserNameChange}
							maxLength={50}
							value={props.userName}
							autoComplete="username"
						/>
					)}
				</FormGroup>
				<FormGroup label="Password">
					{id => (
						<div className={styles.passwordGroup}>
							<input
								id={id}
								type={this.state.pwdInputType}
								className="form-control"
								onChange={this._handlePasswordChange}
								maxLength={50}
								value={props.password}
								autoComplete="current-password"
							/>
							<Button
								className={styles.viewButton}
								onMouseDown={event => {
									if (event.button === 0) {
										this._showPassword();
									}
								}}
								onKeyDown={event => {
									if (event.key === " " || event.key === "Enter") {
										this._showPassword();
									}
								}}
								onKeyUp={this._hidePassword}
								onBlur={this._hidePassword}
							>
								<VisuallyHidden>View Password</VisuallyHidden>
								<ViewPasswordIcon aria-hidden />
							</Button>
						</div>
					)}
				</FormGroup>
				<FormGroup>
					<Captcha />
				</FormGroup>
				<FormGroup>
					<div className={styles.rememberMeForm}>
						<AuthButton
							label="Login"
							disabled={
								props.busyMessage != null ||
								!userNameValid ||
								!passwordValid ||
								!props.captchaValid
							}
						/>

						<CheckboxInput2
							value={props.rememberMe}
							onChange={it => this.props.onRememberMeChange(it)}
						>
							Remember Me
						</CheckboxInput2>
					</div>
				</FormGroup>
				<FormGroup className={styles.helpFormGroup}>
					If you forgot your password, please enter your username and the verification text and then click{" "}
					<Button
						onClick={() => props.onForgotPassword()}
						link
					>
						here
					</Button>
					.
				</FormGroup>
				<FormGroup className={styles.helpFormGroup}>
					Need help? Contact us at{" "}
					<a
						className="link"
						href="mailto:contactsupport@probatefinderondemand.com"
					>
						contactsupport@probatefinderondemand.com
					</a>
					{" "}or call{" "}
					<a
						className="link"
						href="tel:+18773265686"
						itemProp="telephone"
					>
						1-877-326-5686
					</a>
					.
				</FormGroup>
			</AuthPage>
		);
	}

	private _handleUserNameChange(event: React.FormEvent<HTMLInputElement>) {
		const { value } = event.currentTarget;
		this.props.onChange(value, this.props.password);
	}
	private _handlePasswordChange(event: React.FormEvent<HTMLInputElement>) {
		const { value } = event.currentTarget;
		this.props.onChange(this.props.userName, value);
	}

	private _handleRememberMeChange(event: React.FormEvent<HTMLInputElement>) {
		const { checked } = event.currentTarget;
		this.props.onRememberMeChange(checked);
	}

	private _handleSubmit(event: React.FormEvent<HTMLElement>) {
		event.preventDefault();
		this.props.onLogin();
	}
}

const ConnectedToCaptcha = connectToCaptcha<IOwnProps & IConnectedProps>(Login);

const onForgotPassword = async () => {
	const { auth: { loginUser: { userName } }, ui: { captcha: { challenge, response } } } = ReduxStore.getState();
	try {
		const data = await createResetToken(userName, response, challenge!);
		handleApiResponse(data, {
			[OK]: () => toast.success(`If the user exists, an email has been sent to ${userName} with instructions on resetting your password.`),
			[ERROR]: {
				"LOGIN_INCORRECT_CAPTCHA": showInvalidCaptchaMessage
			}
		});
	} catch (error) {
		handleGenericError(error);
	}
};

const onChange = (userName: string, password: string) => {
	const { auth: { loginUser } } = ReduxStore.getState();
	LoginActionCreators.loginUserUpdated(Object.assign({}, loginUser, {
		userName,
		password
	}));
};

const onRememberMeChange = (rememberMe: boolean) => LoginActionCreators.setUserRememberMePreference(rememberMe);

export default connect<IConnectedProps, IOwnProps>(
	ConnectedToCaptcha,
	state => {
		let { authenticating } = state.auth;
		if (
			!authenticating
			&& isAuthenticated(state)
			&& (
				!isCurrentTabLoaded(state)
				|| isLoadingCurrentUser(state)
			)
		) {
			authenticating = true;
		}

		return {
			userName: state.auth.loginUser.userName,
			password: state.auth.loginUser.password,
			rememberMe: state.auth.rememberMe,
			busyMessage: authenticating ? "Initializing..."
				: state.auth.creatingResetToken ? "Resetting password..."
					: null,

			onChange,
			onRememberMeChange,
			onLogin: login,
			onForgotPassword
		};
	}
);