import IAction from "./IAction";
import ActionLogEntry from "../Log/ActionLogEntry";

export default class ActiveTabChangedAction implements IAction {
	activeTabIndex: number;

	constructor(activeTabIndex: number) {
		this.activeTabIndex = activeTabIndex;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"ActiveTabChangedAction",
			{
				"activeTabIndex": JSON.stringify(this.activeTabIndex)
			});
	}
}