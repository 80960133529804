/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	ChangeAccountClaimStatusAction,
	IChangeAccountClaimStatusActionParams,
	GetClaimDisputeFromClaimIDAction,
	IGetClaimDisputeFromClaimIDActionParams,
	GetClaimDocHistoryAction,
	IGetClaimDocHistoryActionParams,
	GetClaimIDLinkedAccountsAction,
	IGetClaimIDLinkedAccountsActionParams,
	GetClaimIDLinkedAccountsByClaimIdAction,
	IGetClaimIDLinkedAccountsByClaimIdActionParams,
	SaveClaimAction,
	ISaveClaimActionParams,
	SaveClaimDisputeAction,
	ISaveClaimDisputeActionParams,
	SaveClaimReturnAction,
	ISaveClaimReturnActionParams,
	SaveClaimReturnAndAlertAction,
	ISaveClaimReturnAndAlertActionParams
} from "../../Actions/Api/claimActions";
import {
	IAccountClaims,
	IChangeAccountClaimStatusResponse,
	IClaim,
	IClaimDispute,
	IClaimDocRequest,
	IClaimReturn,
	ICollectionResponse,
	IResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const changeAccountClaimStatusEndpoint = "Api/Claim/ChangeAccountClaimStatus";

export function changeAccountClaimStatus(
	accountClaims: IAccountClaims, 
	__options__?: IOptions<IChangeAccountClaimStatusResponse>
) {
	return apiExecute<IChangeAccountClaimStatusActionParams, ChangeAccountClaimStatusAction, IChangeAccountClaimStatusResponse>(
		changeAccountClaimStatusEndpoint, 
		true, 
		__options__, 
		ChangeAccountClaimStatusAction,
		{ accountClaims }
	);
}

export const getClaimDisputeFromClaimIDEndpoint = "Api/Claim/GetClaimDisputeFromClaimID";

export function getClaimDisputeFromClaimID(
	accountID: number, 
	claimID: number, 
	__options__?: IOptions<IResponse<IClaimDispute>>
) {
	return apiExecute<IGetClaimDisputeFromClaimIDActionParams, GetClaimDisputeFromClaimIDAction, IResponse<IClaimDispute>>(
		getClaimDisputeFromClaimIDEndpoint, 
		true, 
		__options__, 
		GetClaimDisputeFromClaimIDAction,
		{
			accountID,
			claimID
		}
	);
}

export const getClaimDocHistoryEndpoint = "Api/Claim/GetClaimDocHistory";

export function getClaimDocHistory(
	acctIds: number[], 
	targetStatusId: number, 
	__options__?: IOptions<ICollectionResponse<IClaimDocRequest>>
) {
	return apiExecute<IGetClaimDocHistoryActionParams, GetClaimDocHistoryAction, ICollectionResponse<IClaimDocRequest>>(
		getClaimDocHistoryEndpoint, 
		true, 
		__options__, 
		GetClaimDocHistoryAction,
		{
			acctIds,
			targetStatusId
		}
	);
}

export const getClaimIDLinkedAccountsEndpoint = "Api/Claim/GetClaimIDLinkedAccounts";

export function getClaimIDLinkedAccounts(
	acctIDs: number[], 
	__options__?: IOptions<ICollectionResponse<IAccountClaims>>
) {
	return apiExecute<IGetClaimIDLinkedAccountsActionParams, GetClaimIDLinkedAccountsAction, ICollectionResponse<IAccountClaims>>(
		getClaimIDLinkedAccountsEndpoint, 
		true, 
		__options__, 
		GetClaimIDLinkedAccountsAction,
		{ acctIDs }
	);
}

export const getClaimIDLinkedAccountsByClaimIdEndpoint = "Api/Claim/GetClaimIDLinkedAccountsByClaimId";

export function getClaimIDLinkedAccountsByClaimId(
	acctId: number, 
	claimId: number, 
	__options__?: IOptions<ICollectionResponse<IAccountClaims>>
) {
	return apiExecute<IGetClaimIDLinkedAccountsByClaimIdActionParams, GetClaimIDLinkedAccountsByClaimIdAction, ICollectionResponse<IAccountClaims>>(
		getClaimIDLinkedAccountsByClaimIdEndpoint, 
		true, 
		__options__, 
		GetClaimIDLinkedAccountsByClaimIdAction,
		{
			acctId,
			claimId
		}
	);
}

export const saveClaimEndpoint = "Api/Claim/SaveClaim";

export function saveClaim(
	claim: IClaim, 
	__options__?: IOptions<IResponse<IClaim>>
) {
	return apiExecute<ISaveClaimActionParams, SaveClaimAction, IResponse<IClaim>>(
		saveClaimEndpoint, 
		true, 
		__options__, 
		SaveClaimAction,
		{ claim }
	);
}

export const saveClaimDisputeEndpoint = "Api/Claim/SaveClaimDispute";

export function saveClaimDispute(
	claimDispute: IClaimDispute, 
	__options__?: IOptions<IResponse<IClaimDispute>>
) {
	return apiExecute<ISaveClaimDisputeActionParams, SaveClaimDisputeAction, IResponse<IClaimDispute>>(
		saveClaimDisputeEndpoint, 
		true, 
		__options__, 
		SaveClaimDisputeAction,
		{ claimDispute }
	);
}

export const saveClaimReturnEndpoint = "Api/Claim/SaveClaimReturn";

export function saveClaimReturn(
	claimReturn: IClaimReturn, 
	__options__?: IOptions<IResponse<IClaimReturn>>
) {
	return apiExecute<ISaveClaimReturnActionParams, SaveClaimReturnAction, IResponse<IClaimReturn>>(
		saveClaimReturnEndpoint, 
		true, 
		__options__, 
		SaveClaimReturnAction,
		{ claimReturn }
	);
}

export const saveClaimReturnAndAlertEndpoint = "Api/Claim/SaveClaimReturnAndAlert";

export function saveClaimReturnAndAlert(
	returnMethod: number, 
	claimReturn: IClaimReturn, 
	__options__?: IOptions<IResponse<IClaimReturn>>
) {
	return apiExecute<ISaveClaimReturnAndAlertActionParams, SaveClaimReturnAndAlertAction, IResponse<IClaimReturn>>(
		saveClaimReturnAndAlertEndpoint, 
		true, 
		__options__, 
		SaveClaimReturnAndAlertAction,
		{
			returnMethod,
			claimReturn
		}
	);
}

/* tslint:enable:max-line-length */