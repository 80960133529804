/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IAccount,
	IAccountValidationGroup,
	IAccountValidationResponse,
	ICollectionResponse,
	IResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IAddAccountValidationActionParams {
	account: IAccount;
	pass: boolean;
	groups: IAccountValidationGroup[];
	failReason: string;
	failureType: string;
}

export class AddAccountValidationAction extends ApiActionBase<IAddAccountValidationActionParams, IResponse<IAccount>> {
	constructor(details: IDetails<IAddAccountValidationActionParams, IResponse<IAccount>>) {
		super("Api/AccountValidationActions/AddAccountValidationAction", details);
	}
}

export interface IGetAccountValidationActionParams {
	acctId: number;
}

export class GetAccountValidationAction extends ApiActionBase<IGetAccountValidationActionParams, IAccountValidationResponse> {
	constructor(details: IDetails<IGetAccountValidationActionParams, IAccountValidationResponse>) {
		super("Api/AccountValidationActions/GetAccountValidationAction", details);
	}
}

export interface IGetLockedAccountsActionParams {
	/* no parameters */
}

export class GetLockedAccountsAction extends ApiActionBase<IGetLockedAccountsActionParams, ICollectionResponse<number>> {
	constructor(details: IDetails<IGetLockedAccountsActionParams, ICollectionResponse<number>>) {
		super("Api/AccountValidationActions/GetLockedAccountsAction", details);
	}
}

/* tslint:enable:max-line-length */