import ReplaceTransition from "../ReplaceTransition";
import * as React from "react";
import { AsyncState } from "../../../Models/IAsync";
import TabLoader from "../../Common/TabLoader/TabLoader";
import TabError from "../../Dashboard/Accounts/RightPanel/TabError/TabError";
import pure from "../../../Utils/pure";
import withoutProperties from "../../../Utils/withoutProperties";
export { AsyncState } from "../../../Models/IAsync";

type ChildFactory = () => React.ReactNode;

interface IProps extends React.HTMLAttributes<HTMLElement> {
	loadState: AsyncState | null | undefined;
	children?: React.ReactNode | ChildFactory;
	useTransition?: boolean;
	loader?: React.ReactChild;
}

const AsyncContainer: React.StatelessComponent<IProps> = (props: IProps) => {
	let content: React.ReactNode;
	switch (props.loadState) {
		case null:
		case undefined:
		case AsyncState.Pending:
			content = props.loader;
			break;
		case AsyncState.Rejected:
			content = <TabError />;
			break;
		case AsyncState.Resolved:
			content = typeof props.children === "function" ? (props.children as ChildFactory)() : props.children as React.ReactNode;
			break;
		default:
			if (__DEV__) {
				console.error(`[${__filename}] Unexpected state: ${JSON.stringify(props.loadState)}`);
			}
			break;
	}

	const otherProps = withoutProperties(props, [
		nameof(props.loadState),
		nameof(props.useTransition),
		nameof(props.loader)
	]);
	const style = Object.assign({
		position: "relative"
	}, props.style);

	const container = (
		<div
			key={props.loadState == null ? AsyncState.Pending : props.loadState}
			{...otherProps}
			style={style}
		>
			{content}
		</div>
	);

	if (props.useTransition) {
		return (
			<ReplaceTransition>
				{container}
			</ReplaceTransition>
		);
	}

	return container;
};

AsyncContainer.defaultProps = {
	loader: <TabLoader />
} as any;

export default pure(AsyncContainer);
