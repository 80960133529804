import formatPhone from "../../Utils/formatPhone";
import { phone as validPhone } from "../../Utils/validate";
import FormattingInput from "./FormattingInput";

export default class PhoneInput extends FormattingInput {
	static defaultProps = {
		maxLength: 10,
		placeholder: "(###) ###-####"
	};

	static isValid(value: string | null) {
		return validPhone(value);
	}

	protected allowKey(key: string) {
		return /\d/.test(key);
	}

	protected formatValue(rawValue: string) {
		return formatPhone(rawValue);
	}
}