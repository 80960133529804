import assign from "../../../Utils/assign";
import * as React from "react";
import Button from "../Button/Button";
import Dropzone from "react-dropzone";
import styles from "./FileInput.module.scss";
import cn from "classnames";

interface IProps {
	fileName: string | null;
	placeholder?: string;
	accept?: string;
	disabled?: boolean;
	hasError?: boolean;
	className?: ClassValue;
	onFile(file: File): void;
	onClear?(): void;
	id?: string;
}

interface IState {
	dropping: boolean;
}

class FileInput extends React.PureComponent<IProps, IState> {
	private _dropzone: typeof Dropzone & { open(): void; } | null = null;

	constructor(props: IProps) {
		super(props);

		this.state = {
			dropping: false
		};

		this._handleFileDrop = this._handleFileDrop.bind(this);
		this._handleBrowseClick = this._handleBrowseClick.bind(this);
	}

	render() {
		const { props, state } = this;
		const showPlaceholder = props.fileName == null || props.fileName === "";

		return (
			<Dropzone
				ref={(it: any) => this._dropzone = it}
				className={cn(
					styles.drop,
					{
						[styles.dropHover]: state.dropping && !props.disabled,
						[styles.error]: props.hasError
					},
					props.className
				)}
				disableClick={true}
				multiple={false}
				accept={props.accept}
				onDrop={this._handleFileDrop}
				onDragEnter={this._handleDragEnter}
				onDragLeave={this._handleDragLeave}
				inputProps={{
					id: props.id
				}}
			>
				<div className="input-group">
					<span
						className={cn("form-control", { [styles.placeholder]: showPlaceholder })}
						{...{ disabled: props.disabled }}
					>
						<span>
							{showPlaceholder ? props.placeholder : props.fileName}
						</span>
						{!showPlaceholder && props.onClear != null && (
							<button
								className={styles.clear}
								onClick={props.onClear}
								title="Clear"
							>
								<span aria-hidden="true">&times;</span>
								<span className="sr-only">Clear</span>
							</button>
						)}
					</span>
					<span className="input-group-btn">
						<Button
							className={styles.button}
							label="Browse"
							icon="fa-folder-open-o"
							disabled={props.disabled}
							onClick={this._handleBrowseClick}
						/>
					</span>
				</div>
			</Dropzone>
		);
	}

	private _handleDragEnter = () => this._setDropping(true);

	private _handleDragLeave = () => this._setDropping(false);

	private _setDropping(dropping: boolean) {
		if (dropping !== this.state.dropping) {
			this.setState(oldState => assign(oldState, { dropping }));
		}
	}

	private _handleFileDrop(acceptedFiles: File[], rejectedFiles: File[]) {
		this._setDropping(false);

		if (this.props.disabled) {
			return;
		}

		const file = acceptedFiles[0] || rejectedFiles[0] || null;
		this.props.onFile(file);
	}

	private _handleBrowseClick() {
		this._dropzone!.open();
	}
}

export default FileInput;