import { ActionBase, default as IAction } from "./IAction";
import ActionLogEntry from "../Log/ActionLogEntry";
import { IClaimEdit } from "../Stores/AccountDetailsStore";
import * as Dto from "../Models/dto";

class ApiRequestAction {
	constructor(public callback?: () => void) { }
}

export class TabChangeAction implements IAction {
	constructor(public index: number) { }

	toLogEntry() {
		return new ActionLogEntry("TabChangeAction", {
			index: JSON.stringify(this.index)
		});
	}
}

export class UpdateAccountEditAction implements IAction {
	constructor(public account: Dto.IAccount | Dto.IAccountImport) { }

	toLogEntry() {
		return new ActionLogEntry("UpdateAccountEditAction", {
			account: JSON.stringify(this.account)
		});
	}
}

export class SaveAccountStartAction implements IAction {
	constructor(public account: Dto.IAccount | Dto.IAccountImport) { }

	toLogEntry() {
		return new ActionLogEntry("SaveAccountStartAction", {
			account: JSON.stringify(this.account)
		});
	}
}

export class SaveAccountSuccessAction implements IAction {
	constructor(public response: Dto.IResponse<Dto.IAccount> | Dto.IResponse<Dto.IAccountImport>) { }

	toLogEntry() {
		return new ActionLogEntry("SaveAccountSuccessAction", {
			response: JSON.stringify(this.response)
		});
	}
}

export class SaveAccountErrorAction extends ActionBase {
	constructor() {
		super(nameof(SaveAccountErrorAction));
	}
}

export class RequestProbateCaseAction extends ApiRequestAction implements IAction {
	toLogEntry() {
		return new ActionLogEntry("RequestProbateCaseAction");
	}
}

export class RequestBankoAction extends ApiRequestAction implements IAction {
	toLogEntry() {
		return new ActionLogEntry("RequestBankoAction");
	}
}

export class RequestClaimsAction extends ApiRequestAction implements IAction {
	toLogEntry() {
		return new ActionLogEntry("RequestClaimsAction");
	}
}

export class ClaimEditChangeAction implements IAction {
	constructor(public edit: IClaimEdit) { }

	toLogEntry() {
		return new ActionLogEntry("ClaimEditChangeAction", {
			edit: JSON.stringify(this.edit)
		});
	}
}

export class ClaimDiscardChangesAction implements IAction {
	toLogEntry() {
		return new ActionLogEntry("ClaimDiscardChangesAction");
	}
}

export class ClaimSaveStartAction implements IAction {
	constructor(public claim: Dto.IClaim) { }

	toLogEntry() {
		return new ActionLogEntry("ClaimSaveStartAction", {
			claim: JSON.stringify(this.claim)
		});
	}
}

export class ClaimSaveSuccessAction implements IAction {
	constructor(public response: Dto.IResponse<Dto.IClaim>) { }

	toLogEntry() {
		return new ActionLogEntry("ClaimSaveSuccessAction", {
			response: JSON.stringify(this.response)
		});
	}
}

export class ClaimSaveErrorAction extends ActionBase {
	constructor() {
		super(nameof(ClaimSaveErrorAction));
	}
}

export class RequestAccountClaimsAction extends ApiRequestAction implements IAction {
	toLogEntry() {
		return new ActionLogEntry("RequestAccountClaimsAction");
	}
}

export class RequestDocumentsAction extends ApiRequestAction implements IAction {
	toLogEntry() {
		return new ActionLogEntry("RequestDocumentsAction");
	}
}

export class RequestDisputeAction extends ApiRequestAction implements IAction {
	toLogEntry() {
		return new ActionLogEntry("RequestDisputeAction");
	}
}

export class UpdateDisputeEditAction implements IAction {
	constructor(public dispute: Dto.IClaimDispute) { }

	toLogEntry() {
		return new ActionLogEntry("DisputeEditUpdateAction", {
			"dispute": JSON.stringify(this.dispute)
		});
	}
}

export class SaveDisputeStartAction implements IAction {
	toLogEntry() {
		return new ActionLogEntry("SaveClaimDisputeStartAction");
	}
}

export class SaveDisputeSuccessAction implements IAction {
	constructor(public response: Dto.IResponse<Dto.IClaimDispute>) { }

	toLogEntry() {
		return new ActionLogEntry("SaveClaimDisputeSuccessAction", {
			response: JSON.stringify(this.response)
		});
	}
}

export class SaveDisputeErrorAction extends ActionBase {
	constructor() {
		super(nameof(SaveDisputeErrorAction));
	}
}

export class RequestFeesAction extends ApiRequestAction implements IAction {
	toLogEntry() {
		return new ActionLogEntry("RequestBillingAction");
	}
}

export class RequestHistoryAction extends ApiRequestAction implements IAction {
	toLogEntry() {
		return new ActionLogEntry("RequestHistoryAction");
	}
}

export class HistoryModalAction implements IAction {
	constructor(public show: boolean) { }

	toLogEntry() {
		return new ActionLogEntry("HistoryModalAction", {
			show: JSON.stringify(this.show)
		});
	}
}

export class AddDocumentModalAction implements IAction {
	constructor(public show: boolean) { }

	toLogEntry() {
		return new ActionLogEntry("AddDocumentModalAction", {
			show: JSON.stringify(this.show)
		});
	}
}

export class DeleteDocumentStartAction implements IAction {
	toLogEntry() {
		return new ActionLogEntry("DeleteDocumentStartAction");
	}
}

export class DeleteDocumentSuccessAction implements IAction {
	constructor(public response: Dto.IBaseResponse, public document: Dto.ILinkedDocument) { }

	toLogEntry() {
		return new ActionLogEntry("DeleteDocumentSuccessAction", {
			response: JSON.stringify(this.response),
			document: JSON.stringify(this.document)
		});
	}
}

export class DeleteDocumentErrorAction extends ActionBase {
	constructor() {
		super(nameof(DeleteDocumentErrorAction));
	}
}

export class EditAccountMiscAction extends ActionBase {
	constructor(public edit: Dto.IAccountMisc) {
		super(nameof(EditAccountMiscAction));
	}
}