import React from "react";
import cn from "classnames";
import styles from "./Forms.module.scss";
import icon from "../../../../Images/registration/security-icon.png";
import Form from "../../Common/Form/Form";
import FormGroup from "../../Common/FormGroup/FormGroup";
import validatePassword from "../../../Utils/validatePassword";

import isEmpty from "../../../Utils/isEmpty";
import { IProps } from "./Forms";
import { appName } from "../../../Utils/branding";

interface IValid {
	passwordValid: boolean;
	confirmPasswordValid: boolean;
	securityQuestionValid: boolean;
	securityAnswerValid: boolean;
	allValid: boolean;
}

interface IState {
	password: string;
	confirmPassword: string;
}

export default class SecurityInformation extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			password: props.model.adminPassword,
			confirmPassword: props.model.adminPassword
		};
	}

	validate(): IValid {
		const pwdResult = validatePassword(this.state.password, this.state.confirmPassword, 10);
		const passwordValid = pwdResult && pwdResult.length && pwdResult.lower && pwdResult.number && pwdResult.special && pwdResult.upper;
		const confirmPasswordValid = pwdResult && pwdResult.confirm;
		const securityQuestionValid = !isEmpty(this.props.model.adminSecurityQuestion);
		const securityAnswerValid = !isEmpty(this.props.model.adminSecurityAnswer);
		const allValid = passwordValid && confirmPasswordValid && securityQuestionValid && securityAnswerValid;

		return {
			passwordValid,
			confirmPasswordValid,
			securityQuestionValid,
			securityAnswerValid,
			allValid
		};
	}

	confirm() {
		this.props.setValue(this.state.password, (v, m) => m.adminPassword = v);
		this.props.onConfirm();
	}

	render() {
		const { props } = this;
		const valid = this.validate();

		return (
			<div className={styles.page}>
				<div className={styles.header}>
					<img src={icon} alt="" height={50} width="auto" />
					<div>
						<div className={styles.title}>
							Security information
						</div>
						<div className={styles.subtitle}>
							Your user name for the {appName} application will
							be your email address. Please create your password and security
							question below. The security question will be asked in the
							circumstance you forget your password.
						</div>
					</div>
				</div>

				<div className={styles.fields}>
					<Form
						onSubmit={e => e.preventDefault()}
						horizontal
					>
						<FormGroup label="Email">
							{id => (
								<span id={id} className={styles.readonly}>
									{props.data.adminEmail}
								</span>
							)}
						</FormGroup>

						<FormGroup
							required
							label="Password"
							hasError={!valid.passwordValid}
						>
							{id => (
								<input
									type="password"
									className={cn("form-control")}
									id={id}
									maxLength={50}
									value={this.state.password}
									onChange={e => this.setState({ password: e.target.value })}
								/>
							)}
						</FormGroup>

						<FormGroup
							required
							label="Confirm password"
							hasError={!valid.confirmPasswordValid}
						>
							{id => (
								<input
									type="password"
									className={cn("form-control")}
									id={id}
									maxLength={50}
									value={this.state.confirmPassword}
									onChange={e => this.setState({ confirmPassword: e.target.value })}
								/>
							)}
						</FormGroup>

						<FormGroup
							required
							label="Security question"
							hasError={!valid.securityQuestionValid}
						>
							{id => (
								<input
									type="text"
									className={cn("form-control")}
									id={id}
									maxLength={255}
									value={props.model.adminSecurityQuestion}
									onChange={e => props.setValue(e.target.value, (v, m) => m.adminSecurityQuestion = v)}
								/>
							)}
						</FormGroup>

						<FormGroup
							required
							label="Security answer"
							hasError={!valid.securityAnswerValid}
						>
							{id => (
								<input
									type="text"
									className={cn("form-control")}
									id={id}
									maxLength={255}
									value={props.model.adminSecurityAnswer}
									onChange={e => props.setValue(e.target.value, (v, m) => m.adminSecurityAnswer = v)}
								/>
							)}
						</FormGroup>

						<FormGroup
							label=""
						>
							{id => (
								<div id={id}>
									Your password must meet the following criteria:
									<br /><br />
									<ul>
										<li>Is password 10 characters long?</li>
										<li>Does password contain special characters?</li>
										<li>Does password contain numbers?</li>
										<li>Does password contain uppercase letters?</li>
										<li>Does password contain lowercase letters?</li>
										<li>Does password match confirm password?</li>
									</ul>
								</div>
							)}
						</FormGroup>

						<div className={styles.actions}>
							<button
								type="button"
								className="btn btn-light"
								onClick={() => props.onBack()}
							>
								Back
							</button>
							<button
								type="button"
								className="btn btn-warning"
								disabled={!valid.allValid}
								onClick={() => this.confirm()}
							>
								Continue
							</button>
						</div>
					</Form>
				</div>
			</div>
		);
	}
}