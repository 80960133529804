import isEmpty from "./isEmpty";
import joinParts from "./joinParts";
import keyBy from "lodash/keyBy";
import isEqualWith from "lodash/isEqualWith";
import union from "lodash/union";
import * as Dto from "../Models/dto";
import withoutProperties from "./withoutProperties";
import { Permissions, Permission, IPermissions, map as permissionMap } from "../Models/Permissions";
import compareArrays from "./compareArrays";
import isEqual from "lodash/isEqual";
// import { ACCESS_ROLES } from "../Reducers/Entities/rolesReducer";

type RoleMap = { [clientProfileRoleId: number]: Dto.IClientProfileRole };

export function normalize(user: Dto.IClientProfileParty) {
	if (user == null) {
		return null;
	}

	const {
		clientProfileRoles,
		roles,
		...rest
	} = user;

	let clientProfileRoleIds;
	// if (process.env.REACT_APP_APPLICATION === "access") {
	// 	clientProfileRoleIds = roles
	// 		.filter(it => it !== "Client_User")
	// 		.map(it => ACCESS_ROLES[it].clientProfileRoleId);
	// } else {
	clientProfileRoleIds = clientProfileRoles.map(it => it.clientProfileRoleId);
	// }

	return {
		...rest,
		clientProfileRoleIds
	} as Dto.IClientProfilePartyNormalized;
}

export function denormalize(user: Dto.IClientProfilePartyNormalized | null, rolesById: { [clientProfileRoleId: number]: Dto.IClientProfileRole }) {
	if (user == null) {
		return null;
	}

	const clientProfileRoles = user.clientProfileRoleIds.map(it => rolesById[it]);
	const roles = union(
		...clientProfileRoles.map(clientProfileRole => {
			return clientProfileRole.applicationRoles.map(it => it.name);
		})
	);

	const denormalized = Object.assign({}, user, {
		clientProfileRoles,
		roles
	});

	return withoutProperties(denormalized, nameof(denormalized.clientProfileRoleIds)) as Dto.IClientProfileParty;
}

export function areEqual(a: Dto.IClientProfilePartyNormalized | null | undefined, b: Dto.IClientProfilePartyNormalized | null | undefined) {
	return isEqualWith(a, b, (value: any, otherValue: any, key: string | number | symbol | undefined, object: any, other: any) => {
		if ((key === nameof(a!.clientIds) || key === nameof(a!.clientProfileRoleIds)) && (object === a || other === a)) {
			return compareArrays(value, otherValue);
		}

		return undefined; // use default lodash isEqual
	});
}

export function getDisplayName(party: Dto.IClientProfileParty | Dto.IClientProfilePartyNormalized) {
	if (party == null) {
		return null;
	}

	const name = joinParts(" ", [party.firstName, party.middleName, party.lastName]);
	return isEmpty(name) ? party.email : name;
}

/** Deprecated: Use getCurrentPermissions selector or getPermissions instead */
export function isAdministrator(user: Dto.IClientProfilePartyNormalized, roles: RoleMap) {
	return hasPermission(user, roles, Permissions.ClientAdministrator, Permissions.ApplicationAdministrator);
}

/** Deprecated: Use getCurrentPermissions selector or getPermissions instead */
export function isClientAdministrator(user: Dto.IClientProfilePartyNormalized, roles: RoleMap) {
	return hasPermission(user, roles, Permissions.ClientAdministrator);
}

/** Deprecated: Use getCurrentPermissions selector or getPermissions instead */
export function hasPermission(user: Dto.IClientProfilePartyNormalized, roles: RoleMap, ...permissions: Permission[]) {
	if (user == null) {
		return false;
	}

	const permissionMap = keyBy(permissions) as { [permission: string]: Permission };
	return user.clientProfileRoleIds.some(roleId => {
		const role = roles[roleId];
		if (__DEV__ && role == null) {
			console.error(`[${__filename}] Role ${roleId} does not appear to exist`);
		}
		return role != null && role.applicationRoles.some(it => it.name in permissionMap);
	});
}

function getClientProfileRoleIds(userOrClientProfileRoleIds: Dto.IClientProfilePartyNormalized | number[] | null) {
	if (userOrClientProfileRoleIds == null) {
		return [];
	} else if (Array.isArray(userOrClientProfileRoleIds)) {
		return userOrClientProfileRoleIds;
	} else {
		return userOrClientProfileRoleIds.clientProfileRoleIds;
	}
}

export function getPermissions(user: Dto.IClientProfilePartyNormalized | null, roles: RoleMap): IPermissions;
export function getPermissions(clientProfileRoleIds: number[] | null, roles: RoleMap): IPermissions;
export function getPermissions(userOrClientProfileRoleIds: Dto.IClientProfilePartyNormalized | number[] | null, roles: RoleMap): IPermissions {
	const permissions: IPermissions = {
		applicationAdministrator: false,
		clientAdministrator: false,
		flaggedView: false,
		flaggedModify: false,
		awaitingPlacementView: false,
		awaitingPlacementModify: false,
		nonMatchedView: false,
		nonMatchedModify: false,
		dodmatchedView: false,
		dodmatchedModify: false,
		estateMatchedView: false,
		estateMatchedModify: false,
		approvedView: false,
		approvedModify: false,
		processedClaimsView: false,
		processedClaimsModify: false,
		filedClaimsView: false,
		filedClaimsModify: false,
		disputedClaimsView: false,
		disputedClaimsModify: false,
		returnedClaimsView: false,
		returnedClaimsModify: false,
		closedView: false,
		closedModify: false,
		viewSsn: false,
		reporting: false,
		editAccountData: false,
		exportAccounts: false,
		addAccounts: false,
		affiliateManager: false,
		attachStatements: false,
		lineOfBusinessApproval: false,
		updateAccountData: false,
		validate: false,
		dashboardAccess: false,
		addDocuments: false,
		requestSatisfactionReleaseDocuments: false,
		requestWithdrawalDocuments: false,
		performWorkflowActions: false,
		requestAgreeToSatisfactionReleaseDocuments: false,
		requestWithdrawalDupsDocuments: false,
		manageAPIKeys: false
	};

	const clientProfileRoleIds = getClientProfileRoleIds(userOrClientProfileRoleIds);
	for (const roleId of clientProfileRoleIds) {
		const role = roles[roleId];
		if (role == null) {
			if (__DEV__) {
				console.error(`[${__filename}] Role ${roleId} does not appear to exist`);
			}
			continue;
		}

		for (const applicationRole of role.applicationRoles) {
			const key: keyof IPermissions = (permissionMap as any)[applicationRole.name];
			if (__DEV__ && key == null) {
				console.error(`[${__filename}] Permission ${JSON.stringify(applicationRole.name)} not mapped`);
			} else {
				permissions[key] = true;
			}
		}
	}

	if (permissions.applicationAdministrator) {
		for (const key in permissions) {
			permissions[key as keyof IPermissions] = true;
		}
	}

	return permissions;
}

export function arePermissionsEqual(userA: Dto.IClientProfilePartyNormalized, userB: Dto.IClientProfilePartyNormalized, roles: { [clientProfileRoleId: number]: Dto.IClientProfileRole }): boolean;
export function arePermissionsEqual(clientProfileRoleIdsA: number[], clientProfileRoleIdsAB: number[], roles: { [clientProfileRoleId: number]: Dto.IClientProfileRole }): boolean;
export function arePermissionsEqual(user: Dto.IClientProfilePartyNormalized, clientProfileRoleIds: number[], roles: { [clientProfileRoleId: number]: Dto.IClientProfileRole }): boolean;
export function arePermissionsEqual(clientProfileRoleIds: number[], user: Dto.IClientProfilePartyNormalized, roles: { [clientProfileRoleId: number]: Dto.IClientProfileRole }): boolean;
export function arePermissionsEqual(
	userOrClientProfileRoleIdsA: Dto.IClientProfilePartyNormalized | number[],
	userOrClientProfileRoleIdsB: Dto.IClientProfilePartyNormalized | number[],
	roles: { [clientProfileRoleId: number]: Dto.IClientProfileRole }
) {
	const permissionsA = getPermissions(getClientProfileRoleIds(userOrClientProfileRoleIdsA), roles);
	const permissionsB = getPermissions(getClientProfileRoleIds(userOrClientProfileRoleIdsB), roles);

	return isEqual(permissionsA, permissionsB);
}