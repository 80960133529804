/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	GetProbateCaseAction,
	IGetProbateCaseActionParams
} from "../../Actions/Api/probateCaseActions";
import {
	IProbateCase,
	IResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const getProbateCaseEndpoint = "Api/ProbateCase/GetProbateCase";

export function getProbateCase(
	caseID: number, 
	acctID: number, 
	claimID: number, 
	__options__?: IOptions<IResponse<IProbateCase>>
) {
	return apiExecute<IGetProbateCaseActionParams, GetProbateCaseAction, IResponse<IProbateCase>>(
		getProbateCaseEndpoint, 
		true, 
		__options__, 
		GetProbateCaseAction,
		{
			caseID,
			acctID,
			claimID
		}
	);
}

/* tslint:enable:max-line-length */