import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";

export default class AffiliateSelectedAction implements IAction {
	selectedAffiliateIds: number[];

	constructor(data: number[]) {
		this.selectedAffiliateIds = data;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"AffiliateSelectedAction", { "selectedAffiliates": JSON.stringify(this.selectedAffiliateIds) });
	}
}