import { ActionBase } from "./IAction";
import { AsyncState } from "../Models/IAsync";

type Tags = { [tag: string]: boolean };

function makeTags(tags: string | string[] | null | undefined): Tags {
	const result = {} as Tags;
	if (tags != null) {
		if (typeof tags === "string") {
			result[tags] = true;
		} else {
			for (const tag of tags) {
				result[tag] = true;
			}
		}
	}

	return result;
}

export interface IDetails<TParams, TResponse> {
	requestId: string;
	params: TParams;
	state: AsyncState;
	response?: TResponse;
	error?: any;
	tags?: string | string[];
}

export interface IApiActionFactory<TParams, TAction extends ApiActionBase<TParams, TResponse>, TResponse> {
	(details: IDetails<TParams, TResponse>): TAction;
}

export default class ApiActionBase<TParams extends { [name: string]: any }, TResponse> extends ActionBase {
	requestId: string;
	params: TParams;
	state: AsyncState;
	response: TResponse | null = null;
	error: any = null;
	tags: Tags;

	constructor(name: string, details: IDetails<TParams, TResponse>) {
		super(name);

		this.requestId = details.requestId;
		this.params = details.params;
		this.state = details.state;
		this.response = details.state === AsyncState.Resolved ? details.response! : null;
		this.error = details.state === AsyncState.Rejected ? details.error : null;

		this.tags = makeTags(details.tags);
	}
}