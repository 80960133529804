import React from "react";
const ReactCSSTransitionReplace = require("react-css-transition-replace");

interface IProps {
	children?: React.ReactChild | null | boolean;
}

export default class ReplaceTransition extends React.Component<IProps, {}> {
	render() {
		return (
			<ReactCSSTransitionReplace transitionName="fade-wait" transitionEnterTimeout={400} transitionLeaveTimeout={200}>
				{this.props["children"]}
			</ReactCSSTransitionReplace>
		);
	}
}