import { AsyncState } from "../../../../Models/IAsync";
import { GetClaimBasisOptionsAction } from "../../../../Actions/Api/clientActions";
import IAction from "../../../../Actions/IAction";
import {  OK } from "../../../../Models/apiConstants";
import { IClaimBasisDesc } from "../../../../Models/dto";

export interface IState {
	claimBasisOptions: IClaimBasisDesc[];
	loading: AsyncState | null;
}

export default function claimBasisOptionsReducer(
	previousState: IState = {
		claimBasisOptions: [],
		loading: null
	},
	action: IAction
) {
	if (action instanceof GetClaimBasisOptionsAction) {
		let loading = action.state;
		if (action.state === AsyncState.Resolved && action.response!.status !== OK) {
			loading = AsyncState.Rejected;
		}

		let { claimBasisOptions } = previousState;
		if (loading === AsyncState.Resolved) {
			claimBasisOptions = action.response!.items;
		}
	
		return Object.assign({}, previousState, {
			loading,
			claimBasisOptions
		});
	}

	return previousState;
}