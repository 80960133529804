import { ActionBase } from "./IAction";

let nextId = 0;

export class StartBusyAction extends ActionBase {
	id: number;

	constructor(public message: string, public showWhenLocked: boolean) {
		super(nameof(StartBusyAction));
		this.id = nextId++;
	}
}

export class StopBusyAction extends ActionBase {
	id: number;

	constructor(id: number) {
		super(nameof(StopBusyAction));
		this.id = id;
	}
}

export class StopAllBusyAction extends ActionBase { 
	constructor() {
		super(nameof(StopAllBusyAction));
	}
}