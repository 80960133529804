import IAction from "../../Actions/IAction";
import { GetLowBalanceAccountsAction } from "../../Actions/Api/accountActions";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import getLoadState from "../../Utils/getLoadState";

export interface IState {
	data: Dto.IAccountLowBalance[];
	loading: AsyncState | null;
}

export default function lowBalanceAccountReducer(
	previousState: IState = {
		data: [],
		loading: null
	},
	action: IAction
): IState {
	if (action instanceof GetLowBalanceAccountsAction) {
		const loading = getLoadState(action);

		const data = loading === AsyncState.Resolved
			? action.response!.items
			: previousState.data;

		return Object.assign({}, previousState, {
			loading,
			data
		});
	}

	return previousState;
}