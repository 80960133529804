import * as React from "react";
import getDisplayName from "./getDisplayName";

function pure<TProps>(WrappedComponent: React.StatelessComponent<TProps>) {
	class Pure extends React.PureComponent<TProps> {
		static displayName = `${nameof(Pure)}(${getDisplayName(WrappedComponent)})`;
		static defaultProps = WrappedComponent.defaultProps;

		render() {
			return <WrappedComponent {...this.props} />;
		}
	}

	return Pure;
}

export default pure;