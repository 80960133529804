/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	GetLoginUserAction,
	IGetLoginUserActionParams,
	GetPreStartupDataAction,
	IGetPreStartupDataActionParams,
	GetStartupDataAction,
	IGetStartupDataActionParams
} from "../../Actions/Api/startupActions";
import {
	IClientProfileParty,
	IPreStartupData,
	IResponse,
	IStartupData
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const getLoginUserEndpoint = "Api/Startup/GetLoginUser";

export function getLoginUser(
	__options__?: IOptions<IResponse<IClientProfileParty>>
) {
	return apiExecute<IGetLoginUserActionParams, GetLoginUserAction, IResponse<IClientProfileParty>>(
		getLoginUserEndpoint, 
		true, 
		__options__, 
		GetLoginUserAction,
		{ /* no parameters */ }
	);
}

export const getPreStartupDataEndpoint = "Api/Startup/GetPreStartupData";

export function getPreStartupData(
	__options__?: IOptions<IResponse<IPreStartupData>>
) {
	return apiExecute<IGetPreStartupDataActionParams, GetPreStartupDataAction, IResponse<IPreStartupData>>(
		getPreStartupDataEndpoint, 
		false, 
		__options__, 
		GetPreStartupDataAction,
		{ /* no parameters */ }
	);
}

export const getStartupDataEndpoint = "Api/Startup/GetStartupData";

export function getStartupData(
	__options__?: IOptions<IResponse<IStartupData>>
) {
	return apiExecute<IGetStartupDataActionParams, GetStartupDataAction, IResponse<IStartupData>>(
		getStartupDataEndpoint, 
		true, 
		__options__, 
		GetStartupDataAction,
		{ /* no parameters */ }
	);
}

/* tslint:enable:max-line-length */