import * as React from "react";
import ITab from "../../../Models/ITab";
import Nav from "./Nav/Nav";
import Panel, { IOwnProps as IPanelOwnProps } from "./Panel/Panel";
import getDisplayName from "../../../Utils/getDisplayName";
import { noop } from "lodash";
import uid from "../../../Utils/uid";
import Watcher, { IOwnProps as IWatcherOwnProps } from "./Watcher/Watcher";

interface IProps {
	selected: number | null;
	tabs: ITab<number>[];
	disabled?: boolean;
	onTabClick(key: number): void;
}

export interface IContext extends IProps {
	uid: string;
}

export const TabsContext = React.createContext<IContext>({
	selected: null,
	tabs: [],
	onTabClick: noop,
	uid: uid()
});

function makeChildContainer<TOtherProps>(Component: React.ComponentType<IContext & TOtherProps>): React.FunctionComponent<TOtherProps> {
	function Container(props: TOtherProps) {
		return (
			<TabsContext.Consumer>
				{contextProps => (
					<Component
						{...contextProps}
						{...props}
					/>
				)}
			</TabsContext.Consumer>
		);
	}

	Container.displayName = `${nameof(Tabs)}Container(${getDisplayName(Component)})}`;

	return Container;
}



export const TabNav = makeChildContainer<React.HTMLAttributes<HTMLElement>>(Nav);
export const TabPanel = makeChildContainer<IPanelOwnProps>(Panel);
export const TabWatcher = makeChildContainer<IWatcherOwnProps>(Watcher);

export default class Tabs extends React.PureComponent<IProps> {
	private _uid = uid();

	render() {
		const { props } = this;

		let { selected } = props;
		if (!props.tabs.some(it => it.key === selected) && props.tabs.length > 0) {
			selected = props.tabs[0].key;
		}

		const context: IContext = {
			selected,
			tabs: props.tabs,
			disabled: props.disabled,
			onTabClick: props.onTabClick,
			uid: this._uid
		};

		const count = React.Children.count(props.children);
		let content;
		switch (count) {
			case 0:
				content = null;
				break;
			case 1:
				content = React.Children.only(props.children) as JSX.Element;
				break;
			default:
				content = (
					<div>
						{props.children}
					</div>
				);
				break;
		}

		return (
			<TabsContext.Provider value={context}>
				{content}
			</TabsContext.Provider>
		)
	}
}
