import { OK } from "../Models/apiConstants";
import * as Dto from "../Models/dto";
import noop from "lodash/noop";

type Callback = () => void;
type StatusMessageMap = { [statusMessage: string]: Callback; };
export type StatusMap = { [status: string]: Callback | StatusMessageMap; };

export default function handleApiResponse(response: Dto.IBaseResponse): void;
export default function handleApiResponse(response: Dto.IBaseResponse, map: StatusMap): void;
export default function handleApiResponse(response: Dto.IBaseResponse, okHandler: Callback): void;
export default function handleApiResponse(response: Dto.IBaseResponse, mapOrOkHandler: StatusMap | Callback = { [OK]: noop }) {
	const map = typeof mapOrOkHandler === "function"
		? { [OK]: mapOrOkHandler }
		: mapOrOkHandler;

	const callbackOrMessageMap = get(response, response.status, map);
	if (typeof callbackOrMessageMap === "function") {
		callbackOrMessageMap();
	} else {
		const callback = get(response, response.statusMessage, callbackOrMessageMap) as Callback;
		callback();
	}
}

function get(response: Dto.IBaseResponse, key: string, map: StatusMap | StatusMessageMap): Callback | StatusMessageMap {
	if (key in map) {
		return map[key];
	}

	if ("*" in map) {
		return map["*"];
	}

	throw createUnexpectedStatusError(response);
}

export function createUnexpectedStatusError(response: Dto.IBaseResponse) {
	return new Error(
		`Unexpected status/message: ${JSON.stringify(response.status)}/${JSON.stringify(response.statusMessage)}`
	);
}
