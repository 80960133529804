import { ActionBase } from "../IAction";
import { Tab } from "../../Reducers/ui/dashboard/settings/affiliatesReducer";
import { IClient } from "../../Reducers/Entities/affiliateReducer";
import * as Dto from "../../Models/dto";

export class SelectAffiliateAction extends ActionBase {
	constructor(public client: IClient) {
		super(nameof(SelectAffiliateAction));
	}
}

export class SelectTabAction extends ActionBase {
	constructor(public tab: Tab) {
		super(nameof(SelectTabAction));
	}
}

export class EditAffiliateAction extends ActionBase {
	constructor(public edit: Dto.IClient, public client: Dto.IClient | null) {
		super(nameof(EditAffiliateAction));
	}
}

let next = 0;
export class AddAffiliateAction extends ActionBase {
	name: string | undefined;
	onDemandId: string | undefined;

	constructor(public adding: boolean, public clientProfile: Dto.IClientProfile, public currentUserId: number) {
		super(nameof(AddAffiliateAction));

		if (__DEV__) {
			const random = (next++ + Math.random().toString().substr(2)).substr(0, 9);
			this.name = `Affiliate ${random}`;
			this.onDemandId = `A${random}`;
		}
	}
}

export class SelectAffiliateManagerAction extends ActionBase {
	constructor(public affiliateManagerId: number) {
		super(nameof(SelectAffiliateManagerAction));
	}
}

export class EditAffiliateManagerAction extends ActionBase {
	constructor(public affiliateManager: Dto.IClientProfilePartyNormalized) {
		super(nameof(EditAffiliateManagerAction));
	}
}