import { OK } from "../Models/apiConstants";
import ApiActionBase from "../Actions/ApiActionBase";
import { AsyncState } from "../Models/IAsync";
import * as Dto from "../Models/dto";

export default function getLoadState<TParams extends { [name: string]: any; }, TAction extends ApiActionBase<TParams, TReponse>, TReponse extends Dto.IBaseResponse>(action: TAction) {
	if (action.state === AsyncState.Resolved && action.response!.status !== OK) {
		return AsyncState.Rejected;
	}

	return action.state;
}