import connect from "../../Utils/connect";
import BusyOverlay from "../Common/BusyOverlay/BusyOverlay";
import connectToCaptcha, { ICaptchaProps } from "../../Utils/connectToCaptcha";
import validatePassword from "../../Utils/validatePassword";
import React from "react";
import ReduxStore from "../../Stores/ReduxStore";
import LoginActionCreators, { changePassword } from "../../ActionCreators/LoginActionCreators";
import Captcha from "../Captcha/Captcha";
import cn from "classnames";
import PasswordValidation from "./PasswordValidation/PasswordValidation";
import AuthPage, { FormGroup } from "../Auth/AuthPage/AuthPage";
import AuthButton from "../Auth/AuthButton/AuthButton";
import AuthHeader from "../Auth/AuthHeader/AuthHeader";

interface IOwnProps { }

interface IConnectedProps {
	password: string;
	confirmPassword: string;
	saving: boolean;
	onChange(password: string, confirmPassword: string): void;
	onSave(): void;
}

interface IProps extends IOwnProps, ICaptchaProps, IConnectedProps { }

function isPopulated(value: string) {
	return value != null && value.length > 0;
}

class SetPassword extends React.PureComponent<IProps> {
	constructor(props: IProps) {
		super(props);
		this._handleSubmit = this._handleSubmit.bind(this);
	}

	render(): React.ReactElement<{}> {
		const { props } = this;

		const validation = validatePassword(props.password, props.confirmPassword);

		return (
			<AuthPage onSubmit={this._handleSubmit}>
				<BusyOverlay message={props.saving ? "Saving..." : null} />
				<AuthHeader>Create Password</AuthHeader>
				<FormGroup label="New Password">
					{id => (
						<input
							id={id}
							type="password"
							className={cn("form-control", { errorInput: !isPopulated(props.password) })}
							maxLength={50}
							value={props.password}
							onChange={this._handlePasswordChange}
							autoComplete="new-password"
						/>
					)}
				</FormGroup>
				<FormGroup label="Confirm New Password">
					{id => (
						<input
							id={id}
							type="password"
							className={cn("form-control", { errorInput: !isPopulated(props.confirmPassword) })}
							maxLength={50}
							value={props.confirmPassword}
							onChange={this._handleConfirmPasswordChange}
							autoComplete="new-password"
						/>
					)}
				</FormGroup>
				<FormGroup>
					<PasswordValidation validation={validation} />
				</FormGroup>
				<FormGroup>
					<Captcha />
				</FormGroup>
				<FormGroup>
					<AuthButton disabled={!validation.valid || !props.captchaValid || props.saving}>
						Create Password
					</AuthButton>
				</FormGroup>
			</AuthPage>
		);
	}

	private _handlePasswordChange = (e: React.FormEvent<HTMLElement>) => this._handleChange(e, password => ({ password }));
	private _handleConfirmPasswordChange = (e: React.FormEvent<HTMLElement>) => this._handleChange(e, confirmPassword => ({ confirmPassword }));

	private _handleChange(event: React.FormEvent<HTMLElement>, getUpdate: (value: string) => { password: string } | { confirmPassword: string }) {
		const { value } = event.currentTarget as HTMLInputElement;
		const newProps = Object.assign({}, this.props, getUpdate(value));
		this.props.onChange(newProps.password, newProps.confirmPassword);
	}

	private _handleSubmit(event: React.FormEvent<HTMLElement>) {
		event.preventDefault();
		this.props.onSave();
	}
}

const ConnectedToCaptcha = connectToCaptcha<IOwnProps & IConnectedProps>(SetPassword);

const onChange = (password: string, confirmPassword: string) => {
	const { auth: { loginUser } } = ReduxStore.getState();
	LoginActionCreators.loginUserUpdated(Object.assign({}, loginUser, {
		password,
		confirmPassword
	}));
};

export default connect<IConnectedProps, IOwnProps>(
	ConnectedToCaptcha,
	state => ({
		password: state.auth.loginUser.password,
		confirmPassword: state.auth.loginUser.confirmPassword,
		saving: state.auth.changingPassword,
		onChange,
		onSave: changePassword
	})
);