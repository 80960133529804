import { getProbateCase } from "../ActionCreators/Api/probateCaseActionCreators";
import { makeFakeJqueryXhr } from "../Utils/apiExecute";
import { IGetProbateCaseActionParams } from "../Actions/Api/probateCaseActions";

const ProbateCaseApi = {
	getProbateCase(params: IGetProbateCaseActionParams) {
		return makeFakeJqueryXhr(getProbateCase(params.caseID, params.acctID, params.claimID));
	}
};

export default ProbateCaseApi;