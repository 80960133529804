enum SettingsSection {
	MySettings,
	CompanyProfile,
	AffiliateManagement,
	RoleManagement,
	UserManagement,
	BillingInformation,
	Terms,
	Ftp,
	Invoices,
	AccessControl,
	PublicApiKeys
}

export default SettingsSection;