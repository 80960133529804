import React from "react";
import cn from "classnames";
import styles from "./Forms.module.scss";
import icon from "../../../../Images/registration/admin-profile-icon.jpg";
import Form from "../../Common/Form/Form";
import FormGroup from "../../Common/FormGroup/FormGroup";
import isEmpty from "../../../Utils/isEmpty";
import LicenseContainer from "../LicenseContainer/LicenseContainer";
import { IProps } from "./Forms";
import { AsyncState } from "../../../Models/IAsync";
import CheckboxInput from "../../Common/CheckboxInput/CheckboxInput";

interface IValid {
	lastNameValid: boolean;
	firstNameValid: boolean;
	titleValid: boolean;
	agreeValid: boolean;
	allValid: boolean;
}

interface IState {
	read: boolean;
}

function validate(props: IProps): IValid {
	const titleValid = !isEmpty(props.model.ltcSignerTitle);
	const firstNameValid = !isEmpty(props.model.ltcSignerFirstName);
	const lastNameValid = !isEmpty(props.model.ltcSignerLastName);
	const agreeValid = !!props.model.ltcDocId;
	const allValid = titleValid && firstNameValid && lastNameValid && agreeValid;

	return {
		allValid,
		agreeValid,
		titleValid,
		firstNameValid,
		lastNameValid
	};
}

export default class LicenseTerms extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			read: !!props.model.ltcDocId
		};
	}

	render() {
		const { props } = this;
		const valid = validate(props);

		return (
			<div className={styles.page}>
				<div className={styles.header}>
					<img src={icon} alt="" height={50} width="auto" />
					<div>
						<div className={styles.title}>
							License terms and conditions
						</div>
						<div className={styles.subtitle}>
							Please use the side scroll bar to read license terms and conditions. You will be asked to electronically
							sign off prior to moving forward with the registration process.
						</div>
					</div>
				</div>

				<div className={styles.fields}>
					<Form
						onSubmit={e => e.preventDefault()}
						horizontal
					>

						<div style={{ maxWidth: "800px" }}>
							<LicenseContainer
								loadState={AsyncState.Resolved}
								onRead={() => this.setState({ read: true })}
								read={this.state.read}
								license={props.data.ltcContent}
								warning="You must read the entire agreement"
							/>
						</div>

						<FormGroup
							label=""
							hasError={!valid.agreeValid}
						>
							{id => (
								<CheckboxInput
									id={id}
									label="I understand the Terms and Conditions and agree to the above."
									value={!!props.model.ltcDocId}
									disabled={!this.state.read}
									onChange={e => props.setValue(e, (v, m) => m.ltcDocId = v ? props.data.ltcDocId : null)}
								/>
							)}
						</FormGroup>

						<FormGroup
							label="Title"
							hasError={!valid.titleValid}
						>
							{id => (
								<input
									type="text"
									className={cn("form-control")}
									id={id}
									maxLength={50}
									disabled={!this.state.read}
									value={props.model.ltcSignerTitle}
									onChange={e => props.setValue(e.target.value, (v, m) => m.ltcSignerTitle = v)}
								/>
							)}
						</FormGroup>

						<FormGroup
							label="First Name"
							hasError={!valid.firstNameValid}
						>
							{id => (
								<input
									type="text"
									className={cn("form-control")}
									id={id}
									maxLength={50}
									disabled={!this.state.read}
									value={props.model.ltcSignerFirstName}
									onChange={e => props.setValue(e.target.value, (v, m) => m.ltcSignerFirstName = v)}
								/>
							)}
						</FormGroup>

						<FormGroup
							label="Last Name"
							hasError={!valid.lastNameValid}
						>
							{id => (
								<input
									type="text"
									className={cn("form-control")}
									id={id}
									maxLength={50}
									disabled={!this.state.read}
									value={props.model.ltcSignerLastName}
									onChange={e => props.setValue(e.target.value, (v, m) => m.ltcSignerLastName = v)}
								/>
							)}
						</FormGroup>

						<div className={styles.actions}>
							<button
								type="button"
								className="btn btn-light"
								onClick={() => props.onBack()}
							>
								Back
							</button>
							<button
								type="button"
								className="btn btn-warning"
								disabled={!(valid.allValid && this.state.read)}
								onClick={() => props.onConfirm()}
							>
								Continue
							</button>
						</div>
					</Form>
				</div>
			</div>
		);
	}
}