/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IBaseResponse,
	IClientProfileMin,
	ICollectionResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IAdminSwitchClientProfileActionParams {
	targetClientProfileID: number;
}

export class AdminSwitchClientProfileAction extends ApiActionBase<IAdminSwitchClientProfileActionParams, IBaseResponse> {
	constructor(details: IDetails<IAdminSwitchClientProfileActionParams, IBaseResponse>) {
		super("Api/AdministrationActions/AdminSwitchClientProfileAction", details);
	}
}

export interface IGetAllClientProfilesActionParams {
	/* no parameters */
}

export class GetAllClientProfilesAction extends ApiActionBase<IGetAllClientProfilesActionParams, ICollectionResponse<IClientProfileMin>> {
	constructor(details: IDetails<IGetAllClientProfilesActionParams, ICollectionResponse<IClientProfileMin>>) {
		super("Api/AdministrationActions/GetAllClientProfilesAction", details);
	}
}

/* tslint:enable:max-line-length */