import React, { forwardRef } from "react";
import { IResource } from "../../../Models/dto";

interface IProps extends Omit<React.HTMLAttributes<HTMLAnchorElement | HTMLSpanElement>, "resource"> {
	resource: IResource;
	children?: React.ReactNode;
}

const ResourceLink = forwardRef<HTMLAnchorElement, IProps>(({ resource, children, ...props }, ref) => {
	if (resource.url == null) {
		return (
			<span {...props}>
				{children}
			</span>
		);
	}

	const isExternal = !resource.url.startsWith("mailto:") && !resource.isDocument;
	return (
		<a
			ref={ref}
			href={resource.url}
			target={isExternal ? "_blank" : undefined}
			rel={isExternal ? "noopener noreferrer" : undefined}
			{...props}
		>
			{children}
		</a>
	);
});

export default ResourceLink;