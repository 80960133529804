import { makeAsync } from "../Models/IAsync";
import { IState } from "../Reducers/rootReducer";
import { createSelector } from "reselect";
import getUsersById from "./getUsersById";
import getCurrentUserId from "./getCurrentUserId";

export const getCurrentUserAsync = createSelector(
	getUsersById,
	getCurrentUserId,
	(state: IState) => state.entities.users.loadingCurrentUser,
	(usersById, currentUserId, loadingCurrentUser) => makeAsync(loadingCurrentUser, usersById[currentUserId!])
);

const getCurrentUser = createSelector(
	getCurrentUserAsync,
	({ payload }) => payload
);

export default getCurrentUser;