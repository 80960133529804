import React from "react";
import { ActionBase } from "../../../../../../Actions/IAction";
import * as Dto from "../../../../../../Models/dto";
import AccountDetailsStore from "../../../../../../Stores/AccountDetailsStore";
import ReduxStore from "../../../../../../Stores/ReduxStore";
import AccountsContext from "../../../../../../Utils/AccountsContext";
import catchAsyncErrors from "../../../../../../Utils/catchAsyncErrors";
import { getContacts, saveContact, getContactHistory } from "../../../../../../ActionCreators/Api/accountActionCreators";
import { cancel as cancelRequest } from "../../../../../../Utils/apiExecute";
import { getCurrentDetails, getName, EMPTY_CONTACT } from "./utils";
import { confirm } from "../../../../../../Components/Common/Confirm/Confirm";
import isEmpty from "../../../../../../Utils/isEmpty";
import { startBusy } from "../../../../../../ActionCreators/AppActionCreators";
import handleApiResponse from "../../../../../../Utils/handleApiResponse";
import handleGenericError from "../../../../../../Utils/handleGenericError";
import Dispatcher from "../../../../../../Dispatcher/Dispatcher";
import { OK, MODIFIED_BY_ANOTHER_USER } from "../../../../../../Models/apiConstants";
import noop from "lodash/noop";
import * as toast from "../../../../../../Utils/toast";

export class EditChangeAction extends ActionBase {
	constructor(public editing: boolean, public edit: Dto.IContact | null) {
		super(nameof(EditChangeAction));
	}
}

async function save(acctId: number, contact: Dto.IContact) {
	const stopBusy = startBusy();
	try {
		const response = await saveContact(acctId, contact);

		handleApiResponse(response, {
			[OK]: noop,
			[MODIFIED_BY_ANOTHER_USER]: () => {
				toast.warning("The contact was modified by another user. The data was refreshed but your changes were lost. Please try again.");
			}
		});

		onEditClose();
	} catch (error) {
		handleGenericError(error);
	} finally {
		stopBusy();
	}
}

export function onLoad() {
	const { account } = AccountDetailsStore.getState();
	const { contacts } = ReduxStore.getState().ui.dashboard.accounts.accountDetails;

	if (
		AccountsContext.isAccount(account)
		&& (contacts.acctId == null || contacts.acctId !== account.acctId)
	) {
		const oldRequestId = contacts.requestId;
		catchAsyncErrors(getContacts(account.acctId));
		cancelRequest(oldRequestId);
	}
}

export async function onDelete(id: number) {
	const { account, contacts } = getCurrentDetails();
	const contact = contacts.find(it => it.id === id);
	const name = contact == null ? null : getName(contact);

	if (
		await confirm(
			<span>
				Are you sure you want to delete {" "}
				{isEmpty(name) ? "this contact" : (
					<strong>
						{name}
					</strong>
				)}
				?
			</span>
		)
	) {
		await save(account!.acctId, {
			...contact!,
			remove: true
		});
	}
}

export function onEditChange(contact: Dto.IContact) {
	Dispatcher.dispatch(new EditChangeAction(true, contact));
}

export function onEditOpen(id: number) {
	const { contacts } = getCurrentDetails();
	const contact = contacts.find(it => it.id === id)!;
	Dispatcher.dispatch(new EditChangeAction(true, contact));
}

export function onEditOpenNew() {
	Dispatcher.dispatch(new EditChangeAction(true, EMPTY_CONTACT));
}

export function onEditClose() {
	const { edit } = ReduxStore.getState().ui.dashboard.accounts.accountDetails.contacts;
	Dispatcher.dispatch(new EditChangeAction(false, edit));
}

export async function onSave() {
	const { account } = getCurrentDetails();
	const { edit } = ReduxStore.getState().ui.dashboard.accounts.accountDetails.contacts;

	await save(account!.acctId, edit!);
}

export async function onLoadHistory() {
	const { account } = AccountDetailsStore.getState();
	const { contacts } = ReduxStore.getState().ui.dashboard.accounts.accountDetails;

	if (AccountsContext.isAccount(account)) {
		const oldRequestId = contacts.historyRequestId;
		catchAsyncErrors(getContactHistory(account.acctId));
		cancelRequest(oldRequestId);
	}
}