import { ActionBase } from "../IAction";
import * as Dto from "../../Models/dto";

export class UpdateUserAction extends ActionBase {
	constructor(public edit: Dto.IClientProfilePartyNormalized) {
		super(nameof(UpdateUserAction));
	}
}

export class AddUserAction extends ActionBase {
	constructor(public addingUser: boolean, public clientProfile: Dto.IClientProfile) { 
		super(nameof(AddUserAction));
	}
}

export class SelectUserAction extends ActionBase {
	constructor(public clientProfilePartyId: number) { 
		super(nameof(SelectUserAction));
	}
}