import ApiActionBase from "../../../../Actions/ApiActionBase";
import IAction from "../../../../Actions/IAction";
import * as Dto from "../../../../Models/dto";
import { GetStartupDataAction } from "../../../../Actions/Api/startupActions";
import { SaveClientProfileAction, UseParentAffiliateModelAction } from "../../../../Actions/Api/clientActions";
import { EditAction, DiscardAction, ClaimPackageReviewAction } from "../../../../Actions/Settings/CompanyProfileActions";
import getLoadState from "../../../../Utils/getLoadState";
import { AsyncState } from "../../../../Models/IAsync";

export interface IState {
	edit: Dto.IClientProfile | null;
	changingBusinessModel: boolean;
	claimPackageReview: boolean;
}

function handleCompanyProfileUpdate<TParams extends { [name: string]: any }, TAction extends ApiActionBase<TParams, TResponse>, TResponse extends Dto.IBaseResponse>(
	previousState: IState,
	action: TAction,
	getClientProfile: (action: TAction) => Dto.IClientProfile
): IState {
	if (getLoadState(action) === AsyncState.Resolved) {
		const edit = getClientProfile(action);
		return Object.assign({}, previousState, {
			edit
		});
	}

	return previousState;
}

function companyProfileReducer(
	previousState: IState = {
		edit: null,
		changingBusinessModel: false,
		claimPackageReview: false
	},
	action: IAction
): IState {
	if (action instanceof GetStartupDataAction) {
		return handleCompanyProfileUpdate(previousState, action, it => it.response!.item.clientProfile);
	}

	if (action instanceof SaveClientProfileAction) {
		return handleCompanyProfileUpdate(previousState, action, it => it.response!.clientProfile);
	}

	if (action instanceof EditAction) {
		return Object.assign({}, previousState, {
			edit: action.edit
		});
	}

	if (action instanceof DiscardAction) {
		return {
			...previousState,
			edit: action.edit
		};
	}

	if (action instanceof UseParentAffiliateModelAction) {
		return {
			...handleCompanyProfileUpdate(previousState, action, it => it.response!.clientProfile),
			changingBusinessModel: action.state === AsyncState.Pending
		};
	}

	if (action instanceof ClaimPackageReviewAction) {
		return {
			...previousState,
			claimPackageReview: action.show
		};
	}

	return previousState;
}

export default companyProfileReducer;