import Dispatcher from "../Dispatcher/Dispatcher";
import WorkFlowStatusDataLoadedAction from "../Actions/Accounts/WorkFlowStatusDataLoadedAction";
import WorkFlowStatusSelectedAction from "../Actions/Accounts/WorkFlowStatusSelectedAction";
import AccountsDisplayChangedAction from "../Actions/Accounts/AccountsDisplayChangedAction";
import AccountSelectedAction from "../Actions/Accounts/AccountSelectedAction";
import AffiliateSelectedAction from "../Actions/Accounts/AffiliateSelectedAction";
import ShowSearchAction from "../Actions/Accounts/ShowSearchAction";
import ColumnSelectedAction from "../Actions/Accounts/ColumnSelectedAction";
import AccountStatusUpdatedAction from "../Actions/Accounts/AccountStatusUpdatedAction";
import AccountsFilteredAction from "../Actions/Accounts/AccountsFilteredAction";
import PageDataChangedAction from "../Actions/Accounts/PageDataChangedAction";
import SearchCriteriaUpdatedAction from "../Actions/Accounts/SearchCriteriaUpdatedAction";
import SearchAccountsAction from "../Actions/Accounts/SearchAccountsAction";
import SortChangedAction from "../Actions/Accounts/SortChangedAction";
import AccountsActionEnum from "../Models/AccountsActionEnum";
import NavigateToAccountAction from "../Actions/Accounts/NavigateToAccountAction";
import AccountStatusSelectedAction from "../Actions/Accounts/AccountStatusSelectedAction";
import * as Dto from "../Models/dto";
import * as AccountsData from "../Models/AccountsData";
import { IWorkflowMeta } from "../Components/Dashboard/Accounts/workflows/workflows";
import IWorkflow from "../Models/IWorflow";
import NavigateToAccountLoadedAction from "../Actions/Accounts/NavigateToAccountLoadedAction";
import AccountsContext from "../Utils/AccountsContext";
import NavigateToAccountImportAction from "../Actions/Accounts/NavigateToAccountImportAction";
import ReviewItemSearchAction from "../Actions/Accounts/ReviewItemSearchAction";
import ReviewItemSearchResponseAction from "../Actions/Accounts/ReviewItemSearchResponseAction";

class AccountActionCreators {

	workFlowStatusDataLoaded(data: Dto.ICollectionResponse<Dto.IAccountWorkflowStatusCount>): void {
		Dispatcher.dispatch(new WorkFlowStatusDataLoadedAction(data));
	}

	workFlowStatusSelected(data: Pick<IWorkflowMeta, "id">): void {
		Dispatcher.dispatch(new WorkFlowStatusSelectedAction(data));
	}

	accountStatusSelected(data: number[]): void {
		Dispatcher.dispatch(new AccountStatusSelectedAction(data));
	}

	accountsDisplayChanged(data: AccountsActionEnum, tabId: number): void {
		Dispatcher.dispatch(new AccountsDisplayChangedAction(data, tabId));
	}

	accountSelected(data: Dto.IAccount[], selectAll: boolean): void {
		Dispatcher.dispatch(new AccountSelectedAction(data, selectAll));
	}

	showSearch(data: boolean): void {
		Dispatcher.dispatch(new ShowSearchAction(data));
	}

	columnSelected(selectedIds: number[], workflow: IWorkflow): void {
		Dispatcher.dispatch(new ColumnSelectedAction(selectedIds, workflow));
	}

	affiliateSelected(data: number[]): void {
		Dispatcher.dispatch(new AffiliateSelectedAction(data));
	}

	accountStatusUpdated() {
		Dispatcher.dispatch(new AccountStatusUpdatedAction());
	}

	refresh() {
		this.accountStatusUpdated();
	}

	accountsFiltered(data: Dto.IAccountsResponse) {
		Dispatcher.dispatch(new AccountsFilteredAction(data));
	}

	pageDataChanged(data: AccountsData.IPageData) {
		Dispatcher.dispatch(new PageDataChangedAction(data));
	}

	searchCriteriaUpdated(data: Dto.IClientProfileUserSearch) {
		Dispatcher.dispatch(new SearchCriteriaUpdatedAction(data));
	}

	searchAccounts(): void {
		Dispatcher.dispatch(new SearchAccountsAction());
	}

	sortChanged(data: Array<AccountsData.ISortInfo>): void {
		Dispatcher.dispatch(new SortChangedAction(data));
	}

	navigateToAccount(account: Dto.IAccount | Dto.IAccountImport): void;
	navigateToAccount(acctId: number): void;
	navigateToAccount(acctIdOrAccount: number | Dto.IAccount | Dto.IAccountImport) {
		let acctId;
		let account: Dto.IAccount | Dto.IAccountImport | null = null;
		if (typeof acctIdOrAccount === "number") {
			acctId = acctIdOrAccount;
		} else {
			account = acctIdOrAccount;
			acctId = account.acctId;
		}

		if (AccountsContext.isAccountImport(account)) {
			Dispatcher.dispatch(new NavigateToAccountImportAction(account));
		} else {
			Dispatcher.dispatch(new NavigateToAccountAction(acctId, account));
		}
	}
}

export function navigateToAccountLoaded(account: Dto.IAccount | Dto.IAccountImport | null) {
	Dispatcher.dispatch(new NavigateToAccountLoadedAction(account));
}

export function reviewItemSearch(workflowId: number, search: Partial<Dto.IClientProfileUserSearch>) {
	Dispatcher.dispatch(new ReviewItemSearchAction(workflowId, search));
}

export function reviewItemSearchResponse(response: Dto.IAccountsResponse) {
	Dispatcher.dispatch(new ReviewItemSearchResponseAction(response));
}

export default new AccountActionCreators();