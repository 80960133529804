import TabLoadedAction from "../../../Actions/TabLoadedAction";
import assign from "../../../Utils/assign";
import PageDataChangedAction from "../../../Actions/Accounts/PageDataChangedAction";
import IgnoreSetupAction from "../../../Actions/Settings/IgnoreSetupAction";
import IAction from "../../../Actions/IAction";
import ActiveTabEnum from "../../../Models/ActiveTabEnum";
import ActiveTabChangedAction from "../../../Actions/ActiveTabChangedAction";
import PageSizeChangedAction from "../../../Actions/Home/PageSizeChangedAction";
import settingsReducer, { IState as ISettingsState } from "./settings";
import combineReducers from "../../../Utils/combineReducers";
import reportsReducer, { IState as IReportsState } from "./reportsReducer";
import accountsReducer, { IState as IAccountsState } from "./accounts";
import homeReducer, { IState as IHomeState } from "./homeReducer";
import * as storage from "../../../Utils/storage";
import { ClaimPackageReviewAction } from '../../../Actions/Settings/CompanyProfileActions';
import ReviewItemSearchAction from "../../../Actions/Accounts/ReviewItemSearchAction";

interface ITabState {
	active: ActiveTabEnum | null;
	loaded: { [tab: number]: true | undefined };
}

export interface IState {
	tabs: ITabState;
	pageSize: number;
	ignoreSetup: boolean;
	home: IHomeState;
	accounts: IAccountsState;
	reports: IReportsState;
	settings: ISettingsState;
}

const TAB_KEY = __DEV__ ? "initialTab_261bb45f" : "";
const initialTab = __DEV__ ? (storage.get<ActiveTabEnum>(TAB_KEY) ?? null) : null;

function tabReducer(
	previousState: ITabState = {
		active: initialTab,
		loaded: {}
	},
	action: IAction
): ITabState {
	if (action instanceof ActiveTabChangedAction) {
		if (__DEV__) {
			storage.set(TAB_KEY, action.activeTabIndex);
		}

		return {
			...previousState,
			active: action.activeTabIndex
		};
	}

	if (action instanceof TabLoadedAction) {
		return assign(previousState, {
			loaded: assign<typeof previousState.loaded, typeof previousState.loaded>(previousState.loaded, {
				[action.tab]: true
			})
		});
	}

	if (action instanceof ClaimPackageReviewAction && action.show && previousState.active !== ActiveTabEnum.Settings) {
		return {
			...previousState,
			active: ActiveTabEnum.Settings
		};
	}

	if (action instanceof ReviewItemSearchAction) {
		return {
			...previousState,
			active: ActiveTabEnum.Accounts
		};
	}

	return previousState;
}

function pageSizeReducer(previousState = 25, action: IAction) {
	if (action instanceof PageSizeChangedAction) {
		return action.pageSize;
	}

	if (action instanceof PageDataChangedAction) {
		return action.data.pageSize;
	}

	return previousState;
}

function ignoreSetupReducer(previousState = false, action: IAction) {
	if (action instanceof IgnoreSetupAction) {
		return true;
	}

	return previousState;
}

export default combineReducers<IState>({
	[nameof<IState>(it => it.tabs)]: tabReducer,
	[nameof<IState>(it => it.pageSize)]: pageSizeReducer,
	[nameof<IState>(it => it.home)]: homeReducer,
	[nameof<IState>(it => it.accounts)]: accountsReducer,
	[nameof<IState>(it => it.reports)]: reportsReducer,
	[nameof<IState>(it => it.settings)]: settingsReducer,
	[nameof<IState>(it => it.ignoreSetup)]: ignoreSetupReducer
});
