import Dispatcher from "../Dispatcher/Dispatcher";
import * as React from "react";
import ReduxStore from "../Stores/ReduxStore";
import { IState } from "../Reducers/rootReducer";
import getDisplayName from "./getDisplayName";
import { AsyncState, combineStates } from "../Models/IAsync";
import moment from "moment";
import shallowEqual from "./shallowEqual";

type DateValue = string | Date | moment.Moment | null | undefined;

interface ILoadState {
	loadState: AsyncState | null;
	invalidAt?: DateValue;
}

interface ILoader<TOwnProps> {
	blocking: boolean;
	condition(state: IState, ownProps: TOwnProps): ILoadState;
	load(state: IState, ownProps: TOwnProps): void;
}

const MAX_TIMEOUT_DELAY = 2 ** 31 - 1; // max int 32

export function createLoader<TOwnProps>(
	condition: (state: IState, ownProps: TOwnProps) => AsyncState | ILoadState | null,
	load: (state: IState, ownProps: TOwnProps) => void,
	blocking = true
): ILoader<TOwnProps> {
	return {
		condition: (state, ownProps) => {
			const result = condition(state, ownProps);
			if (result == null || typeof result === "number") {
				return {
					loadState: result,
					invalidAt: null
				} as ILoadState;
			}

			return result;
		},
		load,
		blocking
	};
}

function enhanceWithLoaders<TLoaderProps, TOwnProps>(
	WrappedComponent: React.ComponentClass<TLoaderProps & TOwnProps>,
	loadStateToProps: ((loadState: AsyncState) => TLoaderProps) | null,
	...loaders: ILoader<TOwnProps>[]
): React.ComponentClass<TOwnProps>;
function enhanceWithLoaders<TLoaderProps, TOwnProps>(
	WrappedComponent: React.StatelessComponent<TLoaderProps & TOwnProps>,
	loadStateToProps: ((loadState: AsyncState) => TLoaderProps) | null,
	...loaders: ILoader<TOwnProps>[]
): React.ComponentClass<TOwnProps>;
function enhanceWithLoaders<TLoaderProps, TOwnProps>(
	WrappedComponent: any,
	loadStateToProps: ((loadState: AsyncState) => TLoaderProps) | null,
	...loaders: ILoader<TOwnProps>[]
): React.ComponentClass<TOwnProps> {
	class EnhancedWithLoadersComponent extends React.PureComponent<TOwnProps, TLoaderProps> {
		static displayName = `WithLoaders(${getDisplayName(WrappedComponent)})`;

		private _updateHandle: number | undefined;
		private _invalidHandle: number | undefined;
		private _dataVersion = 0;

		private _tryUpdate = () => {
			clearTimeout(this._updateHandle);
			if (Dispatcher.isDispatching()) {
				this._updateHandle = setTimeout(() => this._update());
			} else {
				this._update();
			}
		}

		constructor(props: TOwnProps, context: any) {
			super(props, context);

			if (loadStateToProps != null) {
				// get state for initial render but dont execute any loaders yet
				// we will check again after mounting
				this.state = this._evaluate(moment(), this._dataVersion, false).state!;
			}
		}

		componentDidMount() {
			ReduxStore.addListener(this._tryUpdate);
			this._tryUpdate();
		}

		componentDidUpdate() {
			this._tryUpdate();
		}

		componentWillUnmount() {
			ReduxStore.removeListener(this._tryUpdate);
			clearTimeout(this._updateHandle);
			clearTimeout(this._invalidHandle);
		}

		render() {
			return <WrappedComponent {...this.props} {...this.state} />;
		}

		private _update() {
			clearTimeout(this._invalidHandle);

			const now = moment();
			const { state, recheckAt } = this._evaluate(now, ++this._dataVersion, true);

			if (recheckAt != null) {
				const delay = Math.min(Math.max(recheckAt.diff(now), 0), MAX_TIMEOUT_DELAY);
				this._invalidHandle = setTimeout(() => this._update(), delay) as any;
			}

			if (state !== null && !shallowEqual(state, this.state)) {
				this.setState(state);
			}
		}

		private _evaluate(now: moment.Moment, dataVersion: number, executeLoaders: boolean) {
			const appState = ReduxStore.getState();
			const ownProps = this.props;

			let nextInvalidAt: moment.Moment | null = null;
			const loadStates = [];

			for (const loader of loaders) {
				const result = loader.condition(appState, ownProps);
				if (__DEV__ && dataVersion !== this._dataVersion) {
					console.error(`[${__filename}] Data was changed as a result of evaluating a loader condition. Conditions should be side-effect free!`);
				}

				let { loadState } = result;
				const invalidAt = result.invalidAt == null ? null : moment(result.invalidAt);

				if ((loadState === AsyncState.Resolved || loadState === AsyncState.Rejected) && invalidAt != null) {
					if (invalidAt.isAfter(now)) {
						if (nextInvalidAt == null || invalidAt.isBefore(nextInvalidAt)) {
							nextInvalidAt = invalidAt;
						}
					} else {
						loadState = null;
					}
				}

				if (executeLoaders && loadState == null) {
					loader.load(appState, ownProps);

					if (dataVersion !== this._dataVersion) {
						// executing the loader changed our data. abort!
						return {
							state: null,
							recheckAt: null
						};
					}
				}

				if (loader.blocking) {
					loadStates.push(loadState);
				}
			}

			let state = null;
			if (loadStateToProps != null) {
				const combinedLoadState = combineStates(...loadStates);
				state = loadStateToProps(combinedLoadState);
			}

			return {
				state,
				recheckAt: nextInvalidAt
			};
		}
	}

	return EnhancedWithLoadersComponent;
}

export type IAppState = IState;
export { AsyncState } from "../Models/IAsync";
export default enhanceWithLoaders;