import { ActionBase } from "../IAction";
import * as Dto from "../../Models/dto";

export class EditAction extends ActionBase {
	constructor(public edit: Dto.IClientProfile) {
		super(nameof(EditAction));
	}
}

export class DiscardAction extends ActionBase {
	constructor(public edit: Dto.IClientProfile) {
		super(nameof(DiscardAction));
	}
}

export class ClaimPackageReviewAction extends ActionBase {
	constructor(public show: boolean) {
		super(nameof(ClaimPackageReviewAction));
	}
}