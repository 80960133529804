import React from "react";
import classnames from "classnames";
import styles from "./MainContent.module.scss";

interface IProps extends Pick<React.HTMLAttributes<HTMLDivElement>, "id"> {
	className?: ClassValue;
	children?: React.ReactNode;
}

export default function MainContent({ className, children, ...props }: IProps) {
	return (
		<div
			{...props}
			className={classnames(styles.container, className)}
		>
			{children}
		</div>
	);
}