import { registerParent } from "../../Common/ClickOutside/ClickOutside";
import { sameDate } from "../../../Utils/dateUtils";
import withoutProperties from "../../../Utils/withoutProperties";
import React from "react";
import ReactDom from "react-dom";
import DatePickerComponent, { ReactDatePickerProps } from "react-datepicker";
import cn from "classnames";
import styles from "./DatePicker.module.scss";
import moment from "moment";
import isEmpty from "../../../Utils/isEmpty";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import uid from "../../../Utils/uid";
import { ReactComponent as CalendarIcon } from "./calendar.svg";
import SvgIconButton from "../SvgIconButton/SvgIconButton";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";

export interface IProps extends ReactDatePickerProps {
	wrapperClassName?: string;
	//selected?: moment.Moment | null;
	showIcon?: boolean;
	onChange(date: moment.Moment | null): void;
	error?: string | null;
}

interface IState {
	value: string | undefined;
}

const MIN_DATE = moment("1800-01-01");
const PRIMARY_DATE_FORMAT = "MM/DD/YYYY";
const DATE_FORMATS = [PRIMARY_DATE_FORMAT, "M/D/YYYY", "MMDDYYYY"];

function parseValue(value: string | null | undefined) {
	if (isEmpty(value)) {
		return null;
	}

	const date = moment(value!, DATE_FORMATS, true);
	return date.isValid() ? date : null;
}

class DatePicker extends React.Component<IProps, IState> {
	static defaultProps = {
		showYearDropdown: true,
		dateFormatCalendar: "MMMM",
		showIcon: true,
		disabledKeyboardNavigation: true
	};

	private _uid = uid();
	private _datePicker: React.Component<ReactDatePickerProps, React.ComponentState> | null = null;

	constructor(props: IProps, context: any) {
		super(props, context);

		this.state = {
			value: undefined
		};

		this._onCalendarClick = this._onCalendarClick.bind(this);
		this._onChange = this._onChange.bind(this);
		this._onChangeRaw = this._onChangeRaw.bind(this);
		this._onBlur = this._onBlur.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (!sameDate(nextProps.selected, parseValue(this.state.value))) {
			this._applyFormtting(nextProps);
		}
	}

	render() {
		const { props } = this;

		const otherProps: Partial<ReactDatePickerProps> = withoutProperties(props, [
			nameof(props.wrapperClassName),
			nameof(props.showIcon)
		]);
		let datepicker = (
			<DatePickerComponent
				minDate={MIN_DATE}
				{...otherProps}
				ref={(it: any) => this._datePicker = it}
				className={cn("form-control", this.props.className)}
				dateFormat={DATE_FORMATS}
				value={this.state.value}
				onChange={this._onChange}
				onChangeRaw={this._onChangeRaw}
				onBlur={this._onBlur}
				dropdownMode="select"
				popperPlacement="bottom"
				popperModifiers={{
					preventOverflow: {
						enabled: true,
						boundariesElement: "scrollParent",
						padding: 5
					}
				}}
			>
				{/* dummy node inside the calendar since we can't get a ref to it directly */}
				<div
					style={{ display: "none" }}
					ref={element => {
						if (element != null) {
							registerParent(element.closest(".react-datepicker"), () => this._datePicker!);
						}
					}}
				/>
			</DatePickerComponent>
		);
		if (props.showIcon) {
			return (
				<div className={cn(styles.container, this.props.wrapperClassName)}>
					{datepicker}
					{!!props.error && (
						<OverlayTrigger
							placement="top"
							trigger={["hover", "focus"]}
							container={this}
							overlay={(
								<Tooltip id={`tooltip-${this._uid}`}>{props.error}</Tooltip>
							)}
						>
							<button
								type="button"
								className={styles.dateError}
							>
								<i className="fa fa-exclamation-triangle" />
							</button>
						</OverlayTrigger>
					)}
					<SvgIconButton
						className={styles.calendar}
						onClick={this._onCalendarClick}
						icon={<CalendarIcon aria-hidden />}
						disabled={this.props.disabled}
						iconOnly
					>
						<VisuallyHidden>
							Open calendar
						</VisuallyHidden>
					</SvgIconButton>
				</div>
			);
		}

		return datepicker;
	}

	private _onCalendarClick() {
		const element = ReactDom.findDOMNode(this);
		if (element instanceof Element) {
			element.getElementsByTagName("input")[0].focus();
		}
	}

	private _onChange(date: moment.Moment, event: React.ChangeEvent<any>) {
		// only bubble up click events from the calendar, input changes are handled in _onChangeRaw
		if (this.props.onChange && event.type === "click") {
			this.props.onChange(date);
		}
	}

	private _onChangeRaw(event: React.FocusEvent<HTMLInputElement>) {
		this.setState({ value: event.currentTarget.value }, () => {
			// don't trigger onChange until state.value is updated
			// to prevent reformatting due to a stale value in componentWillReceiveProps
			if (this.props.onChange != null) {
				const date = parseValue(this.state.value);
				if (!sameDate(date, this.props.selected)) {
					this.props.onChange(date);
				}
			}
		});

		if (this.props.onChangeRaw != null) {
			this.props.onChangeRaw(event);
		}
	}

	private _applyFormtting(props: IProps) {
		this.setState({
			value: props.selected == null ? "" : props.selected.format(PRIMARY_DATE_FORMAT)
		});
	}

	private _onBlur(_event: React.FocusEvent<HTMLInputElement>) {
		this._applyFormtting(this.props);
	}
}

export default DatePicker;