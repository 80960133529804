import React from "react";
import styles from "./NotificationBar.module.scss";
import getNotifications from "./getNotifications";
import * as Dto from "../../Models/dto";
import connect from "../../Utils/connect";
import { dismissNotification } from "../../ActionCreators/Api/userNotificationActionCreators";

export interface IProps {
	notification: Dto.IUserNotification | null;
}

export class NotificationBar extends React.Component<IProps> {
	render() {
		const { notification } = this.props;

		if (!notification) {
			return (<div></div>);
		}

		return (
			<div className={styles.notification}>
				<div className={styles.notificationContent}>
					<i className="fa fa-exclamation-circle" />
					<span
						dangerouslySetInnerHTML={{ __html: notification.notificationText }}
					/>
				</div>
				<i
					className="fa fa-close"
					onClick={() => dismissNotification(notification.userNotificationId)}
				/>
			</div>
		);
	}
}

export default connect<IProps, {}>(
	NotificationBar,
	(state => ({
		notification: getNotifications(state)
	}))
);