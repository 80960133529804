import Dispatcher from "../Dispatcher/Dispatcher";

export default class BaseStore {
	private listeners: (() => void)[] = [];
	private _changeEmitted = false;

	constructor() {
		Dispatcher.register(() => this._changeEmitted = false);
	}

	addListener(listener: () => void) {
		this.listeners.push(listener);
		return () => this.removeListener(listener);
	}

	removeListener(listener: () => void): void {
		var index: number = this.listeners.indexOf(listener);
		if (index > -1) {
			this.listeners.splice(index, 1);
		}
	}

	wasChangeEmitted() {
		return this._changeEmitted;
	}

	protected emitChange(): void {
		this._changeEmitted = true;
		for (var i: number = 0; i < this.listeners.length; i++) {
			this.listeners[i]();
		}
	}
}