import React from "react";
import { genericError } from "../../../../../Global/language";
import styles from "./TabError.module.scss";

interface IProps {
	key?: string | number;
}

export default class TabError extends React.Component<IProps, {}> {
	render() {
		return (
			<p className={styles.errorMessage}>
				<i className="fa fa-exclamation-triangle" />
				{" " + genericError}
			</p>
		);
	}
}
