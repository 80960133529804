import { ActionBase } from "../IAction";
import * as Dto from "../../Models/dto";

export default class NavigateToAccountAction extends ActionBase {
	constructor(
		public acctId: number,
		public account: Dto.IAccount | null
	) {
		super(nameof(NavigateToAccountAction));
	}
}