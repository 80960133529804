import * as React from "react";
import * as ReactDOM from "react-dom";

interface IProps {
	enabled: boolean;
	onClickOutside(event: MouseEvent | TouchEvent): void;
}

const EVENTS = ["mousedown", "touchstart"];

const virtualParents = new WeakMap<Element, () => React.ReactInstance>();

export function registerParent(instance: React.ReactInstance | null, parent: () => React.ReactInstance) {
	if (instance != null) {
		const element = ReactDOM.findDOMNode(instance) as Element;
		virtualParents.set(element, parent);
	}
}

function getParentElement(element: Element) {
	const getter = virtualParents.get(element);
	if (getter != null) {
		const instance = getter();
		return ReactDOM.findDOMNode(instance) as Element;
	}

	return element.parentElement;
}

function isInside(target: Element, element: Element): boolean {
	if (target === element) {
		return true;
	}

	const parent = getParentElement(target);
	if (parent == null) {
		return false;
	}

	return isInside(parent, element);
}

export default class ClickOutside extends React.PureComponent<IProps> {
	private _listening = false;

	constructor(props: IProps, context: any) {
		super(props, context);
		this._handleClick = this._handleClick.bind(this);
	}

	render() {
		return React.Children.only(this.props.children);
	}

	componentDidMount() {
		this._update();
	}

	componentDidUpdate(prevProps: IProps) {
		if (prevProps.enabled !== this.props.enabled) {
			this._update();
		}
	}

	componentWillUnmount() {
		this._stopListening();
	}

	private _update() {
		if (this._listening === this.props.enabled) {
			return;
		}

		if (this.props.enabled) {
			this._listening = true;
			for (const event of EVENTS) {
				document.addEventListener(event, this._handleClick as any);
			}
		} else {
			this._stopListening();
		}
	}

	private _handleClick(event: MouseEvent | TouchEvent) {
		const element = ReactDOM.findDOMNode(this) as Element;
		if (__DEV__ && element == null) {
			console.error(`[${__filename}] No DOM node associated with the component instance`);
		}

		const target = event.target as Element;

		if (!isInside(target, element)) {
			this.props.onClickOutside(event);
		}
	}

	private _stopListening() {
		if (this._listening) {
			this._listening = false;
			for (const event of EVENTS) {
				document.removeEventListener(event, this._handleClick as any);
			}
		}
	}
}