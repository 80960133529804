import BaseStore from "./BaseStore";
import IAction from "./../Actions/IAction";
import Dispatcher from "../Dispatcher/Dispatcher";
import { StartBusyAction, StopBusyAction, StopAllBusyAction } from "../Actions/BusyAction";

type BusyMessage = {
	id: number;
	message: string;
	showWhenLocked: boolean;
};

class AppStore extends BaseStore {
	private _busyMessages: BusyMessage[] = [];

	constructor() {
		super();
		Dispatcher.register((action: IAction) => this.processActions(action));
	}

	getBusyMessage(locked: boolean) {
		for (const message of this._busyMessages) {
			if (!locked || message.showWhenLocked) {
				return message.message;
			}
		}

		return null;
	}

	private processActions(action: IAction): void {
		if (action instanceof StartBusyAction) {
			this._busyMessages = [...this._busyMessages, {
				id: action.id,
				message: action.message,
				showWhenLocked: action.showWhenLocked
			}];

			this.emitChange();
		}

		if (action instanceof StopBusyAction) {
			this._busyMessages = this._busyMessages.filter(it => it.id !== action.id);
			this.emitChange();
		}

		if (action instanceof StopAllBusyAction) {
			this._busyMessages = [];
			this.emitChange();
		}
	}
}

export default new AppStore();