/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	ICaptchaResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IGetCaptchaChallengeAndImageActionParams {
	/* no parameters */
}

export class GetCaptchaChallengeAndImageAction extends ApiActionBase<IGetCaptchaChallengeAndImageActionParams, ICaptchaResponse> {
	constructor(details: IDetails<IGetCaptchaChallengeAndImageActionParams, ICaptchaResponse>) {
		super("Api/CaptchaActions/GetCaptchaChallengeAndImageAction", details);
	}
}

/* tslint:enable:max-line-length */