import assign from "../../../../Utils/assign";
import * as actions from "../../../../Actions/Settings/MySettingsActions";
import IAction from "../../../../Actions/IAction";
import makeUserUpdateReducer from "../../../Entities/makeUserUpdateReducer";
import reduceReducers from "reduce-reducers";
import * as Dto from "../../../../Models/dto";

export interface IState {
	edit: Dto.IClientProfilePartyNormalized | null;
}

function mySettingsReducer(
	previousState: IState = {
		edit: null
	},
	action: IAction
): IState {
	if (action instanceof actions.UpdateMySettingsAction) {
		return assign(previousState, {
			edit: action.user
		});
	}

	return previousState;
}

const userUpdateReducer = makeUserUpdateReducer<IState>((previousState: IState, updatedUsers: { [clientProfilePartyId: number]: Dto.IClientProfilePartyNormalized }) => {
	if (previousState.edit != null && updatedUsers[previousState.edit.clientProfilePartyId] != null) {
		return assign<IState, Pick<IState, "edit">>(previousState, {
			edit: null
		});
	}

	return previousState;
});

export default reduceReducers<IState, IAction>(
	mySettingsReducer,
	userUpdateReducer
);