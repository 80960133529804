import React from "react";
import getId from "../getId";
import { IContext as IContextProps } from "../Tabs";
import cn from "classnames";

export interface IOwnProps {
	className?: string;
}

interface IProps extends IOwnProps, IContextProps { }

export default function Panel(props: IProps) {
	const { selected, tabs, uid } = props;
	const tab = tabs.find(it => it.key === selected) ?? null;
	const id = getId(selected, uid);

	return (
		<div className={cn("tab-content", props.className)}>
			<div role="tabpanel" className="tab-pane active" id={id}>
				{tab != null && tab.render()}
			</div>
		</div>
	);
}
