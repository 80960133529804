import { startBusy } from "../../ActionCreators/AppActionCreators";
import isEmpty from "../../Utils/isEmpty";
import FormGroup from "../Common/FormGroup/FormGroup";
import Form from "../Common/Form/Form";
import handleApiResponse from "../../Utils/handleApiResponse";
import handleGenericError from "../../Utils/handleGenericError";
import { NULL_DATE_STRING } from "../../Utils/dateUtils";
import { saveFeedback } from "../../ActionCreators/Api/feedbackActionCreators";
import React from "react";
import * as toast from "../../Utils/toast";
import { Modal } from "react-bootstrap";
import DraggableModal from "../Common/DraggableModal/DraggableModal";
import TextareaAutosize from "react-textarea-autosize";
import { appName } from "../../Utils/branding";
import Button from "../Common/Button/Button";
import FileInput from "../Common/FileInput/FileInput";
import { ACCEPT, isValidExtension } from "../../Utils/documentUtils";
import Select from "../Common/Select/Select";
import trackEvent from "../../Utils/trackEvent";
import InfoAlert from "../Dashboard/Settings/InfoAlert/InfoAlert";
import styles from "./SupportForm.module.scss";

interface IProps {
	show: boolean;
	onHide: () => void;
	showResetPasswordOption: boolean;
	clientID: number;
	clientName: string;
}

interface IState {
	subject: string;
	message: string;
	accountID: string;
	file: File | null;
}

const INITIAL_STATE: IState = {
	subject: "",
	message: "",
	accountID: "",
	file: null
};

export default class SupportForm extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = INITIAL_STATE;

		this._handleSubjectUpdate = this._handleSubjectUpdate.bind(this);
		this._handleAccountIDUpdate = this._handleAccountIDUpdate.bind(this);
		this._handleNotesUpdate = this._handleNotesUpdate.bind(this);
		this._saveFeedback = this._saveFeedback.bind(this);
	}

	render(): React.ReactElement<{}> {
		const subjectOptions = [
			"Estate question",
			"Claim question",
			"Update case info",
			"Application question"
		]

		if (this.props.showResetPasswordOption) {
			subjectOptions.push("Reset password");
		}

		if (process.env.REACT_APP_APPLICATION === "pfod") {
			subjectOptions.push("Mismatch review");
		}

		subjectOptions.push("Other");

		if (this.state.subject === "") {
			subjectOptions.unshift("");
		}

		return (
			<DraggableModal
				show={this.props.show}
				onHide={this.props.onHide}
				onExited={() => this.setState(INITIAL_STATE)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Contact Support</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ paddingBottom: '0' }}>
					<Form>
						<FormGroup label="Subject">
							{id => (
								<Select
									id={id}
									value={this.state.subject}
									options={subjectOptions}
									onChange={subject => this.setState({ subject })}
								/>
							)}
						</FormGroup>
						<FormGroup label="Attach File">
							{id => (
								<FileInput
									id={id}
									fileName={this.state.file?.name ?? null}
									onFile={this._handleFile}
									onClear={() => this.setState({ file: null })}
									accept={ACCEPT}
								/>
							)}
						</FormGroup>
						<FormGroup label="Account ID">
							{id => (
								<input
									id={id}
									className="form-control"
									value={this.state.accountID}
									onChange={this._handleAccountIDUpdate}
								/>
							)}
						</FormGroup>
						<FormGroup label="Message">
							{id => (
								<TextareaAutosize
									minRows={3}
									maxRows={20}
									id={id}
									className="form-control"
									rows={4}
									onChange={this._handleNotesUpdate}
									value={this.state.message}
								/>
							)}
						</FormGroup>
					</Form>
				</Modal.Body>
				<Modal.Footer className={styles.footer}>
					{process.env.REACT_APP_APPLICATION === "pfod" && (
						<InfoAlert className={styles.alert}>
							Need to meet? Schedule a call:
							<ul style={{ paddingLeft: '14px', marginBottom: '0' }}>
								<li>
									<a
										href="https://outlook.office365.com/owa/calendar/ProbateFinderOnDemandSupport@dcmservicesllc.onmicrosoft.com/bookings/s/Tr7m3W0SNEOw9nTYkyG4Zg2"
										target="_blank"
										rel="noopener noreferrer"
										className="link"
									>
										Strategy &amp; Best Practices
									</a>
								</li>
								<li>
									<a
										href="https://outlook.office365.com/owa/calendar/ProbateFinderOnDemandSupport@dcmservicesllc.onmicrosoft.com/bookings/s/BWYvzfvN20aLNqyV34xBWQ2"
										target="_blank"
										rel="noopener noreferrer"
										className="link"
									>
										File Build &amp; Updates
									</a>
								</li>
								<li>
									<a
										href="https://outlook.office365.com/owa/calendar/ProbateFinderOnDemandSupport@dcmservicesllc.onmicrosoft.com/bookings/s/rvbGTYhwxUCm1rSyCmvS_w2"
										target="_blank"
										rel="noopener noreferrer"
										className="link"
									>
										Application Feedback &amp; Service Needs
									</a>
								</li>
							</ul>
						</InfoAlert>
					)}
					<div>
						<Button
							onClick={this._saveFeedback}
							primary
						>
							Submit
					</Button>
						<Button
							onClick={this.props.onHide}
						>
							Cancel
					</Button>
					</div>
				</Modal.Footer>
			</DraggableModal >
		);
	}

	private _handleSubjectUpdate(event: React.FormEvent<HTMLInputElement>): void {
		this.setState({ subject: event.currentTarget.value });
	}

	private _handleAccountIDUpdate(event: React.FormEvent<HTMLInputElement>): void {
		this.setState({ accountID: event.currentTarget.value });
	}

	private _handleNotesUpdate(event: React.FormEvent<HTMLTextAreaElement>): void {
		this.setState({ message: event.currentTarget.value });
	}

	private _handleFile = (file: File) => {
		if (isValidExtension(file)) {
			this.setState({ file });
			return;
		}

		toast.error("File extension is invalid");
		if (this.state.file != null) {
			this.setState({ file: null });
		}
	}

	private async _saveFeedback() {
		const { subject, message, file, accountID } = this.state;

		if (
			isEmpty(subject)
			&& isEmpty(message)
			&& file == null
		) {
			toast.error("You must enter a subject or body, or attach a file before contacting support.");
			return;
		}

		trackEvent("contactSupport", { subject });

		const stopBusy = startBusy("Sending...");
		try {
			const data = await saveFeedback({
				accountID,
				appUserId: -1,
				attachedFileContents: file || null as any,
				attachedFileName: file == null ? "" : file.name,
				browserInfo: "",
				feedbackId: -1,
				feedbackStatusId: 10,
				ipaddress: "",
				loginName: "",
				message,
				notes: "",
				receivedTimestamp: NULL_DATE_STRING,
				subject,
				suppressEmail: false,
				writeTimestamp: NULL_DATE_STRING,
				clientID: this.props.clientID,
				clientName: this.props.clientName
			});

			handleApiResponse(data, () => {
				this.props.onHide();
				this.setState({
					subject: "",
					message: ""
				});

				// this file input will be cleared as a result of onHide as 
				// it will be unmounted and it is uncontrolled

				toast.success(`Congratulations! Your message was sent to the ${appName} Team.`);
			});
		} catch (error) {
			handleGenericError(error);
		} finally {
			stopBusy();
		}
	}
}