import { MODIFIED_BY_ANOTHER_USER, OK } from "../../Models/apiConstants";
import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import { GetStartupDataAction } from "../../Actions/Api/startupActions";
import { SaveClientProfileAction } from "../../Actions/Api/clientActions";

export interface IState {
	pricing: Dto.IClientProfilePricing | null;
	loading: AsyncState | null;
	saving: boolean | null;
}

export default function pricingReducer(
	previousState: IState = {
		pricing: null,
		loading: null,
		saving: null
	},
	action: IAction
) {
	if (action instanceof GetStartupDataAction) {
		let loading = action.state;
		if (action.state === AsyncState.Resolved && action.response!.status !== OK) {
			loading = AsyncState.Rejected;
		}

		let { pricing } = previousState;
		if (loading === AsyncState.Resolved) {
			pricing = action.response!.item.cpPricing;
		}

		return Object.assign({}, previousState, {
			loading,
			pricing
		});
	}

	if (action instanceof SaveClientProfileAction) {
		const saving = action.state === AsyncState.Pending;

		let { pricing } = previousState;
		if (action.state === AsyncState.Resolved) {
			switch (action.response!.status) {
				case OK:
				case MODIFIED_BY_ANOTHER_USER:
					pricing = action.response!.pricing;
					break;
				default:
					break;
			}
		}

		return Object.assign({}, previousState, {
			pricing,
			saving
		});
	}

	return previousState;
}