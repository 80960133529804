/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IClientProfileFTP,
	IResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IDeleteClientProfileFTPActionParams {
	/* no parameters */
}

export class DeleteClientProfileFTPAction extends ApiActionBase<IDeleteClientProfileFTPActionParams, IResponse<boolean>> {
	constructor(details: IDetails<IDeleteClientProfileFTPActionParams, IResponse<boolean>>) {
		super("Api/FtpActions/DeleteClientProfileFTPAction", details);
	}
}

export interface IGetClientProfileFTPActionParams {
	/* no parameters */
}

export class GetClientProfileFTPAction extends ApiActionBase<IGetClientProfileFTPActionParams, IResponse<IClientProfileFTP>> {
	constructor(details: IDetails<IGetClientProfileFTPActionParams, IResponse<IClientProfileFTP>>) {
		super("Api/FtpActions/GetClientProfileFTPAction", details);
	}
}

export interface ISaveClientProfileFTPActionParams {
	ftpUser: IClientProfileFTP;
}

export class SaveClientProfileFTPAction extends ApiActionBase<ISaveClientProfileFTPActionParams, IResponse<IClientProfileFTP>> {
	constructor(details: IDetails<ISaveClientProfileFTPActionParams, IResponse<IClientProfileFTP>>) {
		super("Api/FtpActions/SaveClientProfileFTPAction", details);
	}
}

/* tslint:enable:max-line-length */