import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";
import * as Dto from "../../Models/dto";

export default class LoginUserUpdatedAction implements IAction {
	constructor(public loginUser: Dto.ILoginUser) {	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"LoginUserUpdatedAction",
			{
				"data": JSON.stringify(this.loginUser)
			});
	}
}