import * as React from "react";
import cn from "classnames";
import { IContextProps, IColumnSize, connectToFormContext } from "../Form/Form";
import styles from "./FormGroup.module.scss";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";
import useUid from "../../../Utils/useUid";

type ChildFactory = (id: string) => React.ReactNode;

interface IProps extends IContextProps {
	label?: React.ReactNode;
	required?: boolean;
	hasError?: boolean;
	className?: string;
	children?: React.ReactNode | ChildFactory;
}

function getColumnClassNames(value: number | IColumnSize | null | undefined, offset = false) {
	let columnSize = typeof value === "number" ? { xs: value } : value;

	let hasXs = false;
	const sizes: { size: string; value: number; }[] = [];
	if (columnSize != null) {
		for (const size of Object.keys(columnSize) as (keyof IColumnSize)[]) {
			hasXs = hasXs || size === "xs";
			const value = columnSize[size];
			if (value != null) {
				sizes.push({ size, value });
			}
		}
	}

	if (!hasXs) {
		sizes.push({ size: "xs", value: 12 });
	}

	return sizes.map(({ size, value }) => {
		return offset ? `col-${size}-offset-${value}` : `col-${size}-${value}`;
	});
}

const FormGroup = (props: IProps) => {
	const uid = useUid();

	let labelClassNames: string[] = ["control-label"];
	let controlClassNames: string[] = [`${nameof(FormGroup)}__controlGroup`];

	if (props.horizontal) {
		labelClassNames.push(...getColumnClassNames(props.labelColumns));
		controlClassNames.push(...getColumnClassNames(props.controlColumns));
		if (props.label == null) {
			controlClassNames.push(...getColumnClassNames(props.labelColumns, true));
		}
	}

	const id = `control-${uid}`;

	const labelProps: React.HTMLProps<HTMLLabelElement> = {
		className: cn(labelClassNames)
	};
	if (props.label != null && typeof props.children === "function") {
		labelProps.htmlFor = id;
	}

	const label = props.label == null ? null : (
		<label {...labelProps}>
			{props.label && (
				<>
					<span>{props.label}</span>
					{props.required && (
						<>
							<i
								title="Required"
								className={cn("fa fa-asterisk", styles.required)}
								aria-hidden
							/>
							<VisuallyHidden>(required)</VisuallyHidden>
						</>
					)}
				</>
			)}
		</label>
	);

	return (
		<div className={cn("form-group", props.className, styles.container, { "has-error": props.hasError })}>
			{label}
			<div className={cn(controlClassNames)}>
				{typeof props.children === "function" ? (props.children as ChildFactory)(id) : props.children}
			</div>
		</div>
	);
};

export default connectToFormContext<IProps>(FormGroup);