import catchAsyncErrors from "../../Utils/catchAsyncErrors";
import { keepAlive } from "../../ActionCreators/Api/loginActionCreators";
import { lock } from "../../ActionCreators/LoginActionCreators";
import * as React from "react";
import moment from "moment";
import noop from "lodash/noop";
import IdlePopup from "./IdlePopup";

interface IOwnProps { }

interface IConnectedProps {
	onIdle(): void;
	onNotIdle(): void;
}

interface IProps extends IOwnProps, IConnectedProps { }

interface IState {
	showWarning: boolean;
	secondsRemaining: number | null;
}

const IDLE_MINUTES = 14.5;
const WARNING_SECONDS = 60;
const EVENTS = ["click", "mousemove"];

class IdleMonitor extends React.PureComponent<IProps, IState> {
	private _idleAt: moment.Moment | undefined;
	private _handle: number | undefined;
	private _unlisten = noop;
	private _listening = false;

	constructor(props: IProps) {
		super(props);

		this.state = {
			showWarning: false,
			secondsRemaining: null
		};

		this._restart = this._restart.bind(this);
		this._evaluate = this._evaluate.bind(this);
		this._handleNotIdle = this._handleNotIdle.bind(this);
	}

	componentDidMount() {
		this._restart();
	}

	componentWillUnmount() {
		clearTimeout(this._handle);
		this._unlisten();
	}

	render() {
		const { state } = this;

		return (
			<IdlePopup
				show={state.showWarning}
				secondsRemaining={state.secondsRemaining}
				onNotIdle={this._handleNotIdle}
			/>
		);
	}

	private _listen() {
		if (this._listening) {
			return;
		}

		this._listening = true;

		for (const event of EVENTS) {
			document.addEventListener(event, this._restart);
		}

		this._unlisten = () => {
			this._listening = false;

			for (const event of EVENTS) {
				document.removeEventListener(event, this._restart);
			}

			this._unlisten = noop;
		};
	}

	private _restart() {
		// need to keep track of the actual time rather than rely on setTimeout,
		// as setTimeout can be inaccurate, especially when a tab is in the background
		this._idleAt = moment().add(IDLE_MINUTES * 60 * 1000);
		this._evaluate();
	}

	private _evaluate() {
		clearTimeout(this._handle);

		let isIdle = false;
		let showWarning = false;
		let secondsRemaining = null;
		let listen = false;

		const msUntilIdle = this._idleAt!.diff(undefined);
		if (msUntilIdle <= 0) {
			isIdle = true;
		} else if (msUntilIdle <= (WARNING_SECONDS * 1000)) {
			showWarning = true;
			secondsRemaining = Math.ceil(msUntilIdle / 1000);

			const msUntilNextSecond = msUntilIdle - (secondsRemaining * 1000) + 1000;
			this._handle = setTimeout(this._evaluate, msUntilNextSecond) as any;
		} else {
			listen = true;
			const msUntilWarning = msUntilIdle - (WARNING_SECONDS * 1000);
			this._handle = setTimeout(this._evaluate, msUntilWarning) as any;
		}

		if (listen) {
			this._listen();
		} else {
			this._unlisten();
		}

		// call setState before onIdle as onIdle may trigger an unmount
		this.setState({
			showWarning,
			secondsRemaining
		});

		if (isIdle) {
			this.props.onIdle();
		}
	}

	private _handleNotIdle() {
		this.props.onNotIdle();
		this._restart();
	}
}

const onNotIdle = () => {
	catchAsyncErrors(keepAlive());
};

export default class ConnectedIdleMonitor extends React.Component<IOwnProps> {
	render() {
		return (
			<IdleMonitor
				onIdle={lock}
				onNotIdle={onNotIdle}
				{...this.props}
			/>
		);
	}
}