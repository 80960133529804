/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IAccountClaims,
	IChangeAccountClaimStatusResponse,
	IClaim,
	IClaimDispute,
	IClaimDocRequest,
	IClaimReturn,
	ICollectionResponse,
	IResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IChangeAccountClaimStatusActionParams {
	accountClaims: IAccountClaims;
}

export class ChangeAccountClaimStatusAction extends ApiActionBase<IChangeAccountClaimStatusActionParams, IChangeAccountClaimStatusResponse> {
	constructor(details: IDetails<IChangeAccountClaimStatusActionParams, IChangeAccountClaimStatusResponse>) {
		super("Api/ClaimActions/ChangeAccountClaimStatusAction", details);
	}
}

export interface IGetClaimDisputeFromClaimIDActionParams {
	accountID: number;
	claimID: number;
}

export class GetClaimDisputeFromClaimIDAction extends ApiActionBase<IGetClaimDisputeFromClaimIDActionParams, IResponse<IClaimDispute>> {
	constructor(details: IDetails<IGetClaimDisputeFromClaimIDActionParams, IResponse<IClaimDispute>>) {
		super("Api/ClaimActions/GetClaimDisputeFromClaimIDAction", details);
	}
}

export interface IGetClaimDocHistoryActionParams {
	acctIds: number[];
	targetStatusId: number;
}

export class GetClaimDocHistoryAction extends ApiActionBase<IGetClaimDocHistoryActionParams, ICollectionResponse<IClaimDocRequest>> {
	constructor(details: IDetails<IGetClaimDocHistoryActionParams, ICollectionResponse<IClaimDocRequest>>) {
		super("Api/ClaimActions/GetClaimDocHistoryAction", details);
	}
}

export interface IGetClaimIDLinkedAccountsActionParams {
	acctIDs: number[];
}

export class GetClaimIDLinkedAccountsAction extends ApiActionBase<IGetClaimIDLinkedAccountsActionParams, ICollectionResponse<IAccountClaims>> {
	constructor(details: IDetails<IGetClaimIDLinkedAccountsActionParams, ICollectionResponse<IAccountClaims>>) {
		super("Api/ClaimActions/GetClaimIDLinkedAccountsAction", details);
	}
}

export interface IGetClaimIDLinkedAccountsByClaimIdActionParams {
	acctId: number;
	claimId: number;
}

export class GetClaimIDLinkedAccountsByClaimIdAction extends ApiActionBase<IGetClaimIDLinkedAccountsByClaimIdActionParams, ICollectionResponse<IAccountClaims>> {
	constructor(details: IDetails<IGetClaimIDLinkedAccountsByClaimIdActionParams, ICollectionResponse<IAccountClaims>>) {
		super("Api/ClaimActions/GetClaimIDLinkedAccountsByClaimIdAction", details);
	}
}

export interface ISaveClaimActionParams {
	claim: IClaim;
}

export class SaveClaimAction extends ApiActionBase<ISaveClaimActionParams, IResponse<IClaim>> {
	constructor(details: IDetails<ISaveClaimActionParams, IResponse<IClaim>>) {
		super("Api/ClaimActions/SaveClaimAction", details);
	}
}

export interface ISaveClaimDisputeActionParams {
	claimDispute: IClaimDispute;
}

export class SaveClaimDisputeAction extends ApiActionBase<ISaveClaimDisputeActionParams, IResponse<IClaimDispute>> {
	constructor(details: IDetails<ISaveClaimDisputeActionParams, IResponse<IClaimDispute>>) {
		super("Api/ClaimActions/SaveClaimDisputeAction", details);
	}
}

export interface ISaveClaimReturnActionParams {
	claimReturn: IClaimReturn;
}

export class SaveClaimReturnAction extends ApiActionBase<ISaveClaimReturnActionParams, IResponse<IClaimReturn>> {
	constructor(details: IDetails<ISaveClaimReturnActionParams, IResponse<IClaimReturn>>) {
		super("Api/ClaimActions/SaveClaimReturnAction", details);
	}
}

export interface ISaveClaimReturnAndAlertActionParams {
	returnMethod: number;
	claimReturn: IClaimReturn;
}

export class SaveClaimReturnAndAlertAction extends ApiActionBase<ISaveClaimReturnAndAlertActionParams, IResponse<IClaimReturn>> {
	constructor(details: IDetails<ISaveClaimReturnAndAlertActionParams, IResponse<IClaimReturn>>) {
		super("Api/ClaimActions/SaveClaimReturnAndAlertAction", details);
	}
}

/* tslint:enable:max-line-length */