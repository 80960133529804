/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	AdvancedSearchAccountsAction,
	IAdvancedSearchAccountsActionParams,
	DeleteUserSearchAction,
	IDeleteUserSearchActionParams,
	GetAccountByAccountIDAction,
	IGetAccountByAccountIDActionParams,
	GetAccountClosedLinkedAccountsAction,
	IGetAccountClosedLinkedAccountsActionParams,
	GetAccountHistoriesByAccountIDAction,
	IGetAccountHistoriesByAccountIDActionParams,
	GetAccountMatchedApprovalLinkedAccountsAction,
	IGetAccountMatchedApprovalLinkedAccountsActionParams,
	GetAccountMatchedRecallWithLinkedAccountsAction,
	IGetAccountMatchedRecallWithLinkedAccountsActionParams,
	GetAccountMiscAction,
	IGetAccountMiscActionParams,
	GetAccountsByAccountStatusAndWorkflowStatusIDAction,
	IGetAccountsByAccountStatusAndWorkflowStatusIDActionParams,
	GetAccountWorkflowStatusCountsAction,
	IGetAccountWorkflowStatusCountsActionParams,
	GetBankoInfoAction,
	IGetBankoInfoActionParams,
	GetClaimsByAccountIDAction,
	IGetClaimsByAccountIDActionParams,
	GetClaimSharedAccountsAction,
	IGetClaimSharedAccountsActionParams,
	GetClientProfileBillingsAction,
	IGetClientProfileBillingsActionParams,
	GetClientProfileUserSearchesAction,
	IGetClientProfileUserSearchesActionParams,
	GetContactHistoryAction,
	IGetContactHistoryActionParams,
	GetContactsAction,
	IGetContactsActionParams,
	GetLowBalanceAccountsAction,
	IGetLowBalanceAccountsActionParams,
	GetRecentAccountsAction,
	IGetRecentAccountsActionParams,
	RecallAllDODMatchedAccountsAction,
	IRecallAllDODMatchedAccountsActionParams,
	RecallAllUnmatchedAccountsAction,
	IRecallAllUnmatchedAccountsActionParams,
	ResetAccountToOpenActiveAction,
	IResetAccountToOpenActiveActionParams,
	RollbackAccountApprovalAction,
	IRollbackAccountApprovalActionParams,
	SaveAccountAction,
	ISaveAccountActionParams,
	SaveAccountMiscAction,
	ISaveAccountMiscActionParams,
	SaveAccountsAction,
	ISaveAccountsActionParams,
	SaveContactAction,
	ISaveContactActionParams,
	SaveUserSearchAction,
	ISaveUserSearchActionParams,
	TrackRecentAccountAction,
	ITrackRecentAccountActionParams
} from "../../Actions/Api/accountActions";
import {
	IAccount,
	IAccountClaimsResponse,
	IAccountHistory,
	IAccountLowBalance,
	IAccountMatchedRecallWithLinkedAccounts,
	IAccountMisc,
	IAccountsResponse,
	IAccountWorkflowStatusCount,
	IAddRecentAccount,
	IBankruptcyCase,
	IBaseResponse,
	IClientProfileBillingResponse,
	IClientProfileUserSearch,
	ICollectionResponse,
	IContact,
	IContactHistResponse,
	IRecentAccount,
	IResponse,
	IRollbackApprovalResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const advancedSearchAccountsEndpoint = "Api/Account/AdvancedSearchAccounts";

export function advancedSearchAccounts(
	userSearch: IClientProfileUserSearch, 
	startIndex: number, 
	endIndex: number, 
	sortField: string, 
	sortDirection: string, 
	__options__?: IOptions<IAccountsResponse>
) {
	return apiExecute<IAdvancedSearchAccountsActionParams, AdvancedSearchAccountsAction, IAccountsResponse>(
		advancedSearchAccountsEndpoint, 
		true, 
		__options__, 
		AdvancedSearchAccountsAction,
		{
			userSearch,
			startIndex,
			endIndex,
			sortField,
			sortDirection
		}
	);
}

export const deleteUserSearchEndpoint = "Api/Account/DeleteUserSearch";

export function deleteUserSearch(
	userSearch: IClientProfileUserSearch, 
	__options__?: IOptions<IResponse<IClientProfileUserSearch>>
) {
	return apiExecute<IDeleteUserSearchActionParams, DeleteUserSearchAction, IResponse<IClientProfileUserSearch>>(
		deleteUserSearchEndpoint, 
		true, 
		__options__, 
		DeleteUserSearchAction,
		{ userSearch }
	);
}

export const getAccountByAccountIDEndpoint = "Api/Account/GetAccountByAccountID";

export function getAccountByAccountID(
	acctID: number, 
	__options__?: IOptions<IResponse<IAccount>>
) {
	return apiExecute<IGetAccountByAccountIDActionParams, GetAccountByAccountIDAction, IResponse<IAccount>>(
		getAccountByAccountIDEndpoint, 
		true, 
		__options__, 
		GetAccountByAccountIDAction,
		{ acctID }
	);
}

export const getAccountClosedLinkedAccountsEndpoint = "Api/Account/GetAccountClosedLinkedAccounts";

export function getAccountClosedLinkedAccounts(
	acctIDs: number[], 
	__options__?: IOptions<ICollectionResponse<IAccount>>
) {
	return apiExecute<IGetAccountClosedLinkedAccountsActionParams, GetAccountClosedLinkedAccountsAction, ICollectionResponse<IAccount>>(
		getAccountClosedLinkedAccountsEndpoint, 
		true, 
		__options__, 
		GetAccountClosedLinkedAccountsAction,
		{ acctIDs }
	);
}

export const getAccountHistoriesByAccountIDEndpoint = "Api/Account/GetAccountHistoriesByAccountID";

export function getAccountHistoriesByAccountID(
	acctId: number, 
	__options__?: IOptions<ICollectionResponse<IAccountHistory>>
) {
	return apiExecute<IGetAccountHistoriesByAccountIDActionParams, GetAccountHistoriesByAccountIDAction, ICollectionResponse<IAccountHistory>>(
		getAccountHistoriesByAccountIDEndpoint, 
		true, 
		__options__, 
		GetAccountHistoriesByAccountIDAction,
		{ acctId }
	);
}

export const getAccountMatchedApprovalLinkedAccountsEndpoint = "Api/Account/GetAccountMatchedApprovalLinkedAccounts";

export function getAccountMatchedApprovalLinkedAccounts(
	acctIDs: number[], 
	includeOriginalAccounts: boolean, 
	__options__?: IOptions<ICollectionResponse<IAccount>>
) {
	return apiExecute<IGetAccountMatchedApprovalLinkedAccountsActionParams, GetAccountMatchedApprovalLinkedAccountsAction, ICollectionResponse<IAccount>>(
		getAccountMatchedApprovalLinkedAccountsEndpoint, 
		true, 
		__options__, 
		GetAccountMatchedApprovalLinkedAccountsAction,
		{
			acctIDs,
			includeOriginalAccounts
		}
	);
}

export const getAccountMatchedRecallWithLinkedAccountsEndpoint = "Api/Account/GetAccountMatchedRecallWithLinkedAccounts";

export function getAccountMatchedRecallWithLinkedAccounts(
	recallAccountID: number, 
	__options__?: IOptions<IResponse<IAccountMatchedRecallWithLinkedAccounts>>
) {
	return apiExecute<IGetAccountMatchedRecallWithLinkedAccountsActionParams, GetAccountMatchedRecallWithLinkedAccountsAction, IResponse<IAccountMatchedRecallWithLinkedAccounts>>(
		getAccountMatchedRecallWithLinkedAccountsEndpoint, 
		true, 
		__options__, 
		GetAccountMatchedRecallWithLinkedAccountsAction,
		{ recallAccountID }
	);
}

export const getAccountMiscEndpoint = "Api/Account/GetAccountMisc";

export function getAccountMisc(
	acctId: number, 
	__options__?: IOptions<IResponse<IAccountMisc>>
) {
	return apiExecute<IGetAccountMiscActionParams, GetAccountMiscAction, IResponse<IAccountMisc>>(
		getAccountMiscEndpoint, 
		true, 
		__options__, 
		GetAccountMiscAction,
		{ acctId }
	);
}

export const getAccountsByAccountStatusAndWorkflowStatusIDEndpoint = "Api/Account/GetAccountsByAccountStatusAndWorkflowStatusID";

export function getAccountsByAccountStatusAndWorkflowStatusID(
	accountWorkflowStatusID: number, 
	searchClientIDs: number[], 
	startIndex: number, 
	endIndex: number, 
	sortField: string, 
	sortDirection: string, 
	accountStatusIds: number[], 
	__options__?: IOptions<IAccountsResponse>
) {
	return apiExecute<IGetAccountsByAccountStatusAndWorkflowStatusIDActionParams, GetAccountsByAccountStatusAndWorkflowStatusIDAction, IAccountsResponse>(
		getAccountsByAccountStatusAndWorkflowStatusIDEndpoint, 
		true, 
		__options__, 
		GetAccountsByAccountStatusAndWorkflowStatusIDAction,
		{
			accountWorkflowStatusID,
			searchClientIDs,
			startIndex,
			endIndex,
			sortField,
			sortDirection,
			accountStatusIds
		}
	);
}

export const getAccountWorkflowStatusCountsEndpoint = "Api/Account/GetAccountWorkflowStatusCounts";

export function getAccountWorkflowStatusCounts(
	clientIds: number[], 
	__options__?: IOptions<ICollectionResponse<IAccountWorkflowStatusCount>>
) {
	return apiExecute<IGetAccountWorkflowStatusCountsActionParams, GetAccountWorkflowStatusCountsAction, ICollectionResponse<IAccountWorkflowStatusCount>>(
		getAccountWorkflowStatusCountsEndpoint, 
		true, 
		__options__, 
		GetAccountWorkflowStatusCountsAction,
		{ clientIds }
	);
}

export const getBankoInfoEndpoint = "Api/Account/GetBankoInfo";

export function getBankoInfo(
	acctID: number, 
	__options__?: IOptions<IResponse<IBankruptcyCase>>
) {
	return apiExecute<IGetBankoInfoActionParams, GetBankoInfoAction, IResponse<IBankruptcyCase>>(
		getBankoInfoEndpoint, 
		true, 
		__options__, 
		GetBankoInfoAction,
		{ acctID }
	);
}

export const getClaimsByAccountIDEndpoint = "Api/Account/GetClaimsByAccountID";

export function getClaimsByAccountID(
	acctId: number, 
	__options__?: IOptions<IAccountClaimsResponse>
) {
	return apiExecute<IGetClaimsByAccountIDActionParams, GetClaimsByAccountIDAction, IAccountClaimsResponse>(
		getClaimsByAccountIDEndpoint, 
		true, 
		__options__, 
		GetClaimsByAccountIDAction,
		{ acctId }
	);
}

export const getClaimSharedAccountsEndpoint = "Api/Account/GetClaimSharedAccounts";

export function getClaimSharedAccounts(
	acctIds: number[], 
	__options__?: IOptions<ICollectionResponse<IAccount>>
) {
	return apiExecute<IGetClaimSharedAccountsActionParams, GetClaimSharedAccountsAction, ICollectionResponse<IAccount>>(
		getClaimSharedAccountsEndpoint, 
		true, 
		__options__, 
		GetClaimSharedAccountsAction,
		{ acctIds }
	);
}

export const getClientProfileBillingsEndpoint = "Api/Account/GetClientProfileBillings";

export function getClientProfileBillings(
	acctID: number, 
	__options__?: IOptions<IClientProfileBillingResponse>
) {
	return apiExecute<IGetClientProfileBillingsActionParams, GetClientProfileBillingsAction, IClientProfileBillingResponse>(
		getClientProfileBillingsEndpoint, 
		true, 
		__options__, 
		GetClientProfileBillingsAction,
		{ acctID }
	);
}

export const getClientProfileUserSearchesEndpoint = "Api/Account/GetClientProfileUserSearches";

export function getClientProfileUserSearches(
	__options__?: IOptions<ICollectionResponse<IClientProfileUserSearch>>
) {
	return apiExecute<IGetClientProfileUserSearchesActionParams, GetClientProfileUserSearchesAction, ICollectionResponse<IClientProfileUserSearch>>(
		getClientProfileUserSearchesEndpoint, 
		true, 
		__options__, 
		GetClientProfileUserSearchesAction,
		{ /* no parameters */ }
	);
}

export const getContactHistoryEndpoint = "Api/Account/GetContactHistory";

export function getContactHistory(
	acctId: number, 
	__options__?: IOptions<IContactHistResponse>
) {
	return apiExecute<IGetContactHistoryActionParams, GetContactHistoryAction, IContactHistResponse>(
		getContactHistoryEndpoint, 
		true, 
		__options__, 
		GetContactHistoryAction,
		{ acctId }
	);
}

export const getContactsEndpoint = "Api/Account/GetContacts";

export function getContacts(
	acctId: number, 
	__options__?: IOptions<ICollectionResponse<IContact>>
) {
	return apiExecute<IGetContactsActionParams, GetContactsAction, ICollectionResponse<IContact>>(
		getContactsEndpoint, 
		true, 
		__options__, 
		GetContactsAction,
		{ acctId }
	);
}

export const getLowBalanceAccountsEndpoint = "Api/Account/GetLowBalanceAccounts";

export function getLowBalanceAccounts(
	clientID: number, 
	__options__?: IOptions<ICollectionResponse<IAccountLowBalance>>
) {
	return apiExecute<IGetLowBalanceAccountsActionParams, GetLowBalanceAccountsAction, ICollectionResponse<IAccountLowBalance>>(
		getLowBalanceAccountsEndpoint, 
		true, 
		__options__, 
		GetLowBalanceAccountsAction,
		{ clientID }
	);
}

export const getRecentAccountsEndpoint = "Api/Account/GetRecentAccounts";

export function getRecentAccounts(
	__options__?: IOptions<ICollectionResponse<IRecentAccount>>
) {
	return apiExecute<IGetRecentAccountsActionParams, GetRecentAccountsAction, ICollectionResponse<IRecentAccount>>(
		getRecentAccountsEndpoint, 
		true, 
		__options__, 
		GetRecentAccountsAction,
		{ /* no parameters */ }
	);
}

export const recallAllDODMatchedAccountsEndpoint = "Api/Account/RecallAllDODMatchedAccounts";

export function recallAllDODMatchedAccounts(
	expectedCount: number, 
	clientIDsForRecall: number[], 
	__options__?: IOptions<IBaseResponse>
) {
	return apiExecute<IRecallAllDODMatchedAccountsActionParams, RecallAllDODMatchedAccountsAction, IBaseResponse>(
		recallAllDODMatchedAccountsEndpoint, 
		true, 
		__options__, 
		RecallAllDODMatchedAccountsAction,
		{
			expectedCount,
			clientIDsForRecall
		}
	);
}

export const recallAllUnmatchedAccountsEndpoint = "Api/Account/RecallAllUnmatchedAccounts";

export function recallAllUnmatchedAccounts(
	expectedCount: number, 
	clientIDsForRecall: number[], 
	__options__?: IOptions<IBaseResponse>
) {
	return apiExecute<IRecallAllUnmatchedAccountsActionParams, RecallAllUnmatchedAccountsAction, IBaseResponse>(
		recallAllUnmatchedAccountsEndpoint, 
		true, 
		__options__, 
		RecallAllUnmatchedAccountsAction,
		{
			expectedCount,
			clientIDsForRecall
		}
	);
}

export const resetAccountToOpenActiveEndpoint = "Api/Account/ResetAccountToOpenActive";

export function resetAccountToOpenActive(
	acctID: number, 
	accountImportID: number, 
	__options__?: IOptions<IBaseResponse>
) {
	return apiExecute<IResetAccountToOpenActiveActionParams, ResetAccountToOpenActiveAction, IBaseResponse>(
		resetAccountToOpenActiveEndpoint, 
		true, 
		__options__, 
		ResetAccountToOpenActiveAction,
		{
			acctID,
			accountImportID
		}
	);
}

export const rollbackAccountApprovalEndpoint = "Api/Account/RollbackAccountApproval";

export function rollbackAccountApproval(
	acctIds: number[], 
	__options__?: IOptions<IRollbackApprovalResponse>
) {
	return apiExecute<IRollbackAccountApprovalActionParams, RollbackAccountApprovalAction, IRollbackApprovalResponse>(
		rollbackAccountApprovalEndpoint, 
		true, 
		__options__, 
		RollbackAccountApprovalAction,
		{ acctIds }
	);
}

export const saveAccountEndpoint = "Api/Account/SaveAccountPfod";

export function saveAccount(
	account: IAccount, 
	__options__?: IOptions<IResponse<IAccount>>
) {
	return apiExecute<ISaveAccountActionParams, SaveAccountAction, IResponse<IAccount>>(
		saveAccountEndpoint, 
		true, 
		__options__, 
		SaveAccountAction,
		{ account }
	);
}

export const saveAccountMiscEndpoint = "Api/Account/SaveAccountMisc";

export function saveAccountMisc(
	accountMisc: IAccountMisc, 
	__options__?: IOptions<IResponse<IAccountMisc>>
) {
	return apiExecute<ISaveAccountMiscActionParams, SaveAccountMiscAction, IResponse<IAccountMisc>>(
		saveAccountMiscEndpoint, 
		true, 
		__options__, 
		SaveAccountMiscAction,
		{ accountMisc }
	);
}

export const saveAccountsEndpoint = "Api/Account/SaveAccounts";

export function saveAccounts(
	accounts: IAccount[], 
	__options__?: IOptions<IBaseResponse>
) {
	return apiExecute<ISaveAccountsActionParams, SaveAccountsAction, IBaseResponse>(
		saveAccountsEndpoint, 
		true, 
		__options__, 
		SaveAccountsAction,
		{ accounts }
	);
}

export const saveContactEndpoint = "Api/Account/SaveContact";

export function saveContact(
	acctId: number, 
	contact: IContact, 
	__options__?: IOptions<ICollectionResponse<IContact>>
) {
	return apiExecute<ISaveContactActionParams, SaveContactAction, ICollectionResponse<IContact>>(
		saveContactEndpoint, 
		true, 
		__options__, 
		SaveContactAction,
		{
			acctId,
			contact
		}
	);
}

export const saveUserSearchEndpoint = "Api/Account/SaveUserSearch";

export function saveUserSearch(
	userSearch: IClientProfileUserSearch, 
	__options__?: IOptions<IResponse<IClientProfileUserSearch>>
) {
	return apiExecute<ISaveUserSearchActionParams, SaveUserSearchAction, IResponse<IClientProfileUserSearch>>(
		saveUserSearchEndpoint, 
		true, 
		__options__, 
		SaveUserSearchAction,
		{ userSearch }
	);
}

export const trackRecentAccountEndpoint = "Api/Account/TrackRecentAccount";

export function trackRecentAccount(
	recentAccounts: IAddRecentAccount[], 
	__options__?: IOptions<ICollectionResponse<IRecentAccount>>
) {
	return apiExecute<ITrackRecentAccountActionParams, TrackRecentAccountAction, ICollectionResponse<IRecentAccount>>(
		trackRecentAccountEndpoint, 
		true, 
		__options__, 
		TrackRecentAccountAction,
		{ recentAccounts }
	);
}

/* tslint:enable:max-line-length */