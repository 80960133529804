import ActiveTabEnum from "../../../Models/ActiveTabEnum";
import { tabLoaded } from "../../../ActionCreators/AppActionCreators";
import createModuleLoader from "../../../Utils/createModuleLoader";
import _Reporting from "./Reporting";

const { loader, load } = createModuleLoader<typeof _Reporting>();

if (process.env.REACT_APP_APPLICATION === "pfod") {
	(async () => {
		const reportingModule = await import("./Reporting" /* webpackChunkName: "reports" */);
		load(reportingModule.default);
		tabLoaded(ActiveTabEnum.Reporting);
	})();
}

export default loader;
