import { Component } from "react";
import moment from "moment";

interface IProps<T> {
	value: T | null;
	onEnter(value: T): void;
	onExit(value: T, elapsedMs: number | null): void;
	isEqual(a: T, b: T): boolean;
}

export default class ChangeWatcher<T> extends Component<IProps<T>> {
	private _enterTime: moment.Moment | null = null;

	render() {
		return this.props.children ?? null;
	}

	private _isEqual(oldValue: T | null, newValue: T | null) {
		if (oldValue == null || newValue == null) {
			return oldValue === newValue;
		}

		return this.props.isEqual(oldValue, newValue);
	}

	private _checkForChanges(oldValue: T | null, newValue: T | null) {
		if (this._isEqual(oldValue, newValue)) {
			return false;
		}

		const now = moment();

		if (oldValue != null) {
			this.props.onExit(oldValue, this._enterTime == null ? null : now.diff(this._enterTime));
			this._enterTime = null;
		}

		if (newValue != null) {
			this.props.onEnter(newValue);
			this._enterTime = now;
		}
	}

	componentDidMount() {
		this._checkForChanges(null, this.props.value);
	}

	componentDidUpdate(prevProps: IProps<T>) {
		this._checkForChanges(prevProps.value, this.props.value);
	}

	componentWillUnmount() {
		this._checkForChanges(this.props.value, null);
	}
}