import React, { createElement } from "react";
import styles from "./VisuallyHidden.module.scss";

interface IProps {
	tagName?: string;
	children: React.ReactNode;
}

export default function VisuallyHidden(props: IProps) {
	return createElement(
		props.tagName ?? "span",
		{ className: styles.visuallyHidden },
		props.children
	);
}