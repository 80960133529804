import isCurrentTabLoaded from "../../selectors/isCurrentTabLoaded";
import getAvailableTabs from "../../selectors/getAvailableTabs";
import getCurrentTab from "../../selectors/getCurrentTab";
import getCurrentPermissions from "../../selectors/getCurrentPermissions";
import { IPermissions } from "../../Models/Permissions";
import UserAgreement from "./LegacyHome/UserAgreement/UserAgreement";
import React, { Component, lazy, Suspense } from "react";
import homeLoader from "./Home/moduleLoader";
import accountsLoader from "./Accounts/moduleLoader";
import reportingLoader from "./Reporting/moduleLoader";
import { IConnectedProps as ISettingsProps } from "./Settings/Settings";
import settingsLoader, { IModule as ISettingsModule } from "./Settings/moduleLoader";
import AppActionCreators from "../../ActionCreators/AppActionCreators";
import Tabs, { TabPanel, TabWatcher } from "../Common/Tabs/Tabs";
import ITab from "../../Models/ITab";
import ActiveTabEnum from "../../Models/ActiveTabEnum";
import connect from "../../Utils/connect";
import IdleMonitor from "./IdleMonitor";
import noop from "lodash/noop";
import accessReportsLoader from "./AccessReports/moduleLoader";
import * as storage from "../../Utils/storage";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import styles from "./Dashboard.module.scss";
import enhanceWithLoaders, { createLoader } from "../../Utils/enhanceWithLoaders";
import { getStartupData } from "../../ActionCreators/Api/startupActionCreators";
import queryString from "query-string";
import trackEvent from "../../Utils/trackEvent";
import getProductId from "Application/selectors/getProductId";
import ClientProfileProducts from "Application/Models/ClientProfileProducts";

const SettingsWizard = lazy(() => import("./Settings/SettingsWizard/SettingsWizard"));

interface IOwnProps { }

interface IConnectedProps {
	tab: ActiveTabEnum;
	product: ClientProfileProducts | null;
	tabs: ActiveTabEnum[];
	permissions: IPermissions;
	settings: ISettingsProps | null;
}

interface IProps extends IOwnProps, IConnectedProps { }

interface IState {
	HomeTab: typeof homeLoader.module | null;
	AccountsTab: typeof accountsLoader.module | null;
	ReportingTab: typeof reportingLoader.module | null;
	SettingsTab: ISettingsModule["default"] | null;
	AccessReports: typeof accessReportsLoader.module | null;
}

class Dashboard extends Component<IProps, IState> {
	private _cancelHomeLoad = noop;
	private _cancelAccountsLoad = noop;
	private _cancelReportingLoad = noop;
	private _cancelSettingsLoad = noop;
	private _cancelAccessReportsLoad = noop;

	constructor(props: IProps) {
		super(props);

		this.state = {
			HomeTab: process.env.REACT_APP_HOME_TAB === "home" && homeLoader.loaded ? homeLoader.module : null,
			AccountsTab: accountsLoader.loaded ? accountsLoader.module : null,
			ReportingTab: process.env.REACT_APP_APPLICATION === "pfod" && reportingLoader.loaded ? reportingLoader.module : null,
			SettingsTab: settingsLoader.loaded ? settingsLoader.module!.default : null,
			AccessReports: process.env.REACT_APP_APPLICATION === "access" && accessReportsLoader.loaded ? accessReportsLoader.module : null
		};

		this._onTabChange = this._onTabChange.bind(this);
	}

	componentDidMount() {
		if (
			process.env.REACT_APP_HOME_TAB === "home"
			&& !homeLoader.loaded
			&& (process.env.REACT_APP_APPLICATION !== "access" || this.props.permissions.dashboardAccess)
		) {
			this._cancelHomeLoad = homeLoader.onLoad(() => {
				this.setState({ HomeTab: homeLoader.module });
			});
		}

		if (!accountsLoader.loaded) {
			this._cancelAccountsLoad = accountsLoader.onLoad(() => {
				this.setState({ AccountsTab: accountsLoader.module });
			});
		}

		if (
			process.env.REACT_APP_APPLICATION === "pfod"
			&& !reportingLoader.loaded
			&& this.props.permissions.reporting
		) {
			this._cancelReportingLoad = reportingLoader.onLoad(() => {
				this.setState({ ReportingTab: reportingLoader.module });
			});
		}

		if (!settingsLoader.loaded) {
			this._cancelSettingsLoad = settingsLoader.onLoad(() => {
				this.setState({ SettingsTab: settingsLoader.module!.default });
			});
		}

		if (process.env.REACT_APP_APPLICATION === "access") {
			if (!accessReportsLoader.loaded) {
				this._cancelAccessReportsLoad = accessReportsLoader.onLoad(() => {
					this.setState({ AccessReports: accessReportsLoader.module });
				});
			}

			this._accessAdminCheck();
		}
	}

	componentDidUpdate() {
		if (process.env.REACT_APP_APPLICATION === "access") {
			this._accessAdminCheck();
		}
	}

	componentWillUnmount() {
		this._cancelHomeLoad();
		this._cancelAccountsLoad();
		this._cancelReportingLoad();
		this._cancelSettingsLoad();
		this._cancelAccessReportsLoad();
	}

	private _needsAccessAdminCheck = process.env.REACT_APP_APPLICATION === "access"
		&& "login" in queryString.parse(window.location.search)
		&& storage.get<boolean>("PF_Admin_Login_580fe3bc") == null;

	private _accessAdminCheck() {
		if (process.env.REACT_APP_APPLICATION === "access") {
			if (this._needsAccessAdminCheck) {
				if (this.props.permissions.applicationAdministrator) {
					storage.set("PF_Admin_Login_580fe3bc", true);
					this._needsAccessAdminCheck = false;
				}
			}
		}
	}

	render(): React.ReactElement<{}> {
		const { props } = this;

		return (
			<>
				<IdleMonitor />
				<UserAgreement />
				{process.env.REACT_APP_APPLICATION === "pfod" && (
					<Suspense fallback={null}>
						<SettingsWizard />
					</Suspense>
				)}

				<Tabs
					selected={props.tab}
					tabs={this._getTabs()}
					onTabClick={this._onTabChange}
				>
					<>
						<Header />
						<TabPanel
							className={styles.tabPanel}
						/>
						<Footer />
						<TabWatcher
							onTabEnter={tab => trackEvent("tabEnter", {
								id: tab.key,
								name: ActiveTabEnum[tab.key]
							})}
							onTabExit={(tab, elapsedMs) => trackEvent("tabExit", {
								id: tab.key,
								name: ActiveTabEnum[tab.key],
								elapsedMs
							})}
						/>
					</>
				</Tabs>
			</>
		);
	}

	private async _onTabChange(tab: ActiveTabEnum) {
		if (
			this.props.tab === ActiveTabEnum.Settings
			&& !await settingsLoader.module!.discardCheck(this.props.settings!)
		) {
			return;
		}

		switch (tab) {
			case ActiveTabEnum.Home:
				await homeLoader.waitFor();
				break;
			case ActiveTabEnum.Accounts:
				await accountsLoader.waitFor();
				break;
			case ActiveTabEnum.Reporting:
				await reportingLoader.waitFor();
				break;
			case ActiveTabEnum.Settings:
				await settingsLoader.waitFor();
				break;
			case ActiveTabEnum.AccessReports:
				await accessReportsLoader.waitFor();
				break;
			default:
				if (__DEV__) {
					console.error(`Unexpected tab: ${JSON.stringify(tab)}`);
				}
				break;
		}

		AppActionCreators.activeTabChanged(tab);
	}

	private _getTabs() {
		const { HomeTab, AccountsTab, ReportingTab, SettingsTab, AccessReports } = this.state;

		return this.props.tabs.map<ITab<ActiveTabEnum>>(key => {
			switch (key) {
				case ActiveTabEnum.Home:
					return {
						key,
						label: "Home",
						render: () => process.env.REACT_APP_HOME_TAB === "home" && HomeTab && <HomeTab />
					}
				case ActiveTabEnum.Accounts:
					return {
						key,
						label: "Accounts",
						render: () => (
							AccountsTab != null && <AccountsTab permissions={this.props.permissions} />
						)
					};
				case ActiveTabEnum.Reporting:
					return {
						key,
						label: "Reporting",
						render: () => process.env.REACT_APP_APPLICATION === "pfod" && ReportingTab != null && <ReportingTab />
					};
				case ActiveTabEnum.Settings:
					return {
						key,
						label: "Settings",
						render: () => SettingsTab != null && (
							<SettingsTab
								permissions={this.props.permissions}
								{...this.props.settings!}
							/>
						)
					};
				case ActiveTabEnum.AccessReports:
					return {
						key,
						label: "Reports",
						render: () => process.env.REACT_APP_APPLICATION === "access" && AccessReports != null && (
							<AccessReports product={this.props.product}/>
						)
					};
				default:
					throw new Error(`[${__filename}] Unexpected tab: ${JSON.stringify(key)}`);
			}
		});
	}
}

const WithLoaders = enhanceWithLoaders<{}, IProps>(
	Dashboard,
	null,
	createLoader(it => it.entities.startup.loading, () => getStartupData())
);

export default connect<IConnectedProps, IOwnProps>(
	WithLoaders,
	(state, _ownProps): IConnectedProps => {
		const tab = getCurrentTab(state);

		return {
			tab,
			product: getProductId(state),
			tabs: getAvailableTabs(state),
			permissions: getCurrentPermissions(state),
			settings: tab === ActiveTabEnum.Settings && isCurrentTabLoaded(state)
				? settingsLoader.module!.getConnectedProps(state)
				: null
		};
	}
);