import { MODIFIED_BY_ANOTHER_USER, OK } from "../../Models/apiConstants";
import ApiActionBase from "../../Actions/ApiActionBase";
import IAction from "../../Actions/IAction";
import { normalize } from "../../Utils/clientProfilePartyUtils";
import {
	GetAllUsersAction,
	GetAllUsersForAffiliateManagerAction,
	SaveClientProfilePartyAction,
	SaveMyClientProfilePartyAction,
	DeleteClientProfilePartyAction,
	SaveNewAffiliateAction
} from "../../Actions/Api/clientActions";
import { GetLoginUserAction } from "../../Actions/Api/startupActions";
import keyBy from "lodash/keyBy";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";

type UserMap = { [clientProfilePartyId: number]: Dto.IClientProfilePartyNormalized };
type UpdateCallback<TState> = (previousState: TState, updatedUsers: UserMap, deletedUserId: number | null, rawUsers: Dto.IClientProfileParty[]) => TState;

function handleUserLoad<TState, TParams, TAction extends ApiActionBase<TParams, TResponse>, TResponse extends Dto.IBaseResponse>(
	previousState: TState,
	action: TAction,
	getUsers: (action: TAction) => Dto.IClientProfileParty[],
	onUserUpdate: UpdateCallback<TState>
): TState {
	if (action.state === AsyncState.Resolved && (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)) {
		const rawUsers = getUsers(action);
		const updatedUsers = keyBy(
			rawUsers.map(normalize),
			it => it!.clientProfilePartyId
		);

		return onUserUpdate(previousState, updatedUsers as UserMap, null, rawUsers);
	}

	return previousState;
}

export default function makeUsersReducer<TState>(
	onUserUpdate: UpdateCallback<TState>
) {
	return function usersReducer(previousState: TState, action: IAction) {
		if (action instanceof GetLoginUserAction) {
			return handleUserLoad(previousState!, action, it => [it.response!.item], onUserUpdate);
		}

		if (
			action instanceof GetAllUsersAction ||
			action instanceof GetAllUsersForAffiliateManagerAction
		) {
			return handleUserLoad(previousState!, action, it => it.response!.items, onUserUpdate);
		}

		if (
			action instanceof SaveClientProfilePartyAction ||
			action instanceof SaveMyClientProfilePartyAction
		) {
			return handleUserLoad(previousState!, action, it => [it.response!.item], onUserUpdate);
		}

		if (action instanceof DeleteClientProfilePartyAction) {
			if (action.state === AsyncState.Resolved && action.response!.status === OK) {
				return onUserUpdate(previousState!, {}, action.response!.item.clientProfilePartyId, []);
			}

			return handleUserLoad(previousState!, action, it => [it.response!.item], onUserUpdate);
		}

		if (action instanceof SaveNewAffiliateAction) {
			return handleUserLoad(previousState!, action, it => [it.response!.affiliateManager], onUserUpdate);
		}

		return previousState;
	};
}
