import * as React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import queryString from "query-string";
import "./App.scss";
import "font-awesome/scss/font-awesome.scss";

const query = queryString.parse(window.location.search);

const register = {
	isRegister: !!query.register,
	registrationId: query.register
};

ReactDOM.render(
	<App
		register={register}
		location={{
			query: query
		}}
	/>,
	document.getElementById("root")
);
