/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	AffiliateReconAction,
	IAffiliateReconActionParams,
	DeleteAffiliateAction,
	IDeleteAffiliateActionParams,
	DeleteClientProfilePartyAction,
	IDeleteClientProfilePartyActionParams,
	DeleteClientProfileRoleAction,
	IDeleteClientProfileRoleActionParams,
	GetAllUsersAction,
	IGetAllUsersActionParams,
	GetAllUsersForAffiliateManagerAction,
	IGetAllUsersForAffiliateManagerActionParams,
	GetApplicationRolesAction,
	IGetApplicationRolesActionParams,
	GetBedFundPolicyForClientIDAction,
	IGetBedFundPolicyForClientIDActionParams,
	GetClaimBasisOptionsAction,
	IGetClaimBasisOptionsActionParams,
	GetClientAction,
	IGetClientActionParams,
	GetClientProfileAction,
	IGetClientProfileActionParams,
	GetClientProfileIPWhiteListAction,
	IGetClientProfileIPWhiteListActionParams,
	GetClientProfilePricingHistoriesAction,
	IGetClientProfilePricingHistoriesActionParams,
	GetClientProfileRolesAction,
	IGetClientProfileRolesActionParams,
	GetClientProfileStatementEmailAddressesAction,
	IGetClientProfileStatementEmailAddressesActionParams,
	GetClientRegistrationDataAction,
	IGetClientRegistrationDataActionParams,
	ResetPasswordAction,
	IResetPasswordActionParams,
	SaveAffiliateAction,
	ISaveAffiliateActionParams,
	SaveClientProfileAction,
	ISaveClientProfileActionParams,
	SaveClientProfileIPWhiteListAction,
	ISaveClientProfileIPWhiteListActionParams,
	SaveClientProfilePartyAction,
	ISaveClientProfilePartyActionParams,
	SaveClientProfileRoleAction,
	ISaveClientProfileRoleActionParams,
	SaveClientProfileStatementEmailAddressesAction,
	ISaveClientProfileStatementEmailAddressesActionParams,
	SaveMyClientProfilePartyAction,
	ISaveMyClientProfilePartyActionParams,
	SaveNewAffiliateAction,
	ISaveNewAffiliateActionParams,
	UseParentAffiliateModelAction,
	IUseParentAffiliateModelActionParams
} from "../../Actions/Api/clientActions";
import {
	IAffiliateReconResult,
	IApplicationRole,
	IBedFundPolicy,
	IClaimBasisDesc,
	IClient,
	IClientProfile,
	IClientProfileAndClientResponse,
	IClientProfileIPWhiteList,
	IClientProfileParty,
	IClientProfilePricingHistoriesResponse,
	IClientProfileRole,
	IClientProfileStatementEmail,
	IClientRegistrationDataResponse,
	ICollectionResponse,
	INewAffiliateResponse,
	IResponse,
	ISaveClientProfileResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const affiliateReconEndpoint = "Api/Client/AffiliateRecon";

export function affiliateRecon(
	data: string | Blob, 
	fileName: string, 
	__options__?: IOptions<IResponse<IAffiliateReconResult>>
) {
	return apiExecute<IAffiliateReconActionParams, AffiliateReconAction, IResponse<IAffiliateReconResult>>(
		affiliateReconEndpoint, 
		true, 
		__options__, 
		AffiliateReconAction,
		{
			data,
			fileName
		}
	);
}

export const deleteAffiliateEndpoint = "Api/Client/DeleteAffiliate";

export function deleteAffiliate(
	clientID: number, 
	__options__?: IOptions<IResponse<boolean>>
) {
	return apiExecute<IDeleteAffiliateActionParams, DeleteAffiliateAction, IResponse<boolean>>(
		deleteAffiliateEndpoint, 
		true, 
		__options__, 
		DeleteAffiliateAction,
		{ clientID }
	);
}

export const deleteClientProfilePartyEndpoint = "Api/Client/DeleteClientProfileParty";

export function deleteClientProfileParty(
	clientProfileParty: IClientProfileParty, 
	__options__?: IOptions<IResponse<IClientProfileParty>>
) {
	return apiExecute<IDeleteClientProfilePartyActionParams, DeleteClientProfilePartyAction, IResponse<IClientProfileParty>>(
		deleteClientProfilePartyEndpoint, 
		true, 
		__options__, 
		DeleteClientProfilePartyAction,
		{ clientProfileParty }
	);
}

export const deleteClientProfileRoleEndpoint = "Api/Client/DeleteClientProfileRole";

export function deleteClientProfileRole(
	clientProfileRole: IClientProfileRole, 
	__options__?: IOptions<IResponse<IClientProfileRole>>
) {
	return apiExecute<IDeleteClientProfileRoleActionParams, DeleteClientProfileRoleAction, IResponse<IClientProfileRole>>(
		deleteClientProfileRoleEndpoint, 
		true, 
		__options__, 
		DeleteClientProfileRoleAction,
		{ clientProfileRole }
	);
}

export const getAllUsersEndpoint = "Api/Client/GetAllUsers";

export function getAllUsers(
	__options__?: IOptions<ICollectionResponse<IClientProfileParty>>
) {
	return apiExecute<IGetAllUsersActionParams, GetAllUsersAction, ICollectionResponse<IClientProfileParty>>(
		getAllUsersEndpoint, 
		true, 
		__options__, 
		GetAllUsersAction,
		{ /* no parameters */ }
	);
}

export const getAllUsersForAffiliateManagerEndpoint = "Api/Client/GetAllUsersForAffiliateManager";

export function getAllUsersForAffiliateManager(
	__options__?: IOptions<ICollectionResponse<IClientProfileParty>>
) {
	return apiExecute<IGetAllUsersForAffiliateManagerActionParams, GetAllUsersForAffiliateManagerAction, ICollectionResponse<IClientProfileParty>>(
		getAllUsersForAffiliateManagerEndpoint, 
		true, 
		__options__, 
		GetAllUsersForAffiliateManagerAction,
		{ /* no parameters */ }
	);
}

export const getApplicationRolesEndpoint = "Api/Client/GetApplicationRoles";

export function getApplicationRoles(
	__options__?: IOptions<ICollectionResponse<IApplicationRole>>
) {
	return apiExecute<IGetApplicationRolesActionParams, GetApplicationRolesAction, ICollectionResponse<IApplicationRole>>(
		getApplicationRolesEndpoint, 
		true, 
		__options__, 
		GetApplicationRolesAction,
		{ /* no parameters */ }
	);
}

export const getBedFundPolicyForClientIDEndpoint = "Api/Client/GetBedFundPolicyForClientID";

export function getBedFundPolicyForClientID(
	clientID: number, 
	__options__?: IOptions<IResponse<IBedFundPolicy>>
) {
	return apiExecute<IGetBedFundPolicyForClientIDActionParams, GetBedFundPolicyForClientIDAction, IResponse<IBedFundPolicy>>(
		getBedFundPolicyForClientIDEndpoint, 
		true, 
		__options__, 
		GetBedFundPolicyForClientIDAction,
		{ clientID }
	);
}

export const getClaimBasisOptionsEndpoint = "Api/Client/GetClaimBasisOptions";

export function getClaimBasisOptions(
	__options__?: IOptions<ICollectionResponse<IClaimBasisDesc>>
) {
	return apiExecute<IGetClaimBasisOptionsActionParams, GetClaimBasisOptionsAction, ICollectionResponse<IClaimBasisDesc>>(
		getClaimBasisOptionsEndpoint, 
		true, 
		__options__, 
		GetClaimBasisOptionsAction,
		{ /* no parameters */ }
	);
}

export const getClientEndpoint = "Api/Client/GetClient";

export function getClient(
	clientID: number, 
	__options__?: IOptions<IResponse<IClient>>
) {
	return apiExecute<IGetClientActionParams, GetClientAction, IResponse<IClient>>(
		getClientEndpoint, 
		true, 
		__options__, 
		GetClientAction,
		{ clientID }
	);
}

export const getClientProfileEndpoint = "Api/Client/GetClientProfile";

export function getClientProfile(
	__options__?: IOptions<IResponse<IClientProfile>>
) {
	return apiExecute<IGetClientProfileActionParams, GetClientProfileAction, IResponse<IClientProfile>>(
		getClientProfileEndpoint, 
		true, 
		__options__, 
		GetClientProfileAction,
		{ /* no parameters */ }
	);
}

export const getClientProfileIPWhiteListEndpoint = "Api/Client/GetClientProfileIPWhiteList";

export function getClientProfileIPWhiteList(
	__options__?: IOptions<ICollectionResponse<IClientProfileIPWhiteList>>
) {
	return apiExecute<IGetClientProfileIPWhiteListActionParams, GetClientProfileIPWhiteListAction, ICollectionResponse<IClientProfileIPWhiteList>>(
		getClientProfileIPWhiteListEndpoint, 
		true, 
		__options__, 
		GetClientProfileIPWhiteListAction,
		{ /* no parameters */ }
	);
}

export const getClientProfilePricingHistoriesEndpoint = "Api/Client/GetClientProfilePricingHistories";

export function getClientProfilePricingHistories(
	__options__?: IOptions<IClientProfilePricingHistoriesResponse>
) {
	return apiExecute<IGetClientProfilePricingHistoriesActionParams, GetClientProfilePricingHistoriesAction, IClientProfilePricingHistoriesResponse>(
		getClientProfilePricingHistoriesEndpoint, 
		true, 
		__options__, 
		GetClientProfilePricingHistoriesAction,
		{ /* no parameters */ }
	);
}

export const getClientProfileRolesEndpoint = "Api/Client/GetClientProfileRoles";

export function getClientProfileRoles(
	__options__?: IOptions<ICollectionResponse<IClientProfileRole>>
) {
	return apiExecute<IGetClientProfileRolesActionParams, GetClientProfileRolesAction, ICollectionResponse<IClientProfileRole>>(
		getClientProfileRolesEndpoint, 
		true, 
		__options__, 
		GetClientProfileRolesAction,
		{ /* no parameters */ }
	);
}

export const getClientProfileStatementEmailAddressesEndpoint = "Api/Client/GetClientProfileStatementEmailAddresses";

export function getClientProfileStatementEmailAddresses(
	__options__?: IOptions<ICollectionResponse<IClientProfileStatementEmail>>
) {
	return apiExecute<IGetClientProfileStatementEmailAddressesActionParams, GetClientProfileStatementEmailAddressesAction, ICollectionResponse<IClientProfileStatementEmail>>(
		getClientProfileStatementEmailAddressesEndpoint, 
		true, 
		__options__, 
		GetClientProfileStatementEmailAddressesAction,
		{ /* no parameters */ }
	);
}

export const getClientRegistrationDataEndpoint = "Api/Client/GetClientRegistrationData";

export function getClientRegistrationData(
	__options__?: IOptions<IClientRegistrationDataResponse>
) {
	return apiExecute<IGetClientRegistrationDataActionParams, GetClientRegistrationDataAction, IClientRegistrationDataResponse>(
		getClientRegistrationDataEndpoint, 
		true, 
		__options__, 
		GetClientRegistrationDataAction,
		{ /* no parameters */ }
	);
}

export const resetPasswordEndpoint = "Api/Client/ResetPassword";

export function resetPassword(
	clientProfileParty: IClientProfileParty, 
	__options__?: IOptions<IResponse<IClientProfileParty>>
) {
	return apiExecute<IResetPasswordActionParams, ResetPasswordAction, IResponse<IClientProfileParty>>(
		resetPasswordEndpoint, 
		true, 
		__options__, 
		ResetPasswordAction,
		{ clientProfileParty }
	);
}

export const saveAffiliateEndpoint = "Api/Client/SaveAffiliate";

export function saveAffiliate(
	affiliate: IClient, 
	__options__?: IOptions<IResponse<IClient>>
) {
	return apiExecute<ISaveAffiliateActionParams, SaveAffiliateAction, IResponse<IClient>>(
		saveAffiliateEndpoint, 
		true, 
		__options__, 
		SaveAffiliateAction,
		{ affiliate }
	);
}

export const saveClientProfileEndpoint = "Api/Client/SaveClientProfile";

export function saveClientProfile(
	clientProfile: IClientProfile, 
	__options__?: IOptions<ISaveClientProfileResponse>
) {
	return apiExecute<ISaveClientProfileActionParams, SaveClientProfileAction, ISaveClientProfileResponse>(
		saveClientProfileEndpoint, 
		true, 
		__options__, 
		SaveClientProfileAction,
		{ clientProfile }
	);
}

export const saveClientProfileIPWhiteListEndpoint = "Api/Client/SaveClientProfileIPWhiteList";

export function saveClientProfileIPWhiteList(
	whitelist: IClientProfileIPWhiteList[], 
	__options__?: IOptions<ICollectionResponse<IClientProfileIPWhiteList>>
) {
	return apiExecute<ISaveClientProfileIPWhiteListActionParams, SaveClientProfileIPWhiteListAction, ICollectionResponse<IClientProfileIPWhiteList>>(
		saveClientProfileIPWhiteListEndpoint, 
		true, 
		__options__, 
		SaveClientProfileIPWhiteListAction,
		{ whitelist }
	);
}

export const saveClientProfilePartyEndpoint = "Api/Client/SaveClientProfileParty";

export function saveClientProfileParty(
	clientProfileParty: IClientProfileParty, 
	__options__?: IOptions<IResponse<IClientProfileParty>>
) {
	return apiExecute<ISaveClientProfilePartyActionParams, SaveClientProfilePartyAction, IResponse<IClientProfileParty>>(
		saveClientProfilePartyEndpoint, 
		true, 
		__options__, 
		SaveClientProfilePartyAction,
		{ clientProfileParty }
	);
}

export const saveClientProfileRoleEndpoint = "Api/Client/SaveClientProfileRole";

export function saveClientProfileRole(
	clientProfileRole: IClientProfileRole, 
	__options__?: IOptions<IResponse<IClientProfileRole>>
) {
	return apiExecute<ISaveClientProfileRoleActionParams, SaveClientProfileRoleAction, IResponse<IClientProfileRole>>(
		saveClientProfileRoleEndpoint, 
		true, 
		__options__, 
		SaveClientProfileRoleAction,
		{ clientProfileRole }
	);
}

export const saveClientProfileStatementEmailAddressesEndpoint = "Api/Client/SaveClientProfileStatementEmailAddresses";

export function saveClientProfileStatementEmailAddresses(
	emailAddresses: IClientProfileStatementEmail[], 
	__options__?: IOptions<ICollectionResponse<IClientProfileStatementEmail>>
) {
	return apiExecute<ISaveClientProfileStatementEmailAddressesActionParams, SaveClientProfileStatementEmailAddressesAction, ICollectionResponse<IClientProfileStatementEmail>>(
		saveClientProfileStatementEmailAddressesEndpoint, 
		true, 
		__options__, 
		SaveClientProfileStatementEmailAddressesAction,
		{ emailAddresses }
	);
}

export const saveMyClientProfilePartyEndpoint = "Api/Client/SaveMyClientProfileParty";

export function saveMyClientProfileParty(
	clientProfileParty: IClientProfileParty, 
	__options__?: IOptions<IResponse<IClientProfileParty>>
) {
	return apiExecute<ISaveMyClientProfilePartyActionParams, SaveMyClientProfilePartyAction, IResponse<IClientProfileParty>>(
		saveMyClientProfilePartyEndpoint, 
		true, 
		__options__, 
		SaveMyClientProfilePartyAction,
		{ clientProfileParty }
	);
}

export const saveNewAffiliateEndpoint = "Api/Client/SaveNewAffiliate";

export function saveNewAffiliate(
	affiliate: IClient, 
	affiliateManager: IClientProfileParty, 
	renamingDefault: boolean, 
	__options__?: IOptions<INewAffiliateResponse>
) {
	return apiExecute<ISaveNewAffiliateActionParams, SaveNewAffiliateAction, INewAffiliateResponse>(
		saveNewAffiliateEndpoint, 
		true, 
		__options__, 
		SaveNewAffiliateAction,
		{
			affiliate,
			affiliateManager,
			renamingDefault
		}
	);
}

export const useParentAffiliateModelEndpoint = "Api/Client/UseParentAffiliateModel";

export function useParentAffiliateModel(
	writeTimestamp: string, 
	clientWriteTimestamp: string, 
	__options__?: IOptions<IClientProfileAndClientResponse>
) {
	return apiExecute<IUseParentAffiliateModelActionParams, UseParentAffiliateModelAction, IClientProfileAndClientResponse>(
		useParentAffiliateModelEndpoint, 
		true, 
		__options__, 
		UseParentAffiliateModelAction,
		{
			writeTimestamp,
			clientWriteTimestamp
		}
	);
}

/* tslint:enable:max-line-length */