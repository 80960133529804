import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";
import * as Dto from "../../Models/dto";

export default class AccountsFilteredAction implements IAction {
	searchResults: Dto.IAccountsResponse;

	constructor(data: Dto.IAccountsResponse) {
		this.searchResults = data;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"AccountsFilteredAction", {
				"searchResults": JSON.stringify(this.searchResults)
			});
	}
}