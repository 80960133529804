import { OK } from "../../Models/apiConstants";
import assign from "../../Utils/assign";
import ApiActionBase from "../../Actions/ApiActionBase";
import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import withoutProperties from "../../Utils/withoutProperties";
import {
	DeleteAffiliateAction,
	DeleteClientProfilePartyAction,
	DeleteClientProfileRoleAction,
	GetAllUsersAction,
	GetAllUsersForAffiliateManagerAction,
	SaveClientProfilePartyAction,
	SaveMyClientProfilePartyAction,
	SaveNewAffiliateAction
} from "../../Actions/Api/clientActions";
import { GetLoginUserAction } from "../../Actions/Api/startupActions";
import each from "lodash/each";
import getLoadState from "../../Utils/getLoadState";
import makeUserUpdateReducer from "./makeUserUpdateReducer";
import reduceReducers from "reduce-reducers";

type ById = { [clientProfilePartyId: number]: Dto.IClientProfilePartyNormalized };
type Flags = { [clientProfilePartyId: number]: true | undefined };

export interface IState {
	byId: ById;
	loading: AsyncState | null;
	currentUserId: number | null;
	loadingCurrentUser: AsyncState | null;
	saving: Flags;
	deleting: Flags;
	inClientProfile: Flags;
}

function updateFlags(flags: Flags, action: SaveClientProfilePartyAction | SaveMyClientProfilePartyAction | DeleteClientProfilePartyAction) {
	const { clientProfilePartyId } = action.params.clientProfileParty;
	if (action.state === AsyncState.Pending) {
		return Object.assign({}, flags, {
			[clientProfilePartyId]: true
		});
	} else {
		return withoutProperties(flags, clientProfilePartyId);
	}
}

function updateInClientProfile<TAction extends GetAllUsersAction | GetAllUsersForAffiliateManagerAction | SaveClientProfilePartyAction | SaveNewAffiliateAction>(
	previousInClientProfile: Flags,
	action: TAction,
	getUsers: (action: TAction) => Dto.IClientProfileParty[]
) {
	if (getLoadState(action) === AsyncState.Resolved) {
		let inClientProfile: Flags | null = null;
		for (const user of getUsers(action)) {
			const { clientProfilePartyId } = user;
			if (!previousInClientProfile[clientProfilePartyId]) {
				if (inClientProfile == null) {
					inClientProfile = assign(previousInClientProfile, {});
				}

				inClientProfile[user.clientProfilePartyId] = true; // safe to mutate
			}
		}

		if (inClientProfile != null) {
			return inClientProfile;
		}
	}

	return previousInClientProfile;
}

function deleteUserRelationship<TParams, TAction extends ApiActionBase<TParams, TResponse>, TResponse extends Dto.IBaseResponse>(
	previousState: IState,
	action: TAction,
	getDeletedId: (action: TAction) => number,
	getUserIds: (user: Dto.IClientProfilePartyNormalized) => number[],
	updateUser: (user: Dto.IClientProfilePartyNormalized, newIds: number[]) => Dto.IClientProfilePartyNormalized
) {
	if (action.state === AsyncState.Resolved && action.response!.status === OK) {
		let byId: ById | null = null;
		each(previousState.byId, user => {
			const deletedId = getDeletedId(action);
			const userIds = getUserIds(user);
			const index = userIds.indexOf(deletedId);
			if (index !== -1) {
				if (byId == null) {
					byId = Object.assign({}, previousState.byId);
				}

				// safe to mutate
				byId[user.clientProfilePartyId] = updateUser(
					user,
					[...userIds.slice(0, index), ...userIds.slice(index + 1)]
				);
			}
		});

		if (byId != null) {
			return Object.assign({}, previousState, { byId });
		}
	}

	return previousState;
}

function usersReducer(
	previousState: IState = {
		byId: {},
		loading: null,
		currentUserId: null,
		loadingCurrentUser: null,
		saving: {},
		deleting: {},
		inClientProfile: {}
	},
	action: IAction
): IState {
	if (action instanceof GetLoginUserAction) {
		const loadingCurrentUser = getLoadState(action);
		return Object.assign({}, previousState, {
			loadingCurrentUser,
			currentUserId: loadingCurrentUser === AsyncState.Resolved
				? action.response!.item.clientProfilePartyId
				: previousState.currentUserId
		});
	}

	if (
		action instanceof GetAllUsersAction ||
		action instanceof GetAllUsersForAffiliateManagerAction
	) {
		return assign<IState, Pick<IState, "loading" | "inClientProfile">>(previousState, {
			loading: getLoadState(action),
			inClientProfile: updateInClientProfile(
				previousState.inClientProfile,
				action,
				it => it.response!.items
			)
		});
	}

	if (action instanceof SaveClientProfilePartyAction) {
		return assign(previousState, {
			saving: updateFlags(previousState.saving, action),
			inClientProfile: updateInClientProfile(
				previousState.inClientProfile,
				action,
				it => [it.response!.item]
			)
		});
	}

	if (action instanceof SaveMyClientProfilePartyAction) {
		return Object.assign({}, previousState, {
			saving: updateFlags(previousState.saving, action)
		});
	}

	if (action instanceof DeleteClientProfilePartyAction) {
		return Object.assign({}, previousState, {
			deleting: updateFlags(previousState.deleting, action)
		});
	}

	if (action instanceof DeleteClientProfileRoleAction) {
		return deleteUserRelationship(
			previousState,
			action,
			it => it.params.clientProfileRole.clientProfileRoleId,
			it => it.clientProfileRoleIds,
			(user, clientProfileRoleIds) => Object.assign({}, user, { clientProfileRoleIds })
		);
	}

	if (action instanceof DeleteAffiliateAction) {
		return deleteUserRelationship(
			previousState,
			action,
			it => it.params.clientID,
			it => it.clientIds,
			(user, clientIds) => Object.assign({}, user, { clientIds })
		);
	}

	if (action instanceof SaveNewAffiliateAction) {
		return assign<IState, Pick<IState, "loading">>(previousState, { loading: null });
	}

	return previousState;
}

const userUpdateReducer = makeUserUpdateReducer<IState>(
	(previousState, updatedUsers, deletedUserId) => {
		const byId = Object.assign({}, previousState.byId, updatedUsers);

		if (deletedUserId != null) {
			delete byId[deletedUserId]; // safe to mutate
		}

		return Object.assign({}, previousState, { byId });
	}
);

export default reduceReducers(usersReducer, userUpdateReducer);