import React from "react";
import Loader from "../../Loader/Loader";
import { IStore } from "../Provider/Provider";
import uniqueRandom from "../../../../Utils/uniqueRandom";
import { createPortal } from "react-dom";
import connect from "../../../../Utils/connect";

interface IProps {
	store: IStore;
	locked?: boolean;
}

interface IConnectedProps {
	needsUpdate: boolean;
}

interface IState {
	message: string | null;
}

function getMessage({ store, locked }: IProps) {
	return store.getMessage(!!locked);
}

class Overlay extends React.Component<IProps & IConnectedProps, IState> {
	private _container: HTMLDivElement | null = null;
	private _unsubscribe: (() => void) | null = null;

	constructor(props: IProps & IConnectedProps, context: any) {
		super(props, context);

		this.state = {
			message: getMessage(props)
		};
	}

	static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
		const message = getMessage(nextProps);
		if (message !== prevState.message) {
			return { message };
		}

		return null;
	}

	componentDidMount() {
		this._container = document.body.appendChild(document.createElement("div"));
		if (__DEV__) {
			this._container.id = `busyoverlay-container-${uniqueRandom()}`;
		}

		this._unsubscribe = this.props.store.subscribe(() => {
			const nextState = Overlay.getDerivedStateFromProps(this.props, this.state);
			if (nextState != null) {
				this.setState(nextState);
			}
		});
	}

	componentWillUnmount() {
		this._unsubscribe!();
		process.nextTick(() => {
			document.body.removeChild(this._container!);
			this._container = null;
		});
	}

	render() {
		const { message } = this.state;

		if (message == null || this.props.needsUpdate) {
			return null;
		}

		return createPortal(
			<div className="busy-overlay">
				<div>
					<Loader className="busy-overlay-loader" />
					<span>{message}</span>
				</div>
			</div>,
			this._container!
		);
	}
}

export default connect<IConnectedProps, IProps>(
	Overlay,
	state => ({
		needsUpdate: state.auth.needsUpdate
	}));