import withoutProperties from "../../../Utils/withoutProperties";
import assign from "../../../Utils/assign";
import * as React from "react";
import Toast from "./Toast/Toast";
import IToast from "./IToast";
import { consume, subscribe, remove } from "./store";
import keyBy from "lodash/keyBy";

type ToastMap = { [id: string]: IToast };

interface IProps { }

interface IState {
	ids: string[];
	active: ToastMap;
	inactive: ToastMap;
}

export default class ToastEmitter extends React.Component<IProps, IState> {
	private _unsubscribe: (() => void) | undefined;

	constructor(props: IProps, context: any) {
		super(props, context);

		this.state = {
			ids: [],
			active: {},
			inactive: {}
		};

		this._handleHidden = this._handleHidden.bind(this);
	}

	componentDidMount() {
		this._consumeToast();
		this._unsubscribe = subscribe(
			() => this._consumeToast(),
			id => this._handleRemoved(id)
		);
	}

	componentWillUnmount() {
		this._unsubscribe!();
	}

	render() {
		const { state } = this;

		return (
			<div id="toast-container" className="toast-top-center" aria-live="polite" role="alert">
				{state.ids.map(id => {
					let toast = state.inactive[id];
					let hiding = toast != null;

					if (!hiding) {
						toast = state.active[id];
					}

					return (
						<Toast
							key={id}
							toast={toast}
							hiding={hiding}
							onDismiss={remove}
							onHidden={this._handleHidden}
						/>
					);
				})}
			</div>
		);
	}

	private _consumeToast() { // mmm... toast...
		const toasts = consume();
		if (toasts.length > 0) {
			this.setState(oldState => assign(oldState, {
				ids: [...oldState.ids, ...toasts.map(it => it.id)],
				active: assign(
					oldState.active,
					keyBy(toasts, it => it.id)
				)
			}));
		}
	}

	private _handleRemoved(id: string) {
		this.setState(oldState => {
			const toast = oldState.active[id];
			if (toast == null) {
				return oldState;
			}

			return assign(oldState, {
				active: withoutProperties(oldState.active, id),
				inactive: assign(oldState.inactive, {
					[id]: toast
				})
			});
		});
	}

	private _handleHidden(id: string) {
		this.setState(oldState => assign(oldState, {
			ids: oldState.ids.filter(it => it !== id),
			inactive: withoutProperties(oldState.inactive, id)
		}));
	}
}