import * as React from "react";
import getId from "../getId";
import cn from "classnames";
import { IContext } from "../Tabs";

type IProps = IContext & React.HTMLAttributes<HTMLElement>;

export default function Nav(props: IProps) {
	const { selected, tabs, uid, disabled, onTabClick, ...otherProps } = props;

	return (
		<ul {...otherProps} className={cn("nav nav-tabs", props.className)} role="tablist">
			{tabs.map(tab => (
				<li
					key={tab.key as any}
					role="presentation"
					className={cn({ active: tab.key === selected })}
				>
					<a
						href={"#" + getId(tab.key, uid)}
						aria-controls={getId(tab.key, uid)}
						role="tab"
						onClick={event => {
							event.preventDefault();
							if (!disabled) {
								onTabClick(tab.key);
							}
						}}
					>
						{tab.label}
					</a>
				</li>
			))}
		</ul>
	);
}