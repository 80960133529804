import { ActionBase } from "../IAction";
import * as Dto from "../../Models/dto";

export class UpdateRoleAction extends ActionBase {
	constructor(public edit: Dto.IClientProfileRole) {
		super("UpdateRoleAction");
	}
}

export class AddRoleAction extends ActionBase {
	constructor(public addingRole: boolean) { 
		super("AddRoleAction");
	}
}

export class SelectRoleAction extends ActionBase {
	constructor(public roleId: number) {
		super(nameof(SelectRoleAction));
	}
}