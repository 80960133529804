/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IBaseResponse,
	IFeedback
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface ISaveFeedbackActionParams {
	feedback: IFeedback;
}

export class SaveFeedbackAction extends ApiActionBase<ISaveFeedbackActionParams, IBaseResponse> {
	constructor(details: IDetails<ISaveFeedbackActionParams, IBaseResponse>) {
		super("Api/FeedbackActions/SaveFeedbackAction", details);
	}
}

/* tslint:enable:max-line-length */