import ActiveTabEnum from "../../../Models/ActiveTabEnum";
import { tabLoaded } from "../../../ActionCreators/AppActionCreators";
import createModuleLoader from "../../../Utils/createModuleLoader";

export type IModule = typeof import("./Settings");

const { loader, load } = createModuleLoader<IModule>();

(async () => {
	const settingsModule = await import("./Settings" /* webpackChunkName: "settings" */);
	load(settingsModule);
	tabLoaded(ActiveTabEnum.Settings);
})();

export default loader;
