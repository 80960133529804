export default function getMediaType(extension: string) {
	extension = (extension || "").toLowerCase();
	if (!extension.startsWith(".")) {
		extension = "." + extension;
	}

	switch (extension) {
		case ".jpg":
		case ".jpeg":
			return "image/jpeg";
		case ".gif":
			return "image/gif";
		case ".pdf":
			return "application/pdf";
		case ".png":
			return "image/png";
		case ".tif":
		case ".tiff":
			return "image/tiff";
		case ".doc":
			return "application/msword";
		case ".docx":
			return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
		case ".xls":
			return "application/excel";
		case ".xlsx":
			return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		case ".txt":
			return "text/plain";
		case ".csv":
			return "text/csv";
		default:
			return null;
	}
}