import keyBy from "lodash/keyBy";
import isEqual from "lodash/isEqual";

type Key = string | number;

export default function compareArrays(a: number[] | null | undefined, b: number[] | null | undefined): boolean;
export default function compareArrays(a: string[] | null | undefined, b: string[] | null | undefined): boolean;
export default function compareArrays<T>(a: T[] | null | undefined, b: T[] | null | undefined, getKey: (item: T) => Key): boolean;
export default function compareArrays<T>(a: T[] | null | undefined, b: T[] | null | undefined, getKey?: (item: T) => Key): boolean {
	if ((a ?? null) === (b ?? null)) {
		return true;
	}

	if (a == null || b == null || a.length !== b.length) {
		return false;
	}

	const aKeys = keyBy(a, getKey);
	return b.every(bValue => {
		const key: Key = getKey == null ? bValue as any : getKey(bValue);
		return key in aKeys && (getKey == null || isEqual(aKeys[key], bValue));
	});
}