import { makeAsync } from "../Models/IAsync";
import { createSelector } from "reselect";
import { getCurrentUserAsync } from "./getCurrentUser";
import getRolesById from "./getRolesById";
import { getPermissions } from "../Utils/clientProfilePartyUtils";

const empty: number[] = [];
const getCurrentClientProfileRoleIdsAsync = createSelector(
	getCurrentUserAsync,
	({ state, payload: user }) => makeAsync(
		state,
		user == null ? empty : user.clientProfileRoleIds
	)
);

export const getCurrentPermissionsAsync = createSelector(
	getCurrentClientProfileRoleIdsAsync,
	// if the current user is loaded all roles assigned to the user are 
	// loaded as well so no need to check the roles load state
	getRolesById,
	(user, roles) => makeAsync(
		user.state,
		getPermissions(user.payload, roles)
	)
);

export default createSelector(
	getCurrentPermissionsAsync,
	({ payload }) => payload!
);