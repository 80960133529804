import createFluxStore, { IStore } from "./createFluxStore";
import IAction, { ActionBase } from "../Actions/IAction";
import * as storage from "./storage";

export interface IPersistDetail<TState, T> {
	key: string;
	getValue: (state: TState) => T;
	hydrate: (state: TState, value: T) => TState;
}

type Reducer<TState> = (previousState: TState | undefined, action: IAction) => TState;

const MISSING_VALUE = Symbol();

class HydrateAction<TState> extends ActionBase {
	constructor(public state: TState) {
		super(nameof(HydrateAction));
	}
}

export function enableReducer<TState>(reducer: Reducer<TState>): Reducer<TState> {
	return (previousState: TState | undefined, action: IAction) => {
		if (action instanceof HydrateAction) {
			return (action as HydrateAction<TState>).state;
		}

		return reducer(previousState, action);
	};
}

export function watchStore<TState>(store: IStore<TState>, details: IPersistDetail<TState, any>[]) {
	store.addListener(() => {
		const state = store.getState();
		for (const detail of details) {
			const value = detail.getValue(state);
			storage.set(detail.key, value);
		}
	});

	let hydratedState = store.getState();
	for (const detail of details) {
		const value = storage.get<any>(detail.key, MISSING_VALUE);
		if (value !== MISSING_VALUE) {
			hydratedState = detail.hydrate(hydratedState, value);
		}
	}

	store.dispatch(new HydrateAction(hydratedState));
}

export function createPersistedFluxStore<TState>(reducer: Reducer<TState>, details: IPersistDetail<TState, any>[]) {
	const store = createFluxStore(enableReducer(reducer));
	watchStore(store, details);

	return store;
}