import { MODIFIED_BY_ANOTHER_USER, OK } from "../../Models/apiConstants";
import ApiActionBase from "../../Actions/ApiActionBase";
import IAction from "../../Actions/IAction";
import {
	GetClientProfileRolesAction,
	SaveClientProfileRoleAction,
	DeleteClientProfileRoleAction
} from "../../Actions/Api/clientActions";
import keyBy from "lodash/keyBy";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import makeUserUpdateReducer from "./makeUserUpdateReducer";
import flatten from "lodash/flatten";
import reduceReducers from "reduce-reducers";

type RoleMap = { [clientProfileRoleId: number]: Dto.IClientProfileRole };
type UpdateCallback<TState> = (previousState: TState, updatedUsers: RoleMap, deletedRoleId: number | null) => TState;

function handleRoleUpdate<TState>(previousState: TState, roles: Dto.IClientProfileRole[], callback: UpdateCallback<TState>) {
	if (roles.length === 0) {
		return previousState;
	}

	const updatedRoles = keyBy(roles, it => it.clientProfileRoleId);
	return callback(previousState, updatedRoles, null);
}

function handleRoleAction<TState, TParams, TAction extends ApiActionBase<TParams, TResponse>, TResponse extends Dto.IBaseResponse>(
	previousState: TState,
	action: TAction,
	getRoles: (action: TAction) => Dto.IClientProfileRole[],
	callback: UpdateCallback<TState>
): TState {
	if (action.state === AsyncState.Resolved && (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)) {
		return handleRoleUpdate(previousState, getRoles(action), callback);
	}

	return previousState;
}

export default function makeRoleUpdateReducer<TState>(
	callback: UpdateCallback<TState>
) {
	const roleUpdateReducer = (previousState: TState, action: IAction) => {
		if (action instanceof GetClientProfileRolesAction) {
			return handleRoleAction(previousState, action, it => it.response!.items, callback);
		}

		if (action instanceof SaveClientProfileRoleAction) {
			return handleRoleAction(previousState, action, it => [it.response!.item], callback);
		}

		if (action instanceof DeleteClientProfileRoleAction) {
			if (action.state === AsyncState.Resolved && action.response!.status === OK) {
				return callback(previousState, {}, action.response!.item.clientProfileRoleId);
			}

			return handleRoleAction(previousState, action, it => [it.response!.item], callback);
		}

		return previousState;
	};

	const userUpdateReducer = makeUserUpdateReducer<TState>((previousState, _updatedUsers, _deletedUserId, rawUsers) => {
		const roles = flatten(rawUsers.map(it => it.clientProfileRoles));
		return handleRoleUpdate(previousState, roles, callback);
	});

	return reduceReducers(roleUpdateReducer, userUpdateReducer);
}
