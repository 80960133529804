import { ActionBase } from "../IAction";
import { IEdit } from "../../Reducers/ui/dashboard/settings/ftpReducer";
import * as Dto from "../../Models/dto";

export class EditAction extends ActionBase {
	constructor(public edit: IEdit) {
		super(nameof(EditAction));
	}
}

export class EnableAction extends ActionBase {
	constructor() {
		super(nameof(EnableAction));
	}
}

export class DiscardChangesAction extends ActionBase {
	constructor(public ftp: Dto.IClientProfileFTP) {
		super(nameof(DiscardChangesAction));
	}
}
