import IToast from "./IToast";

type AddCallback = () => void;
type RemoveCallback = (id: string) => void;

let queue: IToast[] = [];
let addCallbacks: AddCallback[] = [];
let removeCallbacks: RemoveCallback[] = [];

export function consume() {
	const consumed = queue;
	queue = [];
	return consumed;
}

export function add(toast: IToast) {
	queue.push(toast);
	for (const callback of addCallbacks) {
		callback();
	}
}

export function remove(id: string) {
	for (const callback of removeCallbacks) {
		callback(id);
	}
}

export function subscribe(addCallback: AddCallback, removeCallback: RemoveCallback) {
	addCallbacks.push(addCallback);
	removeCallbacks.push(removeCallback);

	return () => {
		addCallbacks = addCallbacks.filter(it => it !== addCallback);
		removeCallbacks = removeCallbacks.filter(it => it !== removeCallback);
	};
}