enum Column {
	PrimaryAccountNumber = 1,
	SecondaryAccountNumber = 2,
	FirstName = 3,
	LastName = 4,
	SocialSecurityNumber = 5,
	Balance = 6,
	DateofDeath = 7,
	DateofBirth = 8,
	AccountStatus = 9,
	StrengthMeter = 10,
	PlacementDate = 11,
	ExpirationDate = 12,
	AccountID = 13,
	ClaimID = 14,
	MailedDate = 15,
	AffiliateID = 16,
	EstateDOD = 17,
	DODFoundTimestamp = 18,
	WorkflowDate = 19,
	PetitionDate = 20,
	StatementsRequired = 21,
	Workflow = 22,
	ClosedDate = 23,
	VerifiedDOD = 24,
	SettledAmount = 25,
	CaseNumber = 26,
	AccountImportStatus = 27,
	SettledDate = 28,
	EstateMatchDate = 29,
	PendingApproval = 30,
	SettlementRequested = 31,
	WithdrawalRequested = 32,
	StatementIncluded = 33,
	LockedIndicator = 34,
	Amend = 35
}

export default Column;