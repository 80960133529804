import { MODIFIED_BY_ANOTHER_USER, OK } from "../../../../Models/apiConstants";
import assign from "../../../../Utils/assign";
import getLoadState from "../../../../Utils/getLoadState";
import AsyncState from "../../../../Models/AsyncState";
import { DeleteUserSearchAction, SaveUserSearchAction } from "../../../../Actions/Api/accountActions";
import { UpdateAction, ClearAction, SelectSearchAction } from "../../../../Components/Dashboard/Accounts/LeftPanel/AdvancedSearch/actions";
import IAction from "../../../../Actions/IAction";
import * as Dto from "../../../../Models/dto";

export interface IState {
	search: Dto.IClientProfileUserSearch | null;
	selectedWorkflowIds: number[] | null;
}

const INITIAL_STATE: IState = {
	search: null,
	selectedWorkflowIds: null
};

export default function advancedSearchReducer(
	previousState: IState = INITIAL_STATE,
	action: IAction
): IState {
	if (action instanceof UpdateAction) {
		let { search } = action;

		if (
			search.clientProfileUserSearchId > 0
			&& previousState.search!.name !== search.name
		) {
			// changing the name, make a copy
			search = assign(search, {
				clientProfileUserSearchId: -1
			});
		}

		return assign<IState, Pick<IState, "search" | "selectedWorkflowIds">>(previousState, {
			search,
			selectedWorkflowIds: action.selectedWorkflowIds || previousState.selectedWorkflowIds
		});
	}

	if (action instanceof ClearAction) {
		return INITIAL_STATE;
	}

	if (action instanceof SelectSearchAction) {
		return {
			search: action.search,
			selectedWorkflowIds: null
		};
	}

	if (
		action instanceof SaveUserSearchAction
		&& action.state === AsyncState.Resolved
		&& (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)
		&& previousState.search != null
		&& previousState.search.clientProfileUserSearchId === action.response!.item.clientProfileUserSearchId
	) {
		return assign<IState, Pick<IState, "search" | "selectedWorkflowIds">>(previousState, {
			search: action.response!.item,
			selectedWorkflowIds: null
		});
	}

	if (
		action instanceof DeleteUserSearchAction
		&& getLoadState(action) === AsyncState.Resolved
		&& previousState != null
		&& previousState.search!.clientProfileUserSearchId === action.params.userSearch.clientProfileUserSearchId
	) {
		return INITIAL_STATE;
	}

	return previousState;
}