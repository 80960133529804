import React from "react";
import styles from "./Registration.module.scss";
import cn from "classnames";
import { RegistrationStep } from "../../Reducers/ui/registrationReducer";

interface IProps {
	steps: RegistrationStep[];
	active: RegistrationStep;
}

function createStep(name: string, active: boolean, idx: number) {
	return (
		<div
			key={idx}
			className={cn(styles.step, active ? styles.active : null)}
		>
			{name}
		</div>
	);
}

function mapStep(step: RegistrationStep, active: RegistrationStep, idx: number) {
	const isActive = step === active;

	switch (step) {
		case RegistrationStep.ProductSelection:
			return createStep("Product selection", isActive, idx);
		case RegistrationStep.Terms:
			return createStep("License terms and conditions", isActive, idx);
		case RegistrationStep.Baa:
			return createStep("Business associate agreement", isActive, idx);
		case RegistrationStep.Nda:
			return createStep("Non-disclosure agreement", isActive, idx);
		case RegistrationStep.AdminProfile:
			return createStep("Administrator profile", isActive, idx);
		case RegistrationStep.Security:
			return createStep("Security information", isActive, idx);
		case RegistrationStep.Profile:
			return createStep("Company profile", isActive, idx);
		case RegistrationStep.NextSteps:
			return createStep("Next steps", isActive, idx);
		case RegistrationStep.Confirm:
			return createStep("Confirmation", isActive, idx);
		default:
			throw new Error("unknown registration step");
	}
}

const Progress = ({ steps, active }: IProps) => (
	<div className={styles.steps}>
		{steps.map((it, idx) => mapStep(it, active, idx))}
	</div>
);

export default Progress;