import React from "react";
import styles from "./Forms.module.scss";
import icon from "../../../../Images/registration/confirm-icon.jpg";
import Form from "../../Common/Form/Form";
import FormGroup from "../../Common/FormGroup/FormGroup";
import cn from "classnames";
import { IProps } from "./Forms";
import moment from "moment";
import { appName } from "../../../Utils/branding";

const Confirm = (props: IProps) => {
	return (
		<div className={styles.page}>
			<div className={styles.header}>
				<img src={icon} alt="" height={50} width="auto" />
				<div className={cn(styles.title, styles.noSubtitle)}>
					Confirmation
				</div>
			</div>

			<div className={styles.fields}>
				<Form
					onSubmit={e => e.preventDefault()}
					horizontal
				>
					<div className={styles.wrapper}>
						<div className="panel panel-default">
							<div className="panel-heading">
								Company Profile
							</div>
							<div className="panel-body">
								<FormGroup label="Name">
									{id => (
										<span id={id}>
											{props.data.companyName}
										</span>
									)}
								</FormGroup>

								<FormGroup label="Phone">
									{id => (
										<span id={id}>
											{props.model.companyPhone}
										</span>
									)}
								</FormGroup>

								<FormGroup label="Address">
									{id => (
										<span id={id}>
											{props.model.companyAddress}
										</span>
									)}
								</FormGroup>

								<FormGroup label="City/State/Zip">
									{id => (
										<span id={id}>
											{`${props.model.companyCity}, ${props.model.companyState} ${props.model.companyZip}`}
										</span>
									)}
								</FormGroup>

								<FormGroup label="Are you a 3rd party debt collector, debt buyer, or collections law firm?">
									{id => (
										<span id={id}>
											{props.model.agencyFlag ? "Yes" : "No"}
										</span>
									)}
								</FormGroup>

								<FormGroup label={`Will you use ${appName} to collect or service any medical debt?`}>
									{id => (
										<span id={id}>
											{props.model.medicalFlag ? "Yes" : "No"}
										</span>
									)}
								</FormGroup>
							</div>
						</div>

						<div>
							<div className="panel panel-default">
								<div className="panel-heading">
									Admin Profile
								</div>

								<div className="panel-body">
									<FormGroup label="Name">
										{id => (
											<span id={id}>
												{`${props.data.adminFirstName} ${props.data.adminLastName}`}
											</span>
										)}
									</FormGroup>

									<FormGroup label="Email">
										{id => (
											<span id={id}>
												{props.data.adminEmail}
											</span>
										)}
									</FormGroup>

									<FormGroup label="Phone">
										{id => (
											<span id={id}>
												{props.model.adminPhone}
											</span>
										)}
									</FormGroup>
								</div>
							</div>

							{!!props.model.implementationOptions.length && (
								<div className="panel panel-default">
									<div className="panel-heading">
										Implementation Selections
									</div>
									<div className="panel-body">
										<ul>
											{props.model.implementationOptions.map((it, idx) => (
												<li key={idx}>{moment(it).format("dddd MM/DD/YYYY [at] h:mm a")}</li>
											))}
										</ul>
									</div>
								</div>
							)}
						</div>
					</div>

					<div className={styles.actions}>
						<button
							type="button"
							className="btn btn-light"
							onClick={() => props.onBack()}
						>
							Back
						</button>
						<button
							type="button"
							className="btn btn-warning"
							onClick={() => props.onConfirm()}
						>
							Complete
						</button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default Confirm;