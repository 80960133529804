import { ERROR, OK } from "../../Models/apiConstants";
import handleApiResponse from "../../Utils/handleApiResponse";
import BusyOverlay from "../Common/BusyOverlay/BusyOverlay";
import connect from "../../Utils/connect";
import { saveSecurityQuestionAnswer } from "../../ActionCreators/Api/loginActionCreators";
import React from "react";
import ReduxStore from "../../Stores/ReduxStore";
import LoginActionCreators, {
	showInvalidCaptchaMessage
} from "../../ActionCreators/LoginActionCreators";
import * as toast from "../../Utils/toast";
import Captcha from "../Captcha/Captcha";
import connectToCaptcha, { ICaptchaProps } from "../../Utils/connectToCaptcha";
import cn from "classnames";
import noop from "lodash/noop";
import AuthPage, { FormGroup } from "../Auth/AuthPage/AuthPage";
import AuthButton from "../Auth/AuthButton/AuthButton";
import AuthHeader from "../Auth/AuthHeader/AuthHeader";

interface IOwnProps { }

interface IConnectedProps {
	question: string;
	answer: string;
	saving: boolean;
	onChange(question: string, answer: string): void;
	onSave(): void;
}

interface IProps extends IOwnProps, ICaptchaProps, IConnectedProps { }

interface IState {
	confirmAnswer: string;
}

function isPopulated(value: string) {
	return value != null && value.length > 0;
}

class SetSecurityQuestion extends React.Component<IProps, IState> {
	constructor(props: IProps, context: any) {
		super(props, context);

		this.state = {
			confirmAnswer: ""
		};

		this._handleConfirmAnswerChange = this._handleConfirmAnswerChange.bind(this);
		this._handleSubmit = this._handleSubmit.bind(this);
	}

	render(): React.ReactElement<{}> {
		const { props, state } = this;

		const questionValid = isPopulated(props.question);
		const answerValid = isPopulated(props.answer);
		const confirmAnswerValid = isPopulated(state.confirmAnswer);

		return (
			<AuthPage onSubmit={this._handleSubmit}>
				<BusyOverlay message={props.saving ? "Saving..." : null} />
				<AuthHeader>Security Question</AuthHeader>
				<FormGroup>
					Please enter a security question and answer, verify the image text and click the save button.
				</FormGroup>
				<FormGroup label="Question">
					{id => (
						<input
							id={id}
							className={cn("form-control", { errorInput: !questionValid })}
							maxLength={50}
							value={props.question || ""}
							onChange={this._handleQuestionChange}
							autoComplete="new-security-question"
						/>
					)}
				</FormGroup>
				<FormGroup label="Answer">
					{id => (
						<input
							id={id}
							type="password"
							className={cn("form-control", { errorInput: !answerValid })}
							maxLength={50}
							value={props.answer || ""}
							onChange={this._handleAnswerChange}
							autoComplete="new-security-answer"
						/>
					)}
				</FormGroup>
				<FormGroup label="Confirm Answer">
					{id => (
						<input
							id={id}
							type="password"
							className={cn("form-control", { errorInput: !confirmAnswerValid })}
							maxLength={50}
							value={state.confirmAnswer || ""}
							onChange={this._handleConfirmAnswerChange}
							autoComplete="new-security-answer"
						/>
					)}
				</FormGroup>
				<FormGroup>
					<Captcha />
				</FormGroup>
				<FormGroup>
					<AuthButton disabled={props.saving || !questionValid || !answerValid || !confirmAnswerValid || !this.props.captchaValid}>
						Save
					</AuthButton>
				</FormGroup>
			</AuthPage>
		);
	}

	private _handleQuestionChange = (e: React.FormEvent<HTMLElement>) => this._handleChange(e, question => ({ question }));
	private _handleAnswerChange = (e: React.FormEvent<HTMLElement>) => this._handleChange(e, answer => ({ answer }));

	private _handleChange(event: React.FormEvent<HTMLElement>, getUpdate: (value: string) => { question: string } | { answer: string }) {
		const { value } = event.currentTarget as HTMLInputElement;
		const newProps = Object.assign({}, this.props, getUpdate(value));
		this.props.onChange(newProps.question, newProps.answer);
	}

	private _handleConfirmAnswerChange(event: React.FormEvent<HTMLElement>) {
		const { value } = event.currentTarget as HTMLInputElement;
		this.setState({ confirmAnswer: value });
	}

	private _handleSubmit(event: React.FormEvent<HTMLElement>) {
		event.preventDefault();

		if (this.props.answer !== this.state.confirmAnswer) {
			toast.error("The answer and confirm answer do not match. Please correct to continue.");
			return;
		}

		this.props.onSave();
	}
}

const ConnectedToCaptcha = connectToCaptcha<IOwnProps & IConnectedProps>(SetSecurityQuestion);

const onChange = (question: string, answer: string) => {
	const { auth: { loginUser } } = ReduxStore.getState();
	LoginActionCreators.loginUserUpdated(Object.assign({}, loginUser, {
		securityQuestion: question,
		securityAnswer: answer
	}));
};

const onSave = async () => {
	const { auth: { loginUser }, ui: { captcha: { challenge, response } } } = ReduxStore.getState();
	try {
		const data = await saveSecurityQuestionAnswer(loginUser, response, challenge!);
		handleApiResponse(data, {
			[OK]: noop,
			[ERROR]: {
				"LOGIN_INCORRECT_CAPTCHA": showInvalidCaptchaMessage
			}
		});
	} catch (error) {
		if (__DEV__) {
			console.error(error);
		}
		toast.genericError();
	}
};

export default connect<IConnectedProps, IOwnProps>(
	ConnectedToCaptcha,
	state => ({
		question: state.auth.loginUser.securityQuestion,
		answer: state.auth.loginUser.securityAnswer,
		saving: state.auth.savingSecurityQuestion,
		onChange,
		onSave
	})
);
