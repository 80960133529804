import { MODIFIED_BY_ANOTHER_USER, OK } from "../Models/apiConstants";
import getWorkflowStepToAccountImportStatusMap from "../selectors/getWorkflowStepToAccountImportStatusMap";
import getWorkflowStepToAccountStatusMap from "../selectors/getWorkflowStepToAccountStatusMap";
import CancelUploadAction from "../Actions/UploadAccount/CancelUploadAction";
import { ChangeAccountClaimStatusAction } from "../Actions/Api/claimActions";
import { DeleteAffiliateAction } from "../Actions/Api/clientActions";
import reduceReducers from "reduce-reducers";
import makeUserUpdateReducer from "../Reducers/Entities/makeUserUpdateReducer";
import { GetAccountImportsByAccountStatusAndWorkflowStatusIDAction } from "../Actions/Api/accountImportActions";
import {
	GetAccountsByAccountStatusAndWorkflowStatusIDAction,
	IGetAccountsByAccountStatusAndWorkflowStatusIDActionParams,
	SaveAccountMiscAction,
	RollbackAccountApprovalAction,
	AdvancedSearchAccountsAction
} from "../Actions/Api/accountActions";
import catchAsyncErrors from "../Utils/catchAsyncErrors";
import { waitFor, cancel } from "../Utils/apiExecute";
import getLoadState from "../Utils/getLoadState";
import { getAccountsByAccountStatusAndWorkflowStatusID, advancedSearchAccounts } from "../ActionCreators/Api/accountActionCreators";
import { getAccountImportsByAccountStatusAndWorkflowStatusID } from "../ActionCreators/Api/accountImportActionCreators";
import assign from "../Utils/assign";
import isEmpty from "../Utils/isEmpty";
import BaseStore from "./BaseStore";
import ReduxStore from "./ReduxStore";
import IAction from "./../Actions/IAction";
import Dispatcher from "../Dispatcher/Dispatcher";
import { GetStartupDataAction, GetLoginUserAction } from "../Actions/Api/startupActions";
import WorkFlowStatusDataLoadedAction from "../Actions/Accounts/WorkFlowStatusDataLoadedAction";
import WorkFlowStatusSelectedAction from "../Actions/Accounts/WorkFlowStatusSelectedAction";
import AccountsDisplayChangedAction from "../Actions/Accounts/AccountsDisplayChangedAction";
import AccountSelectedAction from "../Actions/Accounts/AccountSelectedAction";
import AffiliateSelectedAction from "../Actions/Accounts/AffiliateSelectedAction";
import ActiveTabChangedAction from "../Actions/ActiveTabChangedAction";
import ShowSearchAction from "../Actions/Accounts/ShowSearchAction";
import ColumnSelectedAction from "../Actions/Accounts/ColumnSelectedAction";
import AccountStatusUpdatedAction from "../Actions/Accounts/AccountStatusUpdatedAction";
import AccountsFilteredAction from "../Actions/Accounts/AccountsFilteredAction";
import PageDataChangedAction from "../Actions/Accounts/PageDataChangedAction";
import SearchCriteriaUpdatedAction from "../Actions/Accounts/SearchCriteriaUpdatedAction";
import SearchAccountsAction from "../Actions/Accounts/SearchAccountsAction";
import SortChangedAction from "../Actions/Accounts/SortChangedAction";
import AccountStatusSelectedAction from "../Actions/Accounts/AccountStatusSelectedAction";
import NavigateToAccountAction from "../Actions/Accounts/NavigateToAccountAction";
import ActiveTabEnum from "../Models/ActiveTabEnum";
import AccountsContext from "../Utils/AccountsContext";
import AccountsActionEnum from "../Models/AccountsActionEnum";
import AccountWorkflowSteps from "../Models/AccountWorkflowSteps";
import { parse as parseDate } from "../Utils/dateUtils";
import { SaveAccountSuccessAction } from "../Actions/AccountDetailsActions";
import * as AccountsData from "../Models/AccountsData";
import * as Dto from "../Models/dto";
import find from "lodash/find";
import { AsyncState, combineStates } from "../Models/IAsync";
import { getWorkflowStepForAccount } from "../Utils/statusUtils";
import { ssn as validateSsn, zip as validateZip, redactedSsn as validateRedactedSsn, redactedSsn } from "../Utils/validate";
import * as toast from "../Utils/toast";
import keyBy from "lodash/keyBy";
import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";
import * as storage from "../Utils/storage";
import getWorkflows, { IWorkflowMeta } from "../Components/Dashboard/Accounts/workflows/workflows";
import IWorkflow from "../Models/IWorflow";
import Column from "../Models/Column";
import { startBusy } from "../ActionCreators/AppActionCreators";
import handleApiResponse from "../Utils/handleApiResponse";
import handleGenericError from "../Utils/handleGenericError";
import NavigateToAccountLoadedAction from "../Actions/Accounts/NavigateToAccountLoadedAction";
import { navigateToAccountLoaded, reviewItemSearchResponse } from "../ActionCreators/AccountActionCreators";
import NavigateToAccountImportAction from "../Actions/Accounts/NavigateToAccountImportAction";
import { trackRecentAccount } from "../Utils/useRecentAccounts";
import ReviewItemSearchAction from "../Actions/Accounts/ReviewItemSearchAction";
import ReviewItemSearchResponseAction from "../Actions/Accounts/ReviewItemSearchResponseAction";
import ClientProfileProducts from "../Models/ClientProfileProducts";

const SELECTED_AFFILIATES_STORAGE_KEY = "SelectedAffiliates";
const REVIEW_ITEM_SEARCH_TAG = "ReviewItemSearch_28fdd3e4";

interface IRequest {
	id: string;
	state: AsyncState;
}

interface IAccountRequest extends IRequest {
	params: IGetAccountsByAccountStatusAndWorkflowStatusIDActionParams;
}

interface IUserState {
	currentUserId: number | null;
	clientIds: number[];
}

// TODO these redux store methods should probably have the returned data moved into the applicable actions instead

function getPageSizeFromReduxStore() {
	if (Dispatcher.isDispatching()) {
		Dispatcher.waitFor([ReduxStore.token]);
	}

	return ReduxStore.getState().ui.dashboard.pageSize;
}

function getWorkflowsFromReduxStore() {
	if (Dispatcher.isDispatching()) {
		Dispatcher.waitFor([ReduxStore.token]);
	}

	return getWorkflows(ReduxStore.getState());
}

function translateAccountStatusIds(workflowId: number, selectedStatusIds: number[] | null): number[] {
	if (selectedStatusIds !== null) {
		return selectedStatusIds;
	}

	if (Dispatcher.isDispatching()) {
		Dispatcher.waitFor([ReduxStore.token]);
	}

	const state = ReduxStore.getState();
	const workFlowAccountImportStatusMap = getWorkflowStepToAccountImportStatusMap(state);
	const workFlowAccountStatusMap = getWorkflowStepToAccountStatusMap(state);

	let dictionary: any;
	switch (workflowId) {
		case AccountWorkflowSteps.Flagged:
		case AccountWorkflowSteps.PrePlacement:
			dictionary = workFlowAccountImportStatusMap[workflowId];
			return Object.keys(dictionary).map(k => dictionary[+k].acctImportStatusId);
		default:
			dictionary = workFlowAccountStatusMap[workflowId];
			return Object.keys(dictionary).map(k => dictionary[+k].acctStatusId);
	}
}

const userReducer = reduceReducers(
	(previousState: IUserState, action: IAction) => {
		if (action instanceof GetLoginUserAction && getLoadState(action) === AsyncState.Resolved) {
			return assign(previousState, {
				currentUserId: action.response!.item.clientProfilePartyId as number | null
			});
		}

		if (action instanceof DeleteAffiliateAction && getLoadState(action) === AsyncState.Resolved) {
			let changed = false;
			const clientIds = previousState.clientIds.filter(clientId => {
				if (clientId === action.params.clientID) {
					changed = true;
					return false;
				}

				return true;
			});

			if (changed) {
				return assign(previousState, {
					clientIds
				});
			}
		}

		return previousState;
	},
	makeUserUpdateReducer<IUserState>(
		(previousState, updatedUsers) => {
			if (previousState.currentUserId! in updatedUsers) {
				const { clientIds } = updatedUsers[previousState.currentUserId!];
				if (clientIds !== previousState.clientIds) {
					return assign(previousState, { clientIds });
				}
			}

			return previousState;
		}
	)
);

const getClientIdsForUser = createSelector(
	(state: IUserState) => state.clientIds,
	clientIds => keyBy(clientIds)
);

function getDefaultColumnsIdsForWorkflow(workflow: IWorkflow, productId: ClientProfileProducts | null) {
	return workflow.columns(productId)
		.filter(it => it.default)
		.map(it => it.id);
}

export function getColumnsForWorkflow(workflow: IWorkflow, redactSsn: boolean, affiliateModelFlag: boolean, productId: ClientProfileProducts | null): Array<AccountsData.IAccountColumn> {
	if (workflow == null) {
		return [];
	}

	const allColumns = keyBy(AccountsContext.GetAccountColumns(), it => it.id);

	const storeName: string = "columns" + workflow.id.toString();
	const defaultColumns = getDefaultColumnsIdsForWorkflow(workflow, productId);
	const selectedColumns = storage.get(storeName, defaultColumns);
	const selectedMap = keyBy(selectedColumns);

	let currentColumns = workflow.columns(productId);
	if (!affiliateModelFlag || (process.env.REACT_APP_APPLICATION === "access" && redactSsn)) {
		currentColumns = currentColumns.filter(({ id }) => {
			if (!affiliateModelFlag && id === Column.AffiliateID) {
				return false;
			}

			if (process.env.REACT_APP_APPLICATION === "access" && redactSsn && id === Column.SocialSecurityNumber) {
				return false;
			}

			return true;
		});
	}

	return currentColumns.map(({ id }) => {
		return assign(allColumns[id], {
			isSelected: id in selectedMap
		} as { isSelected?: boolean });
	});
}

class AccountsStore extends BaseStore {

	token: string;

	private workFlowStatusData: Dto.ICollectionResponse<Dto.IAccountWorkflowStatusCount> | undefined;
	private selectedWorkFlowState: Pick<IWorkflowMeta, "id"> | null | undefined;
	private currentAccountAction: AccountsActionEnum = AccountsActionEnum.Normal;
	private currentAccounts: Dto.IAccountsResponse | undefined;
	private selectedAccounts: Array<Dto.IAccount> = [];
	private _selectAll = false;
	private _selectedAffiliates: number[] | null | undefined = null;
	private showSearch: boolean = false;
	private _pageSearchResults: boolean = false;
	private _reviewItemSearch: Dto.IClientProfileUserSearch | null = null;
	private _reviewItemSearchLoadState: AsyncState | null = null;

	private selectedLeftTabId: number = 1;

	private currentPageIndex: number = 1;
	private searchCriteria: Dto.IClientProfileUserSearch | undefined;

	private _startupRequest: IRequest | undefined;

	private _accountsRequest: IAccountRequest | undefined;


	private _userState: IUserState = {
		currentUserId: null,
		clientIds: []
	};

	private _selectedStatusIdsByWorkflow: { [workflowId: number]: number[] | null | undefined } = {};

	private _sortInfoByWorkflow: { [workflowId: number]: Array<AccountsData.ISortInfo> | null | undefined } = {};

	constructor() {
		super();
		this.token = Dispatcher.register((action: IAction) => this.processActions(action));
	}

	getAccountLoadState() {
		const accountState = this._reviewItemSearch != null
			? this._reviewItemSearchLoadState
			: this._accountsRequest?.state;

		return combineStates(
			this._startupRequest == null ? null : this._startupRequest.state,
			accountState ?? null
		);
	}

	getWorkFlowStatusData() {
		return this.workFlowStatusData;
	}

	getSortInfo(workflowId?: number): Array<AccountsData.ISortInfo> {
		if (this.selectedWorkFlowState == null) {
			if (workflowId != null)
			{
				return this.getCurrentSortInfo(workflowId) || this._getSortFromStorage(workflowId);
			}
			return [AccountsContext.defaultSortInfo];
		}
		return this.getCurrentSortInfo(this.selectedWorkFlowState.id) || this._getSortFromStorage(this.selectedWorkFlowState.id);
	}

	getSelectedWorkflowStatusIds() {
		if (this.selectedWorkFlowState == null) {
			return [];
		}

		return translateAccountStatusIds(
			this.selectedWorkFlowState.id,
			this._selectedStatusIdsByWorkflow[this.selectedWorkFlowState.id] || null
		);
	}

	getAccountsByWorkFlowStatus(): Array<Dto.IAccount> {
		return (this.currentAccounts && this.currentAccounts.accounts) ? this.currentAccounts.accounts : new Array<Dto.IAccount>();
	}

	getAccountImportsByWorkFlowStatus(): Array<Dto.IAccountImport> {
		return (this.currentAccounts && this.currentAccounts.accountImports) ? this.currentAccounts.accountImports : new Array<Dto.IAccountImport>();
	}

	getCurrentTotalCount() {
		return !this.currentAccounts ? 0 : this.currentAccounts.totalNumberOfAccounts;
	}

	getSelectedWorkflow(workflows: IWorkflowMeta[]): IWorkflowMeta | null {
		const selectedWorkflowId = this.selectedWorkFlowState == null ? null : this.selectedWorkFlowState.id;
		return (selectedWorkflowId != null && workflows.find(it => it.id === selectedWorkflowId)) || workflows[0] || null;
	}

	getSelectedStatusIds(workflowId: number) {
		return this._selectedStatusIdsByWorkflow[workflowId] || null;
	}

	getCurrentSortInfo(workflowId: number) {
		return this._sortInfoByWorkflow[workflowId] || null;
	}

	getCurrentDisplayState(): AccountsActionEnum {
		return this.currentAccountAction || AccountsActionEnum.Normal;
	}

	getSelectedAccounts(): Array<Dto.IAccount> {
		return this.selectedAccounts || [];
	}

	getSelectedAffiliates() {
		if (this._selectedAffiliates == null) {
			this._selectedAffiliates = storage.get<number[]>(SELECTED_AFFILIATES_STORAGE_KEY);
		}

		let selectedAffiliates = null;
		if (this._selectedAffiliates != null) {
			const clientIdsForUser = getClientIdsForUser(this._userState);
			selectedAffiliates = (this._selectedAffiliates || []).filter(it => it in clientIdsForUser);
		}

		if (selectedAffiliates == null || selectedAffiliates.length === 0) {
			// default to all clients assigned to user
			return this._userState.clientIds;
		}

		return selectedAffiliates;
	}

	getShowSearch(): boolean {
		return this.showSearch;
	}

	private _getEmptySearchCriteria() {
		return {
			clientProfileUserSearchId: -1,
			clientProfilePartyId: -1,
			name: "",
			accountStatusIds: [],
			affiliateIds: [],
			minimumAccountBalance: -1,
			maximumAccountBalance: -1,
			searchDates: this.getInitialSearchDates(),
			shared: false,
			firstName: "",
			lastName: "",
			ssn: "",
			primaryAccountNumber: "",
			secondaryAccountNumber: "",
			acctId: "",
			claimId: "",
			caseNumber: "",
			totalAccountsFound: 0,
			accountWorkflowId: -1,
			writeTimestamp: ""
		};
	}

	getSearchCriteria(): Dto.IClientProfileUserSearch {
		if (!this.searchCriteria) {
			this.searchCriteria = this._getEmptySearchCriteria();
		}

		return this.searchCriteria;
	}

	getPageData(pSize: number, workflowId: number | null): AccountsData.IPageData {
		let numberOfAccounts = 0;
		if (this.currentAccounts != null) {
			numberOfAccounts = this.currentAccounts.totalNumberOfAccounts;
		} else if (
			process.env.REACT_APP_APPLICATION === "access"
			&& workflowId != null
			&& this.workFlowStatusData != null
		) {
			const counts = this.workFlowStatusData.items.find(it => it.statusId === workflowId);
			if (counts != null) {
				numberOfAccounts = counts.count;
			}
		}

		return {
			pageSize: pSize,
			pageIndex: this.currentPageIndex,
			startIndex: pSize * (this.currentPageIndex - 1) + (numberOfAccounts > 0 ? 1 : 0),
			endIndex: numberOfAccounts > 0 && pSize * this.currentPageIndex >= numberOfAccounts
				? numberOfAccounts
				: pSize * this.currentPageIndex,
			totalCount: numberOfAccounts,
			pageCount: Math.ceil(numberOfAccounts / pSize)
		};
	}

	evaluateAccountStrength(item: Dto.IAccount | Dto.IAccountImport | null): number | null {
		if (item == null) {
			return null;
		}

		const hasName = !isEmpty(item.firstName) && !isEmpty(item.lastName);
		const dob = parseDate(item.dob);
		const dod = parseDate(item.dod);
		const hasSsn = !isEmpty(item.ssn) && (validateSsn(item.ssn) || validateRedactedSsn(item.ssn));
		const hasCity = !isEmpty(item.city);
		const hasZip = !isEmpty(item.zip) && validateZip(item.zip);
		const hasState = !isEmpty(item.state);

		const conditions = [
			{
				match: !isEmpty(item.address1) && hasCity && hasZip,
				value: 8
			},
			{
				match: hasCity,
				value: 2
			},
			{
				match: hasZip || (hasCity && hasState),
				value: 2
			},
			{
				match: hasState,
				value: 1
			},
			{
				match: hasZip,
				value: 4
			},
			{
				match: hasSsn,
				value: 16
			},
			{
				match: dob != null && dob.isValid(),
				value: 8
			},
			{
				match: dod != null && dod.isValid(),
				value: 8
			},
			{
				match: hasName,
				value: 16
			},
			{
				match: hasName && hasSsn,
				value: 16
			}
		];

		let strength = 0;
		let maxStrength = 0;

		for (const { match, value } of conditions) {
			maxStrength += value;
			if (match) {
				strength += value;
			}
		}

		return strength / maxStrength;
	}

	evaluateAccountImportStrength(item: Dto.IAccountImport): number {
		return this.evaluateAccountStrength(item)!;
	}

	// Sets the column in local store
	private _setColumnsInStorage(selectedIds: number[], workflow: IWorkflow): void {
		var storeName: string = "columns" + workflow.id.toString();
		storage.set(storeName, selectedIds);
	}

	private _setSortInStorage(sortInfo: Array<AccountsData.ISortInfo>, workflowId: number): void {
		var storeName: string = "accountSortInfo" + workflowId.toString();
		storage.set(storeName, sortInfo);
	}

	private _getSortFromStorage(workflowId: number): Array<AccountsData.ISortInfo> {
		var storeName: string = "accountSortInfo" + workflowId.toString();
		return storage.get(storeName) || [AccountsContext.defaultSortInfo];
	}

	getSelectedLeftTabId() {
		return this.selectedLeftTabId;
	}

	isReviewItems() {
		return this._reviewItemSearch != null;
	}

	private getInitialSearchDates(): Array<Dto.IClientProfileSearchDate> {
		return [
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "AccountPlaced" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "AccountClosed" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "DOB" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "DOD" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "ClaimFiled" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "EstateExpirationDate" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "DodFoundTimestamp" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "WorkflowDate" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "EstateMatchedDate" }
		];
	}

	private _updateSelection(newSelection: Dto.IAccount[], selectAll: boolean) {
		if (!selectAll && newSelection.length > 0) {
			const oldSelection = this._selectAll
				? {} // select all selections aren't considered as "selected" for recent account tracking
				: keyBy(this.selectedAccounts.filter(it => AccountsContext.isAccount(it)), it => it.acctId);

			for (const account of newSelection) {
				if (!AccountsContext.isAccount(account) || account.acctId in oldSelection) {
					continue;
				}

				trackRecentAccount(account);
			}
		}

		this.selectedAccounts = [...newSelection];
		this._selectAll = selectAll;
	}

	private processActions(action: IAction): void {
		const previousUserState = this._userState;
		this._userState = userReducer(previousUserState, action);

		if (action instanceof WorkFlowStatusDataLoadedAction) {
			this.workFlowStatusData = action.data;
			this.emitChange();
		}

		if (action instanceof WorkFlowStatusSelectedAction) {
			this.selectedWorkFlowState = action.data;
			this.currentAccountAction = AccountsActionEnum.Normal;
			this.currentPageIndex = 1;
			this._pageSearchResults = false;
			this._reviewItemSearch = null;
			this._refreshAccounts(getPageSizeFromReduxStore, getWorkflowsFromReduxStore);
			this.emitChange();
		}

		if (action instanceof AccountStatusSelectedAction) {
			const selectedWorkFlow = this.getSelectedWorkflow(getWorkflowsFromReduxStore());
			if (selectedWorkFlow == null) {
				throw new Error("Unexpected state");
			}

			this._selectedStatusIdsByWorkflow = {
				...this._selectedStatusIdsByWorkflow,
				[selectedWorkFlow.id]: action.data
			};

			this.selectedWorkFlowState = selectedWorkFlow;
			this.currentPageIndex = 1;
			this._refreshAccounts(getPageSizeFromReduxStore, getWorkflowsFromReduxStore);
			this.emitChange();
		}

		if (action instanceof AccountsDisplayChangedAction) {
			this.currentAccountAction = action.currentDisplayAction;
			this.selectedLeftTabId = action.tabId;
			this.emitChange();
		}

		if (
			action instanceof GetAccountsByAccountStatusAndWorkflowStatusIDAction
			|| action instanceof GetAccountImportsByAccountStatusAndWorkflowStatusIDAction) {
			if (action.requestId === this._accountsRequest!.id) {
				this._updateSelection([], false); // Unselected

				let { state } = action;
				if (state === AsyncState.Resolved && (action.response == null || action.response.status !== OK)) {
					state = AsyncState.Rejected;
				}

				this._accountsRequest = {
					...this._accountsRequest!,
					state
				};

				this.currentAccounts = action.response!;
				if (state === AsyncState.Resolved && this.workFlowStatusData != null) {
					let workflowStepId: number;
					let statusIds: number[] | null;

					if (action instanceof GetAccountsByAccountStatusAndWorkflowStatusIDAction) {
						workflowStepId = action.params.accountWorkflowStatusID;
						statusIds = action.params.accountStatusIds;
					} else {
						workflowStepId = action.params.accountWorkflowStatusID;
						statusIds = action.params.accountImportStatusIds;
					}

					if (statusIds == null) {
						this.workFlowStatusData = {
							...this.workFlowStatusData,
							items: [
								...this.workFlowStatusData.items.filter(it => it.statusId !== workflowStepId),
								{
									statusId: workflowStepId,
									count: action.response!.totalNumberOfAccounts
								}
							]
						};
					}
				}

				this.emitChange();
			}
		}

		if (action instanceof AccountSelectedAction) {
			this._updateSelection(action.selectedAccounts, action.selectAll);
			this.emitChange();
		}

		if (action instanceof AffiliateSelectedAction) {
			this._selectedAffiliates = action.selectedAffiliateIds;

			storage.set(SELECTED_AFFILIATES_STORAGE_KEY, this._selectedAffiliates);

			AccountsContext.GetAccountWorkflowStatusCounts(this._selectedAffiliates);

			if (this._reviewItemSearch == null) { // review item searches ignore selected affiliates so no need to reload
				this._refreshAccounts(getPageSizeFromReduxStore, getWorkflowsFromReduxStore);
			}

			this.emitChange();
		}

		if (action instanceof ActiveTabChangedAction) {
			if (action.activeTabIndex === ActiveTabEnum.Accounts) {
				// Nothing to do here?
			}
			this.emitChange();
		}

		if (action instanceof ColumnSelectedAction) {
			this._setColumnsInStorage(action.selectedIds, action.workflow);
			this.emitChange();
		}

		if (action instanceof ShowSearchAction) {
			this.showSearch = action.showSearch;

			if (this.showSearch) {
				// clear any previous search criteria
				this.searchCriteria = this._getEmptySearchCriteria();
			} else {
				this.currentPageIndex = 1;
				this._pageSearchResults = false;
				this._refreshAccounts(getPageSizeFromReduxStore, getWorkflowsFromReduxStore);
			}

			this.emitChange();
		}

		if (action instanceof GetStartupDataAction) {
			const loadState = getLoadState(action);
			this._startupRequest = {
				id: action.requestId,
				state: loadState
			};

			if (loadState === AsyncState.Resolved) {
				AccountsContext.GetAccountWorkflowStatusCounts(this.getSelectedAffiliates());

				if (this._reviewItemSearch == null) {
					this.currentPageIndex = 1;
					this._pageSearchResults = false;
					this._refreshAccounts(getPageSizeFromReduxStore, getWorkflowsFromReduxStore);
				}
			}

			this.emitChange();
		}

		if (action instanceof AccountStatusUpdatedAction) {
			this._refreshAccounts(getPageSizeFromReduxStore, getWorkflowsFromReduxStore);
			AccountsContext.GetAccountWorkflowStatusCounts(this.getSelectedAffiliates());
			this.emitChange();
		}

		if ((action instanceof ChangeAccountClaimStatusAction || action instanceof RollbackAccountApprovalAction) && getLoadState(action) === AsyncState.Resolved) {
			this._refreshAccounts(getPageSizeFromReduxStore, getWorkflowsFromReduxStore);
			AccountsContext.GetAccountWorkflowStatusCounts(this.getSelectedAffiliates());
			this.emitChange();
		}

		if (action instanceof PageDataChangedAction) {
			this.currentPageIndex = action.data.pageIndex;
			this._refreshAccounts(getPageSizeFromReduxStore, getWorkflowsFromReduxStore);

			this.emitChange();
		}

		if (action instanceof AccountsFilteredAction) {
			this._updateSelection([], false); // Unselected

			this.currentAccounts = action.searchResults;
			this._pageSearchResults = true;
			this._reviewItemSearch = null;

			if (this.currentAccounts.accounts.length >= 1) {
				this._updateSelection([this.currentAccounts.accounts[0]], false);
			} else if (this.currentAccounts.accountImports.length >= 1) {
				this._updateSelection(Object.assign([], [this.currentAccounts.accountImports[0]]), false);
			}

			this.emitChange();
		}

		if (action instanceof SearchCriteriaUpdatedAction) {
			this.searchCriteria = action.data;
			this.emitChange();
		}

		if (action instanceof SearchAccountsAction) {
			const {
				firstName,
				lastName,
				ssn,
				primaryAccountNumber,
				secondaryAccountNumber
			} = this.searchCriteria!;

			const workflow = this.getSelectedWorkflow(getWorkflowsFromReduxStore());
			if (workflow == null) {
				throw new Error("Unexpected state");
			}

			if (this._reviewItemSearch != null) {
				this.currentPageIndex = 1;
				this._refreshReviewItems({
					...this._reviewItemSearch,
					firstName,
					lastName,
					ssn,
					primaryAccountNumber,
					secondaryAccountNumber
				});
			} else {
				// make sure any "NavigateToAccount" search criterion (e.g. acctId) 
				// are blanked out before performing the search
				this.searchCriteria = assign(this._getEmptySearchCriteria(), {
					firstName,
					lastName,
					ssn,
					primaryAccountNumber,
					secondaryAccountNumber,
					accountWorkflowId: workflow.id,
					affiliateIds: this.getSelectedAffiliates()
				});

				this.currentPageIndex = 1;
				this._refreshSearchAccounts(getWorkflowsFromReduxStore());
			}

			this.emitChange();
		}

		if (action instanceof SortChangedAction) {
			let newSort = [AccountsContext.defaultSortInfo];

			if (action.sortInfo.length === 1) {
				newSort = action.sortInfo;
			} else if (action.sortInfo.length > 1) {
				newSort = [action.sortInfo[1]];
			}

			const selectedWorkFlow = this.getSelectedWorkflow(getWorkflowsFromReduxStore());
			if (selectedWorkFlow == null) {
				throw new Error("Unexpected state");
			}

			this._sortInfoByWorkflow = {
				...this._sortInfoByWorkflow,
				[selectedWorkFlow.id]: newSort
			};

			this._setSortInStorage(newSort, selectedWorkFlow.id);
			this.selectedWorkFlowState = selectedWorkFlow;
			this.currentPageIndex = 1;
			this._refreshAccounts(getPageSizeFromReduxStore, getWorkflowsFromReduxStore);
			this.emitChange();
		}

		if (action instanceof NavigateToAccountAction) {
			const { acctId, account } = action;

			if (account != null) {
				const affiliateIds = this.getSelectedAffiliates();
				if (affiliateIds.indexOf(account.clientId) === -1) {
					toast.error("You currently do not have this affiliate selected for viewing, to select this affiliate please go to the affiliates tab.");
					return;
				}
			}

			(async () => {
				const hideBusy = startBusy("Retrieving...");

				try {
					const response = await advancedSearchAccounts(
						{
							...this._getEmptySearchCriteria(),
							acctId: acctId.toString()
						},
						0,
						1,
						"acctId",
						"asc"
					);

					handleApiResponse(response, () => navigateToAccountLoaded(response.accounts[0] || null));
				} catch (error) {
					handleGenericError(error);
				} finally {
					hideBusy();
				}
			})();

			this.emitChange();
		}

		if (action instanceof NavigateToAccountImportAction) {
			const { accountImport } = action;

			const affiliateIds = this.getSelectedAffiliates();
			if (affiliateIds.indexOf(accountImport.clientId) === -1) {
				toast.error("You currently do not have this affiliate selected for viewing, to select this affiliate please go to the affiliates tab.");
				return;
			}

			(async () => {
				const hideBusy = startBusy("Retrieving...");

				try {
					// can't search by acctImportId directly, so search by client and account number
					// and page through the results until we find the matching acctImportId

					let foundAccount: Dto.IAccountImport | null | undefined;
					let startIndex = 0;
					const pageSize = 50;

					while (foundAccount === undefined) {
						const response = await advancedSearchAccounts(
							{
								...this._getEmptySearchCriteria(),
								affiliateIds: [accountImport.clientId],
								primaryAccountNumber: accountImport.primaryAccountNumber
							},
							startIndex,
							startIndex + pageSize,
							"acctId",
							"asc"
						);

						handleApiResponse(response);

						foundAccount = response.accountImports.find(it => it.acctImportId === accountImport.acctImportId);
						if (foundAccount === undefined && response.accountImports.length < pageSize) {
							// nothing left to check, 
							foundAccount = null;
						}
					}

					navigateToAccountLoaded(foundAccount);
				} catch (error) {
					handleGenericError(error);
				} finally {
					hideBusy();
				}
			})();

			this.emitChange();
		}

		if (action instanceof NavigateToAccountLoadedAction) {
			const { account } = action;

			if (account == null) {
				toast.warning("Account not available.");
				return;
			}

			const affiliateIds = this.getSelectedAffiliates();
			if (affiliateIds.indexOf(account.clientId) === -1) {
				toast.warning("You currently do not have this affiliate selected for viewing, to select this affiliate please go to the affiliates tab.");
				return;
			}

			Dispatcher.waitFor([ReduxStore.token]);
			const workflowStepId = getWorkflowStepForAccount(account, ReduxStore.getState())?.accountWorkflowStepId;

			const workflowStep = workflowStepId == null ? null : getWorkflowsFromReduxStore().find(it => it.id === workflowStepId);
			if (workflowStep == null) {
				toast.genericError();
				return;
			}

			this.selectedWorkFlowState = workflowStep;
			this._selectedStatusIdsByWorkflow = {
				...this._selectedStatusIdsByWorkflow,
				[workflowStep.id]: null
			};

			this.selectedLeftTabId = 1;
			this.currentAccountAction = AccountsActionEnum.Normal;
			this.currentPageIndex = 1;

			this.showSearch = true;
			this.searchCriteria = {
				...this._getEmptySearchCriteria(),
				firstName: account.firstName,
				lastName: account.lastName,
				ssn: isEmpty(account.ssn) || redactedSsn(account.ssn) ? "" : account.ssn,
				primaryAccountNumber: account.primaryAccountNumber,
				secondaryAccountNumber: account.secondaryAccountNumber
			};

			const numberOfAccounts = account == null ? 1 : 0;
			this.currentAccounts = {
				status: OK,
				statusMessage: "",
				accounts: AccountsContext.isAccount(account) ? [account] : [],
				accountImports: AccountsContext.isAccountImport(account) ? [account] : [],
				numberOfAccounts,
				totalNumberOfAccounts: numberOfAccounts
			};

			this._updateSelection(account == null ? [] : [account as any], false);

			this.emitChange();
		}

		if (action instanceof SaveAccountSuccessAction) {
			if (action.response.item != null) {
				const account = find(this.selectedAccounts, this._getFindAccountPredicate(action.response.item));

				let statusChanged = false;
				if (account != null) {
					statusChanged = AccountsContext.getStatusId(action.response.item) !== AccountsContext.getStatusId(account);
					Object.assign(account, action.response.item);
				}

				if (statusChanged) {
					// flagged to preplacment is probably the only possible way this can happen
					this._refreshAccounts(getPageSizeFromReduxStore, getWorkflowsFromReduxStore);
					AccountsContext.GetAccountWorkflowStatusCounts(this.getSelectedAffiliates());
				}

				this.emitChange();
			}
		}

		if (
			action instanceof SaveAccountMiscAction
			&& action.state === AsyncState.Resolved
			&& (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)
		) {
			const account = this.selectedAccounts.find(account => {
				return AccountsContext.isAccount(account) && account.acctId === action.params.accountMisc.acctId;
			});

			if (account != null && account.writeTimestamp === action.params.accountMisc.writeTimestamp) {
				account.pendingApproval = action.response!.item.pendingApproval;
				account.writeTimestamp = action.response!.item.writeTimestamp;
				this.emitChange();
			}
		}

		if (action instanceof CancelUploadAction) {
			this.currentAccountAction = AccountsActionEnum.Normal;
			this.selectedLeftTabId = 1;
			this.emitChange();
		}

		if (action instanceof ReviewItemSearchAction) {
			this._handleReviewItemsSearch(action.workflowId, action.search);
		}

		if (action instanceof ReviewItemSearchResponseAction) {
			this._handleReviewItemAccountsLoaded(action.response);
		}

		if (action instanceof AdvancedSearchAccountsAction && action.tags[REVIEW_ITEM_SEARCH_TAG]) {
			this._reviewItemSearchLoadState = action.state;
			this.emitChange();
		}


		if (!this.wasChangeEmitted() && previousUserState !== this._userState) {
			this.emitChange();
		}
	}

	private _getFindAccountPredicate(account: Dto.IAccount | Dto.IAccountImport) {
		return AccountsContext.ifAccount(account,
			(account: Dto.IAccount) => (it: Dto.IAccount | Dto.IAccountImport) => AccountsContext.ifAccount(it, it => it.acctId === account.acctId, false),
			(accountImport: Dto.IAccountImport) => (it: Dto.IAccount | Dto.IAccountImport) => AccountsContext.ifAccountImport(it, it => it.acctImportId === accountImport.acctImportId, false)
		) as (account: Dto.IAccount | Dto.IAccountImport) => boolean;
	}

	private _refreshAccounts(pageSize: () => number, workflows: () => IWorkflowMeta[]) {
		if (this._pageSearchResults) {
			this._refreshSearchAccounts(workflows());
		} else if (this._reviewItemSearch != null) {
			this._refreshReviewItems(this._reviewItemSearch);
		} else {
			this._refreshAccountsForWorkFlow(pageSize, workflows);
		}
	}

	private async _refreshAccountsForWorkFlow(
		pageSize: () => number,
		workflows: () => IWorkflowMeta[]
	) {
		// params need to be getter functions and not values due to the async nature of this method

		if (this._startupRequest == null || this._startupRequest.state === AsyncState.Pending) {
			await waitFor(this._startupRequest!.id);
		}

		if (this._startupRequest!.state !== AsyncState.Resolved) {
			return;
		}

		const workflow = this.getSelectedWorkflow(workflows());
		if (workflow == null) {
			throw new Error("Unexpected state");
		}

		const pageSizeVal = pageSize();
		const { startIndex, pageIndex } = this.getPageData(pageSizeVal, workflow.id);
		const sortInfo = this.getSortInfo(workflow.id)[0];
		const params: IGetAccountsByAccountStatusAndWorkflowStatusIDActionParams = {
			accountWorkflowStatusID: workflow.id,
			searchClientIDs: this.getSelectedAffiliates(),
			startIndex,
			endIndex: pageIndex * pageSizeVal,
			sortField: sortInfo.name,
			sortDirection: sortInfo.dir > 0 ? "ASC" : "DESC",
			accountStatusIds: this._selectedStatusIdsByWorkflow[workflow.id]!
		};

		if (this._accountsRequest != null && this._accountsRequest.state === AsyncState.Pending) {
			if (isEqual(this._accountsRequest.params, params)) {
				// already requesting this, just wait
				await catchAsyncErrors(waitFor(this._accountsRequest.id));
				return;
			}

			cancel(this._accountsRequest.id);
		}

		let promise;
		switch (workflow.id) {
			case AccountWorkflowSteps.Flagged:
			case AccountWorkflowSteps.PrePlacement:
				promise = getAccountImportsByAccountStatusAndWorkflowStatusID(
					params.accountWorkflowStatusID,
					params.searchClientIDs,
					params.startIndex,
					params.endIndex,
					params.sortField,
					params.sortDirection,
					params.accountStatusIds
				);
				break;
			default:
				promise = getAccountsByAccountStatusAndWorkflowStatusID(
					params.accountWorkflowStatusID,
					params.searchClientIDs,
					params.startIndex,
					params.endIndex,
					params.sortField,
					params.sortDirection,
					params.accountStatusIds
				);
				break;
		}

		this._accountsRequest = {
			id: promise.requestId,
			state: AsyncState.Pending,
			params
		};

		await catchAsyncErrors(promise);
	}

	private _refreshSearchAccounts(workflows: IWorkflowMeta[]) {
		const workflow = this.getSelectedWorkflow(workflows);
		if (workflow == null) {
			throw new Error("Unexpected state");
		}

		const selectedStatusIds = this._selectedStatusIdsByWorkflow[workflow.id];

		var pageData: AccountsData.IPageData = this.getPageData(getPageSizeFromReduxStore(), workflow.id);
		const userSearch = {
			...this.getSearchCriteria(),
			accountStatusIds: translateAccountStatusIds(workflow.id, selectedStatusIds || null)
		};

		const sortInfo = this.getSortInfo(workflow.id)[0];
		var searchPayload: AccountsData.IAdvancedSearchAccounts = {
			startIndex: pageData.startIndex, endIndex: pageData.pageIndex * pageData.pageSize, sortField: sortInfo.name,
			sortDirection: sortInfo.dir > 0 ? "ASC" : "DESC", userSearch: userSearch
		};

		AccountsContext.SearchAccounts(searchPayload, true);
	}

	private _handleReviewItemsSearch(workflowId: number, search: Partial<Dto.IClientProfileUserSearch>) {
		if (this._accountsRequest != null && this._accountsRequest.state === AsyncState.Pending) {
			cancel(this._accountsRequest.id);
		}

		this.currentAccounts = undefined;

		this.selectedWorkFlowState = { id: workflowId };
		this.currentAccountAction = AccountsActionEnum.Normal;
		this.currentPageIndex = 1;
		this._pageSearchResults = false;
		this.showSearch = false;

		this._reviewItemSearch = {
			...this._getEmptySearchCriteria(),
			...search,
			accountWorkflowId: workflowId
		};

		this._refreshReviewItems(this._reviewItemSearch);

		this.emitChange();
	}

	private _handleReviewItemAccountsLoaded(response: Dto.IAccountsResponse) {
		this._updateSelection([], false); // Unselected

		this.currentAccounts = response;
		if (this.currentAccounts.accounts.length === 1) {
			this._updateSelection([this.currentAccounts.accounts[0]], false);
		} else if (this.currentAccounts.accountImports.length === 1) {
			this._updateSelection(Object.assign([], [this.currentAccounts.accountImports[0]]), false);
		}

		this.emitChange();
	}

	private async _refreshReviewItems(search: Dto.IClientProfileUserSearch) {
		const pageData = this.getPageData(getPageSizeFromReduxStore(), null);

		const hideBusy = startBusy("Retrieving...");

		const sortInfo = this.getSortInfo()[0];
		try {
			const data = await advancedSearchAccounts(
				search,
				pageData.startIndex,
				pageData.pageIndex * pageData.pageSize,
				sortInfo.name,
				sortInfo.dir > 0 ? "ASC" : "DESC",
				{
					tags: REVIEW_ITEM_SEARCH_TAG
				}
			);

			handleApiResponse(data, () => reviewItemSearchResponse(data));
		} catch (error) {
			handleGenericError(error);
		} finally {
			hideBusy();
		}
	}
}

export default new AccountsStore();