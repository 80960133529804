import AsyncState from "./AsyncState";
export { default as AsyncState } from "./AsyncState";

export function getCombinedState(...asyncs: IAsync<any>[]) {
	return combineStates(...asyncs.map(it => it == null ? null : it.state));
}

export function combineStates(...states: (AsyncState | null)[]) {
	let pending = false;
	for (const state of states) {
		if (state == null) {
			pending = true;
			continue;
		}

		switch (state) {
			case AsyncState.Pending:
				pending = true;
				break;
			case AsyncState.Rejected:
				return AsyncState.Rejected;
			case AsyncState.Resolved:
				break;
			default:
				if (__DEV__) {
					console.error(`[${__filename}] Unknown state: ${state}`);
				}
				break;
		}
	}

	return pending ? AsyncState.Pending : AsyncState.Resolved;
}

export function makeAsync<T>(state: AsyncState | null, payload: T | null = null): IAsync<T> {
	return {
		state,
		payload
	};
}

interface IAsync<T> {
	state: AsyncState | null;
	payload: T | null;
}

export default IAsync;