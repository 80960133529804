import getLoadState from "../../../../Utils/getLoadState";
import AsyncState from "../../../../Models/AsyncState";
import assign from "../../../../Utils/assign";
import * as actions from "../../../../Actions/Settings/RolesActions";
import IAction from "../../../../Actions/IAction";
import * as Dto from "../../../../Models/dto";
import { NULL_DATE_STRING } from "../../../../Utils/dateUtils";
import {
	SaveClientProfileRoleAction,
	DeleteClientProfileRoleAction
} from "../../../../Actions/Api/clientActions";
import makeRoleUpdateReducer from "../../../Entities/makeRoleUpdateReducer";
import reduceReducers from "reduce-reducers";

type EditMap = { [clientProfileRoleId: number]: Dto.IClientProfileRole | undefined };

export interface IState {
	edits: EditMap; // TODO refactor to just have a single edit
	selectedRoleId: number | null;
	newRole: Dto.IClientProfileRole | null;
}

const rolesReducer = (
	previousState: IState = {
		edits: {},
		selectedRoleId: null,
		newRole: null
	},
	action: IAction
): IState => {
	if (action instanceof actions.SelectRoleAction) {
		return assign<IState, Pick<IState, "selectedRoleId">>(previousState, {
			selectedRoleId: action.roleId
		});
	}

	if (action instanceof actions.UpdateRoleAction) {
		const { edit } = action;
		const { clientProfileRoleId } = edit;

		if (clientProfileRoleId > 0) {
			return Object.assign({}, previousState, {
				edits: Object.assign({}, previousState.edits, {
					[clientProfileRoleId]: edit
				})
			});
		} else {
			if (__DEV__ && previousState.newRole == null) {
				console.error(`[${__filename}] New role update received but ${nameof(previousState.newRole)} not defined`);
			}

			return Object.assign({}, previousState, {
				newRole: edit
			});
		}
	}

	if (action instanceof SaveClientProfileRoleAction && getLoadState(action) === AsyncState.Resolved) {
		return Object.assign({}, previousState, {
			newRole: null,
			selectedRoleId: action.response!.item.clientProfileRoleId
		});
	}

	if (
		action instanceof DeleteClientProfileRoleAction
		&& getLoadState(action) === AsyncState.Resolved
		&& action.params.clientProfileRole.clientProfileRoleId === previousState.selectedRoleId
	) {
		return assign<IState, Pick<IState, "selectedRoleId">>(previousState, {
			selectedRoleId: null
		});
	}

	if (action instanceof actions.AddRoleAction && action.addingRole === (previousState.newRole == null)) {
		let newRole: Dto.IClientProfileRole | null = null;
		if (action.addingRole) {
			newRole = {
				applicationRoles: [],
				clientProfileRoleId: -1,
				locked: false,
				name: "",
				writeTimestamp: NULL_DATE_STRING
			};
		}

		return assign(previousState, { newRole });
	}

	return previousState;
};

const roleUpdateReducer = makeRoleUpdateReducer<IState>((previousState, updatedRoles, deletedRoleId) => {
	let edits = assign<EditMap, EditMap>(previousState.edits, updatedRoles);
	if (deletedRoleId != null) {
		delete edits[deletedRoleId]; // safe to mutate
	}

	return assign(previousState, { edits });
});

export default reduceReducers<IState, IAction>(
	rolesReducer,
	roleUpdateReducer
);