import * as React from "react";
import createContextComponent from "../../../Utils/createContextComponent";
import cn from "classnames";
import uniqueRandom from "../../../Utils/uniqueRandom";
import propTypes from "prop-types";

export interface IColumnSize {
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
}

export interface IContextProps {
	horizontal?: boolean;
	labelColumns?: number | IColumnSize;
	controlColumns?: number | IColumnSize;
}

export interface IFormStyle extends IContextProps {
	condensed?: boolean;
}

interface IProps extends IFormStyle {
	className?: string;
	children?: React.ReactNode;
	onSubmit?: React.FormEventHandler<HTMLFormElement>;
}

export function getContextProps(props: IContextProps): IContextProps {
	const { horizontal, labelColumns, controlColumns } = props;
	return {
		horizontal,
		labelColumns,
		controlColumns
	};
}

export function getStyleProps(props: IFormStyle): IFormStyle {
	return Object.assign(getContextProps(props), {
		condensed: props.condensed
	});
}

const { Component: FormContext, connectToContext } = createContextComponent<IContextProps>("FormContext");

const inAForm = `Form_InAForm_${uniqueRandom}`;
const types = {
	[inAForm]: propTypes.any
};

class Form extends React.PureComponent<IProps> {
	static contextTypes = types;
	static childContextTypes = types;

	getChildContext() {
		return {
			[inAForm]: true
		};
	}

	render() {
		const { props, context } = this;

		return (
			<FormContext {...getContextProps(props)} >
				{React.createElement(context[inAForm] ? "div" : "form", {
					className: cn(props.className, { "form-horizontal": props.horizontal, "form-condensed": props.condensed }),
					onSubmit: props.onSubmit
				}, [props.children])}
			</FormContext>
		);
	}
}

export const connectToFormContext = connectToContext;
export default Form;