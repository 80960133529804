/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IClientProfile,
	IClientProfileParty,
	ICompleteRegistrationModel,
	IPendingRegistrationResponse,
	IRegistrationDataResponse,
	IRegistrationResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface ICompleteRegistrationActionParams {
	model: ICompleteRegistrationModel;
}

export class CompleteRegistrationAction extends ApiActionBase<ICompleteRegistrationActionParams, IRegistrationResponse> {
	constructor(details: IDetails<ICompleteRegistrationActionParams, IRegistrationResponse>) {
		super("Api/ClientRegistrationActions/CompleteRegistrationAction", details);
	}
}

export interface IGetClientRegistrationDataActionParams {
	/* no parameters */
}

export class GetClientRegistrationDataAction extends ApiActionBase<IGetClientRegistrationDataActionParams, IRegistrationDataResponse> {
	constructor(details: IDetails<IGetClientRegistrationDataActionParams, IRegistrationDataResponse>) {
		super("Api/ClientRegistrationActions/GetClientRegistrationDataAction", details);
	}
}

export interface IGetPendingRegistrationActionParams {
	registrationId: string;
}

export class GetPendingRegistrationAction extends ApiActionBase<IGetPendingRegistrationActionParams, IPendingRegistrationResponse> {
	constructor(details: IDetails<IGetPendingRegistrationActionParams, IPendingRegistrationResponse>) {
		super("Api/ClientRegistrationActions/GetPendingRegistrationAction", details);
	}
}

export interface ISaveRegistrationActionParams {
	captchaChallenge: string;
	captchaResponse: string;
	clientProfile: IClientProfile;
	administrator: IClientProfileParty;
}

export class SaveRegistrationAction extends ApiActionBase<ISaveRegistrationActionParams, IRegistrationResponse> {
	constructor(details: IDetails<ISaveRegistrationActionParams, IRegistrationResponse>) {
		super("Api/ClientRegistrationActions/SaveRegistrationAction", details);
	}
}

/* tslint:enable:max-line-length */