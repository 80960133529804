import uniqueId from "lodash/uniqueId";

const CHARS = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
function encode(value: number) {
	if (value === 0) {
		return "0";
	}

	let encoded = "";
	while (value > 0) {
		encoded = CHARS[value % CHARS.length] + encoded;
		value = Math.floor(value / CHARS.length);
	}

	return encoded;
}

const time = new Date().getTime();
const random = Math.floor(Math.random() * 1000000000);
const prefix = `${encode(time)}-${encode(random)}-`;

export default function uid() {
	return uniqueId(prefix);
}