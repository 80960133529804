import IAction from "../Actions/IAction";

export default function combineReducers<TState extends {}>(
	reducers: { [key: string]: (state: any, action: IAction) => any }
) {
	return function reducer(previousState: TState = {} as TState, action: IAction): TState {
		const reducerKeys = Object.keys(reducers);

		let hasChanged = false;
		const nextState = {} as TState;

		for (let i = 0; i < reducerKeys.length; i++) {
			const key = reducerKeys[i];
			const reducer = reducers[key];

			const previousStateForKey = (previousState as any)[key];
			const nextStateForKey = reducer(previousStateForKey, action);

			(nextState as any)[key] = nextStateForKey;
			hasChanged = hasChanged || nextStateForKey !== previousStateForKey;
		}

		return hasChanged ? nextState : previousState;
	};
}
