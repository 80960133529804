import * as React from "react";
import { Modal } from "react-bootstrap";
import DraggableModal from "../Common/DraggableModal/DraggableModal";
import Button from "../Common/Button/Button";

interface IProps {
	show: boolean;
	secondsRemaining: number | null;
	onNotIdle(): void;
}

const IdlePopup = (props: IProps) => (
	<DraggableModal
		show={props.show}
		onHide={props.onNotIdle}
		backdrop="static"
	>
		<Modal.Header>
			<Modal.Title>Are you there?</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<div className="row">
				<div className="col-xs-12">
					{"Due to a lack of activity your session will be locked in "}
					<span className="badge">{props.secondsRemaining}</span>
					{props.secondsRemaining === 1 ? " second" : " seconds"}.
				</div>
			</div>
		</Modal.Body>
		<Modal.Footer>
			<Button
				label="I'm still here"
				onClick={props.onNotIdle}
				primary
			/>
		</Modal.Footer>
	</DraggableModal>
);

export default IdlePopup;