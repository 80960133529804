import React from "react";
import Loader, { IProps as ILoaderProps } from "../Loader/Loader";
import styles from "./TabLoader.module.scss";
import cn from "classnames";

interface IProps extends ILoaderProps { }

export default class TabLoader extends React.Component<IProps, {}> {
	render() {
		return (
			<div className={cn(styles.container, this.props.className)}>
				<Loader
					{...this.props}
					className={styles.loader}
				/>
			</div>
		);
	}
}