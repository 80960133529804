import isAuthenticated from "../selectors/isAuthenticated";
import isLocked from "../selectors/isLocked";
import { lock } from "../ActionCreators/LoginActionCreators";
import ReduxStore from "../Stores/ReduxStore";
import {
	clientLogoutEndpoint,
	getUserAuthenticatedEndpoint,
	keepAlive
} from "../ActionCreators/Api/loginActionCreators";
import NeedsUpdateAction from "../Actions/Login/NeedsUpdateAction";
import { StopAllBusyAction } from "../Actions/BusyAction";
import ApiActionBase, { IDetails } from "../Actions/ApiActionBase";
import uniqueRandom from "../Utils/uniqueRandom";
import createDeferred from "../Utils/createDeferred";
import Dispatcher from "../Dispatcher/Dispatcher";
import { AsyncState } from "../Components/Common/AsyncContainer/AsyncContainer";
import ExtendableError from "es6-error";
import axios, { AxiosError, AxiosRequestConfig, CancelTokenSource } from "axios";
import uid from "./uid";

let buildId: string = "";

const csrfPromise = new Promise<string>(resolve => {
	if ((window as any)["csrfToken"]) {
		resolve((window as any)["csrfToken"][0]);
	}

	(window as any)["csrfToken"] = {
		push: resolve
	};
});

export interface IOptions<TResponse> {
	tags?: string | string[];
	retry?: (state: AsyncState.Resolved | AsyncState.Rejected, response: TResponse | null, attempt: number) => boolean;
}

export type ApiErrorType = "timeout" | "error" | "abort" | "parsererror";

export class ApiError extends ExtendableError {
	constructor(public type: ApiErrorType, public status: number = 0, public statusText: string | null = null) {
		super(`${type}: ${status} ${statusText}`);
	}
}

interface IActionCtor<TParams, TAction, TResponse> {
	new(details: IDetails<TParams, TResponse>): TAction;
}

interface IRequest<TParams extends { [name: string]: any }, TAction extends ApiActionBase<TParams, TResponse>, TResponse> {
	requestId: string;
	url: string;
	authenticated: boolean;
	options: IOptions<TResponse>;
	actionCtor: IActionCtor<TParams, TAction, TResponse>;
	params: TParams;
	data: string | FormData;
	contentType: string;
	promise: Promise<TResponse>;
	resolve: (response: TResponse) => void;
	reject: (error: any) => void;
	cancelTokenSource: CancelTokenSource;
	cancelled: boolean;
	attempt: number;
}

const activeRequests: { [requestId: string]: IRequest<any, any, any>; } = {};

function getPayload(params: { [name: string]: any }) {
	const files: { [key: string]: Blob } = {};
	const json = JSON.stringify(params || {}, (_key: string, value: any) => {
		if (value instanceof Blob) {
			const fileId = `__file_${uniqueRandom()}__`;
			files[fileId] = value;
			return fileId;
		}

		return value;
	});

	let data: string | FormData = json;
	let contentType = "application/json";

	const fileIds = Object.keys(files);
	if (fileIds.length > 0) {
		const formData = new FormData();
		formData.append("payload", json);

		for (const fileId of fileIds) {
			formData.append(fileId, files[fileId]);
		}

		data = formData;
		contentType = "multipart/form-data";
	}

	return {
		data,
		contentType
	};
}

export default function apiExecute<TParams extends { [name: string]: any }, TAction extends ApiActionBase<TParams, TResponse>, TResponse>(
	url: string,
	authenticated: boolean,
	options: IOptions<TResponse> | undefined,
	actionCtor: IActionCtor<TParams, TAction, TResponse>,
	params: TParams
) {
	const requestId = uid();
	const { resolve, reject, promise } = createDeferred<TResponse>();
	const { data, contentType } = getPayload(params);

	options = options || {};

	const request: IRequest<TParams, TAction, TResponse> = activeRequests[requestId] = {
		requestId,
		url,
		authenticated,
		options: options,
		actionCtor,
		params,
		data,
		contentType,
		promise,
		resolve,
		reject,
		cancelTokenSource: axios.CancelToken.source(),
		cancelled: false,
		attempt: 0
	};

	Dispatcher.dispatch(new actionCtor({
		requestId,
		params,
		state: AsyncState.Pending,
		tags: options.tags
	}));

	executeXhr(request);

	return Object.assign(promise, {
		requestId
	});
}

function executeXhr<TParams extends { [name: string]: any }, TAction extends ApiActionBase<TParams, TResponse>, TResponse>(
	request: IRequest<TParams, TAction, TResponse>
) {
	const {
		url,
		authenticated,
		options,
		actionCtor,
		params,
		data,
		contentType,
		requestId,
		resolve,
		reject,
		cancelled
	} = request;

	if (cancelled) {
		// cancelled before/between xhr executions
		delete activeRequests[requestId];
		reject(new ApiError("abort"));
		return;
	}

	if (authenticated && isLocked(ReduxStore.getState()) && !allowUrlWhenLocked(url)) {
		executeXhrWhenUnlocked(request);
		return;
	}

	request.attempt++;

	csrfPromise.then(csrfToken => {
		let headers: any = {
			"Content-Type": contentType,
			"X-Requested-With": "XMLHttpRequest",
			"X-Forte-UI": "OnDemandV4",
			"X-CSRF-TOKEN": csrfToken
		};

		if (buildId !== "") {
			headers["pfod-build"] = buildId;
		}

		const config: AxiosRequestConfig = {
			url: `/${url}`,
			method: "POST",
			data,
			headers: headers,
			cancelToken: request.cancelTokenSource.token
		};

		axios(config).then(
			payload => {
				const response = payload.data as TResponse;

				if (buildId === "" && payload.headers["pfod-build"]) {
					buildId = payload.headers["pfod-build"];
				}

				if (options.retry != null && options.retry(AsyncState.Resolved, response, request.attempt)) {
					executeXhr(request);
					return;
				}

				delete activeRequests[requestId];

				Dispatcher.dispatch(new actionCtor({
					requestId,
					params,
					state: AsyncState.Resolved,
					response,
					tags: options.tags
				}));

				resolve(response);
				scheduleKeepAlive();
			},
			(error: AxiosError) => {
				let status;
				let statusText;
				if (error.response != null) {
					({ status, statusText } = error.response);
				}

				if (status === 426) {
					Dispatcher.dispatch(new StopAllBusyAction());
					Dispatcher.dispatch(new NeedsUpdateAction());
					delete activeRequests[requestId];
					return;
				}

				if (lockCheck(status, request)) {
					request.attempt--;
					return;
				}

				if (options.retry != null && options.retry(AsyncState.Rejected, null, request.attempt)) {
					executeXhr(request);
					return;
				}

				delete activeRequests[requestId];

				const apiError = new ApiError(
					axios.isCancel(error) ? "abort" : "error",
					status,
					statusText
				);

				Dispatcher.dispatch(new actionCtor({
					requestId,
					params,
					state: AsyncState.Rejected,
					error: apiError,
					tags: options.tags
				}));

				reject(apiError);
				scheduleKeepAlive();
			}
		);
	});
}

function allowUrlWhenLocked(url: string) {
	return [
		clientLogoutEndpoint,
		getUserAuthenticatedEndpoint
	].indexOf(url) !== -1;
}

function lockCheck<TParams extends { [name: string]: any }, TAction extends ApiActionBase<TParams, TResponse>, TResponse>(
	status: number | undefined,
	request: IRequest<TParams, TAction, TResponse>
) {
	if (status !== 401 || allowUrlWhenLocked(request.url)) {
		return false;
	}

	const state = ReduxStore.getState();
	const locked = isLocked(state);

	if (locked || isAuthenticated(state)) {
		if (!locked) {
			lock();
		}

		executeXhrWhenUnlocked(request);

		return true;
	}

	return false;
}

let waiting = false;
const pending: IRequest<any, any, any>[] = [];

function executeXhrWhenUnlocked<TParams extends { [name: string]: any }, TAction extends ApiActionBase<TParams, TResponse>, TResponse>(
	request: IRequest<TParams, TAction, TResponse>
) {
	pending.push(request);

	if (!waiting) {
		waiting = true;
		const checkIfUnlocked = () => {
			if (!isLocked(ReduxStore.getState())) {
				while (pending.length > 0) {
					executeXhr(pending.shift()!);
				}

				waiting = false;
				ReduxStore.removeListener(checkIfUnlocked);
			}
		};

		ReduxStore.addListener(checkIfUnlocked);
		checkIfUnlocked();
	}
}

export function cancel(requestId: string | null | undefined) {
	if (requestId != null) {
		const request = activeRequests[requestId];
		if (request != null && !request.cancelled) {
			request.cancelled = true;
			request.cancelTokenSource.cancel();
		}
	}
}

export function waitFor(requestId: string | null) {
	if (requestId != null) {
		const request = activeRequests[requestId];
		if (request != null) {
			return request.promise;
		}
	}

	return Promise.resolve();
}

export interface IFakeJQueryXhr<T> extends Promise<T> {
	state: () => string;
	abort: () => void;
}

export function makeFakeJqueryXhr<T>(promise: Promise<T> & { requestId: string }): IFakeJQueryXhr<T> {
	let state = "pending";
	const newPromise = promise
		.then(response => {
			state = "resolved";
			return response;
		}, err => {
			state = "rejected";
			throw err;
		});

	return Object.assign(newPromise, {
		state: () => state,
		abort: () => cancel(promise.requestId)
	});
}

let keepAliveHandle: number;
function scheduleKeepAlive() {
	clearTimeout(keepAliveHandle);
	keepAliveHandle = setTimeout(tryKeepAlive, 10 * 60 * 1000) as any;
}

function tryKeepAlive() {
	if (isAuthenticated(ReduxStore.getState())) {
		keepAlive().catch(error => {
			if (__DEV__) {
				console.warn(error);
			}
		});
	} else if (__DEV__) {
		console.log(`[${__filename}] Skipping keep alive, not authenticated`);
	}
}