import React from "react";
import AppStore from "../../../../Stores/AppStore";
import BusyOverlay from "../BusyOverlay";

interface IProps {
	locked?: boolean;
}

interface IState {
	message: string | null;
}

function getStateFromStore(props: IProps): IState {
	return {
		message: AppStore.getBusyMessage(!!props.locked)
	};
}

export default class LegacyConnector extends React.Component<IProps, IState> {
	private _updateState = () => this.setState(getStateFromStore(this.props));

	state = getStateFromStore(this.props);

	componentDidMount() {
		AppStore.addListener(this._updateState);
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.locked !== prevProps.locked) {
			this._updateState();
		}
	}

	componentWillUnmount() {
		AppStore.removeListener(this._updateState);
	}

	render() {
		return <BusyOverlay message={this.state.message} />;
	}
}