import React from "react";
import Button, { IProps as IButtonProps } from "../../Common/Button/Button";
import styles from "./AuthButton.module.scss";

type IProps = Omit<IButtonProps, "primary" | "success" | "info" | "warning" | "danger" | "alt" | "link" | "default">;

export default function AuthButton(props: IProps) {
	return (
		<Button
			{...props}
			className={[styles.button, props.className]}
			alt
		/>
	)
};

AuthButton.defaultProps = {
	type: "submit"
};