import ActiveTabEnum from "../../../Models/ActiveTabEnum";
import { tabLoaded } from "../../../ActionCreators/AppActionCreators";
import createModuleLoader from "../../../Utils/createModuleLoader";
import _Accounts from "./Accounts";

const { loader, load } = createModuleLoader<typeof _Accounts>();

(async () => {
	const accountsModule = await import("./Accounts" /* webpackChunkName: "accounts" */);
	load(accountsModule.default);
	tabLoaded(ActiveTabEnum.Accounts);
})();

export default loader;
