import {
	GetClientProfileIPWhiteListAction,
	SaveClientProfileIPWhiteListAction
} from "../../../../Actions/Api/clientActions";
import IAction from "../../../../Actions/IAction";
import IAsync, { AsyncState } from "../../../../Models/IAsync";
import getLoadState from "../../../../Utils/getLoadState";
import * as actions from "../../../../Actions/Settings/IpWhitelistActions";
import * as Dto from "../../../../Models/dto";

export interface IEdit {
	ranges: Dto.IClientProfileIPWhiteList[];
}

export interface IState {
	ranges: IAsync<Dto.IClientProfileIPWhiteList[]>;
	edit: IEdit;
}

const DEFAULT_STATE: IState = {
	ranges: {
		state: null,
		payload: []
	},
	edit: {
		ranges: []
	}
};

export default function ipWhitelistReducer(
	previousState: IState = DEFAULT_STATE,
	action: IAction
): IState {
	if (action instanceof GetClientProfileIPWhiteListAction) {
		const asyncState = getLoadState(action);
		let ranges: Dto.IClientProfileIPWhiteList[] = [];
		let edit = previousState.edit;

		if (asyncState === AsyncState.Resolved) {
			const { response } = action;
			ranges = response!.items;
			edit = { ...previousState.edit, ranges: response!.items };
		}
		return {
			...previousState,
			ranges: { state: asyncState, payload: ranges },
			edit: edit
		};
	}

	if (action instanceof actions.UpdateIpWhitelistAction) {
		return {
			...previousState,
			edit: {
				...previousState.edit,
				...action.edit
			}
		};
	}

	if (action instanceof SaveClientProfileIPWhiteListAction && getLoadState(action) === AsyncState.Resolved) {
		return {
			...previousState,
			ranges: {
				...previousState.ranges,
				payload: action.response!.items
			},
			edit: {
				...previousState.edit,
				ranges: action.response!.items
			}
		};
	}

	return previousState;
}