/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	AddStatementAction,
	IAddStatementActionParams,
	DeleteAccountClaimDocumentAction,
	IDeleteAccountClaimDocumentActionParams,
	GetAllDocumentsByAcctIDAction,
	IGetAllDocumentsByAcctIDActionParams,
	UploadAccountClaimDocumentAction,
	IUploadAccountClaimDocumentActionParams
} from "../../Actions/Api/documentActions";
import {
	IBaseResponse,
	ILinkedDocumentResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const addStatementEndpoint = "Api/Document/AddStatement";

export function addStatement(
	acctId: number, 
	fileName: string, 
	fileBytes: string | Blob, 
	__options__?: IOptions<ILinkedDocumentResponse>
) {
	return apiExecute<IAddStatementActionParams, AddStatementAction, ILinkedDocumentResponse>(
		addStatementEndpoint, 
		true, 
		__options__, 
		AddStatementAction,
		{
			acctId,
			fileName,
			fileBytes
		}
	);
}

export const deleteAccountClaimDocumentEndpoint = "Api/Document/DeleteAccountClaimDocument";

export function deleteAccountClaimDocument(
	accountID: number, 
	claimDocID: number, 
	accountDocID: number, 
	__options__?: IOptions<IBaseResponse>
) {
	return apiExecute<IDeleteAccountClaimDocumentActionParams, DeleteAccountClaimDocumentAction, IBaseResponse>(
		deleteAccountClaimDocumentEndpoint, 
		true, 
		__options__, 
		DeleteAccountClaimDocumentAction,
		{
			accountID,
			claimDocID,
			accountDocID
		}
	);
}

export const getAllDocumentsByAcctIDEndpoint = "Api/Document/GetAllDocumentsByAcctID";

export function getAllDocumentsByAcctID(
	acctID: number, 
	__options__?: IOptions<ILinkedDocumentResponse>
) {
	return apiExecute<IGetAllDocumentsByAcctIDActionParams, GetAllDocumentsByAcctIDAction, ILinkedDocumentResponse>(
		getAllDocumentsByAcctIDEndpoint, 
		true, 
		__options__, 
		GetAllDocumentsByAcctIDAction,
		{ acctID }
	);
}

export const uploadAccountClaimDocumentEndpoint = "Api/Document/UploadAccountClaimDocument";

export function uploadAccountClaimDocument(
	bytes: string | Blob, 
	filename: string, 
	claimID: number, 
	claimDocTypeID: number, 
	acctId: number, 
	accountDocTypeID: number, 
	__options__?: IOptions<ILinkedDocumentResponse>
) {
	return apiExecute<IUploadAccountClaimDocumentActionParams, UploadAccountClaimDocumentAction, ILinkedDocumentResponse>(
		uploadAccountClaimDocumentEndpoint, 
		true, 
		__options__, 
		UploadAccountClaimDocumentAction,
		{
			bytes,
			filename,
			claimID,
			claimDocTypeID,
			acctId,
			accountDocTypeID
		}
	);
}

/* tslint:enable:max-line-length */