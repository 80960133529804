/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IBaseResponse,
	IClaimPackageReview,
	IClientProfile,
	IResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IApproveActionParams {
	claimPackageReview: IClaimPackageReview;
}

export class ApproveAction extends ApiActionBase<IApproveActionParams, IBaseResponse> {
	constructor(details: IDetails<IApproveActionParams, IBaseResponse>) {
		super("Api/ClaimPackageReviewActions/ApproveAction", details);
	}
}

export interface IGenerateClaimPackageReviewActionParams {
	clientProfile: IClientProfile;
	changeType: number;
}

export class GenerateClaimPackageReviewAction extends ApiActionBase<IGenerateClaimPackageReviewActionParams, IResponse<IClaimPackageReview>> {
	constructor(details: IDetails<IGenerateClaimPackageReviewActionParams, IResponse<IClaimPackageReview>>) {
		super("Api/ClaimPackageReviewActions/GenerateClaimPackageReviewAction", details);
	}
}

export interface IGetConfirmationActionParams {
	/* no parameters */
}

export class GetConfirmationAction extends ApiActionBase<IGetConfirmationActionParams, IResponse<string>> {
	constructor(details: IDetails<IGetConfirmationActionParams, IResponse<string>>) {
		super("Api/ClaimPackageReviewActions/GetConfirmationAction", details);
	}
}

/* tslint:enable:max-line-length */