import Dispatcher from "../Dispatcher/Dispatcher";
import ColumnSelectedAction from "../Actions/Search/ColumnSelectedAction";
import SimpleSearchUpdatedAction from "../Actions/Search/SimpleSearchUpdatedAction";
import SearchResultsAction from "../Actions/Search/SearchResultsAction";
import AccountSelectedAction from "../Actions/Search/AccountSelectedAction";
import PageDataChangedAction from "../Actions/Search/PageDataChangedAction";
import SearchAccountsAction from "../Actions/Search/SearchAccountsAction";
import SortChangedAction from "../Actions/Search/SortChangedAction";
import ExportFieldSelectedAction from "../Actions/Search/ExportFieldSelectedAction";
import * as Dto from "../Models/dto";
import * as AccountsData from "../Models/AccountsData";

class SearchActionCreators {

	accountSelected(data: Dto.IAccount[]): void {
		Dispatcher.dispatch(new AccountSelectedAction(data));
	}

	simpleSearchUpdated(data: Dto.IClientProfileUserSearch | null): void {
		Dispatcher.dispatch(new SimpleSearchUpdatedAction(data));
	}

	searchResultsFetched(data: Dto.IAccountsResponse): void {
		Dispatcher.dispatch(new SearchResultsAction(data));
	}

	columnSelected(selectedIds: number[]): void {
		Dispatcher.dispatch(new ColumnSelectedAction(selectedIds));
	}

	searchAccounts(data: Dto.IClientProfileUserSearch): void {
		Dispatcher.dispatch(new SearchAccountsAction(data));
	}

	pageDataChanged(data: AccountsData.IPageData) {
		Dispatcher.dispatch(new PageDataChangedAction(data));
	}

	sortChanged(data: Array<AccountsData.ISortInfo>): void {
		Dispatcher.dispatch(new SortChangedAction(data));
	}

	exportFieldsSelected(data: Array<string>): void {
		Dispatcher.dispatch(new ExportFieldSelectedAction(data));
	}
}

export default new SearchActionCreators();