import connect from "../../Utils/connect";
import * as React from "react";
import { logout } from "../../ActionCreators/LoginActionCreators";
import adminLoader, { IAdmin } from "../Admin/moduleLoader";
import { parse as parseDate } from "../../Utils/dateUtils";
import getCurrentUser from "../../selectors/getCurrentUser";
import * as Dto from "../../Models/dto";
import styles from "./Header.module.scss";
import noop from "lodash/noop";
import { logoHeader } from "../../Utils/branding";
import { TabsContext } from "../Common/Tabs/Tabs";
import MainContent from "../Common/MainContent/MainContent";
import joinParts from "../../Utils/joinParts";
import ActiveTabEnum from "../../Models/ActiveTabEnum";
import { ReactComponent as LogoutIcon } from "./logout.svg";
import { ReactComponent as ReportingIcon } from "./reporting.svg";
import { ReactComponent as SettingsIcon } from "./settings.svg";
import { ReactComponent as AccountsIcon } from "./accounts.svg";
import { ReactComponent as HomeIcon } from "./home.svg";
import getTabId from "../Common/Tabs/getId";
import classnames from "classnames";

interface IOwnProps { }

interface IConnectedProps {
	user: Dto.IClientProfilePartyNormalized | null;
	loginUser: Dto.ILoginUser;
	onLogout(): void;
}

interface IProps extends IOwnProps, IConnectedProps { }

interface IState extends IAdmin { }

function getTabIcon(key: ActiveTabEnum) {
	switch (key) {
		case ActiveTabEnum.Home:
			return <HomeIcon aria-hidden />;
		case ActiveTabEnum.Reporting:
		case ActiveTabEnum.AccessReports:
			return <ReportingIcon aria-hidden />;
		case ActiveTabEnum.Settings:
			return <SettingsIcon aria-hidden />;
		case ActiveTabEnum.Accounts:
			return <AccountsIcon aria-hidden />;
		default:
			throw new Error("Unexpected state");
	};

}

class Header extends React.PureComponent<IProps, IState> {
	private _cancelOnLoad = noop;

	state = adminLoader.loaded ? adminLoader.module! : {
		ProfileSwitcher: null
	};

	componentDidMount() {
		if (!adminLoader.loaded) {
			this._cancelOnLoad = adminLoader.onLoad(() => this.setState(adminLoader.module));
		}
	}

	componentWillUnmount() {
		this._cancelOnLoad();
	}

	render() {
		const { user, loginUser, onLogout } = this.props;
		const { ProfileSwitcher } = this.state;

		let welcomeMessage = "Welcome";
		if (user != null) {
			welcomeMessage += `, ${joinParts(" ", [user.firstName, user.lastName])}`;
		}

		const lastLoginDate = loginUser == null ? null : parseDate(loginUser.lastLoginDate);

		let sizes;
		if (__DEV__) {
			// stick the screen size in the logo, helpful for responsive testing
			sizes = (
				<div style={{
					position: "absolute",
					top: 10,
					left: 10,
					width: 25,
					textAlign: "center",
					fontWeight: "bold",
					opacity: .5
				}}>
					{["xs", "sm", "md", "lg"].map(size => (
						<span
							key={size}
							className={`visible-${size}-inline`}
						>
							{size.toUpperCase()}
						</span>
					))}
				</div>
			);
		}

		return (
			<MainContent className={styles.container}>
				{sizes}
				<div className={styles.logo}>
					{logoHeader}
				</div>

				<div className={styles.welcome}>
					<div>{welcomeMessage}</div>
					{lastLoginDate != null ? (
						<div className={styles.lastLoginDate}>
							Last Login: {lastLoginDate.format("M/D/YYYY [at] h:mm A zz")}
						</div>
					) : null}
				</div>

				{ProfileSwitcher != null && (
					<div className={styles.profileSwitcher}>
						<ProfileSwitcher />
					</div>
				)}

				<div className={styles.nav}>
					<TabsContext.Consumer>
						{({ tabs, onTabClick, uid, selected }) => (
							<ul
								className={styles.tabs}
								role="tablist"
							>
								{tabs.map(tab => (
									<li
										key={tab.key}
										role="presentation"
										className={classnames({ [styles.activeTab]: tab.key === selected })}
									>
										<button
											role="tab"
											aria-controls={getTabId(tab.key, uid)}
											onClick={() => onTabClick(tab.key)}
										>
											{getTabIcon(tab.key)}
											{tab.label}
										</button>
									</li>
								))}
							</ul>
						)}
					</TabsContext.Consumer>

					<div className={styles.logoutDivider} />

					<button
						type="button"
						className={styles.logoutLink}
						onClick={onLogout}
					>
						<LogoutIcon aria-hidden />
						Logout
					</button>
				</div>
			</MainContent>
		);
	}
}

export default connect<IConnectedProps, IOwnProps>(
	Header,
	state => ({
		user: getCurrentUser(state),
		loginUser: state.auth.loginUser,
		onLogout: logout
	})
);