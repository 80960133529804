import { MODIFIED_BY_ANOTHER_USER, OK } from "../../Models/apiConstants";
import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import {
	GetClientProfileFTPAction,
	SaveClientProfileFTPAction,
	DeleteClientProfileFTPAction
} from "../../Actions/Api/ftpActions";
import getLoadState from "../../Utils/getLoadState";

export interface IState {
	ftp: Dto.IClientProfileFTP | null;
	loading: AsyncState | null;
	saving: boolean;
	deleting: boolean;
}

export default function ftpReducer(
	previousState: IState = {
		ftp: null,
		loading: null,
		saving: false,
		deleting: false
	},
	action: IAction
) {
	if (action instanceof GetClientProfileFTPAction) {
		let loading = getLoadState(action);

		let { ftp } = previousState;
		if (loading === AsyncState.Resolved) {
			ftp = action.response!.item;
		}

		return Object.assign({}, previousState, {
			ftp,
			loading
		});
	}

	if (action instanceof SaveClientProfileFTPAction) {
		const saving = action.state === AsyncState.Pending;

		let { ftp } = previousState;
		if (action.state === AsyncState.Resolved) {
			switch (action.response!.status) {
				case OK:
				case MODIFIED_BY_ANOTHER_USER:
					ftp = action.response!.item;
					break;
				default:
					break;
			}
		}

		return Object.assign({}, previousState, {
			ftp,
			saving
		});
	}

	if (action instanceof DeleteClientProfileFTPAction) {
		const deleting = action.state === AsyncState.Pending;

		let { ftp } = previousState;
		if (action.state === AsyncState.Resolved) {
			switch (action.response!.status) {
				case OK:
					ftp = null;
					break;
				default:
					break;
			}
		}

		return Object.assign({}, previousState, {
			ftp,
			deleting
		});
	}

	return previousState;
}