import React from "react";
import styles from "./Registration.module.scss";
import connect from "../../Utils/connect";
import {
	IPendingRegistrationResponse,
	ICompleteRegistrationModel
} from "../../Models/dto";
import { RegistrationStep } from "../../Reducers/ui/registrationReducer";
import {
	getPendingRegistration,
	completeRegistration
} from "./../../ActionCreators/Api/clientRegistrationActionCreators";
import BusyOverlay from "../Common/BusyOverlay/BusyOverlay";
import { AsyncState } from "../../Models/IAsync";
import Progress from "./Progress";
import ProductConfirm from "./ProductConfirm/ProductConfirm";
import AdminProfile from "./Forms/AdminProfile";
import CompanyProfile from "./Forms/CompanyProfile";
import SecurityInformation from "./Forms/SecurityInformation";
import NextSteps from "./Forms/NextSteps";
import Confirm from "./Forms/Confirm";
import Complete from "./Forms/Complete";
import LicenseTerms from "./Forms/LicenseTerms";
import NonDisclosure from "./Forms/NonDisclosure";
import Baa from "./Forms/Baa";
import { IProps as IFormProps } from "./Forms/Forms";
import RegistrationActions from "../../ActionCreators/RegistrationActionCreators";

interface IConnectedProps {
	requiredSteps: RegistrationStep[];
	currentStep: RegistrationStep;
	loadState: AsyncState;
	data: IPendingRegistrationResponse;
}

interface IOwnProps {
	registrationId: string;
}

interface IState {
	model: ICompleteRegistrationModel;
}

class Registration extends React.Component<IConnectedProps & IOwnProps, IState> {
	constructor(props: IConnectedProps & IOwnProps) {
		super(props);
		this.state = {
			model: {
				registrationKey: props.registrationId,
				adminMiddleName: "",
				adminPassword: "",
				adminPhone: "",
				adminSecurityAnswer: "",
				adminSecurityQuestion: "",
				adminTitle: "",
				companyAddress: "",
				companyBillingPhone: "",
				companyCity: "",
				companyPhone: "",
				companyState: "",
				companyZip: "",
				hospitalFlag: null,
				medicalFlag: null,
				agencyFlag: null,
				disclosureFlag: null,
				implementationOptions: [],
				baaDocId: null,
				baaSignerFirstName: "",
				baaSignerLastName: "",
				baaSignerTitle: "",
				ltcDocId: null,
				ltcSignerFirstName: "",
				ltcSignerLastName: "",
				ltcSignerTitle: "",
				ndaDocId: null,
				ndaSignerFirstName: "",
				ndaSignerLastName: "",
				ndaSignerTitle: "",
				timezone: null as any
			}
		};
	}

	render(): React.ReactElement<{}> {
		const loading = this.props.loadState === AsyncState.Pending;
		const error = this.props.loadState === AsyncState.Rejected;

		return (
			<BusyOverlay message={loading ? "Loading..." : null}>
				<div>
					<div className={styles.header}>
						<div className={styles.logo}></div>
					</div>
					<div className={styles.container}>
						{error && (
							<div className={styles.error}>
								<h1>
									We can't find your registration
								</h1>

								<p>
									If you have already registered please
									login at <a href="https://probatefinderondemand.com/Application/">https://probatefinderondemand.com/Application/</a>
								</p>

								<p>
									If you need assistance contact support at <a href="mailto:contactsupport@probatefinderondemand.com">contactsupport@probatefinderondemand.com</a>
								</p>
							</div>
						)}
						{!error && !loading && (
							((this.props.currentStep === RegistrationStep.Complete && (
								<Complete />
							)) || (
									<div>
										<Progress
											steps={this.props.requiredSteps}
											active={this.props.currentStep}
										/>
										<div className={styles.details}>
											{this.renderContent()}
										</div>
									</div>
								))
						)}
					</div>
				</div>
			</BusyOverlay>
		);
	}

	renderContent(): React.ReactElement<{}> {
		const formProps: IFormProps = {
			data: this.props.data,
			model: this.state.model,
			onBack: () => RegistrationActions.prevStep(this.state.model),
			onConfirm: () => RegistrationActions.nextStep(this.state.model),
			setValue: (v, s) => this.setValue(v, s)
		};

		switch (this.props.currentStep) {
			case RegistrationStep.ProductSelection:
				return (
					<ProductConfirm
						onConfirm={() => RegistrationActions.nextStep(this.state.model)}
						product={this.props.data.productTypeId}
					/>
				);
			case RegistrationStep.AdminProfile:
				return <AdminProfile {...formProps} />;
			case RegistrationStep.Security:
				return <SecurityInformation {...formProps} />;
			case RegistrationStep.Profile:
				return <CompanyProfile {...formProps} />;
			case RegistrationStep.NextSteps:
				return <NextSteps {...formProps} />;
			case RegistrationStep.Terms:
				return <LicenseTerms {...formProps} />;
			case RegistrationStep.Nda:
				return <NonDisclosure {...formProps} />;
			case RegistrationStep.Baa:
				return <Baa {...formProps} />;
			case RegistrationStep.Confirm:
				return (
					<Confirm
						{...formProps}
						onConfirm={() => completeRegistration(this.state.model)}
					/>
				);
			default:
				throw new Error("unknown step");
		}
	}

	setValue<T>(value: T, setter: (value: T, model: ICompleteRegistrationModel) => void) {
		this.setState(state => {
			const nState = {
				...state,
				model: {
					...state.model
				}
			};

			setter(value, nState.model);
			return nState;
		});
	}

	componentDidMount() {
		getPendingRegistration(this.props.registrationId);
	}
}

export default connect<IConnectedProps, IOwnProps>(
	Registration,
	(state) => {
		return {
			currentStep: state.ui.registration.currentStep!,
			requiredSteps: state.ui.registration.requiredSteps!,
			loadState: state.ui.registration.loading!,
			data: state.ui.registration.data!
		};
	}
);