import { MODIFIED_BY_ANOTHER_USER, OK } from "../../Models/apiConstants";
import ApiActionBase from "../../Actions/ApiActionBase";
import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import { GetAllClientProfilesAction } from "../../Actions/Api/administrationActions";
import getLoadState from "../../Utils/getLoadState";
import { GetStartupDataAction } from "../../Actions/Api/startupActions";
import { SaveClientProfileAction, UseParentAffiliateModelAction } from "../../Actions/Api/clientActions";

// TODO combine this reducer with clientProfileReducer a la the users reducer

type ProfileMap = { [clientProfileId: number]: Dto.IClientProfileMin };

export interface IState {
	byId: ProfileMap;
	loading: AsyncState | null;
}

function updateById(previousById: ProfileMap, clientProfiles: Dto.IClientProfileMin[]) {
	if (clientProfiles.length === 0) {
		return previousById;
	}

	const byId: ProfileMap = Object.assign({}, previousById);
	for (const clientProfile of clientProfiles) {
		byId[clientProfile.clientProfileId] = clientProfile;
	}

	return byId;
}

function handleClientProfileUpdateAction<TParams, TAction extends ApiActionBase<TParams, TResponse>, TResponse extends Dto.IBaseResponse>(
	previousState: IState,
	action: TAction,
	getClientProfile: (action: TAction) => Dto.IClientProfileMin
): IState {
	if (action.state === AsyncState.Resolved && (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)) {
		return Object.assign({}, previousState, {
			byId: updateById(previousState.byId, [getClientProfile(action)])
		});
	}

	return previousState;
}

export default function clientProfilesReducer(
	previousState: IState = {
		byId: {},
		loading: null
	},
	action: IAction
): IState {
	if (action instanceof GetAllClientProfilesAction) {
		let loading = getLoadState(action);
		let { byId } = previousState;

		if (loading === AsyncState.Resolved) {
			byId = updateById(byId, action.response!.items);
		}

		return Object.assign({}, previousState, {
			loading,
			byId
		});
	}

	if (action instanceof GetStartupDataAction) {
		return handleClientProfileUpdateAction(previousState, action, it => it.response!.item.clientProfile);
	}

	if (action instanceof SaveClientProfileAction || action instanceof UseParentAffiliateModelAction) {
		return handleClientProfileUpdateAction(previousState, action, it => it.response!.clientProfile);
	}

	return previousState;
}