import { makeFakeJqueryXhr } from "../Utils/apiExecute";
import { getClaimDisputeFromClaimID, getClaimIDLinkedAccounts } from "../ActionCreators/Api/claimActionCreators";
import {
	IGetClaimDisputeFromClaimIDActionParams,
	IGetClaimIDLinkedAccountsActionParams
} from "../Actions/Api/claimActions";

const ClaimApi = {
	getClaimDisputeFromClaimID(params: IGetClaimDisputeFromClaimIDActionParams) {
		return makeFakeJqueryXhr(getClaimDisputeFromClaimID(params.accountID, params.claimID));
	},

	getClaimIdLinkedAccounts(params: IGetClaimIDLinkedAccountsActionParams) {
		return makeFakeJqueryXhr(getClaimIDLinkedAccounts(params.acctIDs));
	}
};

export default ClaimApi;