import { OK } from "../../Models/apiConstants";
import IAction from "../../Actions/IAction";
import { GetInvoicesAction } from "../../Actions/Api/invoiceActions";
import { AsyncState } from "../../Models/IAsync";

export interface IState {
	paths: string[];
	loading: AsyncState | null;
}

export default function invoicesReducer(
	previousState: IState = {
		paths: [],
		loading: null
	},
	action: IAction
): IState {
	if (action instanceof GetInvoicesAction) {
		const loading = action.state === AsyncState.Resolved && action.response!.status !== OK
			? AsyncState.Rejected
			: action.state;

		const paths = loading === AsyncState.Resolved
			? action.response!.items
			: previousState.paths;

		return Object.assign({}, previousState, {
			loading,
			paths
		});
	}

	return previousState;
}