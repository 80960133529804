import { makeFakeJqueryXhr } from "../Utils/apiExecute";
import {
	getAccountHistoriesByAccountID,
	getBankoInfo,
	getClaimsByAccountID,
	getClientProfileBillings
} from "../ActionCreators/Api/accountActionCreators";
import {
	IGetAccountHistoriesByAccountIDActionParams,
	IGetBankoInfoActionParams,
	IGetClaimsByAccountIDActionParams,
	IGetClientProfileBillingsActionParams
} from "../Actions/Api/accountActions";

const AccountApi = {
	getAccountHistoriesByAccountID(params: IGetAccountHistoriesByAccountIDActionParams) {
		return makeFakeJqueryXhr(getAccountHistoriesByAccountID(params.acctId));
	},

	getBankoInfo(params: IGetBankoInfoActionParams) {
		return makeFakeJqueryXhr(getBankoInfo(params.acctID));
	},

	getClaimsByAccountID(params: IGetClaimsByAccountIDActionParams) {
		return makeFakeJqueryXhr(getClaimsByAccountID(params.acctId));
	},

	getClientProfileBillings(params: IGetClientProfileBillingsActionParams) {
		return makeFakeJqueryXhr(getClientProfileBillings(params.acctID));
	}
};

export default AccountApi;