import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";
import * as Dto from "../../Models/dto";

export default class SearchAccountsAction implements IAction {
	data: Dto.IClientProfileUserSearch;

	constructor(data: Dto.IClientProfileUserSearch) {
		this.data = data;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"SearchAccountsAction", { "data": JSON.stringify(this.data) });
	}
}