/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	CompleteRegistrationAction,
	ICompleteRegistrationActionParams,
	GetClientRegistrationDataAction,
	IGetClientRegistrationDataActionParams,
	GetPendingRegistrationAction,
	IGetPendingRegistrationActionParams,
	SaveRegistrationAction,
	ISaveRegistrationActionParams
} from "../../Actions/Api/clientRegistrationActions";
import {
	IClientProfile,
	IClientProfileParty,
	ICompleteRegistrationModel,
	IPendingRegistrationResponse,
	IRegistrationDataResponse,
	IRegistrationResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const completeRegistrationEndpoint = "Api/ClientRegistration/CompleteRegistration";

export function completeRegistration(
	model: ICompleteRegistrationModel, 
	__options__?: IOptions<IRegistrationResponse>
) {
	return apiExecute<ICompleteRegistrationActionParams, CompleteRegistrationAction, IRegistrationResponse>(
		completeRegistrationEndpoint, 
		false, 
		__options__, 
		CompleteRegistrationAction,
		{ model }
	);
}

export const getClientRegistrationDataEndpoint = "Api/ClientRegistration/GetClientRegistrationData";

export function getClientRegistrationData(
	__options__?: IOptions<IRegistrationDataResponse>
) {
	return apiExecute<IGetClientRegistrationDataActionParams, GetClientRegistrationDataAction, IRegistrationDataResponse>(
		getClientRegistrationDataEndpoint, 
		false, 
		__options__, 
		GetClientRegistrationDataAction,
		{ /* no parameters */ }
	);
}

export const getPendingRegistrationEndpoint = "Api/ClientRegistration/GetPendingRegistration";

export function getPendingRegistration(
	registrationId: string, 
	__options__?: IOptions<IPendingRegistrationResponse>
) {
	return apiExecute<IGetPendingRegistrationActionParams, GetPendingRegistrationAction, IPendingRegistrationResponse>(
		getPendingRegistrationEndpoint, 
		false, 
		__options__, 
		GetPendingRegistrationAction,
		{ registrationId }
	);
}

export const saveRegistrationEndpoint = "Api/ClientRegistration/SaveRegistration";

export function saveRegistration(
	captchaChallenge: string, 
	captchaResponse: string, 
	clientProfile: IClientProfile, 
	administrator: IClientProfileParty, 
	__options__?: IOptions<IRegistrationResponse>
) {
	return apiExecute<ISaveRegistrationActionParams, SaveRegistrationAction, IRegistrationResponse>(
		saveRegistrationEndpoint, 
		false, 
		__options__, 
		SaveRegistrationAction,
		{
			captchaChallenge,
			captchaResponse,
			clientProfile,
			administrator
		}
	);
}

/* tslint:enable:max-line-length */