
const R_PHONE = /^(\d{3})(\d{3})(\d{4})$/;
export default function formatPhone(value: string) {
	if (value != null) {
		const match = value.match(R_PHONE);
		if (match) {
			return `(${match[1]}) ${match[2]}-${match[3]}`;
		}
	}

	return value;
}