import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";

export default class PageSizeChangedAction implements IAction {
	pageSize: number;

	constructor(pageSize: number) {
		this.pageSize = pageSize;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"PageSizeChangedAction", { "pageSize": JSON.stringify(this.pageSize) });
	}
}