import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import getLoadState from "../../Utils/getLoadState";
import { GetStartupDataAction } from "../../Actions/Api/startupActions";
import { DismissNotificationAction } from "../../Actions/Api/userNotificationActions";

export interface IState {
	notifications: Dto.IUserNotification[];
}

export default function notificationsReducer(
	previousState: IState = {
		notifications: []
	},
	action: IAction
) {
	if (action instanceof GetStartupDataAction) {
		const loading = getLoadState(action);

		let { notifications } = previousState;
		if (loading === AsyncState.Resolved) {
			notifications = action.response!.item.userNotifications;
		}

		return Object.assign({}, previousState, {
			notifications
		});
	}

	if (action instanceof DismissNotificationAction) {
		return {
			...previousState,
			notifications: previousState.notifications
				.filter(it => it.userNotificationId !== action.params.notificationId)
		};
	}

	return previousState;
}