import * as React from "react";
import { ITime } from "../models";
import tryParseTime from "./tryParseTime";
import withoutProperties from "../../../../../../../../../Utils/withoutProperties";
import cn from "classnames";
import padStart from "lodash/padStart";

interface IProps {
	value: string | ITime | null;
	className?: string;
	placeholder?: string;
	preferPm?: boolean;
	onChange(value: ITime | null, raw: string): void;
}

interface IState {
	focused: boolean;
	raw: string;
}

class TimeInput extends React.PureComponent<IProps, IState> {
	private _input: HTMLInputElement | null | undefined;

	constructor(props: IProps, context: any) {
		super(props, context);

		this.state = {
			focused: false,
			raw: this._getRawValue()
		};

		this._onChange = this._onChange.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onFocus = this._onFocus.bind(this);
		this._onKeyPress = this._onKeyPress.bind(this);
	}

	componentDidMount() {
		const focused = this._input === document.activeElement;
		this.setState({ focused });
	}

	render() {
		const { props, state } = this;

		const value = state.focused
			? state.raw
			: this._getRawValue();

		const otherProps = withoutProperties(props, [
			nameof(props.value),
			nameof(props.onChange),
			nameof(props.preferPm)
		]);

		return (
			<input
				ref={it => this._input = it as HTMLInputElement}
				{...otherProps}
				className={cn("form-control", props.className)}
				value={value}
				onChange={this._onChange}
				onBlur={this._onBlur}
				onFocus={this._onFocus}
				onKeyPress={this._onKeyPress}
			/>
		);
	}

	private _getRawValue() {
		const { value } = this.props;

		if (value == null) {
			return "";
		}

		if (typeof value === "string") {
			return value;
		}

		let { hour } = value;
		const { minute } = value;

		if (hour < 0 || minute < 0) {
			return "";
		}

		const amPm = hour < 12 ? "AM" : "PM";
		if (hour === 0) {
			hour = 12;
		} else if (hour > 12) {
			hour -= 12;
		}

		return `${hour}:${padStart(minute.toString(), 2, "0")} ${amPm}`;
	}

	private _onChange() {
		const raw = this._input!.value;
		this.setState({ raw });

		const value = tryParseTime(raw, this.props.preferPm);
		this.props.onChange(value, raw);
	}

	private _onBlur() {
		this.setState({ focused: false });
	}

	private _onFocus() {
		this.setState({
			focused: true,
			raw: this._getRawValue()
		});
	}

	private _onKeyPress(event: React.KeyboardEvent<HTMLElement>) {
		// this could be smarter
		if (!/^[\d\s:apm]$/i.test(event.key)) {
			event.preventDefault();
		}
	}
}

export default TimeInput;