import isEmpty from "../../Utils/isEmpty";
import BusyOverlay from "../Common/BusyOverlay/BusyOverlay";
import connect from "../../Utils/connect";
import connectToCaptcha, { ICaptchaProps } from "../../Utils/connectToCaptcha";
import validatePassword from "../../Utils/validatePassword";
import React from "react";
import ReduxStore from "../../Stores/ReduxStore";
import LoginActionCreators, {
	changePassword
} from "../../ActionCreators/LoginActionCreators";
import Captcha from "../Captcha/Captcha";
import cn from "classnames";
import PasswordValidation from "./PasswordValidation/PasswordValidation";
import AuthPage, { FormGroup } from "../Auth/AuthPage/AuthPage";
import AuthButton from "../Auth/AuthButton/AuthButton";
import AuthHeader from "../Auth/AuthHeader/AuthHeader";

interface IOwnProps { }

interface IConnectedProps {
	reason: string | null;
	oldPassword: string;
	password: string;
	confirmPassword: string;
	saving: boolean;
	onChange(oldPassword: string, password: string, confirmPassword: string): void;
	onSave(): void;
	reset: boolean;
}

interface IProps extends IOwnProps, ICaptchaProps, IConnectedProps { }

class ChangePassword extends React.PureComponent<IProps> {
	constructor(props: IProps) {
		super(props);
		this._handleSubmit = this._handleSubmit.bind(this);
	}

	render() {
		const { props } = this;

		const validation = validatePassword(props.password, props.confirmPassword);

		let reason;
		if (!isEmpty(props.reason)) {
			reason = (
				<FormGroup>{props.reason}</FormGroup>
			);
		}

		return (
			<AuthPage onSubmit={this._handleSubmit}>
				<BusyOverlay message={props.saving ? "Saving..." : null} />
				<AuthHeader>Change Password</AuthHeader>
				{reason}
				<FormGroup label={`${props.reset ? "Temporary" : "Old"} Password`}>
					{id => (
						<input
							id={id}
							type="password"
							className={cn("form-control", { errorInput: props.oldPassword.length === 0 })}
							maxLength={50}
							value={props.oldPassword}
							onChange={this._handleOldPasswordChange}
							autoComplete="current-password"
						/>
					)}
				</FormGroup>
				<FormGroup label="New Password">
					{id => (
						<input
							id={id}
							type="password"
							className={cn("form-control", { errorInput: props.password.length === 0 })}
							maxLength={50}
							value={props.password}
							onChange={this._handlePasswordChange}
							autoComplete="new-password"
						/>
					)}
				</FormGroup>
				<FormGroup label="Confirm New Password">
					{id => (
						<input
							id={id}
							type="password"
							className={cn("form-control", { errorInput: props.confirmPassword.length === 0 })}
							maxLength={50}
							value={props.confirmPassword}
							onChange={this._handleConfirmPasswordChange}
							autoComplete="new-password"
						/>
					)}
				</FormGroup>
				<FormGroup>
					<PasswordValidation validation={validation} />
				</FormGroup>
				<FormGroup>
					<Captcha />
				</FormGroup>
				<FormGroup>
					<AuthButton
						type="submit"
						disabled={!validation.valid || !props.captchaValid || props.saving}
					>
						Change Password
					</AuthButton>
				</FormGroup>
			</AuthPage>
		);
	}

	private _handleOldPasswordChange = (e: React.FormEvent<HTMLElement>) => this._handleChange(e, oldPassword => ({ oldPassword }));
	private _handlePasswordChange = (e: React.FormEvent<HTMLElement>) => this._handleChange(e, password => ({ password }));
	private _handleConfirmPasswordChange = (e: React.FormEvent<HTMLElement>) => this._handleChange(e, confirmPassword => ({ confirmPassword }));

	private _handleChange(event: React.FormEvent<HTMLElement>, getUpdate: (value: string) => { oldPassword: string } | { password: string } | { confirmPassword: string }) {
		const { value } = event.currentTarget as HTMLInputElement;
		const newProps = Object.assign({}, this.props, getUpdate(value));
		this.props.onChange(newProps.oldPassword, newProps.password, newProps.confirmPassword);
	}

	private _handleSubmit(event: React.FormEvent<HTMLElement>) {
		event.preventDefault();
		this.props.onSave();
	}
}

const ConnectedToCaptcha = connectToCaptcha<IOwnProps & IConnectedProps>(ChangePassword);

const onChange = (oldPassword: string, password: string, confirmPassword: string) => {
	const { auth: { loginUser } } = ReduxStore.getState();
	LoginActionCreators.loginUserUpdated(Object.assign({}, loginUser, {
		oldPassword,
		password,
		confirmPassword
	}));
};

export default connect<IConnectedProps, IOwnProps>(
	ConnectedToCaptcha,
	state => ({
		reason: state.auth.passwordChangeReason === "FORCE_CHANGE_PASSWORD_REASON_PASSWORD_EXPIRED" || true
			? "Your company profile dictates that your password must expire periodically. You will have to change your password before you can successfully log in."
			: null,
		oldPassword: state.auth.loginUser.oldPassword,
		password: state.auth.loginUser.password,
		confirmPassword: state.auth.loginUser.confirmPassword,
		saving: state.auth.changingPassword,
		onChange,
		onSave: changePassword,
		reset: state.auth.passwordChangeReason === "FORCE_CHANGE_PASSWORD_REASON_PASSWORD_RESET"
	})
);