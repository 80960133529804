type EventProperty = string | number | boolean | Date | null | undefined;

(window as any).dataLayer = (window as any).dataLayer || [];
export default function trackEvent(eventName: string, otherProperties?: { [name: string]: EventProperty }) {
	const event = {
		event: eventName,
		...otherProperties
	};

	if (process.env.NODE_ENV === "development") {
		console.log(`%ctrackEvent(${JSON.stringify(event)})`, "color: #aaa");
	}

	(window as any).dataLayer.push(event);
}