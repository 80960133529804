import * as React from "react";
import pure from "../../../../Utils/pure";
import cn from "classnames";
import styles from "./Question.module.scss";

interface IProps {
	key?: string | number;
	label: React.ReactNode;
	hasError?: boolean;
	className?: string;
	children?: React.ReactNode;
	id?: string;
}

const Question = (props: IProps) => (
	<div
		className={cn(styles.container, props.className)}
		id={props.id}
	>
		<label className={cn(styles.label, { [styles.error]: props.hasError })}>{props.label}</label>
		<div className={cn(styles.controls, `${nameof(Question)}__controls`)}>
			{props.children}
		</div>
	</div>
);

export default pure(Question);