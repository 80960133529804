import * as React from "react";
import Question from "../Question/Question";
import YesNoSelector from "../../../Common/YesNoSelector/YesNoSelector";
import pure from "../../../../Utils/pure";

interface IProps {
	key?: string | number;
	label: React.ReactNode;
	value: boolean | null;
	disabled?: boolean | null;
	hasError?: boolean;
	className?: string;
	children?: React.ReactNode;
	yesLabel?: string;
	noLabel?: string;
	onChange(value: boolean): void;
	id?: string;
}

const YesNoQuestion = (props: IProps) => (
	<Question
		label={props.label}
		hasError={props.hasError}
		className={props.className}
		id={props.id}
	>
		<YesNoSelector
			value={props.value}
			onChange={props.onChange}
			disabled={props.disabled}
			yesLabel={props.yesLabel}
			noLabel={props.noLabel}
		/>
		{props.children}
	</Question>
);

export default pure(YesNoQuestion);