import pure from "../../../Utils/pure";
import * as React from "react";
import { Modal } from "react-bootstrap";
import DraggableModal from "../../Common/DraggableModal/DraggableModal";
import Button from "../../Common/Button/Button";
import styles from "./AuthModal.module.scss";

interface IProps {
	show: boolean;
	onHide(): void;
	children?: React.ReactNode;
}

const AuthModal = (props: IProps) => (
	<DraggableModal {...props}>
		<Modal.Body className={styles.body}>
			{props.children}
		</Modal.Body>
		<Modal.Footer>
			<Button
				primary
				onClick={props.onHide}
			>
				Ok
			</Button>
		</Modal.Footer>
	</DraggableModal>
);

export default pure(AuthModal);