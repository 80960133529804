import React from "react";
import cn from "classnames";
import styles from "./Forms.module.scss";
import icon from "../../../../Images/registration/company-profile-icon.jpg";
import Form from "../../Common/Form/Form";
import FormGroup from "../../Common/FormGroup/FormGroup";
import PhoneInput from "../../Common/PhoneInput";
import StateInput from "../../Common/StateInput/Control/Control";
import isEmpty from "../../../Utils/isEmpty";
import ZipCodeInput from "../../Common/ZipCodeInput";
import { IProps } from "./Forms";
import { AsyncState } from "../../../Models/IAsync";
import AgencyMedicalQuestions,
{
	validate as validateMedicalFlags,
	IMedicalFlags
} from "../../Dashboard/Settings/CompanyProfile/CompanyDetails/Questions/AgencyMedicalQuestions/AgencyMedicalQuestions";
import {
	phone as validPhone,
	zip as validZip
} from "../../../Utils/validate";
import { appName } from "../../../Utils/branding";

interface IValid {
	addressValid: boolean;
	cityValid: boolean;
	stateValid: boolean;
	zipValid: boolean;
	phoneValid: boolean;
	billingPhoneValid: boolean;
	allValid: boolean;
}

function validate(props: IProps): IValid {
	const addressValid = !isEmpty(props.model.companyAddress);
	const cityValid = !isEmpty(props.model.companyCity);
	const stateValid = !isEmpty(props.model.companyState);
	const zipValid = !isEmpty(props.model.companyZip) && validZip(props.model.companyZip);
	const phoneValid = !isEmpty(props.model.companyPhone) && validPhone(props.model.companyPhone);
	const billingPhoneValid = !isEmpty(props.model.companyBillingPhone) && validPhone(props.model.companyBillingPhone);
	const allValid = addressValid
		&& cityValid
		&& stateValid
		&& zipValid
		&& phoneValid
		&& billingPhoneValid;

	return {
		addressValid,
		cityValid,
		stateValid,
		zipValid,
		phoneValid,
		billingPhoneValid,
		allValid
	};
}

const CompanyProfile = (props: IProps) => {
	const valid = validate(props);
	const medicalFlags: IMedicalFlags = {
		agencyFlag: props.model.agencyFlag,
		medicalFlag: props.model.medicalFlag,
		hospitalFlag: props.model.hospitalFlag,
		initialDisclosureFlag: props.model.disclosureFlag,
		showInitialDisclosureFlag: false,
		clientProfileProductId: props.data.productTypeId
	};

	const medicalValid = validateMedicalFlags(medicalFlags);
	const allValid = valid.allValid && medicalValid.valid;

	return (
		<div className={styles.page}>
			<div className={styles.header}>
				<img src={icon} alt="" height={50} width="auto" />
				<div>
					<div className={styles.title}>
						Company profile
					</div>
					<div className={styles.subtitle}>
						Please provide your company overview information to allow {appName}
						to customize your profile and house accurate mailing and correspondence data.
					</div>
				</div>
			</div>

			<div className={styles.fields}>
				<Form
					onSubmit={e => e.preventDefault()}
					horizontal
				>
					<FormGroup label="Company Name">
						{id => (
							<span id={id} className={styles.readonly}>
								{props.data.companyName}
							</span>
						)}
					</FormGroup>

					<FormGroup
						required
						label="Address"
						hasError={!valid.addressValid}
					>
						{id => (
							<input
								type="text"
								className={cn("form-control")}
								id={id}
								maxLength={50}
								value={props.model.companyAddress}
								onChange={e => props.setValue(e.target.value, (v, m) => m.companyAddress = v)}
							/>
						)}
					</FormGroup>

					<FormGroup
						required
						label="City"
						hasError={!valid.cityValid}
					>
						{id => (
							<input
								type="text"
								className={cn("form-control")}
								id={id}
								maxLength={50}
								value={props.model.companyCity}
								onChange={e => props.setValue(e.target.value, (v, m) => m.companyCity = v)}
							/>
						)}
					</FormGroup>

					<FormGroup
						required
						label="State"
						hasError={!valid.stateValid}
					>
						{id => (
							<StateInput
								id={id}
								className={cn("form-control")}
								states={props.data.states}
								abbreviation={props.model.companyState}
								onSelectionChange={(a, _s) => props.setValue(a, (v, m) => m.companyState = v!)}
								loading={AsyncState.Resolved}
							/>
						)}
					</FormGroup>

					<FormGroup
						required
						label="Zip"
						hasError={!valid.zipValid}
					>
						{id => (
							<ZipCodeInput
								className={cn("form-control")}
								id={id}
								value={props.model.companyZip}
								onChange={e => props.setValue(e.target.value, (v, m) => m.companyZip = v)}
							/>
						)}
					</FormGroup>

					<FormGroup
						required
						label="Phone"
						hasError={!valid.phoneValid}
					>
						{id => (
							<PhoneInput
								className={cn("form-control")}
								id={id}
								value={props.model.companyPhone}
								onChange={e => props.setValue(e.target.value, (v, m) => m.companyPhone = v)}
							/>
						)}
					</FormGroup>

					<FormGroup
						required
						label="Billing Phone"
						hasError={!valid.billingPhoneValid}
					>
						{id => (
							<PhoneInput
								className={cn("form-control")}
								id={id}
								value={props.model.companyBillingPhone}
								onChange={e => props.setValue(e.target.value, (v, m) => m.companyBillingPhone = v)}
							/>
						)}
					</FormGroup>

					<FormGroup
						label=""
					>
						{_id => (
							<AgencyMedicalQuestions
								flags={medicalFlags}
								validation={medicalValid}
								onChange={flags => props.setValue(flags, (v, m) => {
									m.agencyFlag = v.agencyFlag;
									m.hospitalFlag = v.hospitalFlag;
									m.medicalFlag = v.medicalFlag;
									m.disclosureFlag = v.initialDisclosureFlag;
								})}
							/>
						)}
					</FormGroup>

					<div className={styles.actions}>
						<button
							type="button"
							className="btn btn-light"
							onClick={() => props.onBack()}
						>
							Back
						</button>
						<button
							type="button"
							className="btn btn-warning"
							disabled={!allValid}
							onClick={() => props.onConfirm()}
						>
							Continue
						</button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default CompanyProfile;