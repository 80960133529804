
const R_ZIP = /^(\d{5})(\d{4})$/;
export default function formatZip(value: string | null) {
	if (value != null) {
		const match = value.match(R_ZIP);
		if (match) {
			return `${match[1]}-${match[2]}`;
		}
	}

	return value;
}