import * as React from "react";
import styles from "./AuthPage.module.scss";
import AuthForm, { IProps as IAuthFormProps } from "../AuthForm/AuthForm";
import connect from "../../../Utils/connect";
import { ILoginBanner } from "../../../Models/dto";
import VisuallyHidden from "../../Common/VisuallyHidden/VisuallyHidden";
import classnames from "classnames";
import ResourceLink from "../../Common/ResourceLink/ResourceLink";
import useImageLoad from "../../../Utils/useImageLoad";
import AsyncState from "../../../Models/AsyncState";
import TabLoader from "../../Common/TabLoader/TabLoader";
import getContrastRatio from "get-contrast-ratio";
import maxBy from "lodash/maxBy";

type IOwnProps = IAuthFormProps;

interface IConnectedProps {
	loginBanner: ILoginBanner | null;
}

type IProps = IOwnProps & IConnectedProps;

const AuthPage = (props: IProps) => {
	const { loginBanner } = props;
	const imageLoadState = useImageLoad(loginBanner?.image ?? null);

	const loadColor = React.useMemo(() => {
		if (loginBanner == null) {
			return styles.primaryLoaderColor;
		}

		const colorOption = (color: string) => ({
			color,
			ratio: getContrastRatio(color, loginBanner.backgroundColor)
		});

		const primaryOption = colorOption(styles.primaryLoaderColor);
		if (primaryOption.ratio >= 2.5) {
			return primaryOption.color
		}

		return maxBy([
			primaryOption,
			...[
				styles.altLoaderColor1,
				styles.altLoaderColor2,
				styles.altLoaderColor3
			].map(colorOption)
		], it => it.ratio)!.color;
	}, [loginBanner])

	return (
		<div className={classnames(styles.wrapper, { [styles.noBanner]: loginBanner == null || imageLoadState === AsyncState.Rejected })}>
			<div className={styles.formContent}>
				<div>
					<AuthForm {...props} />
				</div>
			</div>

			{loginBanner != null && imageLoadState !== AsyncState.Rejected && (
				<ResourceLink
					className={styles.banner}
					style={{
						backgroundColor: loginBanner.backgroundColor,
						borderColor: loginBanner.backgroundColor // todo use loadColor when focused
					}}
					resource={loginBanner}
				>
					<VisuallyHidden>
						{loginBanner.title}
					</VisuallyHidden>

					{imageLoadState === AsyncState.Pending && (
						<TabLoader
							className={styles.loader}
							color={loadColor}
						/>
					)}

					<div
						className={styles.image}
						style={{
							backgroundImage: `url('${loginBanner.image}')`
						}}
					/>
				</ResourceLink>
			)}
		</div>
	);
}

export { FormGroup } from "../AuthForm/AuthForm";

export default connect<IConnectedProps, IOwnProps>(
	AuthPage,
	state => ({
		loginBanner: state.auth.preStartupData?.loginBanner ?? null
	})
);