import React from "react";
import useUid from "../../../Utils/useUid";
import classnames from "classnames";
import styles from "./CheckboxInput2.module.scss";
import Check from "../icons/Check/Check";

interface IProps {
	value: boolean;
	children?: React.ReactNode;
	onChange: (value: boolean) => void;
	className?: ClassValue;
	disabled?: boolean | null;
	id?: string;
	name?: string;
	small?: boolean;
}

export default function CheckboxInput2(props: IProps) {
	const uid = useUid();
	const id = props.id ?? `checkbox-${uid}`;

	return (
		<div className={classnames(styles.container, props.className, { [styles.small]: props.small })}>
			<input
				id={id}
				name={props.name}
				type="checkbox"
				className={styles.checkboxFunction}
				checked={props.value}
				onChange={e => props.onChange(e.currentTarget.checked)}
				disabled={props.disabled ?? undefined}
			/>
			<label
				className={classnames(styles.checkboxStyle, {
					[styles.disabled]: props.disabled
				})}
				htmlFor={id}
			>
				<span className={styles.checkboxStyleBox}>
					{props.value && (
						<Check />
					)}
				</span>
				<span className={styles.checkboxStyleLabel}>
					{props.children}
				</span>
			</label>
		</div>
	)
}