import keyBy from "lodash/keyBy";

export default class ActionLogEntry {
	name: string;
	logProperties: { [key: string]: string };

	static create(action: any, name: string, ...ignoredKeys: string[]) {
		const ignoredMap = keyBy(ignoredKeys);

		const logProperties: { [key: string]: string } = {};
		Object.keys(action)
			.filter(it => !(it in ignoredMap))
			.forEach(key => {
				logProperties[key] = JSON.stringify(action[key]);
			});

		return new ActionLogEntry(name, logProperties);
	}

	constructor(name: string, logProperties: { [key: string]: string } = {}) {
		this.name = name;
		this.logProperties = logProperties;
	}

	toString(): string {
		return `${this.name} | ${JSON.stringify(this.logProperties)}`;
	}
}