import { MODIFIED_BY_ANOTHER_USER, OK } from "../../../../Models/apiConstants";
import assign from "../../../../Utils/assign";
import getLoadState from "../../../../Utils/getLoadState";
import { GetStartupDataAction } from "../../../../Actions/Api/startupActions";
import * as actions from "../../../../Actions/Settings/BillingActions";
import IAction from "../../../../Actions/IAction";
import IAsync, { AsyncState } from "../../../../Models/IAsync";
import {
	SaveClientProfileAction,
	GetClientProfileStatementEmailAddressesAction,
	SaveClientProfileStatementEmailAddressesAction
} from "../../../../Actions/Api/clientActions";
import * as Dto from "../../../../Models/dto";

export interface IEdit {
	phone: string | null;
	purchaseOrderNumber: string | null;
	emails: Dto.IClientProfileStatementEmail[];
}

export interface IState {
	statementEmails: IAsync<Dto.IClientProfileStatementEmail[]>;
	edit: IEdit;
}

export const tags = {
	SAVE_BILLING: "saveBilling"
};

function updateEdit(state: IState, clientProfile: Dto.IClientProfile) {
	const { billingPhone, purchaseOrderNumber } = clientProfile || {
		billingPhone: null,
		purchaseOrderNumber: null
	};

	return assign(state, {
		edit: {
			phone: billingPhone || null,
			purchaseOrderNumber: purchaseOrderNumber || null,
			emails: state.statementEmails.payload || []
		}
	});
}

export default function billingReducer(
	previousState: IState = {
		statementEmails: {
			state: null,
			payload: null
		},
		edit: {
			phone: null,
			purchaseOrderNumber: null,
			emails: []
		}
	},
	action: IAction
): IState {
	if (action instanceof GetStartupDataAction && action.state === AsyncState.Resolved && action.response!.status === OK) {
		return updateEdit(previousState, action.response!.item.clientProfile);
	}

	if (action instanceof GetClientProfileStatementEmailAddressesAction) {
		const asyncState = getLoadState(action);
		let payload: Dto.IClientProfileStatementEmail[] | null = null;
		let edit = previousState.edit;

		if (asyncState === AsyncState.Resolved) {
			const { response } = action;

			payload = response!.items;
			edit = assign(edit, {
				emails: response!.items
			});
		}

		previousState = assign<IState, Pick<IState, "statementEmails" | "edit">>(previousState, {
			statementEmails: {
				state: asyncState,
				payload
			},
			edit
		});

		return previousState;
	}

	if (action instanceof actions.UpdateBillingAction) {
		return assign(previousState, {
			edit: assign(previousState.edit, action.edit)
		});
	}

	if (
		action instanceof SaveClientProfileAction
		&& action.state === AsyncState.Resolved
		&& (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)
	) {
		return updateEdit(previousState, action.response!.clientProfile);
	}

	if (action instanceof SaveClientProfileStatementEmailAddressesAction && getLoadState(action) === AsyncState.Resolved) {
		return assign(previousState, {
			statementEmails: assign<IAsync<Dto.IClientProfileStatementEmail[]>, Pick<IAsync<Dto.IClientProfileStatementEmail[]>, "payload">>(
				previousState.statementEmails,
				{
					payload: action.response!.items
				}
			),
			edit: assign(previousState.edit, {
				emails: action.response!.items
			})
		});
	}

	return previousState;
}