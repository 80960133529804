import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";
import * as Dto from "../../Models/dto";

export default class AccountSelectedAction implements IAction {
	selectedAccounts: Dto.IAccount[];

	constructor(data: Dto.IAccount[]) {
		this.selectedAccounts = data;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"AccountSelectedAction", { "selectedAccounts": JSON.stringify(this.selectedAccounts) });
	}
}