import * as Dto from "../../../../../../Models/dto";
import joinParts from "../../../../../../Utils/joinParts";
import trim from "../../../../../../Utils/trim";
import AccountDetailsStore from "../../../../../../Stores/AccountDetailsStore";
import ReduxStore from "../../../../../../Stores/ReduxStore";
import AsyncState from "../../../../../../Models/AsyncState";
import AccountsContext from "../../../../../../Utils/AccountsContext";

export enum PartyType {
	Unspecified = 1,
	Attorney = 2,
	PersonalRepresentative = 3,
	FamilyFriendOther = 4
}

export enum PhoneType {
	Unspecified = 1,
	Home = 2,
	Work = 3,
	Cell = 4,
	Fax = 5
}

export const EMPTY_CONTACT: Dto.IContact = {
	address1: "",
	address2: "",
	city: "",
	email: "",
	firstName: "",
	id: -1,
	lastName: "",
	middleName: "",
	notes: "",
	partyWriteTimestamp: "",
	phoneNumbers: [],
	prefixName: "",
	relationship: PartyType.Unspecified,
	remove: false,
	stateId: null,
	suffixName: "",
	zip: "",
	writeTimestamp: ""
};

const EMPTY_CONTACTS: Dto.IContact[] = [];
export function getCurrentDetails() {
	const { account } = AccountDetailsStore.getState();
	const contactState = ReduxStore.getState().ui.dashboard.accounts.accountDetails.contacts;

	let loadState: AsyncState | null = null;
	let contacts = EMPTY_CONTACTS;
	let historyLoadState: AsyncState | null = null;
	let history: Pick<Dto.IContactHistResponse, "contacts" | "phones"> | null = null;

	if (
		AccountsContext.isAccount(account)
		&& contactState != null
		&& contactState.acctId === account.acctId
	) {
		loadState = contactState.loadState;
		contacts = contactState.contacts;
		historyLoadState = contactState.historyLoadState;
		history = contactState.history;
	}

	return {
		account,
		loadState,
		contacts,
		historyLoadState,
		history
	};
}

export function getPartyType(value: PartyType | Dto.IContact) {
	const relationship = typeof value === "number" ? value : value.relationship;
	switch (relationship) {
		case PartyType.Attorney:
			return "Attorney";
		case PartyType.PersonalRepresentative:
			return "Personal Representative";
		case PartyType.FamilyFriendOther:
			return "Family/\u200bFriend/\u200bOther";
		case PartyType.Unspecified:
		default:
			return null;
	}
}

export function getPhoneType(value: PhoneType | Dto.IContactPhone) {
	const type = typeof value === "number" ? value : value.type;
	switch (type) {
		case PhoneType.Home:
			return "Home";
		case PhoneType.Work:
			return "Work";
		case PhoneType.Cell:
			return "Cell";
		case PhoneType.Fax:
			return "Fax";
		case PhoneType.Unspecified:
		default:
			return null;
	}
}

export function getName(contact: Dto.IContact) {
	return joinParts(" ", [
		trim(contact.prefixName),
		trim(contact.firstName),
		trim(contact.middleName),
		trim(contact.lastName),
		trim(contact.suffixName)
	]);
}