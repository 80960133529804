import React, { forwardRef } from "react";
import Button, { IProps as IButtonProps } from "../Button/Button";
import styles from "./SvgIconButton.module.scss";

interface IProps extends Omit<IButtonProps, "icon"> {
	icon: React.ReactNode;
	right?: boolean;
	iconOnly?: boolean;
}

const SvgIconButton = forwardRef<HTMLButtonElement, IProps>(({ icon, right, iconOnly, className, children, ...props }: IProps, ref) => {
	return (
		<Button
			ref={ref}
			className={[styles.button, className, { [styles.right]: right, [styles.iconOnly]: iconOnly }] as ClassValue}
			{...props}
		>
			{!right && icon}
			{!iconOnly && (
				<div>{children}</div>
			)}
			{right && icon}
		</Button>
	);
});

export default SvgIconButton;