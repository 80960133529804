import React from "react";
import AccountWorkflowSteps from "../../../../Models/AccountWorkflowSteps";
import IWorkflow, { IWorkflowBase } from "../../../../Models/IWorflow";
import { Permission, Permissions, IPermissions, map as permissionMap } from "../../../../Models/Permissions";
import { IAccountWorkflowStep } from "../../../../Models/dto";
import { IState as IAppState } from "../../../../Reducers/rootReducer";
import { createSelector } from "reselect";
import getCurrentPermissions from "../../../../selectors/getCurrentPermissions";
import getClientProfile from "../../../../selectors/getClientProfile";
import { ReactComponent as ProcessedIcon } from "./icons/processed.svg"
import { ReactComponent as ReturnedIcon } from "./icons/returned.svg"
import { ReactComponent as ActiveSearchingIcon } from "./icons/active-searching.svg"
import { ReactComponent as ApprovedIcon } from "./icons/approved.svg"
import { ReactComponent as ClosedIcon } from "./icons/closed.svg"
import { ReactComponent as DisallowedIcon } from "./icons/disallowed.svg"
import { ReactComponent as DodMatchedIcon } from "./icons/dod-matched.svg"
import FlaggedIcon from "../../../Common/icons/FlaggedIcon/FlaggedIcon";
import FiledIcon from "../../../Common/icons/DocumentCheckIcon/DocumentCheckIcon";
import EstateMatchedIcon from "../../../Common/icons/EstateMatchedIcon/EstateMatchedIcon";
import PrePlacementIcon from "../../../Common/icons/PrePlacementIcon/PrePlacementIcon";

interface IWorkflowBootstrap {
	permission?: Permission | null | undefined;
	icon: () => React.ReactNode;
	import: () => Promise<{ default: IWorkflowBase }>;
	highlight?: boolean;
}

export interface IWorkflowMeta {
	id: AccountWorkflowSteps;
	description: string;
	icon: () => React.ReactNode;
	load: () => Promise<IWorkflow>;
	highlight: boolean;
}

let workflows: { [id: number]: IWorkflowBootstrap } = {
	[AccountWorkflowSteps.Flagged]: {
		permission: process.env.REACT_APP_APPLICATION === "pfod" ? Permissions.FlaggedView : null,
		icon: () => <FlaggedIcon aria-hidden />,
		import: () => import("./flagged"),
		highlight: process.env.REACT_APP_APPLICATION === "pfod"
	},
	[AccountWorkflowSteps.NonMatched]: {
		permission: process.env.REACT_APP_APPLICATION === "pfod" ? Permissions.NonMatchedView : null,
		icon: () => <ActiveSearchingIcon aria-hidden />,
		import: () => import("./activeSearching")
	},
	[AccountWorkflowSteps.Closed]: {
		permission: process.env.REACT_APP_APPLICATION === "pfod" ? Permissions.ClosedView : null,
		icon: () => <ClosedIcon aria-hidden />,
		import: () => import("./closed")
	}
};

if (process.env.REACT_APP_APPLICATION === "pfod") {
	workflows = {
		...workflows,
		[AccountWorkflowSteps.PrePlacement]: {
			permission: Permissions.AwaitingPlacementView,
			icon: () => <PrePlacementIcon aria-hidden />,
			import: () => import("./prePlacement"),
			highlight: true
		},
		[AccountWorkflowSteps.DodMatched]: {
			permission: Permissions.DODMatchedView,
			icon: () => <DodMatchedIcon aria-hidden />,
			import: () => import("./dodMatched")
		},
		[AccountWorkflowSteps.EstateMatched]: {
			permission: Permissions.EstateMatchedView,
			icon: () => <EstateMatchedIcon aria-hidden />,
			import: () => import("./estateMatchedPfod"),
			highlight: true
		},
		[AccountWorkflowSteps.Approved]: {
			permission: Permissions.ApprovedView,
			icon: () => <ApprovedIcon aria-hidden />,
			import: () => import("./approved")
		},
		[AccountWorkflowSteps.Processed]: {
			permission: Permissions.ProcessedClaimsView,
			icon: () => <ProcessedIcon aria-hidden />,
			import: () => import("./processed")
		},
		[AccountWorkflowSteps.Filed]: {
			permission: Permissions.FiledClaimsView,
			icon: () => <FiledIcon aria-hidden />,
			import: () => import("./filedPfod"),
			highlight: true
		},
		[AccountWorkflowSteps.Disputed]: {
			permission: Permissions.DisputedClaimsView,
			icon: () => <DisallowedIcon aria-hidden />,
			import: () => import("./disallowed")
		},
		[AccountWorkflowSteps.Returned]: {
			permission: Permissions.ReturnedClaimsView,
			icon: () => <ReturnedIcon aria-hidden />,
			import: () => import("./returned")
		}
	};
}

if (process.env.REACT_APP_APPLICATION === "access") {
	workflows = {
		...workflows,
		[AccountWorkflowSteps.EstateMatched]: {
			icon: () => <EstateMatchedIcon aria-hidden />,
			import: () => import("./estateMatchedAccess")
		},
		[AccountWorkflowSteps.BacEstateMatched]: {
			icon: () => <EstateMatchedIcon aria-hidden />,
			import: () => import("./bacEstateMatched")
		},
		[AccountWorkflowSteps.PendingValidation]: {
			icon: () => <EstateMatchedIcon aria-hidden />, // todo
			import: () => import("./pendingValidation")
		},
		[AccountWorkflowSteps.Validated]: {
			icon: () => <ProcessedIcon aria-hidden />,
			import: () => import("./validated")
		},
		[AccountWorkflowSteps.Filed]: {
			icon: () => <FiledIcon aria-hidden />,
			import: () => import("./filedAccess")
		},
		[AccountWorkflowSteps.BacFiled]: {
			icon: () => <FiledIcon aria-hidden />,
			import: () => import("./bacFiled")
		}
	};
}

function canViewWorkflow({ permission }: IWorkflowBootstrap, permissions: IPermissions) {
	if (permission == null) {
		return true;
	}

	const key = permissionMap[permission];
	return permissions[key];
}

const getAutoPlace = process.env.REACT_APP_APPLICATION !== "pfod" ? (() => false) : createSelector(
	getClientProfile,
	it => it == null ? false : it.autoPlaceFlag
);

const getWorkflows = createSelector(
	getCurrentPermissions,
	(state: IAppState) => state.entities.startup.workflowSteps,
	getAutoPlace,
	function (permissions: IPermissions, workflowSteps: IAccountWorkflowStep[], autoPlace: boolean) {
		const steps: IWorkflowMeta[] = [];

		for (const { accountWorkflowStepId, description } of workflowSteps) {
			if (
				process.env.REACT_APP_APPLICATION === "pfod"
				&& autoPlace
				&& accountWorkflowStepId === AccountWorkflowSteps.PrePlacement
			) {
				continue;
			}

			const bootstrap = workflows[accountWorkflowStepId];
			if (bootstrap == null || !canViewWorkflow(bootstrap, permissions)) {
				continue;
			}

			steps.push({
				id: accountWorkflowStepId,
				description,
				icon: bootstrap.icon,
				highlight: bootstrap.highlight ?? false,
				load: async () => {
					const workflow = (await bootstrap.import()).default;
					return {
						...workflow,
						description
					} as IWorkflow;
				}
			});
		}

		return steps;
	}
);

export default getWorkflows;