import connect from "./connect";

export interface ICaptchaProps {
	captchaValid: boolean;
}

function connectToCaptcha<TProps>(WrappedComponent: React.ComponentClass<TProps & ICaptchaProps>): React.ComponentClass<TProps>;
function connectToCaptcha<TProps>(WrappedComponent: React.StatelessComponent<TProps & ICaptchaProps>): React.ComponentClass<TProps>; 
function connectToCaptcha<TProps>(WrappedComponent: any): React.ComponentClass<TProps> {
	return connect<ICaptchaProps, TProps>(
		WrappedComponent,
		state => {
			if (state.ui.captcha.challenge === "__NOT_REQUIRED__") {
				return {
					captchaValid: true
				};
			}

			const { response } = state.ui.captcha;
			return {
				captchaValid: response != null && response.length === 4
			};
		}
	);
}

export default connectToCaptcha;