import { MODIFIED_BY_ANOTHER_USER, OK } from "../../../../Models/apiConstants";
import { EditAccountMiscAction } from "../../../../Actions/AccountDetailsActions";
import assign from "../../../../Utils/assign";
import IAction from "../../../../Actions/IAction";
import {
	GetAccountMiscAction,
	IGetAccountMiscActionParams,
	SaveAccountAction,
	SaveAccountMiscAction
} from "../../../../Actions/Api/accountActions";
import * as Dto from "../../../../Models/dto";
import AsyncState from "../../../../Models/AsyncState";

export interface IState {
	request: {
		id: string;
		state: AsyncState;
		params: IGetAccountMiscActionParams;
	} | null;
	data: Dto.IAccountMisc | null;
	edit: Dto.IAccountMisc | null;
}

function tryUpdateFromAccount(target: Dto.IAccountMisc | null, action: SaveAccountAction, forEdit: boolean) {
	if (
		target != null
		&& target.acctId === action.params.account.acctId
		&& target.writeTimestamp === action.params.account.writeTimestamp

		// if the edit has a different pendingApproval value we will just let the modified error happen
		&& (!forEdit || target.pendingApproval === action.response!.item.pendingApproval)
	) {
		return assign(target, {
			pendingApproval: action.response!.item.pendingApproval,
			writeTimestamp: action.response!.item.writeTimestamp
		});
	}

	return target;
}

export default function accountMiscReducer(
	previousState: IState = {
		request: null,
		data: null,
		edit: null
	},
	action: IAction
): IState {
	if (action instanceof GetAccountMiscAction) {
		if (action.state === AsyncState.Pending) {
			return assign<IState, Pick<IState, "request" | "data">>(previousState, {
				request: {
					id: action.requestId,
					state: action.state,
					params: action.params
				},
				data: null
			});
		} else if (previousState.request != null && action.requestId === previousState.request.id) {
			const { state } = action;

			let { data, edit } = previousState;
			if (
				state === AsyncState.Resolved
				&& (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)
			) {
				data = action.response!.item;
				edit = null;
			}

			return assign<IState, Pick<IState, "request" | "data" | "edit">>(previousState, {
				request: assign(previousState.request as any, { state }),
				data,
				edit
			});
		}
	}

	if (
		action instanceof SaveAccountMiscAction
		&& action.state === AsyncState.Resolved
		&& (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)
	) {
		return assign<IState, Pick<IState, "data" | "edit">>(previousState, {
			data: action.response!.item,
			edit: null
		});
	}

	if (
		action instanceof SaveAccountAction
		&& action.state === AsyncState.Resolved
		&& (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)
	) {
		const updates = {
			data: tryUpdateFromAccount(previousState.data, action, false),
			edit: tryUpdateFromAccount(previousState.edit, action, true)
		};

		if (updates.data !== previousState.data || updates.edit !== previousState.edit) {
			return assign(previousState, updates);
		}
	}

	if (action instanceof EditAccountMiscAction) {
		return assign<IState, Pick<IState, "edit">>(previousState, {
			edit: action.edit
		});
	}

	return previousState;
}