import React from "react";
import { Modal, ModalProps } from "react-bootstrap";
import { getClassSet, prefix, splitBsProps } from "react-bootstrap/lib/utils/bootstrapUtils";
import Draggable from "react-draggable";
import styles from "./DraggableModal.module.scss";
import cn from "classnames";

class DraggableModalDialog extends React.Component<ModalProps> {
	render() {
		const { dialogClassName, className, style, children, ...props } = this.props;
		const [bsProps, elementProps] = splitBsProps(props);
		const bsClassName = prefix(bsProps);
		const modalStyle = { display: "block", ...style };

		const dialogClasses = {
			...getClassSet(bsProps),
			[bsClassName]: false,
			[prefix(bsProps, "dialog")]: true
		};

		return (
			<div
				role="dialog"
				tabIndex={-1}
				className={cn(className, bsClassName, styles.draggableModal)}
				style={modalStyle}
				{...elementProps}
			>
				<div className={cn(dialogClassName, dialogClasses)}>
					<Draggable handle=".modal-title">
						<div className={prefix(bsProps, "content")} role="document">
							{children}
						</div>
					</Draggable>
				</div>
			</div>
		);
	}
}

export default class DraggableModal extends React.Component<ModalProps> {
	static defaultProps: Partial<ModalProps> = {
		backdrop: "static"
	};

	render() {
		return (
			<Modal
				enforceFocus={false}
				{...{ dialogComponentClass: DraggableModalDialog }}
				{...this.props}
				onEntered={this._handleEntered}
			>
				{this.props.children}
			</Modal>
		);
	}

	private _handleEntered = (node: HTMLElement) => {
		if (node != null) {
			// im getting some weird visual artifacts in modals in chrome (text cut off, borders not lining up)... 
			// messing with the transform on the draggable div seems to fix it?

			const draggable = node.getElementsByClassName("react-draggable")[0] as HTMLDivElement;
			draggable.style.transform = "";
		}

		if (this.props.onEntered != null) {
			this.props.onEntered(node);
		}
	}
}