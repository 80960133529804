import React from "react";

let appName: string;
let logoLogin: JSX.Element | undefined;
let logoHeader: JSX.Element | undefined;

if (process.env.REACT_APP_BRANDING === "pfod") {
	appName = "Probate Finder OnDemand®";

	const logo = require("../../Images/logo_transparent.png");
	logoLogin = (
		<img
			src={logo}
			alt={appName}
			height="49"
			width="167"
		/>
	);
	logoHeader = (
		<img
			src={logo}
			alt={appName}
			width="116"
			height="34"
		/>
	);
}

if (process.env.REACT_APP_BRANDING === "access") {
	appName = "ACCESS Probate Finder™";

	const logo = require("../../Images/logo_access.png");
	logoLogin = (
		<img
			src={logo}
			alt={appName}
			height="66"
			width="167"
		/>
	);
	logoHeader = (
		<img
			src={logo}
			alt={appName}
			width="116"
			height="46"
		/>
	);
}

if (appName! == null || logoLogin == null || logoHeader == null) {
	throw new Error("Unexpected state");
}

export { appName, logoLogin, logoHeader };
