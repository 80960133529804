import uniq from "lodash/uniq";
import getMediaType from "./getMediaType";

export const EXTENSIONS = [
	".JPG",
	".JPEG",
	".GIF",
	".PNG",
	".PDF",
	".TIF",
	".TIFF",
	".DOC",
	".DOCX",
	".XLS",
	".XLSX",
	".TXT",
	".CSV"
];

export const ACCEPT = makeAccept(EXTENSIONS);

export const STATEMENT_EXTENSIONS = [".PDF"];

export const ACCEPT_STATEMENT = makeAccept(STATEMENT_EXTENSIONS);

function makeAccept(extensions: string[]) {
	return [
		...extensions.map(it => it.toLowerCase()),
		...uniq(
			extensions
				.map(it => getMediaType(it))
				.filter(it => it != null)
		)
	].join(",");
}

export function isValidExtension(file: File, statement = false) {
	const fileNameLower = file.name.toLowerCase();
	const extensions = statement ? STATEMENT_EXTENSIONS : EXTENSIONS;
	return extensions.some(it => fileNameLower.endsWith(it.toLowerCase()));
}