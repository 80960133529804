import isEmptyString from "./isEmpty";
import moment from "moment";

export const NULL_DATE_STRING = "0001-01-01";
const NULL_MOMENT = moment(NULL_DATE_STRING);

function _parse(value: string | Date | moment.Moment | null | undefined, strict: boolean) {
	if (value == null) {
		return null;
	}

	let date: moment.Moment;
	if (typeof value === "string") {
		if (value === NULL_DATE_STRING || value === NULL_DATE_STRING + "T00:00:00" || isEmptyString(value)) {
			return null;
		}

		date = moment(value, [moment.ISO_8601, "M/D/YYYY h:mm:ss a"], strict);
	} else {
		date = moment(value);
	}

	return date.isSame(NULL_MOMENT, "day") ? null : date;
}

export function isEmpty(value: string | Date | moment.Moment | null, strict = false) {
	return _parse(value, strict) == null;
}

export function parse(value: string | null | undefined, strict = false) {
	return _parse(value, strict);
}

export function stringify(value: moment.Moment | null, emptyIfNull = false) {
	return value != null ? value.format("YYYY-MM-DD")
		: emptyIfNull ? ""
			: NULL_DATE_STRING;
}

export function sameDate(a: string | Date | moment.Moment | null | undefined, b: string | Date | moment.Moment | null | undefined, strict = false) {
	const aDate = _parse(a, strict);
	const bDate = _parse(b, strict);

	return (aDate === bDate) || (aDate != null && bDate != null && aDate.isSame(bDate, "day"));
}

export function format(value: string | Date | moment.Moment, format = "M/D/YYYY", strict = false) {
	const date = _parse(value, strict);
	return date == null ? "" : date.format(format);
}