import { ActionBase } from "../IAction";

export class DynamicKpiDateChangedAction extends ActionBase {
	constructor(public startDate: string | null, public endDate: string | null) {
		super(nameof(DynamicKpiDateChangedAction));
	}
}

export class DynamicKpiAffiliateChangedAction extends ActionBase {
	constructor(public clientIds: number[] | null) {
		super(nameof(DynamicKpiAffiliateChangedAction));
	}
}

export class ReportAffiliateChangedAction extends ActionBase {
	constructor(public clientIds: number[] | null) {
		super(nameof(ReportAffiliateChangedAction));
	}
}