import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";

export default class AffiliateSelectedAction implements IAction {
	selectedAffiliate: number;

	constructor(data: number) {
		this.selectedAffiliate = data;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"AffiliateSelectedAction", { "selectedAffiliate": JSON.stringify(this.selectedAffiliate) });
	}
}