import React from "react";
import HelpPopup, { IProps as IHelpPopupProps } from "../HelpPopup/HelpPopup";
import styles from "./HelpContent.module.scss";
import classNames from "classnames";

interface IProps extends Pick<IHelpPopupProps, "title" | "showOnFocus" | "placement" | "onButtonClick"> {
	help: IHelpPopupProps["children"];
	children?: React.ReactNode;
	flex?: boolean;
	block?: boolean; // todo change block to default and add inline prop instead?
}

export default function HelpContent({ help, children, flex, block, ...helpPopupProps }: IProps) {
	return (
		<span className={classNames(styles.wrapper, {
			[styles.block]: block
		})}>
			<span className={classNames({ [styles.flex]: flex ?? block })}>
				{children}
			</span>
			<HelpPopup
				className={styles.help}
				{...helpPopupProps}
			>
				{help}
			</HelpPopup>
		</span>
	)
}