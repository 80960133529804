import Dispatcher from "../Dispatcher/Dispatcher";
import IAction, { ActionBase } from "../Actions/IAction";
import BaseStore from "../Stores/BaseStore";
import shallowEqual from "./shallowEqual";

class InitStateAction extends ActionBase {
	constructor() {
		super("InitStateAction");
	}
}

export interface IStore<TState> {
	getState(): TState;
	dispatch(action: IAction): void;
	addListener(listener: () => void): void;
	removeListener(listener: () => void): void;
}

class Store<TState> extends BaseStore implements IStore<TState> {
	token: string;

	private _state: TState;

	constructor(reducer: (previousState: TState, action: IAction) => TState) {
		super();

		this._state = reducer(undefined as any, new InitStateAction());

		if (__DEV__) {
			(window as any).state = this._state;
		}

		this.token = Dispatcher.register(action => {
			if (__DEV__) {
				console.log(action);
			}

			const previousState = this._state;
			this._state = reducer(previousState, action);

			if (__DEV__) {
				(window as any).state = this._state;
			}

			if (!shallowEqual(previousState, this._state)) {
				this.emitChange();
			}
		});
	}

	getState() {
		return this._state;
	}

	dispatch(action: IAction) {
		Dispatcher.dispatch(action);
	}
}

export default function createFluxStore<TState>(reducer: (previousState: TState | undefined, action: IAction) => TState) {
	return new Store<TState>(reducer);
}
