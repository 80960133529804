enum AccountWorkflowSteps {
	Flagged = 10,
	PrePlacement = 20,
	NonMatched = 30,
	DodMatched = 35,
	EstateMatched = 40,
	BacEstateMatched = 41,
	PendingValidation = 42,
	Approved = 50,
	Validated = 52,
	Processed = 60,
	Filed = 70,
	BacFiled = 71,
	Returned = 80,
	Closed = 90,
	Disputed = 100
}

export default AccountWorkflowSteps;