import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";
import * as AccountsData from "../../Models/AccountsData";

export default class PageDataChangedAction implements IAction {
	data: AccountsData.IPageData;

	constructor(data: AccountsData.IPageData) {
		this.data = data;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"PageDataChangedAction", { "data": JSON.stringify(this.data) });
	}
}