import React from "react";
import ITab from "../../../../Models/ITab";
import { IContext as IContextProps } from "../Tabs";
import ChangeWatcher from "../../ChangeWatcher/ChangeWatcher";

export interface IOwnProps {
	onTabEnter: (tab: ITab) => void;
	onTabExit: (tab: ITab, elapsedMs: number | null) => void;
}

type IProps = IOwnProps & IContextProps;

export default function Watcher(props: IProps) {
	return (
		<ChangeWatcher
			value={props.tabs.find(it => it.key === props.selected) ?? null}
			onEnter={props.onTabEnter}
			onExit={props.onTabExit}
			isEqual={(a, b) => a.key === b.key}
		/>
	);
}