import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";
import * as Dto from "../../Models/dto";

export default class SimpleSearchUpdatedAction implements IAction {
	search: Dto.IClientProfileUserSearch | null;

	constructor(data: Dto.IClientProfileUserSearch | null) {
		this.search = data;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"SimpleSearchUpdatedAction", {
				"search": JSON.stringify(this.search)
			});
	}
}