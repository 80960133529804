/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IAccount,
	IAccountClaimsResponse,
	IAccountHistory,
	IAccountLowBalance,
	IAccountMatchedRecallWithLinkedAccounts,
	IAccountMisc,
	IAccountsResponse,
	IAccountWorkflowStatusCount,
	IAddRecentAccount,
	IBankruptcyCase,
	IBaseResponse,
	IClientProfileBillingResponse,
	IClientProfileUserSearch,
	ICollectionResponse,
	IContact,
	IContactHistResponse,
	IRecentAccount,
	IResponse,
	IRollbackApprovalResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IAdvancedSearchAccountsActionParams {
	userSearch: IClientProfileUserSearch;
	startIndex: number;
	endIndex: number;
	sortField: string;
	sortDirection: string;
}

export class AdvancedSearchAccountsAction extends ApiActionBase<IAdvancedSearchAccountsActionParams, IAccountsResponse> {
	constructor(details: IDetails<IAdvancedSearchAccountsActionParams, IAccountsResponse>) {
		super("Api/AccountActions/AdvancedSearchAccountsAction", details);
	}
}

export interface IDeleteUserSearchActionParams {
	userSearch: IClientProfileUserSearch;
}

export class DeleteUserSearchAction extends ApiActionBase<IDeleteUserSearchActionParams, IResponse<IClientProfileUserSearch>> {
	constructor(details: IDetails<IDeleteUserSearchActionParams, IResponse<IClientProfileUserSearch>>) {
		super("Api/AccountActions/DeleteUserSearchAction", details);
	}
}

export interface IGetAccountByAccountIDActionParams {
	acctID: number;
}

export class GetAccountByAccountIDAction extends ApiActionBase<IGetAccountByAccountIDActionParams, IResponse<IAccount>> {
	constructor(details: IDetails<IGetAccountByAccountIDActionParams, IResponse<IAccount>>) {
		super("Api/AccountActions/GetAccountByAccountIDAction", details);
	}
}

export interface IGetAccountClosedLinkedAccountsActionParams {
	acctIDs: number[];
}

export class GetAccountClosedLinkedAccountsAction extends ApiActionBase<IGetAccountClosedLinkedAccountsActionParams, ICollectionResponse<IAccount>> {
	constructor(details: IDetails<IGetAccountClosedLinkedAccountsActionParams, ICollectionResponse<IAccount>>) {
		super("Api/AccountActions/GetAccountClosedLinkedAccountsAction", details);
	}
}

export interface IGetAccountHistoriesByAccountIDActionParams {
	acctId: number;
}

export class GetAccountHistoriesByAccountIDAction extends ApiActionBase<IGetAccountHistoriesByAccountIDActionParams, ICollectionResponse<IAccountHistory>> {
	constructor(details: IDetails<IGetAccountHistoriesByAccountIDActionParams, ICollectionResponse<IAccountHistory>>) {
		super("Api/AccountActions/GetAccountHistoriesByAccountIDAction", details);
	}
}

export interface IGetAccountMatchedApprovalLinkedAccountsActionParams {
	acctIDs: number[];
	includeOriginalAccounts: boolean;
}

export class GetAccountMatchedApprovalLinkedAccountsAction extends ApiActionBase<IGetAccountMatchedApprovalLinkedAccountsActionParams, ICollectionResponse<IAccount>> {
	constructor(details: IDetails<IGetAccountMatchedApprovalLinkedAccountsActionParams, ICollectionResponse<IAccount>>) {
		super("Api/AccountActions/GetAccountMatchedApprovalLinkedAccountsAction", details);
	}
}

export interface IGetAccountMatchedRecallWithLinkedAccountsActionParams {
	recallAccountID: number;
}

export class GetAccountMatchedRecallWithLinkedAccountsAction extends ApiActionBase<IGetAccountMatchedRecallWithLinkedAccountsActionParams, IResponse<IAccountMatchedRecallWithLinkedAccounts>> {
	constructor(details: IDetails<IGetAccountMatchedRecallWithLinkedAccountsActionParams, IResponse<IAccountMatchedRecallWithLinkedAccounts>>) {
		super("Api/AccountActions/GetAccountMatchedRecallWithLinkedAccountsAction", details);
	}
}

export interface IGetAccountMiscActionParams {
	acctId: number;
}

export class GetAccountMiscAction extends ApiActionBase<IGetAccountMiscActionParams, IResponse<IAccountMisc>> {
	constructor(details: IDetails<IGetAccountMiscActionParams, IResponse<IAccountMisc>>) {
		super("Api/AccountActions/GetAccountMiscAction", details);
	}
}

export interface IGetAccountsByAccountStatusAndWorkflowStatusIDActionParams {
	accountWorkflowStatusID: number;
	searchClientIDs: number[];
	startIndex: number;
	endIndex: number;
	sortField: string;
	sortDirection: string;
	accountStatusIds: number[];
}

export class GetAccountsByAccountStatusAndWorkflowStatusIDAction extends ApiActionBase<IGetAccountsByAccountStatusAndWorkflowStatusIDActionParams, IAccountsResponse> {
	constructor(details: IDetails<IGetAccountsByAccountStatusAndWorkflowStatusIDActionParams, IAccountsResponse>) {
		super("Api/AccountActions/GetAccountsByAccountStatusAndWorkflowStatusIDAction", details);
	}
}

export interface IGetAccountWorkflowStatusCountsActionParams {
	clientIds: number[];
}

export class GetAccountWorkflowStatusCountsAction extends ApiActionBase<IGetAccountWorkflowStatusCountsActionParams, ICollectionResponse<IAccountWorkflowStatusCount>> {
	constructor(details: IDetails<IGetAccountWorkflowStatusCountsActionParams, ICollectionResponse<IAccountWorkflowStatusCount>>) {
		super("Api/AccountActions/GetAccountWorkflowStatusCountsAction", details);
	}
}

export interface IGetBankoInfoActionParams {
	acctID: number;
}

export class GetBankoInfoAction extends ApiActionBase<IGetBankoInfoActionParams, IResponse<IBankruptcyCase>> {
	constructor(details: IDetails<IGetBankoInfoActionParams, IResponse<IBankruptcyCase>>) {
		super("Api/AccountActions/GetBankoInfoAction", details);
	}
}

export interface IGetClaimsByAccountIDActionParams {
	acctId: number;
}

export class GetClaimsByAccountIDAction extends ApiActionBase<IGetClaimsByAccountIDActionParams, IAccountClaimsResponse> {
	constructor(details: IDetails<IGetClaimsByAccountIDActionParams, IAccountClaimsResponse>) {
		super("Api/AccountActions/GetClaimsByAccountIDAction", details);
	}
}

export interface IGetClaimSharedAccountsActionParams {
	acctIds: number[];
}

export class GetClaimSharedAccountsAction extends ApiActionBase<IGetClaimSharedAccountsActionParams, ICollectionResponse<IAccount>> {
	constructor(details: IDetails<IGetClaimSharedAccountsActionParams, ICollectionResponse<IAccount>>) {
		super("Api/AccountActions/GetClaimSharedAccountsAction", details);
	}
}

export interface IGetClientProfileBillingsActionParams {
	acctID: number;
}

export class GetClientProfileBillingsAction extends ApiActionBase<IGetClientProfileBillingsActionParams, IClientProfileBillingResponse> {
	constructor(details: IDetails<IGetClientProfileBillingsActionParams, IClientProfileBillingResponse>) {
		super("Api/AccountActions/GetClientProfileBillingsAction", details);
	}
}

export interface IGetClientProfileUserSearchesActionParams {
	/* no parameters */
}

export class GetClientProfileUserSearchesAction extends ApiActionBase<IGetClientProfileUserSearchesActionParams, ICollectionResponse<IClientProfileUserSearch>> {
	constructor(details: IDetails<IGetClientProfileUserSearchesActionParams, ICollectionResponse<IClientProfileUserSearch>>) {
		super("Api/AccountActions/GetClientProfileUserSearchesAction", details);
	}
}

export interface IGetContactHistoryActionParams {
	acctId: number;
}

export class GetContactHistoryAction extends ApiActionBase<IGetContactHistoryActionParams, IContactHistResponse> {
	constructor(details: IDetails<IGetContactHistoryActionParams, IContactHistResponse>) {
		super("Api/AccountActions/GetContactHistoryAction", details);
	}
}

export interface IGetContactsActionParams {
	acctId: number;
}

export class GetContactsAction extends ApiActionBase<IGetContactsActionParams, ICollectionResponse<IContact>> {
	constructor(details: IDetails<IGetContactsActionParams, ICollectionResponse<IContact>>) {
		super("Api/AccountActions/GetContactsAction", details);
	}
}

export interface IGetLowBalanceAccountsActionParams {
	clientID: number;
}

export class GetLowBalanceAccountsAction extends ApiActionBase<IGetLowBalanceAccountsActionParams, ICollectionResponse<IAccountLowBalance>> {
	constructor(details: IDetails<IGetLowBalanceAccountsActionParams, ICollectionResponse<IAccountLowBalance>>) {
		super("Api/AccountActions/GetLowBalanceAccountsAction", details);
	}
}

export interface IGetRecentAccountsActionParams {
	/* no parameters */
}

export class GetRecentAccountsAction extends ApiActionBase<IGetRecentAccountsActionParams, ICollectionResponse<IRecentAccount>> {
	constructor(details: IDetails<IGetRecentAccountsActionParams, ICollectionResponse<IRecentAccount>>) {
		super("Api/AccountActions/GetRecentAccountsAction", details);
	}
}

export interface IRecallAllDODMatchedAccountsActionParams {
	expectedCount: number;
	clientIDsForRecall: number[];
}

export class RecallAllDODMatchedAccountsAction extends ApiActionBase<IRecallAllDODMatchedAccountsActionParams, IBaseResponse> {
	constructor(details: IDetails<IRecallAllDODMatchedAccountsActionParams, IBaseResponse>) {
		super("Api/AccountActions/RecallAllDODMatchedAccountsAction", details);
	}
}

export interface IRecallAllUnmatchedAccountsActionParams {
	expectedCount: number;
	clientIDsForRecall: number[];
}

export class RecallAllUnmatchedAccountsAction extends ApiActionBase<IRecallAllUnmatchedAccountsActionParams, IBaseResponse> {
	constructor(details: IDetails<IRecallAllUnmatchedAccountsActionParams, IBaseResponse>) {
		super("Api/AccountActions/RecallAllUnmatchedAccountsAction", details);
	}
}

export interface IResetAccountToOpenActiveActionParams {
	acctID: number;
	accountImportID: number;
}

export class ResetAccountToOpenActiveAction extends ApiActionBase<IResetAccountToOpenActiveActionParams, IBaseResponse> {
	constructor(details: IDetails<IResetAccountToOpenActiveActionParams, IBaseResponse>) {
		super("Api/AccountActions/ResetAccountToOpenActiveAction", details);
	}
}

export interface IRollbackAccountApprovalActionParams {
	acctIds: number[];
}

export class RollbackAccountApprovalAction extends ApiActionBase<IRollbackAccountApprovalActionParams, IRollbackApprovalResponse> {
	constructor(details: IDetails<IRollbackAccountApprovalActionParams, IRollbackApprovalResponse>) {
		super("Api/AccountActions/RollbackAccountApprovalAction", details);
	}
}

export interface ISaveAccountActionParams {
	account: IAccount;
}

export class SaveAccountAction extends ApiActionBase<ISaveAccountActionParams, IResponse<IAccount>> {
	constructor(details: IDetails<ISaveAccountActionParams, IResponse<IAccount>>) {
		super("Api/AccountActions/SaveAccountAction", details);
	}
}

export interface ISaveAccountMiscActionParams {
	accountMisc: IAccountMisc;
}

export class SaveAccountMiscAction extends ApiActionBase<ISaveAccountMiscActionParams, IResponse<IAccountMisc>> {
	constructor(details: IDetails<ISaveAccountMiscActionParams, IResponse<IAccountMisc>>) {
		super("Api/AccountActions/SaveAccountMiscAction", details);
	}
}

export interface ISaveAccountsActionParams {
	accounts: IAccount[];
}

export class SaveAccountsAction extends ApiActionBase<ISaveAccountsActionParams, IBaseResponse> {
	constructor(details: IDetails<ISaveAccountsActionParams, IBaseResponse>) {
		super("Api/AccountActions/SaveAccountsAction", details);
	}
}

export interface ISaveContactActionParams {
	acctId: number;
	contact: IContact;
}

export class SaveContactAction extends ApiActionBase<ISaveContactActionParams, ICollectionResponse<IContact>> {
	constructor(details: IDetails<ISaveContactActionParams, ICollectionResponse<IContact>>) {
		super("Api/AccountActions/SaveContactAction", details);
	}
}

export interface ISaveUserSearchActionParams {
	userSearch: IClientProfileUserSearch;
}

export class SaveUserSearchAction extends ApiActionBase<ISaveUserSearchActionParams, IResponse<IClientProfileUserSearch>> {
	constructor(details: IDetails<ISaveUserSearchActionParams, IResponse<IClientProfileUserSearch>>) {
		super("Api/AccountActions/SaveUserSearchAction", details);
	}
}

export interface ITrackRecentAccountActionParams {
	recentAccounts: IAddRecentAccount[];
}

export class TrackRecentAccountAction extends ApiActionBase<ITrackRecentAccountActionParams, ICollectionResponse<IRecentAccount>> {
	constructor(details: IDetails<ITrackRecentAccountActionParams, ICollectionResponse<IRecentAccount>>) {
		super("Api/AccountActions/TrackRecentAccountAction", details);
	}
}

/* tslint:enable:max-line-length */