import { useState, useEffect } from "react";
import AsyncState from "../Models/AsyncState";

export default function useImageLoad(url: string | null) {
	const [loadState, setLoadState] = useState<AsyncState | null>(null);

	useEffect(() => {
		if (url == null) {
			return;
		}

		const onLoad = () => setLoadState(AsyncState.Resolved);
		const onError = () => setLoadState(AsyncState.Rejected);

		const img = document.createElement("img");

		img.addEventListener("load", onLoad);
		img.addEventListener("error", onError);

		img.src = url;

		return () => {
			img.removeEventListener("load", onLoad);
			img.removeEventListener("error", onError);
			setLoadState(null);
		}
	}, [url]);

	return url == null ? AsyncState.Resolved : (loadState ?? AsyncState.Pending);
}