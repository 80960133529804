import * as Dto from "../Models/dto";
import AccountsContext from "./AccountsContext";

export default function isSameAccount(a: Dto.IAccount | Dto.IAccountImport | null = null, b: Dto.IAccount | Dto.IAccountImport | null = null) {
	if (a === b) {
		return true;
	}

	if (a == null || b == null) {
		return false;
	}

	if (AccountsContext.isAccount(a)) {
		return AccountsContext.isAccount(b) && a.acctId === b.acctId;
	}

	return AccountsContext.isAccountImport(b) && a.acctImportId === b.acctImportId;
}