/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import apiExecute, { IOptions } from "../../Utils/apiExecute";
import {
	DismissNotificationAction,
	IDismissNotificationActionParams
} from "../../Actions/Api/userNotificationActions";
import {
	IResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export const dismissNotificationEndpoint = "Api/UserNotification/DismissNotification";

export function dismissNotification(
	notificationId: number, 
	__options__?: IOptions<IResponse<boolean>>
) {
	return apiExecute<IDismissNotificationActionParams, DismissNotificationAction, IResponse<boolean>>(
		dismissNotificationEndpoint, 
		true, 
		__options__, 
		DismissNotificationAction,
		{ notificationId }
	);
}

/* tslint:enable:max-line-length */