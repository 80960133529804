import ActiveTabEnum from "../../../Models/ActiveTabEnum";
import { tabLoaded } from "../../../ActionCreators/AppActionCreators";
import createModuleLoader from "../../../Utils/createModuleLoader";

const { loader, load } = createModuleLoader<typeof import("./AccessReports").default>();

if (process.env.REACT_APP_APPLICATION === "access") {
	(async () => {
		const module = await import("./AccessReports");
		load(module.default);
		tabLoaded(ActiveTabEnum.AccessReports);
	})();
}

export default loader;
