import combineReducers from "../../../../Utils/combineReducers";
import accountDetailsReducer, { IState as IAccountDetailsState } from "./accountDetails";
import advancedSearchReducer, { IState as IAdvancedSearchState } from "./advancedSearchReducer";
import accountMiscReducer, { IState as IAccountMiscState } from "./accountMiscReducer";
import uploadFileReducer, { IState as IUploadFileState } from "./uploadFileReducer";
import lockedAccountsReducer, { IState as ILockedAccountsState } from "./lockedAccountsReducer";

export interface IState {
	accountDetails: IAccountDetailsState;
	advancedSearch: IAdvancedSearchState;
	accountMisc: IAccountMiscState;
	uploadFile: IUploadFileState;
	lockedAccounts: ILockedAccountsState;
}

export default combineReducers({
	[nameof<IState>(it => it.accountDetails)]: accountDetailsReducer,
	[nameof<IState>(it => it.advancedSearch)]: advancedSearchReducer,
	[nameof<IState>(it => it.accountMisc)]: accountMiscReducer,
	[nameof<IState>(it => it.uploadFile)]: uploadFileReducer,
	[nameof<IState>(it => it.lockedAccounts)]: lockedAccountsReducer
});