import React, { forwardRef } from "react";
import cn from "classnames";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import withoutProperties from "../../../Utils/withoutProperties";
import styles from "./Button.module.scss";
import useUid from "../../../Utils/useUid";

export interface IProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "className"> {
	label?: string | null;
	tooltip?: string | null;
	icon?: string;
	primary?: boolean;
	success?: boolean;
	info?: boolean;
	warning?: boolean;
	danger?: boolean;
	alt?: boolean;
	alt2?: boolean;
	link?: boolean;
	active?: boolean;
	fake?: boolean;
	default?: boolean;
	className?: ClassValue;
	bsRole?: "toggle"
	bsClass?: unknown;
}

const noop = (event: React.SyntheticEvent<HTMLButtonElement>) => {
	if (__DEV__) {
		console.log(`[${__filename}] Ignoring ${event.type} event`);
	}
};

const Button = forwardRef<HTMLButtonElement, IProps>((props, ref) => {
	const uid = useUid();

	const buttonProps = withoutProperties(props, [
		nameof(props.label),
		nameof(props.tooltip),
		nameof(props.icon),
		nameof(props.primary),
		nameof(props.success),
		nameof(props.info),
		nameof(props.warning),
		nameof(props.danger),
		nameof(props.alt),
		nameof(props.alt2),
		nameof(props.link),
		nameof(props.active),
		nameof(props.default),
		nameof(props.className),
		nameof(props.bsRole),
		nameof(props.bsClass)
	]) as React.ButtonHTMLAttributes<HTMLButtonElement>;

	if (buttonProps.type == null) {
		buttonProps.type = "button";
	}

	// can't actually disable the button if we have a tooltip so let's fake it
	const fakeDisabled = props.disabled && props.tooltip != null;

	if (fakeDisabled) {
		Object.assign(buttonProps, {
			disabled: false,
			tabIndex: -1,
			onClick: noop,
			onFocus: (event: React.FocusEvent<HTMLButtonElement>) => {
				event.currentTarget.blur();
				noop(event);
			},
			onBlur: noop
		});
	}

	buttonProps.className = cn(
		{
			[styles.button]: !props.link,
			[styles.primary]: props.primary,
			"btn-success": props.success,
			"btn-info": props.info,
			"btn-warning": props.warning,
			[styles.danger]: props.danger,
			[styles.alt]: props.alt,
			[styles.alt2]: props.alt2,
			[styles.link]: props.link,
			[styles.default]: props.default || (
				props.default == null
				&& !props.primary
				&& !props.success
				&& !props.info
				&& !props.warning
				&& !props.danger
				&& !props.link
				&& !props.alt
				&& !props.alt2
			),
			"disabled": fakeDisabled,
			"active": props.active
		},
		props.className
	);

	let label;
	if (props.children === undefined) {
		label = (
			<>
				{props.icon && (
					<>
						<i className={cn("fa", props.icon)} />
						{" "}
					</>
				)}
				<span>{props.label}</span>
			</>
		);
	} else {
		label = props.children;
	}

	let button = (
		<button
			ref={ref}
			{...buttonProps}
		>
			{label}
		</button>
	);

	if (props.tooltip) {
		const tooltip = (
			<Tooltip id={`tooltip-${uid}`}>
				{props.tooltip}
			</Tooltip>
		);

		return (
			<OverlayTrigger
				placement="top"
				overlay={tooltip}
			>
				{button}
			</OverlayTrigger>
		);
	}

	return button;
});

export default Button;