import React from "react";
import cn from "classnames";
import styles from "./CheckboxInput.module.scss";
import uid from "../../../Utils/uid";

interface IProps {
	value: boolean;
	label?: React.ReactNode;
	onChange: (value: boolean) => void;
	className?: string;
	disabled?: boolean | null;
	id?: string;
	name?: string;
}

export default class CheckboxInput extends React.Component<IProps, {}> {
	private _uid: string = uid();

	constructor(props: IProps) {
		super(props);
		this._onChange = this._onChange.bind(this);
	}

	render() {
		const { props } = this;
		const id = props.id || `checkbox-${this._uid}`;
		const name = props.name || id;

		return (
			<div className={cn("checkbox", styles.container, props.className, {
				[styles.disabled]: props.disabled
			})}>
				<input
					type="checkbox"
					className="styled"
					id={id}
					name={name}
					onChange={this._onChange}
					checked={props.value}
					disabled={!!props.disabled}
				/>
				<label
					htmlFor={id}
					className={cn({ [styles.emptyLabel]: props.label == null })}
				>
					{props.label && (
						<span>
							{props.label}
						</span>
					)}
				</label>
			</div>
		);
	}

	private _onChange(event: React.FormEvent<HTMLElement>) {
		const { checked } = event.currentTarget as HTMLInputElement;
		this.props.onChange(checked);
	}
}