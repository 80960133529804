/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IBaseResponse,
	ILinkedDocumentResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IAddStatementActionParams {
	acctId: number;
	fileName: string;
	fileBytes: string | Blob;
}

export class AddStatementAction extends ApiActionBase<IAddStatementActionParams, ILinkedDocumentResponse> {
	constructor(details: IDetails<IAddStatementActionParams, ILinkedDocumentResponse>) {
		super("Api/DocumentActions/AddStatementAction", details);
	}
}

export interface IDeleteAccountClaimDocumentActionParams {
	accountID: number;
	claimDocID: number;
	accountDocID: number;
}

export class DeleteAccountClaimDocumentAction extends ApiActionBase<IDeleteAccountClaimDocumentActionParams, IBaseResponse> {
	constructor(details: IDetails<IDeleteAccountClaimDocumentActionParams, IBaseResponse>) {
		super("Api/DocumentActions/DeleteAccountClaimDocumentAction", details);
	}
}

export interface IGetAllDocumentsByAcctIDActionParams {
	acctID: number;
}

export class GetAllDocumentsByAcctIDAction extends ApiActionBase<IGetAllDocumentsByAcctIDActionParams, ILinkedDocumentResponse> {
	constructor(details: IDetails<IGetAllDocumentsByAcctIDActionParams, ILinkedDocumentResponse>) {
		super("Api/DocumentActions/GetAllDocumentsByAcctIDAction", details);
	}
}

export interface IUploadAccountClaimDocumentActionParams {
	bytes: string | Blob;
	filename: string;
	claimID: number;
	claimDocTypeID: number;
	acctId: number;
	accountDocTypeID: number;
}

export class UploadAccountClaimDocumentAction extends ApiActionBase<IUploadAccountClaimDocumentActionParams, ILinkedDocumentResponse> {
	constructor(details: IDetails<IUploadAccountClaimDocumentActionParams, ILinkedDocumentResponse>) {
		super("Api/DocumentActions/UploadAccountClaimDocumentAction", details);
	}
}

/* tslint:enable:max-line-length */