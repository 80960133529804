import entitiesReducer, { IState as IEntitiesState } from "./Entities/entitiesReducer";
import uiReducer, { IState as IUIState } from "./ui/uiReducer";
import authReducer, { IState as IAuthState } from "./auth/authReducer";
import combineReducers from "../Utils/combineReducers";

export interface IState {
	auth: IAuthState;
	entities: IEntitiesState;
	ui: IUIState;
}

export default combineReducers<IState>({
	[nameof<IState>(it => it.auth)]: authReducer,
	[nameof<IState>(it => it.entities)]: entitiesReducer,
	[nameof<IState>(it => it.ui)]: uiReducer
});