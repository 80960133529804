import { makeAsync } from "../Models/IAsync";
import { createSelector } from "reselect";
import { getCurrentPermissionsAsync } from "./getCurrentPermissions";

export const isCurrentUserAppAdminAsync = createSelector(
	getCurrentPermissionsAsync,
	({ state, payload: permissions }) => makeAsync(
		state,
		permissions!.applicationAdministrator
	)
);

export default createSelector(
	isCurrentUserAppAdminAsync,
	({ payload }) => payload || false
);