import { MODIFIED_BY_ANOTHER_USER, OK } from "../../../../Models/apiConstants";
import ApiActionBase from "../../../../Actions/ApiActionBase";
import * as Dto from "../../../../Models/dto";
import { NULL_DATE_STRING } from "../../../../Utils/dateUtils";
import { normalize } from "../../../../Utils/clientProfilePartyUtils";
import { GetStartupDataAction } from "../../../../Actions/Api/startupActions";
import { SaveClientProfileAction, UseParentAffiliateModelAction } from "../../../../Actions/Api/clientActions";
import IAction from "../../../../Actions/IAction";
import { AsyncState } from "../../../../Models/IAsync";
import isEqual from "lodash/isEqual";

interface IClientProfileUserValues {
	clientProfileId: number;
	clientProfileProductId: number;
}

function getClientProfileUserValues(target: IClientProfileUserValues) {
	return {
		clientProfileId: target == null ? -1 : target.clientProfileId,
		clientProfileProductId: target == null ? -1 : target.clientProfileProductId
	} as IClientProfileUserValues;
}

function updateNewUser<TParams, TAction extends ApiActionBase<TParams, TResponse>, TResponse extends Dto.IBaseResponse>(
	newUser: Dto.IClientProfilePartyNormalized,
	action: TAction,
	getClientProfile: (action: TAction) => Dto.IClientProfile
) {
	if (
		action.state === AsyncState.Resolved
		&& (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)
	) {
		const oldValues = getClientProfileUserValues(newUser);
		const newValues = getClientProfileUserValues(getClientProfile(action));

		if (!isEqual(oldValues, newValues)) {
			return Object.assign({}, newUser, newValues);
		}
	}

	return newUser;
}

export function createNewUser(clientProfile: Dto.IClientProfile) {
	const newUser = Object.assign({
		appUserId: -1,
		clientIds: [],
		clientProfilePartyId: -1,
		clientProfilePartyTypeId: 4,
		clientProfileRoles: [],
		creationDate: NULL_DATE_STRING,
		email: "",
		emailOnMatchFlag: true,
		firstName: "",
		lastName: "",
		middleName: "",
		phone: "",
		roles: [],
		title: "",
		userAgreementAccepted: false,
		userAgreementAcceptedLegalDocId: -1,
		userAgreementPreviouslyAccepted: false,
		writeTimestamp: NULL_DATE_STRING,
		clientUserID1: "",
		clientUserID2: "",
		lastLoginDate: NULL_DATE_STRING
	}, getClientProfileUserValues(clientProfile)) as Dto.IClientProfileParty;

	return normalize(newUser);
}

/**
 * If the client profile changes, update the user with the appropriate client profile values.
 * Likely never going to happen, don't know why I wasted my time on this.
 */
export function makeNewUserReducer<TState>(
	getNewUser: (state: TState) => Dto.IClientProfilePartyNormalized | null,
	updateState: (state: TState, newUser: Dto.IClientProfilePartyNormalized) => TState
) {
	return function newUserReducer(previousState: TState, action: IAction): TState {
		const newUser = getNewUser(previousState);
		let updatedNewUser = newUser;

		if (newUser != null) {
			if (action instanceof GetStartupDataAction) {
				updatedNewUser = updateNewUser(newUser, action, it => it.response!.item.clientProfile);
			}

			if (action instanceof SaveClientProfileAction || action instanceof UseParentAffiliateModelAction) {
				updatedNewUser = updateNewUser(newUser, action, it => it.response!.clientProfile);
			}
		}

		return newUser === updatedNewUser
			? previousState
			: updateState(previousState, updatedNewUser!);
	};
}