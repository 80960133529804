import { MODIFIED_BY_ANOTHER_USER, OK } from "../../Models/apiConstants";
import assign from "../../Utils/assign";
import getLoadState from "../../Utils/getLoadState";
import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import withoutProperties from "../../Utils/withoutProperties";
import keyBy from "lodash/keyBy";
import {
	GetClientProfileRolesAction,
	SaveClientProfileRoleAction,
	DeleteClientProfileRoleAction
} from "../../Actions/Api/clientActions";
import makeUserUpdateReducer from "./makeUserUpdateReducer";
import reduceReducers from "reduce-reducers";

type Flags = { [id: number]: boolean };
type ById = { [id: number]: Dto.IClientProfileRole };

export interface IState {
	byId: { [id: number]: Dto.IClientProfileRole };
	loading: AsyncState | null;
	saving: Flags;
	deleting: Flags;
	inClientProfile: Flags;
}

function updateRoleMap(previousMap: ById, roles: Dto.IClientProfileRole[]) {
	const newMap = keyBy(roles, it => it.clientProfileRoleId);
	return Object.assign({}, previousMap, newMap);
}

function updateInClientProfile<TAction extends GetClientProfileRolesAction | SaveClientProfileRoleAction>(
	previousInClientProfile: Flags,
	action: TAction,
	getRoles: (action: TAction) => Dto.IClientProfileRole[]
) {
	if (getLoadState(action) === AsyncState.Resolved) {
		let inClientProfile: Flags | null = null;
		for (const role of getRoles(action)) {
			const { clientProfileRoleId } = role;
			if (!previousInClientProfile[clientProfileRoleId]) {
				if (inClientProfile == null) {
					inClientProfile = assign(previousInClientProfile, {});
				}

				inClientProfile[role.clientProfileRoleId] = true; // safe to mutate
			}
		}

		if (inClientProfile != null) {
			return inClientProfile;
		}
	}

	return previousInClientProfile;
}

const INITIAL_STATE: IState = {
	byId: {},
	loading: null,
	saving: {},
	deleting: {},
	inClientProfile: {}
};

function rolesReducer(
	previousState: IState = INITIAL_STATE,
	action: IAction
) {
	if (action instanceof GetClientProfileRolesAction) {
		let loading = action.state;
		if (action.state === AsyncState.Resolved && action.response!.status !== OK) {
			loading = AsyncState.Rejected;
		}

		let { byId } = previousState;
		if (loading === AsyncState.Resolved) {
			byId = updateRoleMap(byId, action.response!.items);
		}

		return assign<IState, Pick<IState, "loading" | "byId" | "inClientProfile">>(previousState, {
			loading,
			byId,
			inClientProfile: updateInClientProfile(
				previousState.inClientProfile,
				action,
				it => it.response!.items
			)
		});
	}

	if (action instanceof SaveClientProfileRoleAction) {
		const { clientProfileRoleId } = action.params.clientProfileRole;

		let { byId, saving } = previousState;
		if (action.state === AsyncState.Pending) {
			saving = Object.assign({}, saving, {
				[clientProfileRoleId]: true
			});
		} else {
			saving = withoutProperties(saving, clientProfileRoleId);
			if (action.state === AsyncState.Resolved) {
				switch (action.response!.status) {
					case OK:
					case MODIFIED_BY_ANOTHER_USER:
						byId = updateRoleMap(byId, [action.response!.item]);
						break;
					default:
						break;
				}
			}
		}

		return assign(previousState, {
			byId,
			saving,
			inClientProfile: updateInClientProfile(
				previousState.inClientProfile,
				action,
				it => [it.response!.item]
			)
		});
	}

	if (action instanceof DeleteClientProfileRoleAction) {
		const { clientProfileRoleId } = action.params.clientProfileRole;

		let { byId, deleting } = previousState;
		if (action.state === AsyncState.Pending) {
			deleting = Object.assign({}, deleting, {
				[clientProfileRoleId]: true
			});
		} else {
			deleting = withoutProperties(deleting, clientProfileRoleId);
			if (action.state === AsyncState.Resolved) {
				switch (action.response!.status) {
					case OK:
						byId = withoutProperties(byId, clientProfileRoleId);
						break;
					case MODIFIED_BY_ANOTHER_USER:
						byId = updateRoleMap(byId, [action.response!.item]);
						break;
					default:
						break;
				}
			}
		}

		return Object.assign({}, previousState, {
			byId,
			deleting
		});
	}

	return previousState;
}

const userUpdateReducer = makeUserUpdateReducer<IState>(
	(
		previousState: IState,
		_updatedUsers: { [clientProfilePartyId: number]: Dto.IClientProfilePartyNormalized },
		_deletedUserId: number | null,
		rawUsers: Dto.IClientProfileParty[]
	) => {
		// since users come with roles attached, pluck out any roles on any loaded users and use them to update the store
		let { byId } = previousState;
		for (const user of rawUsers) {
			byId = updateRoleMap(byId, user.clientProfileRoles);
		}

		return Object.assign({}, previousState, {
			byId
		});
	}
);

export default reduceReducers(rolesReducer, userUpdateReducer);