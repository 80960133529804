import { IState } from "../Reducers/rootReducer";
import getAvailableTabs from "./getAvailableTabs";
import { createSelector } from "reselect";

export default createSelector(
	(state: IState) => state.ui.dashboard.tabs.active,
	getAvailableTabs,
	(active, available) => {
		if (active == null || available.indexOf(active) === -1) {
			return available[0];
		}

		return active;
	}
);