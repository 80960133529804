import AccountStatuses from "../Models/AccountStatuses";
import { isEmpty as isEmptyDate, NULL_DATE_STRING } from "./dateUtils";
import * as Dto from "../Models/dto";
import AccountsContext from "./AccountsContext";
import { IState } from "../Reducers/rootReducer";

// if accounts are in one of these statuses and have a dodFound the workflow is 
// DOD Matched (35) and the status should be displayed as "DOD Found"
const SPECIAL_DOD_FOUND_STATUSES: { [statusId: number]: true | undefined } = process.env.REACT_APP_APPLICATION === "pfod" ? {
	[AccountStatuses.OpenActiveAccount]: true,
	[AccountStatuses.EstateLocated]: true,
	[AccountStatuses.EstateMatchVerified]: true,
	[AccountStatuses.EstateMatchRejected]: true,
	[AccountStatuses.EstateIncomplete]: true,
	[AccountStatuses.LowBalanceHold]: true,
	[AccountStatuses.EstateFoundInvalidLicensure]: true,
	[AccountStatuses.ClaimVoided]: true
} : {};

export function getStatusDisplayName(account: Dto.IAccount | Dto.IAccountImport, state: IState) {
	if (account == null) {
		return "";
	}

	let statusId = AccountsContext.getStatusId(account);
	if (
		AccountsContext.isAccount(account)
		&& SPECIAL_DOD_FOUND_STATUSES[statusId]
		&& !isEmptyDate(account.dodFound)
	) {
		statusId = AccountStatuses.DodLocated;
	}

	const { accountStatuses, accountImportStatuses } = state.entities.startup;
	const status: Dto.IAccountStatus | Dto.IAccountImportStatus = accountStatuses.byId[statusId] || accountImportStatuses.byId[statusId];
	return status == null ? "" : status.displayName;
}

export function getWorkflowStepForAccount(account: Dto.IAccount | Dto.IAccountImport | null, state: IState) {
	if (account == null) {
		return null;
	}

	if (process.env.REACT_APP_APPLICATION === "access" && AccountsContext.isAccount(account)) {
		return state.entities.startup.workflowSteps.find(it => it.accountWorkflowStepId === account.workflowStepId) || null;
	}

	const statusId = AccountsContext.getStatusId(account);
	const dodFound = AccountsContext.isAccount(account) ? account.dodFound : NULL_DATE_STRING;

	return getWorkflowStepForStatus(statusId, dodFound, state);
}

function getWorkflowStepForStatus(statusId: number, dodFound: string | null, state: IState) {
	const { accountStatuses, accountImportStatuses, workflowSteps } = state.entities.startup;

	if (process.env.REACT_APP_APPLICATION === "pfod" && SPECIAL_DOD_FOUND_STATUSES[statusId] && !isEmptyDate(dodFound)) {
		return workflowSteps.find(it => it.accountWorkflowStepId === 35)!; // DOD Matched
	}

	const status = accountStatuses.byId[statusId] || accountImportStatuses.byId[statusId];
	return status == null ? null : workflowSteps.find(it => it.accountWorkflowStepId === status.accountWorkflowStepId)!;
}

export function getStatusId(status: Dto.IAccountImportStatus | Dto.IAccountStatus) {
	return nameof<Dto.IAccountImportStatus>(it => it.acctImportStatusId) in status
		? (status as Dto.IAccountImportStatus).acctImportStatusId
		: (status as Dto.IAccountStatus).acctStatusId;
}