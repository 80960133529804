/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IAccountWorkflowStatusCount,
	ICollectionResponse
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IGetAccountWorkflowStatusCountsActionParams {
	clientIds: number[];
}

export class GetAccountWorkflowStatusCountsAction extends ApiActionBase<IGetAccountWorkflowStatusCountsActionParams, ICollectionResponse<IAccountWorkflowStatusCount>> {
	constructor(details: IDetails<IGetAccountWorkflowStatusCountsActionParams, ICollectionResponse<IAccountWorkflowStatusCount>>) {
		super("Api/AccountWorkflowActions/GetAccountWorkflowStatusCountsAction", details);
	}
}

/* tslint:enable:max-line-length */