import * as React from "react";
import assign from "./assign";
import { ToastType } from "../Components/Common/ToastEmitter/IToast";
import { add, remove } from "../Components/Common/ToastEmitter/store";
import { genericError as genericErrorText } from "../Global/language";
import uid from "./uid";

export interface IToastOptions {
	timeout?: number | null;
}

export interface IToastDetail {
	clear(): void;
}

const DEFAULT_OPTIONS: Required<IToastOptions> = {
	timeout: 5000
};

function makeToastUtil(type: ToastType) {
	return (message: React.ReactNode, options?: IToastOptions): IToastDetail => {
		const id = uid();
		const { timeout } = assign(DEFAULT_OPTIONS, options || {});

		add({
			id,
			type,
			message,
			timeout
		});

		return {
			clear: () => remove(id)
		};
	};
}

export const info = makeToastUtil("info");
export const success = makeToastUtil("success");
export const warning = makeToastUtil("warning");
export const error = makeToastUtil("error");

export const genericError = () => error(genericErrorText);

if (__DEV__) {
	const util: any = (message: string, options?: IToastOptions) => info(message, options);

	util.info = info;
	util.success = success;
	util.warning = warning;
	util.error = error;
	util.genericError = genericError;

	(window as any)["toast"] = util;
}
