import IAction from "../IAction";
import ActionLogEntry from "../../Log/ActionLogEntry";

export default class ShowSearchAction implements IAction {
	showSearch: boolean;

	constructor(data: boolean) {
		this.showSearch = data;
	}

	toLogEntry(): ActionLogEntry {
		return new ActionLogEntry(
			"ShowSearchAction", { "showSearch": JSON.stringify(this.showSearch) });
	}
}