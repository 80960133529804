import combineReducers from "../../Utils/combineReducers";
import affiliateReducer, { IState as IAffiliateState } from "./affiliateReducer";
import applicationRolesReducer, { IState as IApplicationRolesState } from "./applicationRolesReducer";
import bedFundPolicyReducer, { IState as IBedFundPolicyState } from "./bedFundPolicyReducer";
import clientProfileReducer, { IState as IClientProfileState } from "./clientProfileReducer";
import clientProfilesReducer, { IState as IClientProfilesState } from "./clientProfilesReducer";
import ftpReducer, { IState as IFtpState } from "./ftpReducer";
import invoicesReducer, { IState as IInvoicesState } from "./invoicesReducer";
import lowBalanceAccountReducer, { IState as ILowBalanceAccountState } from "./lowBalanceAccountReducer";
import notificationsReducer, { IState as INotificationsState } from "./notificationsReducer";
import pricingReducer, { IState as IPricingState } from "./pricingReducer";
import pricingHistoryReducer, { IState as IPricingHistoryState } from "./pricingHistoryReducer";
import rolesReducer, { IState as IRolesState } from "./rolesReducer";
import startupReducer, { IState as IStartupState } from "./startupReducer";
import usersReducer, { IState as IUsersState } from "./usersReducer";
import legalDocumentReducer, { IState as ITermsState } from "./legalDocumentReducer";
import reportsReducer, { IState as IReportsState } from "./reportsReducer";
import userSearchReducer, { IState as IUserSearchState } from "./userSearchReducer";

export interface IState {
	affiliates: IAffiliateState;
	applicationRoles: IApplicationRolesState;
	bedFundPolicies: IBedFundPolicyState;
	clientProfile: IClientProfileState;
	clientProfiles: IClientProfilesState;
	ftp: IFtpState;
	invoices: IInvoicesState;
	lowBalanceAccounts: ILowBalanceAccountState;
	notifications: INotificationsState;
	pricing: IPricingState;
	pricingHistory: IPricingHistoryState;
	reports: IReportsState;
	roles: IRolesState;
	startup: IStartupState;
	users: IUsersState;
	userSearches: IUserSearchState;
	terms: ITermsState;
}

const entitiesReducer = combineReducers<IState>({
	[nameof<IState>(it => it.affiliates)]: affiliateReducer,
	[nameof<IState>(it => it.applicationRoles)]: applicationRolesReducer,
	[nameof<IState>(it => it.bedFundPolicies)]: bedFundPolicyReducer,
	[nameof<IState>(it => it.clientProfile)]: clientProfileReducer,
	[nameof<IState>(it => it.clientProfiles)]: clientProfilesReducer,
	[nameof<IState>(it => it.ftp)]: ftpReducer,
	[nameof<IState>(it => it.invoices)]: invoicesReducer,
	[nameof<IState>(it => it.lowBalanceAccounts)]: lowBalanceAccountReducer,
	[nameof<IState>(it => it.notifications)]: notificationsReducer,
	[nameof<IState>(it => it.pricing)]: pricingReducer,
	[nameof<IState>(it => it.pricingHistory)]: pricingHistoryReducer,
	[nameof<IState>(it => it.reports)]: reportsReducer,
	[nameof<IState>(it => it.roles)]: rolesReducer,
	[nameof<IState>(it => it.startup)]: startupReducer,
	[nameof<IState>(it => it.users)]: usersReducer,
	[nameof<IState>(it => it.userSearches)]: userSearchReducer,
	[nameof<IState>(it => it.terms)]: legalDocumentReducer
});

export default entitiesReducer;