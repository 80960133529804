import { MODIFIED_BY_ANOTHER_USER, OK } from "../../Models/apiConstants";
import assign from "../../Utils/assign";
import getLoadState from "../../Utils/getLoadState";
import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import { GetStartupDataAction } from "../../Actions/Api/startupActions";
import {
	DeleteAffiliateAction,
	SaveClientProfileAction,
	SaveNewAffiliateAction,
	UseParentAffiliateModelAction
} from "../../Actions/Api/clientActions";

export interface IState {
	clientProfile: Dto.IClientProfile | null;
	loading: AsyncState | null;
	saving: boolean | null;
}

export default function clientProfileReducer(
	previousState: IState = {
		clientProfile: null,
		loading: null,
		saving: null
	},
	action: IAction
) {
	if (action instanceof GetStartupDataAction) {
		let loading = action.state;
		if (action.state === AsyncState.Resolved && action.response!.status !== OK) {
			loading = AsyncState.Rejected;
		}

		let { clientProfile } = previousState;
		if (loading === AsyncState.Resolved) {
			clientProfile = action.response!.item.clientProfile;
		}

		return Object.assign({}, previousState, {
			loading,
			clientProfile
		});
	}

	if (action instanceof SaveClientProfileAction || action instanceof UseParentAffiliateModelAction) {
		const saving = action.state === AsyncState.Pending;

		let { clientProfile } = previousState;
		if (action.state === AsyncState.Resolved) {
			switch (action.response!.status) {
				case OK:
				case MODIFIED_BY_ANOTHER_USER:
					clientProfile = action.response!.clientProfile;
					break;
				default:
					break;
			}
		}

		return Object.assign({}, previousState, {
			clientProfile,
			saving
		});
	}

	if (action instanceof SaveNewAffiliateAction && getLoadState(action) === AsyncState.Resolved) {
		return assign<IState, Pick<IState, "clientProfile">>(previousState, {
			clientProfile: assign(previousState.clientProfile, {
				clientIds: [...previousState.clientProfile!.clientIds, action.response!.affiliate.clientId]
			})
		});
	}

	if (action instanceof DeleteAffiliateAction && getLoadState(action) === AsyncState.Resolved) {
		return assign<IState, Pick<IState, "clientProfile">>(previousState, {
			clientProfile: assign(previousState.clientProfile, {
				clientIds: previousState.clientProfile!.clientIds.filter(it => it !== action.params.clientID)
			})
		});
	}

	return previousState;
}