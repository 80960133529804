import combineReducers from "../../Utils/combineReducers";
import dashboardReducer, { IState as IDashboardState } from "./dashboard/dashboardReducer";
import captchaReducer, { IState as ICaptchaState } from "./captchaReducer";
import registrationReducer, { IState as IRegistrationState } from "./registrationReducer";
import IAction from "../../Actions/IAction";
import ContactSupportAction from "../../Actions/ContactSupportAction";

export interface IState {
	contactSupport: boolean;
	dashboard: IDashboardState;
	captcha: ICaptchaState;
	registration: IRegistrationState;
}

function contactSupportReducer(previousState: boolean = false, action: IAction): boolean {
	if (action instanceof ContactSupportAction) {
		return action.show;
	}

	return previousState;
}

export default combineReducers<IState>({
	[nameof<IState>(it => it.captcha)]: captchaReducer,
	[nameof<IState>(it => it.contactSupport)]: contactSupportReducer,	
	[nameof<IState>(it => it.dashboard)]: dashboardReducer,
	[nameof<IState>(it => it.registration)]: registrationReducer
});