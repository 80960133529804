import { isSameReport } from "../../Utils/reportUtils";
import getLoadState from "../../Utils/getLoadState";
import assign from "../../Utils/assign";
import { AsyncState } from "../../Models/IAsync";
import { DeleteReportAction, SaveReportAction } from "../../Actions/Api/pfodReportingActions";
import { GetAvailableReportsV4Action } from "../../Actions/Api/pfodReportingActions";
import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";

export interface IState {
	reports: Dto.IReport[];
	loadState: AsyncState | null;
	saving: boolean;
	deleting: boolean;
}

export default function reportsReducer(
	previousState: IState = {
		reports: [],
		loadState: null,
		saving: false,
		deleting: false
	},
	action: IAction
): IState {
	let { reports } = previousState;

	if (action instanceof GetAvailableReportsV4Action) {
		const loadState = getLoadState(action);
		if (loadState === AsyncState.Resolved) {
			reports = action.response!.items;
		}

		return assign<IState, Pick<IState, "loadState" | "reports">>(previousState, {
			loadState,
			reports
		});
	}

	if (action instanceof SaveReportAction) {
		const loadState = getLoadState(action);

		const saving = loadState === AsyncState.Pending;
		let { reports } = previousState;

		if (loadState === AsyncState.Resolved) {
			const savedReport = action.response!.item;

			let isNew = true;
			reports = reports.map(report => {
				if (isSameReport(report, savedReport)) {
					isNew = false;
					return savedReport;
				}

				return report;
			});

			if (isNew) {
				// safe to mutate here
				reports.push(savedReport);
			}
		}

		return assign(previousState, {
			reports,
			saving
		});
	}

	if (action instanceof DeleteReportAction && getLoadState(action) === AsyncState.Resolved) {
		const loadState = getLoadState(action);

		const deleting = loadState === AsyncState.Pending;
		let { reports } = previousState;

		if (loadState === AsyncState.Resolved) {
			reports = reports.filter(it => it.userReportId !== action.params.reportID);
		}

		return assign(previousState, {
			reports,
			deleting
		});
	}

	return previousState;
}