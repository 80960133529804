import AsyncState from "../../../../../../Models/AsyncState";
import { GetContactsAction, SaveContactAction, GetContactHistoryAction } from "../../../../../../Actions/Api/accountActions";
import IAction from "../../../../../../Actions/IAction";
import * as Dto from "../../../../../../Models/dto";
import { OK, MODIFIED_BY_ANOTHER_USER } from "../../../../../../Models/apiConstants";
import { EditChangeAction } from "./actions";

export interface IState {
	requestId: string | null;
	acctId: number | null;
	loadState: AsyncState | null;
	contacts: Dto.IContact[];
	edit: Dto.IContact | null;
	editing: boolean;
	historyRequestId: string | null;
	historyLoadState: AsyncState | null;
	history: Pick<Dto.IContactHistResponse, "contacts" | "phones"> | null;
}

const INITIAL_STATE: IState = {
	requestId: null,
	acctId: null,
	loadState: null,
	contacts: [],
	edit: null,
	editing: false,
	historyRequestId: null,
	historyLoadState: null,
	history: null
};

export default function contactsReducer(
	previousState = INITIAL_STATE,
	action: IAction
): IState {
	if (action instanceof GetContactsAction) {
		const { requestId } = action;
		let { state } = action;

		if (state !== AsyncState.Pending && previousState.requestId !== requestId) {
			return previousState;
		}

		if (state === AsyncState.Resolved && action.response!.status !== OK) {
			state = AsyncState.Rejected;
		}

		let { historyLoadState, history } = previousState;
		if (previousState.acctId !== action.params.acctId) {
			historyLoadState = null;
			history = null;
		}

		return {
			...previousState,
			requestId,
			acctId: action.params.acctId,
			loadState: state,
			contacts: state === AsyncState.Resolved
				? action.response!.items
				: INITIAL_STATE.contacts,
			historyLoadState,
			history
		};
	}

	if (
		action instanceof SaveContactAction
		&& action.state === AsyncState.Resolved
		&& (action.response!.status === OK || action.response!.status === MODIFIED_BY_ANOTHER_USER)
		&& (previousState.acctId == null || action.params.acctId === previousState.acctId)
	) {
		return {
			...previousState,
			acctId: action.params.acctId,
			loadState: AsyncState.Resolved,
			contacts: action.response!.items,
			historyLoadState: null,
			history: null
		};
	}

	if (action instanceof EditChangeAction) {
		return {
			...previousState,
			editing: action.editing,
			edit: action.edit
		};
	}

	if (action instanceof GetContactHistoryAction) {
		const { requestId } = action;
		let { state } = action;

		if (
			(state !== AsyncState.Pending && previousState.historyRequestId !== requestId)
			|| action.params.acctId !== previousState.acctId
		) {
			return previousState;
		}

		if (state === AsyncState.Resolved && action.response!.status !== OK) {
			state = AsyncState.Rejected;
		}

		return {
			...previousState,
			historyRequestId: requestId,
			historyLoadState: state,
			history: state === AsyncState.Resolved ? {
				contacts: action.response!.contacts,
				phones: action.response!.phones
			} : null
		};
	}

	return previousState;
}