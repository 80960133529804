import { ClearAction } from "../Components/Dashboard/Accounts/LeftPanel/AdvancedSearch/actions";
import isEmpty from "../Utils/isEmpty";
import uniqueRandom from "../Utils/uniqueRandom";
import BaseStore from "./BaseStore";
import IAction from "./../Actions/IAction";
import Dispatcher from "../Dispatcher/Dispatcher";
import ReduxStore from "../Stores/ReduxStore";
import AccountsContext from "../Utils/AccountsContext";
import ColumnSelectedAction from "../Actions/Search/ColumnSelectedAction";
import SimpleSearchUpdatedAction from "../Actions/Search/SimpleSearchUpdatedAction";
import SearchResultsAction from "../Actions/Search/SearchResultsAction";
import AccountSelectedAction from "../Actions/Search/AccountSelectedAction";
import PageDataChangedAction from "../Actions/Search/PageDataChangedAction";
import SearchAccountsAction from "../Actions/Search/SearchAccountsAction";
import SortChangedAction from "../Actions/Search/SortChangedAction";
import ExportFieldSelectedAction from "../Actions/Search/ExportFieldSelectedAction";
import * as Dto from "../Models/dto";
import * as AccountsData from "../Models/AccountsData";
import forEach from "lodash/forEach";
import * as storage from "../Utils/storage";

function getPersistedPageSize() {
	if (Dispatcher.isDispatching()) {
		Dispatcher.waitFor([ReduxStore.token]);
	}

	return ReduxStore.getState().ui.dashboard.pageSize;
}

class SearchAccountsStore extends BaseStore {

	token: string;
	defaultSearchName: string = uniqueRandom();

	private _currentSearch: Dto.IClientProfileUserSearch | undefined;
	private simpleSearch: Dto.IClientProfileUserSearch | null | undefined;
	private searchResults: Dto.IAccountsResponse | null | undefined;
	private storeName: string = "SearchResultsColumns";
	private selectedAccounts: Array<Dto.IAccount> | null = [];
	private selectedExportFields: Array<string> = [];
	private currentPageIndex: number = 1;
	private sortInfo: Array<AccountsData.ISortInfo> = [AccountsContext.defaultSortInfo];

	constructor() {
		super();
		this.token = Dispatcher.register((action: IAction) => this.processActions(action));
	}

	getCurrentSearch() {
		return this._currentSearch;
	}

	getSelectedExportFields(): Array<string> {
		return this.selectedExportFields;
	}

	getSimpleSearch(): Dto.IClientProfileUserSearch {
		if (!this.simpleSearch) {
			this.simpleSearch = {
				clientProfileUserSearchId: -1, clientProfilePartyId: -1, name: this.defaultSearchName, accountStatusIds: [], affiliateIds: [], minimumAccountBalance: -1,
				maximumAccountBalance: -1, searchDates: this.getInitialSearchDates(), shared: false, firstName: "", lastName: "", ssn: "", primaryAccountNumber: "",
				secondaryAccountNumber: "", acctId: "", claimId: "", caseNumber: "", totalAccountsFound: 0, accountWorkflowId: -1, writeTimestamp: ""
			};
		}
		return this.simpleSearch;
	}

	isSimpleSearchReady(): boolean {
		return !!this.simpleSearch && (
			!isEmpty(this.simpleSearch.primaryAccountNumber) ||
			!isEmpty(this.simpleSearch.secondaryAccountNumber) ||
			!isEmpty(this.simpleSearch.firstName) ||
			!isEmpty(this.simpleSearch.lastName) ||
			!isEmpty(this.simpleSearch.ssn) ||
			!isEmpty(this.simpleSearch.acctId) ||
			!isEmpty(this.simpleSearch.claimId) ||
			!isEmpty(this.simpleSearch.caseNumber)
		);
	}

	getAccountColumns(): Array<AccountsData.IAccountColumn> {
		var colNames: Set<string> = new Set<string>();
		var allColumns: Array<AccountsData.IAccountColumn> = [];
		AccountsContext.GetAccountColumns().forEach(col => {
			if (!colNames.has(col.displayText)) {
				allColumns.push(col);
				colNames.add(col.displayText);
			}
		});

		var selectedColumns = storage.get<number[]>(this.storeName);
		var displayColumns: Array<number> = AccountsContext.getDefaultSearchDisplayColumns();
		forEach(allColumns, (c: AccountsData.IAccountColumn) => {
			if (selectedColumns) {
				c.isSelected = (selectedColumns.indexOf(c.id) > -1);
			} else { c.isSelected = (displayColumns.indexOf(c.id) > -1); }
		});

		return allColumns;
	}

	// Sets the column in local store
	setColumnsInStorage(selectedIds: number[]): void {
		var storeName: string = this.storeName;
		storage.set(storeName, selectedIds);
	}

	getSearchResults(): Dto.IAccountsResponse {
		if (!this.searchResults) { this.searchResults = { accountImports: [], accounts: [], numberOfAccounts: 0, status: "", statusMessage: "", totalNumberOfAccounts: 0 }; }

		return this.searchResults;
	}

	getSelectedAccounts(): Array<Dto.IAccount | Dto.IAccountImport> {
		return this.selectedAccounts || [];
	}

	getPageData(pSize: number): AccountsData.IPageData {
		var numberOfAccounts: number = this.getSearchResults().totalNumberOfAccounts;

		return {
			pageSize: pSize, pageIndex: this.currentPageIndex, startIndex: (pSize * (this.currentPageIndex - 1)) + (numberOfAccounts > 0 ? 1 : 0),
			endIndex: numberOfAccounts > 0 && pSize * this.currentPageIndex >= numberOfAccounts ? numberOfAccounts : pSize * this.currentPageIndex,
			totalCount: numberOfAccounts, pageCount: Math.ceil(numberOfAccounts / pSize)
		};
	}

	getSortInfo(): Array<AccountsData.ISortInfo> {
		return this.sortInfo;
	}
	private getInitialSearchDates(): Array<Dto.IClientProfileSearchDate> {
		return [
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "AccountPlaced" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "AccountClosed" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "DOB" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "DOD" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "ClaimFiled" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "EstateExpirationDate" },
			{ beginDate: "", endDate: "", useRelativeDates: false, searchDateType: "DodFoundTimestamp" }
		];
	}

	private processActions(action: IAction): void {
		if (action instanceof SimpleSearchUpdatedAction) {
			this.simpleSearch = action.search;
			if (!this.simpleSearch) { // Clear
				this.searchResults = null;
				this.currentPageIndex = 1;
				this.selectedAccounts = null;
			}
			this.emitChange();
		}

		if (action instanceof SearchResultsAction) {
			this.searchResults = action.searchResults;
			this.emitChange();
		}

		if (action instanceof ColumnSelectedAction) {
			this.setColumnsInStorage(action.selectedIds);
			this.emitChange();
		}

		if (action instanceof AccountSelectedAction) {
			this.selectedAccounts = [...action.selectedAccounts];
			this.emitChange();
		}

		if (action instanceof SearchAccountsAction) {
			this._currentSearch = action.data;
			this.currentPageIndex = 1;
			this.DoSearchAccounts();
			this.emitChange();
		}

		if (action instanceof PageDataChangedAction) {
			this.currentPageIndex = action.data.pageIndex;
			this.DoSearchAccounts();
			this.emitChange();
		}

		if (action instanceof SortChangedAction) {
			if (action.sortInfo.length === 0) {
				this.sortInfo = [AccountsContext.defaultSortInfo];
			} else if (action.sortInfo.length > 1) {
				this.sortInfo = [action.sortInfo[1]];
			} else { this.sortInfo = action.sortInfo; }

			this.currentPageIndex = 1;
			this.DoSearchAccounts();
			this.emitChange();
		}

		if (action instanceof ExportFieldSelectedAction) {
			this.selectedExportFields = action.data;
			this.emitChange();
		}

		if (action instanceof ClearAction) {
			this.searchResults = null;
			this.selectedAccounts = null;
			this.currentPageIndex = 1;
			this.emitChange();
		}
	}

	private DoSearchAccounts(): void {
		var pageData: AccountsData.IPageData = this.getPageData(getPersistedPageSize());

		var searchPayload: AccountsData.IAdvancedSearchAccounts = {
			startIndex: pageData.startIndex, endIndex: pageData.pageIndex * pageData.pageSize, sortField: this.sortInfo[0].name,
			sortDirection: this.sortInfo[0].dir > 0 ? "ASC" : "DESC", userSearch: this._currentSearch!
		};

		AccountsContext.SearchAccounts(searchPayload);
	}
}

export default new SearchAccountsStore();