import React from "react"
import styles from "./AuthHeader.module.scss";

interface IProps {
	children: React.ReactNode;
}

export default function AuthHeader({ children }: IProps) {
	return (
		<h1 className={styles.header}>
			{children}
		</h1>
	);
}