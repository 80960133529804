import { OK } from "../../Models/apiConstants";
import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import keyBy from "lodash/keyBy";
import { GetApplicationRolesAction } from "../../Actions/Api/clientActions";
import reduceReducers from "reduce-reducers";
import makeRoleUpdateReducer from "./makeRoleUpdateReducer";

type ById = { [id: number]: Dto.IApplicationRole };

export interface IState {
	byId: ById;
	loading: AsyncState | null;
}

function updateById(previousById: ById, permissions: Dto.IApplicationRole[]) {
	const newById = keyBy(permissions, it => it.onDemandRoleId);
	return Object.assign({}, previousById, newById);
}

function applicationRolesReducer(
	previousState: IState = {
		byId: {},
		loading: null
	},
	action: IAction
) {
	if (action instanceof GetApplicationRolesAction) {
		let loading = action.state;
		if (action.state === AsyncState.Resolved && action.response!.status !== OK) {
			loading = AsyncState.Rejected;
		}

		let { byId } = previousState;
		if (loading === AsyncState.Resolved) {
			byId = updateById(byId, action.response!.items);
		}

		return Object.assign({}, previousState, {
			loading,
			byId
		});
	}

	return previousState;
}

const roleUpdateReducer = makeRoleUpdateReducer<IState>((previousState, updatedRoles) => {
	const keys = Object.keys(updatedRoles);
	if (keys.length > 0) {
		const byId = Object.assign({}, previousState.byId);
		for (const key of keys) {
			const role = updatedRoles[+key];
			for (const permission of role.applicationRoles) {
				byId[permission.onDemandRoleId] = permission;
			}
		}

		return Object.assign({}, previousState, {
			byId
		});
	}

	return previousState;
});

export default reduceReducers(applicationRolesReducer, roleUpdateReducer);