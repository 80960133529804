/*
 * This file was automatically generated. Do not make any changes
 * directly as they may be overwritten.
 *
 * To update this file, run `yarn run generate-api`.
 */

import ApiActionBase, { IDetails } from "../ApiActionBase";
import {
	IBaseResponse,
	ICollectionResponse,
	IHomeReport,
	IKpi,
	IReportData,
	IResource,
	IResponse,
	IReviewItem
} from "../../Models/dto";

/* tslint:disable:max-line-length */

export interface IDismissReviewItemActionParams {
	reviewItem: IReviewItem;
}

export class DismissReviewItemAction extends ApiActionBase<IDismissReviewItemActionParams, IBaseResponse> {
	constructor(details: IDetails<IDismissReviewItemActionParams, IBaseResponse>) {
		super("Api/HomeActions/DismissReviewItemAction", details);
	}
}

export interface IGetDynamicKpisActionParams {
	startDate: string;
	endDate: string;
	clientIds: number[] | null;
}

export class GetDynamicKpisAction extends ApiActionBase<IGetDynamicKpisActionParams, ICollectionResponse<IKpi>> {
	constructor(details: IDetails<IGetDynamicKpisActionParams, ICollectionResponse<IKpi>>) {
		super("Api/HomeActions/GetDynamicKpisAction", details);
	}
}

export interface IGetHomeReportDataActionParams {
	id: number;
	clientIds: number[] | null;
}

export class GetHomeReportDataAction extends ApiActionBase<IGetHomeReportDataActionParams, IResponse<IReportData>> {
	constructor(details: IDetails<IGetHomeReportDataActionParams, IResponse<IReportData>>) {
		super("Api/HomeActions/GetHomeReportDataAction", details);
	}
}

export interface IGetHomeReportsActionParams {
	/* no parameters */
}

export class GetHomeReportsAction extends ApiActionBase<IGetHomeReportsActionParams, ICollectionResponse<IHomeReport>> {
	constructor(details: IDetails<IGetHomeReportsActionParams, ICollectionResponse<IHomeReport>>) {
		super("Api/HomeActions/GetHomeReportsAction", details);
	}
}

export interface IGetLatestArticlesActionParams {
	/* no parameters */
}

export class GetLatestArticlesAction extends ApiActionBase<IGetLatestArticlesActionParams, ICollectionResponse<IResource>> {
	constructor(details: IDetails<IGetLatestArticlesActionParams, ICollectionResponse<IResource>>) {
		super("Api/HomeActions/GetLatestArticlesAction", details);
	}
}

export interface IGetResourcesActionParams {
	resourceTypeId: number;
}

export class GetResourcesAction extends ApiActionBase<IGetResourcesActionParams, ICollectionResponse<IResource>> {
	constructor(details: IDetails<IGetResourcesActionParams, ICollectionResponse<IResource>>) {
		super("Api/HomeActions/GetResourcesAction", details);
	}
}

export interface IGetReviewItemsActionParams {
	appName: string;
}

export class GetReviewItemsAction extends ApiActionBase<IGetReviewItemsActionParams, ICollectionResponse<IReviewItem>> {
	constructor(details: IDetails<IGetReviewItemsActionParams, ICollectionResponse<IReviewItem>>) {
		super("Api/HomeActions/GetReviewItemsAction", details);
	}
}

export interface IGetStaticKpisActionParams {
	/* no parameters */
}

export class GetStaticKpisAction extends ApiActionBase<IGetStaticKpisActionParams, ICollectionResponse<IKpi>> {
	constructor(details: IDetails<IGetStaticKpisActionParams, ICollectionResponse<IKpi>>) {
		super("Api/HomeActions/GetStaticKpisAction", details);
	}
}

/* tslint:enable:max-line-length */