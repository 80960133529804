import AsyncState from "../../../../Models/AsyncState";
import getLoadState from "../../../../Utils/getLoadState";
import assign from "../../../../Utils/assign";
import * as actions from "../../../../Actions/Settings/UsersActions";
import IAction from "../../../../Actions/IAction";
import * as Dto from "../../../../Models/dto";
import {
	DeleteClientProfilePartyAction,
	SaveClientProfilePartyAction
} from "../../../../Actions/Api/clientActions";
import withoutProperties from "../../../../Utils/withoutProperties";
import makeUserUpdateReducer from "../../../Entities/makeUserUpdateReducer";
import reduceReducers from "reduce-reducers";
import { createNewUser, makeNewUserReducer } from "./newUserUtils";

type EditMap = { [partyId: number]: Dto.IClientProfilePartyNormalized | undefined };

export interface IState {
	selectedPartyId: number | null;
	edits: EditMap;
	newUser: Dto.IClientProfilePartyNormalized | null;
}

function usersReducer(
	previousState: IState = {
		selectedPartyId: null,
		edits: {},
		newUser: null
	},
	action: IAction
): IState {
	if (action instanceof actions.SelectUserAction) {
		return assign<IState, Pick<IState, "selectedPartyId">>(previousState, {
			selectedPartyId: action.clientProfilePartyId
		});
	}

	if (action instanceof actions.UpdateUserAction) {
		const { edit } = action;
		if (edit.clientProfilePartyId > 0) {
			return Object.assign({}, previousState, {
				edits: Object.assign({}, previousState.edits, {
					[edit.clientProfilePartyId]: edit
				})
			});
		} else {
			if (__DEV__ && previousState.newUser == null) {
				console.error(`[${__filename}] New user update received but ${nameof(previousState.newUser)} not defined`);
			}

			return Object.assign({}, previousState, {
				newUser: edit
			});
		}
	}

	if (action instanceof SaveClientProfilePartyAction && getLoadState(action) === AsyncState.Resolved) {
		return assign<IState, Pick<IState, "newUser" | "selectedPartyId">>(previousState, {
			newUser: null,
			selectedPartyId: action.response!.item.clientProfilePartyId
		});
	}

	if (
		action instanceof DeleteClientProfilePartyAction
		&& getLoadState(action) === AsyncState.Resolved
		&& previousState.selectedPartyId === action.params.clientProfileParty.clientProfilePartyId
	) {
		return assign<IState, Pick<IState, "selectedPartyId">>(previousState, {
			selectedPartyId: null
		});
	}

	if (action instanceof actions.AddUserAction) {
		if (action.addingUser === (previousState.newUser == null)) {
			return Object.assign({}, previousState, {
				newUser: action.addingUser ? createNewUser(action.clientProfile) : null
			});
		}
	}

	return previousState;
}

const userUpdateReducer = makeUserUpdateReducer((previousState: IState, updatedUsers, deletedUserId): IState => {
	let edits = Object.assign({}, previousState.edits, updatedUsers);
	if (deletedUserId != null) {
		edits = withoutProperties(edits, deletedUserId);
	}

	return Object.assign({}, previousState, {
		edits
	});
});

const newUserReducer = makeNewUserReducer<IState>(
	state => state.newUser,
	(state, newUser) => Object.assign({}, state, { newUser })
);

export default reduceReducers<IState, IAction>(
	usersReducer,
	userUpdateReducer,
	newUserReducer
);
