import { OK } from "../../Models/apiConstants";
import IAction from "../../Actions/IAction";
import * as Dto from "../../Models/dto";
import { AsyncState } from "../../Models/IAsync";
import keyBy from "lodash/keyBy";
import {
	GetLegalDocumentTypesForClientProfileAction
} from "../../Actions/Api/legalDocumentActions";
import { ApproveAction } from "../../Actions/Api/claimPackageReviewActions";
import getLoadState from '../../Utils/getLoadState';

type ById = { [legalDocTypeId: number]: Dto.ILegalDocumentType };

export interface IState {
	byId: { [legalDocTypeId: number]: Dto.ILegalDocumentType };
	loading: AsyncState | null;
}

function updateDocumentTypeMap(previousMap: ById, documentTypes: Dto.ILegalDocumentType[]) {
	const newMap = keyBy(documentTypes, it => it.legalDocTypeId);
	return Object.assign({}, previousMap, newMap);
}

const INITIAL_STATE: IState = {
	byId: {},
	loading: null
};

export default function legalDocumentsReducer(
	previousState: IState = INITIAL_STATE,
	action: IAction
) {
	if (action instanceof GetLegalDocumentTypesForClientProfileAction) {
		let loading = action.state;
		if (action.state === AsyncState.Resolved && action.response!.status !== OK) {
			loading = AsyncState.Rejected;
		}

		let { byId } = previousState;
		if (loading === AsyncState.Resolved) {
			byId = updateDocumentTypeMap(byId, action.response!.items);
		}

		return {
			...previousState,
			loading,
			byId
		};
	}

	if (action instanceof ApproveAction && getLoadState(action) === AsyncState.Resolved) {
		return INITIAL_STATE;
	}

	return previousState;
}