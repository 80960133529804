function stringifyCookie(value: { [key: string]: string }) {
	return encodeURIComponent(JSON.stringify(value))
		.replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);
}

function parseCookie(value: string) {
	return JSON.parse(decodeURIComponent(value)) as { [key: string]: string };
}

const COOKIE = "localStorage=";
const rawCookie = document.cookie.split(";").map(it => it.trim()).find(it => it.startsWith(COOKIE));
let cookie: { [key: string]: string } = rawCookie == null ? {} : parseCookie(rawCookie.substr(COOKIE.length));

for (const key of Object.keys(cookie)) {
	// try and write any cookie values to localStorage,
	// will eliminate the cookie if possible
	setStringValue(key, cookie[key]);
}

function updateCookie() {
	let value = "";
	let maxAge = -1;

	if (Object.keys(cookie).length > 0) {
		value = stringifyCookie(cookie);
		maxAge = 10 * 365 * 24 * 60 * 60;
	}

	document.cookie = `${COOKIE}${value}; max-age=${maxAge}`;
}

export function get<T>(key: string, valueIfMissing?: T) {
	let stringValue: string | null | undefined;
	if (key in cookie) {
		stringValue = cookie[key];
	} else {
		try {
			stringValue = localStorage.getItem(key);
		} catch (error) {
			if (__DEV__) {
				console.warn(error);
			}
		}
	}

	if (stringValue == null) {
		return valueIfMissing;
	}

	return JSON.parse(stringValue) as T;
}

function setStringValue(key: string, stringValue: string) {

	let success = true;
	try {
		localStorage.setItem(key, stringValue);
	} catch (error) {
		if (__DEV__) {
			console.warn(error);
		}

		success = false;
	}

	if (!success) {
		cookie[key] = stringValue;
		updateCookie();
	} else if (key in cookie) {
		delete cookie[key];
		updateCookie();
	}
}

export function set<T>(key: string, value: T) {
	if (value == null) {
		remove(key);
		return;
	}

	setStringValue(key, JSON.stringify(value));
}

export function remove(key: string) {
	if (key in cookie) {
		delete cookie[key];
		updateCookie();
	} else {
		try {
			localStorage.removeItem(key);
		} catch (error) {
			if (__DEV__) {
				console.warn(error);
			}
		}
	}
}

export function clear() {
	if (Object.keys(cookie).length > 0) {
		cookie = {};
		updateCookie();
	}

	try {
		localStorage.clear();
	} catch (error) {
		if (__DEV__) {
			console.warn(error);
		}
	}
}