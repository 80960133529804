import BusyOverlay from "../Common/BusyOverlay/BusyOverlay";
import connect from "../../Utils/connect";
import ReduxStore from "../../Stores/ReduxStore";
import connectToCaptcha, { ICaptchaProps } from "../../Utils/connectToCaptcha";
import React from "react";
import LoginActionCreators, { login, logout } from "../../ActionCreators/LoginActionCreators";
import Captcha from "../Captcha/Captcha";
import cn from "classnames";
import AuthPage, { FormGroup } from "../Auth/AuthPage/AuthPage";
import AuthButton from "../Auth/AuthButton/AuthButton";
import Button from "../Common/Button/Button";
import AuthHeader from "../Auth/AuthHeader/AuthHeader";

interface IOwnProps { }

interface IConnectedProps {
	password: string;
	locking: boolean;
	unlocking: boolean;
	onChange(password: string): void;
	onUnlock(): void;
	onLogout(): void;
}

interface IProps extends IOwnProps, ICaptchaProps, IConnectedProps { }

class LockView extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);

		this._handlePasswordChange = this._handlePasswordChange.bind(this);
		this._handleSubmit = this._handleSubmit.bind(this);
	}

	render() {
		const { props } = this;

		const passwordValid = props.password != null && props.password.length > 0;

		return (
			<>
				<BusyOverlay
					message={
						props.locking ? "Locking..."
							: props.unlocking ? "Initializing..."
								: null
					}
					showWhenLocked
				/>
				<AuthPage onSubmit={this._handleSubmit}>
					<AuthHeader>Session Locked</AuthHeader>

					<FormGroup>
						Your session has been locked. Please provide your credentials to unlock your session and continue.
					</FormGroup>
					<FormGroup label="Password">
						{id => (
							<input
								type="password"
								className={cn("form-control", { errorInput: !passwordValid })}
								id={id}
								maxLength={50}
								value={props.password}
								onChange={this._handlePasswordChange}
								autoComplete="current-password"
							/>
						)}
					</FormGroup>
					<FormGroup>
						<Captcha />
					</FormGroup>
					<FormGroup>
						<AuthButton disabled={!passwordValid || !props.captchaValid || props.locking || props.unlocking}>
							Unlock
						</AuthButton>
						{" "}
						<Button
							onClick={props.onLogout}
							disabled={props.locking || props.unlocking}
						>
							Logout
						</Button>
					</FormGroup>
				</AuthPage>
			</>
		);

		// return (
		// 	<Form
		// 		className={styles.container}
		// 		onSubmit={this._handleSubmit}
		// 	>
		// 		<BusyOverlay
		// 			message={
		// 				props.locking ? "Locking..."
		// 					: props.unlocking ? "Initializing..."
		// 						: null
		// 			}
		// 			showWhenLocked
		// 		/>
		// 		<div className="panel panel-default">
		// 			<div className="panel-heading"><strong>Unlock Session</strong></div>
		// 			<div className="panel-body">
		// 				<AuthForm>
		// 					<FormGroup>
		// 						Your session has been locked. Please provide your credentials to unlock your session and continue.
		// 					</FormGroup>
		// 					<FormGroup label="Password">
		// 						{id => (
		// 							<input
		// 								type="password"
		// 								className={cn("form-control", { errorInput: !passwordValid })}
		// 								id={id}
		// 								maxLength={50}
		// 								value={props.password}
		// 								onChange={this._handlePasswordChange}
		// 								autoComplete="current-password"
		// 							/>
		// 						)}
		// 					</FormGroup>
		// 					<FormGroup>
		// 						<Captcha />
		// 					</FormGroup>
		// 				</AuthForm>
		// 			</div>
		// 			<div className="panel-footer btn-footer">
		// 				<button
		// 					type="submit"
		// 					className="btn btn-warning"
		// 					disabled={!passwordValid || !props.captchaValid || props.locking || props.unlocking}
		// 				>
		// 					Unlock
		// 				</button>
		// 				<button
		// 					type="button"
		// 					className="btn btn-default"
		// 					onClick={props.onLogout}
		// 					disabled={props.locking || props.unlocking}
		// 				>
		// 					Logout
		// 				</button>
		// 			</div>
		// 		</div>
		// 	</Form>
		// );
	}

	private _handlePasswordChange(event: React.FormEvent<HTMLElement>) {
		const { value } = event.currentTarget as HTMLInputElement;
		this.props.onChange(value);
	}

	private _handleSubmit(event: React.FormEvent<HTMLElement>) {
		event.preventDefault();
		this.props.onUnlock();
	}
}

const ConnectedToCaptcha = connectToCaptcha<IOwnProps & IConnectedProps>(LockView);

const onChange = (password: string) => {
	const { loginUser } = ReduxStore.getState().auth;
	LoginActionCreators.loginUserUpdated(Object.assign({}, loginUser, { password }));
};

export default connect<IConnectedProps, IOwnProps>(
	ConnectedToCaptcha,
	state => ({
		password: state.auth.loginUser.password,
		unlocking: state.auth.authenticating,
		locking: state.auth.loggingOut,
		onChange,
		onUnlock: login,
		onLogout: logout
	})
);