import { ERROR, OK } from "../Models/apiConstants";
import { startBusy } from "./AppActionCreators";
import handleGenericError from "../Utils/handleGenericError";
import handleApiResponse from "../Utils/handleApiResponse";
import isEmpty from "../Utils/isEmpty";
import { clientLogout, doLogin, changePassword as changePasswordApi } from "./Api/loginActionCreators";
import Dispatcher from "../Dispatcher/Dispatcher";
import RememberMeAction from "../Actions/Login/RememberMeAction";
import UserLockedOutAction from "../Actions/Login/UserLockedOutAction";
import LoginUserUpdatedAction from "../Actions/Login/LoginUserUpdatedAction";
import EditCaptchaAction from "../Actions/Login/EditCaptchaAction";
import InvalidateCaptchaAction from "../Actions/Login/InvalidateCaptchaAction";
import ReduxStore from "../Stores/ReduxStore";
import { getCaptchaChallengeAndImage } from "../ActionCreators/Api/captchaActionCreators";
import { AsyncState } from "../Models/IAsync";
import * as toast from "../Utils/toast";
import * as Dto from "../Models/dto";
import { getLoginUser } from "./Api/startupActionCreators";

class LoginActionCreators {
	loginUserUpdated(data: Dto.ILoginUser): void {
		Dispatcher.dispatch(new LoginUserUpdatedAction(data));
	}

	setUserRememberMePreference(rememberMe: boolean): void {
		Dispatcher.dispatch(new RememberMeAction(rememberMe));
	}
}

export const showInvalidCaptchaMessage = () => toast.error("The verification text is invalid. Please correct the field to continue.");

const showInvalidMessage = () => toast.error("The username or password entered is invalid. Please correct the fields to continue.");
const showInvalidIPMessage = () => toast.error("Login not allowed from current IP Address.");

export async function login() {
	const { auth: { loginUser }, ui: { captcha: { challenge, response } } } = ReduxStore.getState();

	try {
		const data = await doLogin(loginUser, response, challenge!);
		let success = false;

		handleApiResponse(data, {
			[OK]: () => { success = true; },
			[ERROR]: {
				"LOGIN_INCORRECT_CAPTCHA": showInvalidCaptchaMessage,
				"LOGIN_LOCKED_OUT": showInvalidMessage,
				"Invalid IP": showInvalidIPMessage,
				"*": showInvalidMessage
			}
		});

		if (success && !data.item.forceChangePassword) {
			await postAuth();
			toast.success(`Login Successful. Welcome ${loginUser.userName}!`, {timeout: 2000})
		}
	} catch (error) {
		handleGenericError(error);
	}
}

export async function postAuth() {
	const loginUserResponse = await getLoginUser();
	handleApiResponse(loginUserResponse);
}

export async function lock() {
	Dispatcher.dispatch(new UserLockedOutAction());
	try {
		await clientLogout();
	} catch (error) {
		if (__DEV__) {
			console.warn(error);
		}
	}
}

export async function logout() {
	startBusy("Logging out...", true);

	try {
		await clientLogout();
	} catch (error) {
		if (__DEV__) {
			console.warn(error);
		}
	}

	window.location.reload();
	// TODO, do something to force the login screen after the refresh, in case the actual logout failed
}

export const passwordChangeSuccessMessage = "Congratulations! Your password has been changed. You will receive a confirmation email to verify your password change.";

export async function changePassword() {
	const { auth: { loginUser }, ui: { captcha: { challenge, response } } } = ReduxStore.getState();

	try {
		const data = await changePasswordApi(loginUser, response, challenge!);

		if (data.status === OK) {
			if (isEmpty(loginUser.resetToken)) { // reset token case handled in reducer
				await postAuth();
				toast.success(passwordChangeSuccessMessage);
			}

			return;
		}

		if (data.statusMessage.startsWith("PREVIOUS_PASSWORD_USED")) {
			const numPasswords = data.statusMessage.replace("PREVIOUS_PASSWORD_USED:", "");
			toast.error(`Your company's settings prevent the use of the last ${numPasswords} passwords. Please choose another password.`);
			return;
		}

		switch (data.statusMessage) {
			case "LOGIN_INCORRECT_CAPTCHA":
				showInvalidCaptchaMessage();
				break;
			case "LOGIN_NEWPASSWORD_SAME_ERROR":
				toast.error("Unable to update your password. The new password does not meet the complexity and history requirements. Please choose another password.");
				break;
			case "INVALID_RESET_TOKEN":
				break;
			default:
				toast.error("Please validate the fields to continue.");
				break;
		}
	} catch (error) {
		handleGenericError(error);
	}
}

export function editCaptcha(value: string) {
	Dispatcher.dispatch(new EditCaptchaAction(value));
}

export function refreshCaptcha() {
	if (ReduxStore.getState().ui.captcha.loading !== AsyncState.Pending) {
		getCaptchaChallengeAndImage();
	}
}

export function invalidateCaptcha() {
	const { challenge } = ReduxStore.getState().ui.captcha;
	Dispatcher.dispatch(new InvalidateCaptchaAction(challenge!));
}

export default new LoginActionCreators();
