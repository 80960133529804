import ActionLogEntry from "../Log/ActionLogEntry";
import moment from "moment";

export class ActionBase implements IAction {
	private __name__: string;
	timestamp = moment().toISOString();

	constructor(name: string) {
		this.__name__ = name;
	}

	toLogEntry() {
		return ActionLogEntry.create(this, this.__name__, nameof(this.__name__));
	}
}

/**
 * Interface for all actions
 */
interface IAction {
	/**
	 * Converts the action to a loggable object.
	 */
	toLogEntry(): ActionLogEntry;
}
export default IAction;